import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import OrgProfileSectionHead from "../../components/OrgProfileSectionHead/OrgProfileSectionHead";


const OrgProfileInfo = ({ profileForm, onFinish, loading, orgData }) => {

    useEffect(() => {
        if (orgData) {
            profileForm.setFieldsValue({
                org_name: orgData?.name,
                org_website: orgData?.website,
                org_bio: orgData?.bio,
                org_description: orgData?.description,
            })
        }
    }, [orgData, profileForm]);

    return (
        <div className="org-profile-section org-profile-info-container">
            <OrgProfileSectionHead
                title="About the organizer"
                description="Let attendees know who is hosting events."
            />
            <div className="org-profile-info-content">
                <Form form={profileForm} layout="vertical" requiredMark={true} style={{ width: 500 }}
                      onFinish={onFinish}>
                    <Form.Item
                        name="org_name"
                        label="Organizer name"
                        rules={[
                            {
                                required: true,
                                message: 'Missing required information'
                            }
                        ]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="org_website"
                        label="Organizer website">
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="org_bio"
                        label="Organizer bio">
                        <Input.TextArea/>
                    </Form.Item>

                    <Form.Item
                        name="org_description"
                        label="Organizer description">
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item>
                        <Button loading={loading} htmlType={'submit'} type="primary">Save profile</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default OrgProfileInfo;
