import { PhoneSlash } from "@phosphor-icons/react";
import { Constants, useMeeting } from "@videosdk.live/react-sdk";
import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { processRecording } from "../../../../../../common/redux/actions/recordingAction";
import { setIsMeetingLeft } from "../../../../../../common/redux/slice/videosdkSlice";

const LeaveButton = ({ meetingMode }) => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { leave, end } = useMeeting();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { isMeetingLeft, meetingId } = useSelector((state) => state.videosdk);
    const showModal = () => setIsModalOpen(true);

    const handleOk = () => {
        setIsModalOpen(false);
        handleLeave();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isMeetingLeft) {
            if (meetingMode === Constants.modes.VIEWER) {
                navigate(`/events/${eventId}/feedback`);
            } else {
                navigate(`/organization/manage/events/${eventId}/host-online-event`);
            }
        }
        // eslint-disable-next-line
    }, [isMeetingLeft]);

    const handleLeave = () => {
        if (meetingMode === Constants.modes.VIEWER) {
            dispatch(setIsMeetingLeft(true));
            leave();
        } else {
            dispatch(setIsMeetingLeft(true));
            dispatch(processRecording({
                eventId: eventId,
                meetingCode: meetingId
            }));
            end();
        }
    }

    return <>
        <Button
            danger={true}
            size="large"
            type="primary"
            icon={<PhoneSlash size={24} weight="fill"/>}
            onClick={showModal}
        >
            End
        </Button>
        <Modal
            title={meetingMode === Constants.modes.VIEWER ? "Confirm leave this event?" : "Do you want to end this event?"}
            open={isModalOpen}
            centered={true}
            onOk={handleOk}
            onCancel={handleCancel}
        >
        </Modal>
    </>
};

export default LeaveButton;