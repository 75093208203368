import { combineReducers } from 'redux';
import accountReducer from "../slice/accountSlice";
import addressReducer from "../slice/addressSlice";
import authReducer from '../slice/authSlice';
import bankReducer from "../slice/bankSlice";
import cartReducer from "../slice/cartSlice";
import channelReducer from "../slice/channelSlice";
import checkinReducer from "../slice/checkinSlice";
import contentReducer from "../slice/contentSlice";
import eventReducer from '../slice/eventSlice';
import feedbackReducer from "../slice/feedbackSlice";
import mediaReducer from "../slice/mediaSlice";
import meetingReducer from "../slice/meetingSlice";
import messageReducer from "../slice/messageSlice";
import notificationReducer from "../slice/notificationSlice";
import orderReducer from "../slice/orderSlice";
import organizationReducer from "../slice/organizationSlice";
import paymentReducer from "../slice/paymentSlice";
import recordingReducer from "../slice/recordingSlice";
import reportReducer from "../slice/reportSlice";
import searchReducer from "../slice/searchSlice";
import statisticReducer from "../slice/statisticSlice";
import threadReducer from "../slice/threadSlice";
import ticketReducer from "../slice/ticketSlice";
import transactionReducer from "../slice/transactionSlice";
import videosdkReducer from "../slice/videosdkSlice";
import voucherReducer from "../slice/voucherSlice";
import asyncReducer from "../slice/asyncSlice";

const rootReducer = combineReducers({
    auth: authReducer,
    event: eventReducer,
    channel: channelReducer,
    search: searchReducer,
    account: accountReducer,
    address: addressReducer,
    media: mediaReducer,
    organization: organizationReducer,
    ticket: ticketReducer,
    voucher: voucherReducer,
    cart: cartReducer,
    payment: paymentReducer,
    thread: threadReducer,
    message: messageReducer,
    videosdk: videosdkReducer,
    content: contentReducer,
    meeting: meetingReducer,
    report: reportReducer,
    notification: notificationReducer,
    recording: recordingReducer,
    feedback: feedbackReducer,
    bank: bankReducer,
    order: orderReducer,
    statistic: statisticReducer,
    transaction: transactionReducer,
    checkin: checkinReducer,
    async: asyncReducer,
});

export default rootReducer;
