export const eventTimeItems = [
    {
        key: 'any_day',
        value: 'any_day',
        label: <p>Any day</p>,
    },
    {
        key: 'today',
        value: 'today',
        label: <p>Today</p>,
    },
    {
        key: 'tomorrow',
        value: 'tomorrow',
        label: <p>Tomorrow</p>,
    },
    {
        key: 'this_week',
        value: 'this_week',
        label: <p>This week</p>,
    },
    {
        key: 'this_month',
        value: 'this_month',
        label: <p>This month</p>,
    },
];

export const eventFormatItems = [
    {
        key: 'any_format',
        value: 'any_format',
        label: <p>Any format</p>,
    },
    {
        key: 'online',
        value: 'online',
        label: <p>Online</p>,
    },
    {
        key: 'offline',
        value: 'offline',
        label: <p>Offline</p>,
    }
]

export const eventPriceItems = [
    {
        key: 'any_price',
        value: 'any_price',
        label: <p>Any price</p>,
    },
    {
        key: 'free',
        value: 'free',
        label: <p>Free</p>,
    },
    {
        key: 'paid',
        value: 'paid',
        label: <p>Paid</p>,
    }
]

export const eventCategoryItems = [
    {
        key: 1,
        value: "Art & Culture",
        label: <p>Art & Culture</p>,
    },
    {
        key: 2,
        value: "Career",
        label: <p>Career</p>,
    },

    {
        key: 3,
        value: "Business",
        label: <p>Business</p>,
    },
    {
        key: 4,
        value: "Game",
        label: <p>Game</p>,
    },
    {
        key: 5,
        value: "Health",
        label: <p>Health</p>,
    },
    {
        key: 6,
        value: "Technology",
        label: <p>Technology</p>,
    },
    {
        key: 7,
        value: "Travel",
        label: <p>Travel</p>,
    },
    {
        key: 8,
        value: "Cars",
        label: <p>Cars</p>,
    },
    {
        key: 9,
        value: "Daily life",
        label: <p>Daily life</p>,
    },
    {
        key: 10,
        value: "Sport",
        label: <p>Sport</p>,
    },
    {
        key: 11,
        value: "Education",
        label: <p>Education</p>,
    },
    {
        key: 12,
        value: "Music",
        label: <p>Music</p>,
    },
    {
        key: 13,
        value: "Social activities",
        label: <p>Social activities</p>,
    },
    {
        key: 14,
        value: "Community",
        label: <p>Community</p>,
    },
    {
        key: 15,
        value: "Environment",
        label: <p>Environment</p>,
    },
    {
        key: 16,
        value: "Dance",
        label: <p>Dance</p>,
    },
    {
        key: 17,
        value: "Exhibition",
        label: <p>Exhibition</p>,
    },
]