import React from 'react';
import './style.scss';

function Highlight(props) {
    const { name, imgSrc, category = [] } = props;

    return (
        <div className="highlightItemContainer">
            <div className="imageHolder">
                <img src={imgSrc} alt=""/>
            </div>
            <div className="highlightText">
                <div className="highlightName">{name}</div>
                <div className="highlightCategory">{category.join(', ')}</div>
            </div>
        </div>
    );
}

export default Highlight;
