import { CalendarDots } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventsByFollowingOrganizations } from "../../../../common/redux/actions/eventActions";
import EventListHorizontal from "../../components/EventListHorizontal/EventListHorizontal";
import "./EventListOfFollowingOrgs.scss";
import Loading from "../Loading/Loading";
import NoDataFound from "../NoDataFound";

const EventListOfFollowingOrgs = () => {
    const dispatch = useDispatch();
    const { eventsOfFollowingOrgs, loading } = useSelector((state) => state.event);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const username = user?.id;
        if (username) {
            const params = {
                username: username,
                startDate: null,
                endDate: null,
                categories: [],
                eventType: null,
                sortBy: "startDateTime",
                sortOrder: "ASC",
                pageSize: 14,
                pageNumber: 1
            };
            dispatch(getEventsByFollowingOrganizations(params));
        }
    }, [user, dispatch]);

    return (
        <>
            {eventsOfFollowingOrgs && <div className="home-section-header">
                <div className="home-section-title">
                    From your following organizers
                </div>
            </div>}
            <div className="events-of-fl-orgs-container">
                {
                    loading || !eventsOfFollowingOrgs
                        ? <Loading/>
                        : (
                            eventsOfFollowingOrgs?.length > 0
                                ? <EventListHorizontal events={eventsOfFollowingOrgs}/>
                                : <NoDataFound icon={<CalendarDots size={60}/>} message="No data found!"/>
                        )
                }
            </div>
        </>
    );
};

export default EventListOfFollowingOrgs;
