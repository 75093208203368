import axiosInstance from "../api";

const recordingService = {
    getHostRecordByEventId: async (params) => {
        try {
            return await axiosInstance.get(
                "/api/v1/host/getRecordByEventId",
                { params: params });
        } catch (error) {
            console.error("Error get host recording by eventId:", error);
            throw error;
        }
    },
    getRecordByEventId: async (params) => {
        try {
            return await axiosInstance.get(
                "/api/v1/audience/getRecordByEventId",
                { params: params });
        } catch (error) {
            console.error("Error get recording by eventId:", error);
            throw error;
        }
    },
    updateRecording: async (payload) => {
        try {
            return await axiosInstance.post(
                "/api/v1/host/updateRecording",
                payload);
        } catch (error) {
            console.error("Error update recording:", error);
            throw error;
        }
    },
    updateRecordingPrivacy: async (data) => {
        try {
            return await axiosInstance.post("/api/v1/host/publishOrUnpublishRecording", data);
        } catch (error) {
            console.error("Error update recording privacy:", error);
            throw error;
        }
    },
    processRecording: async (payload) => {
        try {
            return await axiosInstance.post("/api/v1/host/processRecording", payload);
        } catch (error) {
            console.error("Error process recording:", error);
            throw error;
        }
    },
    searchScenes: async (payload) => {
        try {
            return await axiosInstance.post("/api/v1/search/searchScenes", payload);
        } catch (error) {
            console.error("Error search scenes:", error);
            throw error;
        }
    },
    applySceneSearch: async (payload) => {
        try {
            return await axiosInstance.post("/api/v1/host/applySceneSearch", payload);
        } catch (error) {
            console.error("Error apply scene search:", error);
            throw error;
        }
    },
    updateExternalRecording: async (payload) => {
        try {
            return await axiosInstance.post("/api/v1/host/updateExternalRecording", payload);
        } catch (error) {
            console.error("Error update external recording:", error);
            throw error;
        }
    },
    scheduleSession: async (payload) => {
        try {
            return await axiosInstance.post("/api/v1/host/scheduleSession", payload);
        } catch (error) {
            console.error("Error schedule session:", error);
            throw error;
        }
    },
};

export default recordingService;