import { createSlice } from "@reduxjs/toolkit";
import { VoucherModel } from "../../models/voucherModel.model";
import {
    createVoucher,
    deleteVoucherById,
    getVoucherByEventId,
    getVouchersByEventIdForAttendee,
    updateVoucher,
    verifyVoucher
} from "../actions/voucherAction";

const voucherSlice = createSlice({
    name: 'voucher',
    initialState: VoucherModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createVoucher.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createVoucher.fulfilled, (state, action) => {
                state.loading = false;
                state.voucherId = action.payload;
            })
            .addCase(createVoucher.rejected, (state, action) => {
                state.loading = false;
                state.voucher = null;
                state.error = action.payload;
            })
            .addCase(getVoucherByEventId.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getVoucherByEventId.fulfilled, (state, action) => {
                state.loading = false;
                state.vouchers = action.payload;
            })
            .addCase(getVoucherByEventId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteVoucherById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteVoucherById.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload === true) {
                    state.vouchers = state.vouchers.filter((voucher) => voucher.id !== action.meta.arg.voucherId)
                }
            })
            .addCase(deleteVoucherById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(verifyVoucher.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(verifyVoucher.fulfilled, (state, action) => {
                state.loading = false;
                state.voucher = action.payload;
            })
            .addCase(verifyVoucher.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateVoucher.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateVoucher.fulfilled, (state, action) => {
                state.loading = false;
                state.voucherId = action.meta.arg.code;
            })
            .addCase(updateVoucher.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getVouchersByEventIdForAttendee.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getVouchersByEventIdForAttendee.fulfilled, (state, action) => {
                state.loading = false;
                state.vouchers = action.payload;
            })
            .addCase(getVouchersByEventIdForAttendee.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});
export default voucherSlice.reducer;
