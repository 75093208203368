import { SquaresFour } from "@phosphor-icons/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { FOLLOWING_ORG } from "../../../../../common/constants/LocalStorageConstants";
import {
    getFollowingOrganizationIds,
    getFollowingOrganizations
} from "../../../../../common/redux/actions/accountAction";
import Loading from "../../../../modules/components/Loading/Loading";
import NoDataFound from "../../../../modules/components/NoDataFound";
import OrganizationList from "../../../../modules/components/OrganizationList/OrganizationList";
import "./UserFollowing.scss";

const UserFollowing = () => {
    const { setActiveItem } = useOutletContext();
    const dispatch = useDispatch();
    const [followOrgList, setFollowOrgList] = useState([]);
    const { followingOrganizations: followOrgs, loading } = useSelector((state) => state.account);
    const { user } = useSelector((state) => state.auth);

    useLayoutEffect(() => {
        document.title = "User - Following organizations";
        setActiveItem("following-organizations");
    });

    useEffect(() => {
        const username = user?.id;
        if (username) {
            const params = { username: username };
            dispatch(getFollowingOrganizations({ ...params, pageNumber: 1, pageSize: 15 }));
            dispatch(getFollowingOrganizationIds(params));
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (followOrgs) {
            const followOrgIds = followOrgs.map(item => item?.id);
            localStorage.setItem(FOLLOWING_ORG, followOrgIds);
            setFollowOrgList(followOrgs);
        }
    }, [followOrgs]);

    return (
        <div className="user-following-org-container">
            <div className="user-following-org-head">Following organization</div>
            {loading || !followOrgList
                ? <Loading/>
                : ((followOrgList && followOrgList.length > 0)
                        ? <OrganizationList organizations={followOrgList}/>
                        : <NoDataFound
                            icon={<SquaresFour size={60}/>}
                            message="You haven't followed any organizations yet!"
                        />
                )}
        </div>
    );
};

export default UserFollowing;