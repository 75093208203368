import { PaperPlaneRight } from "@phosphor-icons/react";
import { usePubSub } from "@videosdk.live/react-sdk";
import { Button, Input } from "antd";
import { useRef, useState } from "react";
import "./ChatInput.scss";

const ChatInput = () => {
    const [message, setMessage] = useState("");
    const { publish } = usePubSub("CHAT");
    const input = useRef();

    const handleSendMessage = () => {
        const messageText = message.trim();
        if (messageText.length > 0) {
            publish(messageText, { persist: true });
            setTimeout(() => {
                setMessage("");
            }, 100);
            input.current?.focus();
        }
    }
    return (
        <div className="chat-input-container">
            <Input
                ref={input}
                className="chat-input-message"
                placeholder="Write your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onPressEnter={handleSendMessage}
            />
            <Button
                className="chat-input-button"
                disabled={message.length === 0}
                htmlType="submit"
                type="text"
                icon={<PaperPlaneRight weight="fill"/>}
                onClick={handleSendMessage}
            />
        </div>
    );
};

export default ChatInput;