import axiosInstance from "../api";

const feedbackService = {
    getFeedbackForEventOfUser: async (params) => {
        try {
            return await axiosInstance.get(
                "/api/v1/audience/getFeedbackByEventId",
                { params: params });
        } catch (error) {
            console.error("Error get feedback for event of user:", error);
            throw error;
        }
    },
    createEventFeedback: async (request) => {
        try {
            return await axiosInstance.post(
                "/api/v1/audience/createEventFeedback", request);
        } catch (error) {
            console.error("Error create event feedback:", error);
            throw error;
        }
    },
    getAllEventFeedbacks: async (eventId) => {
        try {
            return await axiosInstance.get(
                "/api/v1/host/getAllEventFeedbacks", {params: eventId});
        } catch (error) {
            console.error("Error create event feedback:", error);
            throw error;
        }
    }
};

export default feedbackService;