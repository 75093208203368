import supabase from "../supbaseClient";

const supabaseService = {
    loginWithPassword: async (credentials) => {
        try {
            return await supabase.auth.signInWithPassword(credentials);
        } catch (error) {
            throw error;
        }
    },
    logout: async () => {
        try {
            return await supabase.auth.signOut();
        } catch (error) {
            throw error;
        }
    },
    register: async (credentials) => {
        try {
            const { data, error } = await supabase.auth.signUp(credentials);
            return { data, error };
        } catch (error) {
            throw error;
        }
    },
    sendResetEmail: async (email, updatePasswordUrl) => {
        try {
            return await supabase.auth.resetPasswordForEmail(email, { redirectTo: updatePasswordUrl })
        } catch (error) {
            console.error(error);
            throw error
        }
    },
    updatePassword: async (newPassword) => {
        try {
            return await supabase.auth.updateUser({
                password: newPassword
            })
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    fetchMessagesForThread: async (threadId) => {
        try {
            return await supabase
                .from('message')
                .select('*')
                .eq('thread', threadId)
                .order('created_at', { ascending: true });
            
        } catch (error) {
            console.error(error)
            throw error;
        }
    },
    sendMessage: async (data) => {
        try {
            return await supabase
                .from('message')
                .insert(data)
                .select()
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    createNewThread: async (threadInfo) => {
        try {
            const { data, error } = await supabase
                .from('thread')
                .insert(threadInfo)
                .select()
                .single();
            if (!data) {
                return error
            }
            return data?.id;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    fetchThreadsForUser: async (username) => {
        try {
            return await supabase
                .from("thread")
                .select("*")
                .eq("user", username)
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    fetchThreadsForOrg: async (orgId) => {
        try {
            return await supabase
                .from("thread")
                .select("*")
                .eq("groupAdmin", orgId)
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    checkThread: async ({ orgId, username }) => {
        try {
            return await supabase
                .from("thread")
                .select("id")
                .eq("groupAdmin", orgId)
                .eq("user", username);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    loginWithGoogle: async () => {
        try {
            return await supabase.auth.signInWithOAuth({ provider: 'google' });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
};

export default supabaseService;
