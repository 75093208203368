import { createAsyncThunk } from '@reduxjs/toolkit';
import addressService from "../../services/addressService";
import { GET_PROVINCES_DISTRICTS } from "../types/address";

export const getProvincesDistricts = createAsyncThunk(
    GET_PROVINCES_DISTRICTS,
    async (data, { rejectWithValue }) => {
        try {
            return await addressService.getProvincesDistricts(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
