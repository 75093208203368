import { CurrencyCircleDollar, Eye, ShoppingBag, Star, Ticket } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import "./OrganizationEventDashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEventStatistic } from "../../../common/redux/actions/statisticAction";
import RecentOrdersEvent from "./partials/RecentOrdersEvent/RecentOrdersEvent";
import SalesByTicketType from "./partials/SalesByTicketType/SalesByTicketType";
import SalesByVoucher from "./partials/SalesByVoucher/SalesByVoucher";

let eventStatisticItems = [
    {
        key: "orders",
        icon: <ShoppingBag size={24} weight="fill"/>,
        title: "Orders",
        value: 0,
    },
    {
        key: "netSale",
        icon: <CurrencyCircleDollar size={24} weight="fill"/>,
        title: "Net Sales",
        value: 0,
    },
    {
        key: "ticketSold",
        icon: <Ticket size={24} weight="fill"/>,
        title: "Ticket Sold",
        value: 0,
    },
    {
        key: "views",
        icon: <Eye size={24} weight="fill"/>,
        title: "Views",
        value: 0,
    },
    {
        key: "rating",
        icon: <Star size={24} weight="fill"/>,
        title: "Rating",
        value: 0,
    }
]

const OrganizationEventDashboard = () => {
    const [eventIndicators, setEventIndicators] = useState();
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const { eventStatistic } = useSelector((state) => state.statistic);

    useEffect(() => {
        if (eventId) {
            dispatch(getEventStatistic({ eventId: eventId }));
        }
    }, [eventId, dispatch]);

    useEffect(() => {
        if (eventStatistic) {
            const netSale = eventStatistic.netSale;
            const orders = eventStatistic.order.volume;
            const { views, ticketSold, rating } = eventStatistic.events;

            let rateQty = 0;
            let rateTotal = 0;

            Object.keys(rating).forEach(key => {
                const value = rating[key];
                rateQty += value;
                rateTotal += key * value;
            });

            const avgRating = rateQty > 0
                ? Math.round(rateTotal / rateQty * 100) / 100
                : 0;

            let _netSale = `${netSale.toLocaleString()} VND`;
            let indicators = {
                netSale: _netSale,
                orders: orders,
                views: views,
                ticketSold,
                rating: avgRating,
            };

            eventStatisticItems.forEach((item) => {
                if (indicators.hasOwnProperty(item.key)) {
                    item.value = indicators[item.key];
                }
            });
            setEventIndicators(eventStatisticItems);
        }
    }, [eventStatistic]);

    return (
        <div className="org-event-dashboard-container">
            <div className="org-event-dashboard-head">
                <div className="org-event-dashboard-head-message">
                    Event Dashboard
                </div>
                <div className="org-event-dashboard-head-content">
                    <div className="org-event-dashboard-statistic">
                        {
                            eventIndicators && eventIndicators.map((item) => (
                                <div key={item.key}
                                     className="org-event-dashboard-card org-event-dashboard-statistic-card">
                                    <div className="org-event-dashboard-statistic-card-icon">
                                        {item.icon}
                                    </div>
                                    <div className="org-event-dashboard-statistic-card-content">
                                        <div className="org-event-dashboard-statistic-card-title">
                                            {item.title}
                                        </div>
                                        <div className="org-event-dashboard-statistic-card-value">
                                            {item.value}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="org-event-dashboard-main-content">
                {
                    eventStatistic && <div className="event-dashboard-section">
                        <div className="event-dashboard-section-title">
                            Sales by Ticket types
                        </div>
                        <div className="event-dashboard-section-content">
                            <SalesByTicketType tickets={eventStatistic?.byTickets}/>
                        </div>
                    </div>
                }
                {
                    eventStatistic?.order?.orders && <div className="event-dashboard-section">
                        <div className="event-dashboard-section-title">
                            Recent orders
                        </div>
                        <div className="event-dashboard-section-content">
                            <RecentOrdersEvent orders={eventStatistic?.order?.orders}/>
                        </div>
                    </div>
                }
                {
                    eventStatistic?.byVouchers && <div className="event-dashboard-section">
                        <div className="event-dashboard-section-title">
                            Voucher usage
                        </div>
                        <div className="event-dashboard-section-content">
                            <SalesByVoucher vouchers={eventStatistic.byVouchers}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default OrganizationEventDashboard;
