import { Microphone, MicrophoneSlash, VideoCamera, VideoCameraSlash } from "@phosphor-icons/react";
import { Button } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMic, setSelectedWebcam } from "../../../../../common/redux/slice/videosdkSlice";
import "./DevicePreview.scss";

export function DevicePreview() {
    // eslint-disable-next-line
    const [setting, setSetting] = useState("video");
    // eslint-disable-next-line
    const [{ webcams, mics }, setDevices] = useState({
        devices: [],
        webcams: [],
        mics: [],
    });
    // eslint-disable-next-line
    const [dlgMuted, setDlgMuted] = useState(false);
    const [videoTrack, setVideoTrack] = useState(null);
    // eslint-disable-next-line
    const [settingDialogueOpen, setSettingDialogueOpen] = useState(false);
    const audioTrackRef = useRef();
    const videoTrackRef = useRef();
    const videoPlayerRef = useRef();
    const popupVideoPlayerRef = useRef();

    const [audioTrack, setAudioTrack] = useState(null);
    const webcamOn = useMemo(() => !!videoTrack, [videoTrack]);
    const micOn = useMemo(() => !!audioTrack, [audioTrack]);
    const dispatch = useDispatch();
    const { micOn: micEnabled, webcamOn: webcamEnabled } = useSelector((state) => state.videosdk);

    const _handleToggleWebcam = () => {
        const _handleTurnOffWebcam = () => {
            const videoTrack = videoTrackRef.current;
            if (videoTrack) {
                videoTrack.stop();
                setVideoTrack(null);
            }
        };
        const _handleTurnOnWebcam = () => {
            const videoTrack = videoTrackRef.current;

            if (!videoTrack) {
                getDefaultMediaTracks({ mic: false, webcam: true });
            }
        };

        const videoTrack = videoTrackRef.current;
        if (videoTrack) _handleTurnOffWebcam();
        else _handleTurnOnWebcam();
    };

    const _handleToggleMic = () => {
        const _handleTurnOffMic = () => {
            const audioTrack = audioTrackRef.current;
            if (audioTrack) {
                audioTrack.stop();
                setAudioTrack(null);
            }
        };
        const _handleTurnOnMic = () => {
            const audioTrack = audioTrackRef.current;
            if (!audioTrack) {
                getDefaultMediaTracks({ mic: true, webcam: false });
            }
        };

        const audioTrack = audioTrackRef.current;
        if (audioTrack) _handleTurnOffMic();
        else _handleTurnOnMic();
    };

    const getDefaultMediaTracks = async ({ mic, webcam, firstTime }) => {
        if (mic) {
            try {
                const audioConstraints = { audio: true };

                const stream = await navigator.mediaDevices.getUserMedia(
                    audioConstraints
                );
                const audioTracks = stream.getAudioTracks();
                const audioTrack = audioTracks.length ? audioTracks[0] : null;

                setAudioTrack(audioTrack);
                if (firstTime) {
                    dispatch(setSelectedMic({
                        id: audioTrack?.getSettings()?.deviceId,
                    }));
                }
            } catch (error) {
                console.log("Failed to access microphone", error);
            }
        }

        if (webcam) {
            const videoConstraints = {
                video: {
                    width: 1280,
                    height: 720,
                },
            };

            const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
            const videoTracks = stream.getVideoTracks();

            const videoTrack = videoTracks.length ? videoTracks[0] : null;
            setVideoTrack(videoTrack);
            if (firstTime) {
                dispatch(setSelectedWebcam({
                    id: videoTrack?.getSettings()?.deviceId,
                }));
            }
        }
    };

    async function startMuteListener() {
        const currentAudioTrack = audioTrackRef.current;
        if (currentAudioTrack) {
            if (currentAudioTrack.muted) {
                setDlgMuted(true);
            }
            currentAudioTrack.addEventListener("mute", (ev) => {
                setDlgMuted(true);
            });
        }
    }

    const getDevices = async ({ micEnabled, webcamEnabled }) => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();

            const webcams = devices.filter((d) => d.kind === "videoinput");
            const mics = devices.filter((d) => d.kind === "audioinput");

            const hasMic = mics.length > 0;
            const hasWebcam = webcams.length > 0;

            setDevices({ webcams, mics, devices });

            if (hasMic) startMuteListener();

            getDefaultMediaTracks({
                mic: hasMic && micEnabled,
                webcam: hasWebcam && webcamEnabled,
                firstTime: true,
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        try {
            audioTrackRef.current = audioTrack;
            startMuteListener();
            return () => {
                const currentAudioTrack = audioTrackRef.current;
                currentAudioTrack && currentAudioTrack.stop();
                audioTrackRef.current = null;
            };
        } catch (error) {
            console.log("Error in audio track", error);
        }
    }, [audioTrack]);

    useEffect(() => {
        videoTrackRef.current = videoTrack;

        var isPlaying =
            videoPlayerRef.current.currentTime > 0 &&
            !videoPlayerRef.current.paused &&
            !videoPlayerRef.current.ended &&
            videoPlayerRef.current.readyState >
            videoPlayerRef.current.HAVE_CURRENT_DATA;

        if (videoTrack) {
            const videoSrcObject = new MediaStream([videoTrack]);

            if (videoPlayerRef.current) {
                videoPlayerRef.current.srcObject = videoSrcObject;
                if (videoPlayerRef.current.pause && !isPlaying) {
                    try {
                        videoPlayerRef.current.play();
                    } catch (err) {
                        console.log("error in playing video", err);
                    }
                }
            }

            setTimeout(() => {
                if (popupVideoPlayerRef.current) {
                    popupVideoPlayerRef.current.srcObject = videoSrcObject;
                    try {
                        popupVideoPlayerRef.current.play();
                    } catch (err) {
                        console.log("error in playing video", err);
                    }
                }
            }, 1000);
        } else {
            if (videoPlayerRef.current) {
                videoPlayerRef.current.srcObject = null;
            }
            if (popupVideoPlayerRef.current) {
                popupVideoPlayerRef.current.srcObject = null;
            }
        }
    }, [videoTrack, setting, settingDialogueOpen]);

    useEffect(() => {
        getDevices({ micEnabled, webcamEnabled });
        // eslint-disable-next-line
    }, [getDevices]);

    return (
        <div className="device-preview-container">
            <div className="device-preview-content">
                <video
                    autoPlay
                    playsInline
                    muted
                    ref={videoPlayerRef}
                    controls={false}
                    className="device-preview-video"
                />
                <div className="device-preview-message">
                    {!webcamOn && <p>The camera is off</p>}
                </div>
            </div>
            <div className="device-preview-button">
                <Button
                    onClick={_handleToggleMic}
                    type={micOn ? "primary" : "default"}
                    icon={micOn
                        ? <Microphone weight="fill"/>
                        : <MicrophoneSlash weight="fill"/>
                    }
                />
                <Button
                    onClick={_handleToggleWebcam}
                    type={webcamOn ? "primary" : "default"}
                    icon={webcamOn
                        ? <VideoCamera weight="fill"/>
                        : <VideoCameraSlash weight="fill"/>
                    }
                />
            </div>
        </div>
    );
}
