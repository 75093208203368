import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { uploadMultipleMedia } from "../../../../../common/redux/actions/mediaAction";
import { getBase64 } from "../../../../../common/utils/fileUtils";


const CreateEventMedia = ({ event }) => {
    const [loadingSaveBtn, setLoadingSaveBtn] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const mediaUrls = event?.imageUrls?.map((item, index) => ({
            uid: index,
            url: item
        }));
        setFileList(mediaUrls);
    }, [event]);

    const uploadButton = <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined/>
            <div style={{ marginTop: 8, }}>
                Upload
            </div>
        </button>;

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const customRequest = async ({ file, onSuccess, onError }) => {
        try {
            onSuccess();
        } catch (error) {
            onError();
            toast.error("Error uploading image");
        }
    };

    const handleUploadEventMedia = () => {
        setLoadingSaveBtn(true);
        const mediaList = fileList.map((item) => item.originFileObj);
        const request = {
            image: mediaList,
            eventId: event.eventId,
        }
        dispatch(uploadMultipleMedia(request))
            .unwrap()
            .then((response) => {
                toast.success("Successfully upload media!");
            })
            .catch((error) => {
                toast.error("Error upload media!");
            })
            .finally(() => {
                setLoadingSaveBtn(false);
            });
    }

    return (
        <div className="event-detail-info-section event-media-container">
            <div className="event-detail-info-section-head">
                <div className="event-detail-info-section-title">
                    Event media
                </div>
                <div className="event-detail-info-section-desc">
                    Add photos to show what your event will be about. You can upload up to 8 images
                </div>
            </div>
            <div className="event-media-content">
                <Upload
                    multiple={true}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    customRequest={customRequest}
                    maxCount={8}
                >
                    {fileList?.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                        alt="example"
                        style={{ width: '100%' }}
                        src={previewImage}
                    />
                </Modal>
            </div>
            <div className="event-detail-info-section-buttons">
                <Button
                    onClick={handleUploadEventMedia}
                    loading={loadingSaveBtn}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export default CreateEventMedia;
