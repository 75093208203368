import { createSlice } from "@reduxjs/toolkit";
import { AddressModel } from "../../models/addressModel.model";
import { getProvincesDistricts } from "../actions/addressAction";


const addressSlice = createSlice({
    name: 'address',
    initialState: AddressModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                getProvincesDistricts.pending,
                (state) => {
                    state.loading = true;
                }
            )
            .addCase(getProvincesDistricts.fulfilled, (state, action) => {
                state.loading = false;
                state.address = action.payload;
                state.error = null;
                state.success = true;
            })
            .addCase(getProvincesDistricts.rejected, (state, action) => {
                state.loading = false;
                state.address = [];
                state.error = action.payload;
                state.success = null;
            })
    },
});

export default addressSlice.reducer;
