import { createAsyncThunk } from '@reduxjs/toolkit';
import recordingService from "../../services/recordingService";
import {
    APPLY_SCENE_SEARCH,
    GET_RECORDING_BY_EVENT_FOR_ATTENDEE,
    GET_RECORDING_BY_EVENT_FOR_ORGANIZER,
    PROCESS_RECORDING, SCHEDULE_SESSION,
    SEARCH_SCENES, UPDATE_EXTERNAL_RECORDING,
    UPDATE_RECORDING,
    UPDATE_RECORDING_PRIVACY
} from "../types/recording";

export const getHostRecordByEventId = createAsyncThunk(
    GET_RECORDING_BY_EVENT_FOR_ORGANIZER,
    async (data, { rejectWithValue }) => {
        try {
            return recordingService.getHostRecordByEventId(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getRecordByEventId = createAsyncThunk(
    GET_RECORDING_BY_EVENT_FOR_ATTENDEE,
    async (data, { rejectWithValue }) => {
        try {
            return recordingService.getRecordByEventId(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateRecording = createAsyncThunk(
    UPDATE_RECORDING,
    async (data, { rejectWithValue }) => {
        try {
            return recordingService.updateRecording(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateRecordingPrivacy = createAsyncThunk(
    UPDATE_RECORDING_PRIVACY,
    async (data, { rejectWithValue }) => {
        try {
            return recordingService.updateRecordingPrivacy(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const processRecording = createAsyncThunk(
    PROCESS_RECORDING,
    async (data, { rejectWithValue }) => {
        try {
            return recordingService.processRecording(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const searchScenes = createAsyncThunk(
    SEARCH_SCENES,
    async (data, { rejectWithValue }) => {
        try {
            return recordingService.searchScenes(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const applySceneSearch = createAsyncThunk(
    APPLY_SCENE_SEARCH,
    async (data, { rejectWithValue }) => {
        try {
            return recordingService.applySceneSearch(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateExternalRecording = createAsyncThunk(
    UPDATE_EXTERNAL_RECORDING,
    async (data, { rejectWithValue }) => {
        try {
            return recordingService.updateExternalRecording(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const scheduleSession = createAsyncThunk(
    SCHEDULE_SESSION,
    async (data, { rejectWithValue }) => {
        try {
            return recordingService.scheduleSession(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);