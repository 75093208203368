import { Lightning } from "@phosphor-icons/react";
import { Editor } from "@tinymce/tinymce-react";
import { Button } from "antd";
import React, { useRef, useState } from "react";
import "./CreateEventDescription.scss";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { generateEventContent } from "../../../../../common/redux/actions/contentAction";
import { createOrUpdateEventDescription } from "../../../../../common/redux/actions/eventActions";

const CreateEventDescription = ({ event }) => {
    const [eventDescription, setEventDescription] = useState(event.description);
    const dispatch = useDispatch();
    const [loadingBtnSave, setLoadingBtnSave] = useState(false);
    const [loadingBtnSuggestContent, setLoadingBtnSuggestContent] = useState(false);
    const editorRef = useRef(null);

    const initalConfig = {
        height: 500,
        menubar: false,
        plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
        ],
        toolbar: 'undo redo blocks link ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
    }


    const handleClickSuggestContent = () => {
        if (event) {
            const request = {
                event_name: event.eventName,
                event_format: event.eventType,
            }
            setLoadingBtnSuggestContent(true);
            dispatch(generateEventContent(request))
                .unwrap()
                .then((response) => {
                    setEventDescription(response.description);
                })
                .catch((error) => {
                    toast.error("Error generate content");
                })
                .finally(() => {
                    setLoadingBtnSuggestContent(false);
                })
        }
    };

    const handleSaveEventDescription = () => {
        setLoadingBtnSave(true);
        const request = {
            eventId: event.eventId,
            description: eventDescription,
        }
        dispatch(createOrUpdateEventDescription(request))
            .unwrap()
            .then((response) => {
                toast.success("Successfully update description");
            })
            .catch((error) => {
                toast.error("Error update description");
            })
            .finally(() => {
                setLoadingBtnSave(false);
            });
    }

    const handleChangeEventDescription = () => {
        if (editorRef.current) {
            setEventDescription(editorRef.current.getContent());
        }
    }

    return (
        <div className="event-detail-info-section create-event-desc-container">
            <div className="event-detail-info-section-head">
                <div className="event-detail-info-section-title">
                    Event Description
                </div>
                <div className="event-detail-info-section-desc">
                    Add more details about your event and include what people can expect if they attend.
                </div>
            </div>

            <div className="create-event-desc-content">
                <div className="create-event-desc">
                    <Editor
                        apiKey={process.env.REACT_APP_EDITOR_API}
                        onInit={(evt, editor) => editorRef.current = editor}
                        onEditorChange={handleChangeEventDescription}
                        value={eventDescription}
                        init={initalConfig}
                    />
                </div>
            </div>

            <div className="event-detail-info-section-buttons">
                <Button
                    onClick={handleSaveEventDescription}
                    loading={loadingBtnSave}
                >
                    Save
                </Button>
                <Button
                    className="highlight-button"
                    icon={<Lightning/>}
                    onClick={handleClickSuggestContent}
                    loading={loadingBtnSuggestContent}
                >
                    Suggest content
                </Button>
            </div>
        </div>
    );
};

export default CreateEventDescription;
