import { createSlice } from "@reduxjs/toolkit";
import { OrganizationModel } from "../../models/organizationModel.model";
import {
    getAllOrganizers,
    getOrganizationProfile,
    getOrganizerProfile,
    isOrganizationRegistered,
    updateOrganizationCover,
    updateOrganizationInformation,
    updateOrganizationProfile
} from "../actions/organizationActions";


const organizationSlice = createSlice({
    name: "organization",
    initialState: OrganizationModel,
    reducers: {
        setOrganizationProfile(state, action) {
            state.organization = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrganizers.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.organizations = [];
            })
            .addCase(getAllOrganizers.fulfilled, (state, action) => {
                state.loading = false;
                state.organizations = action.payload.organizationDtos;
                state.error = null;
                state.hasMoreOrganizations = action.payload.hasMoreOrganizations;
            })
            .addCase(getAllOrganizers.rejected, (state, action) => {
                state.loading = false;
                state.organizations = [];
                state.error = action.payload;
            })
            .addCase(getOrganizerProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getOrganizerProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.organizer = action.payload;
                state.error = null;
            })
            .addCase(getOrganizerProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateOrganizationProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOrganizationProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.organizationId = action.payload;
            })
            .addCase(updateOrganizationProfile.rejected, (state, action) => {
                state.loading = false;
                state.organizationId = null;
                state.error = action.payload;
            })
            .addCase(updateOrganizationInformation.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOrganizationInformation.fulfilled, (state, action) => {
                state.loading = false;
                state.organizationId = action.payload;
            })
            .addCase(updateOrganizationInformation.rejected, (state, action) => {
                state.loading = false;
                state.organizationId = null;
                state.error = action.payload;
            })
            .addCase(getOrganizationProfile.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getOrganizationProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.organization = action.payload;
            })
            .addCase(getOrganizationProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(isOrganizationRegistered.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(isOrganizationRegistered.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload;
            })
            .addCase(isOrganizationRegistered.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateOrganizationCover.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOrganizationCover.fulfilled, (state, action) => {
                state.loading = false;
                state.coverUrl = action.payload;
            })
            .addCase(updateOrganizationCover.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default organizationSlice.reducer;
export const { setOrganizationProfile } = organizationSlice.actions;
