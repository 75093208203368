import React, { useEffect } from 'react'
import "./TicketDrawer.scss"
import { Button, DatePicker, Drawer, Form, Input, InputNumber, Radio } from "antd";
import {
    disabledDate,
    disabledTime,
    formatDateRange
} from "../../../../../../../common/utils/dateTimeUtils";
import { TicketType } from "../../../../../../../common/enums/ticketType";

function TicketDrawer({
                          openDrawer,
                          form,
                          onFinish,
                          loading,
                          onClose,
                          ticketData,
                          handleChangeTicketType,
                          ticketType
                      }) {
    const { RangePicker } = DatePicker;
    useEffect(() => {
        if (ticketData) {
            form.setFieldsValue({
                ticketTitle: ticketData.title,
                ticketDescription: ticketData.description,
                ticketQuantity: String(ticketData.totalQuantity),
                ticketPrice: String(ticketData.price),
                ticketDateTime: [formatDateRange(ticketData.startDateAvailable), formatDateRange(ticketData.endDateAvailable)],
                ticketType: ticketData.ticketType
            });
        }
    }, [ticketData, form]);

    return (
        <Drawer open={openDrawer} onClose={onClose} width={450}>
            <div className="ticket-drawer-contents">
                <div className="ticket-drawer-form-container">
                    <Form form={form} layout="vertical" requiredMark={true} onFinish={onFinish}>
                        <Form.Item
                            label="Ticket Title"
                            name="ticketTitle"
                            rules={[
                                {
                                    required: true,
                                    message: "Missing ticket title",
                                },
                            ]}
                        >
                            <Input placeholder="Title" size="large"/>
                        </Form.Item>
                        <Form.Item
                            label="Ticket Description"
                            name="ticketDescription"
                            rules={[
                                {
                                    required: true,
                                    message: "Missing ticket description",
                                },
                            ]}
                        >
                            <Input placeholder="Description" size="large"/>
                        </Form.Item>
                        <Form.Item
                            label="Ticket type"
                            name="ticketType"
                            rules={[
                                {
                                    required: true,
                                    message: "Missing ticket type",
                                },
                            ]}
                        >
                            <Radio.Group value={ticketType}
                                         onChange={(e) => handleChangeTicketType(e.target.value)}>
                                <Radio value={TicketType.FREE}>Free</Radio>
                                <Radio value={TicketType.PAID}>Paid</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Ticket Quantity"
                            name="ticketQuantity"
                            rules={[
                                {
                                    required: true,
                                    message: "Missing ticket quantity",
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder="Quantity"
                                size="large"
                                min={1}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Ticket Price"
                            name="ticketPrice"
                            rules={[
                                {
                                    required: ticketType === TicketType.PAID,
                                    message: "Missing ticket price",
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder="Price"
                                size="large"
                                type="number"
                                disabled={ticketType === TicketType.FREE}
                                min={ticketType === TicketType.FREE ? 0 : 1000}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Date Time Open/Close"
                            name="ticketDateTime"
                            rules={[
                                {
                                    required: true,
                                    message: 'Missing open/close date time',
                                },
                            ]}
                        >
                            <RangePicker
                                size='large'
                                style={{ width: '100%' }}
                                placement='topRight'
                                disabledDate={disabledDate}
                                disabledTime={disabledTime}
                                showTime={{
                                    hideDisabledOptions: true,
                                }}
                                format='DD-MM-YYYY HH:mm'
                            />
                        </Form.Item>

                        <div className="ticket-drawer-buttons">
                            <div className="cancel-button-container">
                                <Button size={"large"} onClick={onClose} block={true}>
                                    Cancel
                                </Button>
                            </div>
                            <div className="save-button">
                                <Form.Item>
                                    <Button type="primary" size={"large"} htmlType={'submit'} loading={loading}
                                            block={true}>
                                        {ticketData ? "Update" : "Save"}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Drawer>
    )
}

export default TicketDrawer