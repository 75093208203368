import { Bank, CalendarBlank, ChatCircleText, GearSix, House } from '@phosphor-icons/react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Menu from '../MainMenu/MainMenu';
import './sidebar.scss';

const menuItems = [
    {
        key: "home",
        icon: <House size={18}/>,
        title: "Home",
        path: "home"
    },
    {
        key: "events",
        icon: <CalendarBlank size={18}/>,
        title: "Events",
        path: "events"
    },
    {
        key: "messages",
        icon: <ChatCircleText size={18} />,
        title: "Messages",
        path: "messages"
    },
    {
        key: "finance",
        icon: <Bank size={18} />,
        title: "Finance",
        path: "finance"
    },
    {
        key: "profile",
        icon: <GearSix size={18}/>,
        title: "Org. Profile",
        path: "profile"
    },
]


const Sidebar = () => {
    const [activeItem, setActiveItem] = useState("home");
    const location = useLocation();
    
    const getActiveItem = (pathname) => {
        for (const menuItem of menuItems) {
            if (pathname.includes(menuItem.path)) {
                return menuItem.key;
            }
        }
        return 'home';
    };
    
    useEffect(() => {
        setActiveItem(getActiveItem(location.pathname));
    }, [location.pathname]);
    
    const handleMenuItemClick = (key) => {
        setActiveItem(key);
    };
    
    return (
        <div className='sidebar-container'>
            <div className='sidebar-menu'>
                <Menu menuItems={menuItems} onMenuItemClick={handleMenuItemClick} activeItem={activeItem}/>
            </div>
            <div className='sidebar-footer-container'>
                <div className='footer-link'>
                    <Link to="/">About</Link>
                    <Link to="/">Contact</Link>
                    <Link to="/">Policy</Link>
                    <Link to="/">Terms & Privacy</Link>
                </div>
                <p className='footer-company'>@2024 Dolphin Ltd.</p>
            </div>
        </div>
    );
};

export default Sidebar;