import { Lightning } from "@phosphor-icons/react";
import { Button, Form } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { generateEventFAQ } from "../../../../../common/redux/actions/contentAction";
import { createOrUpdateEventFaq } from "../../../../../common/redux/actions/eventActions";
import InputFAQItem from "../../components/InputFAQItem/InputFAQItem";

const CreateEventFAQ = ({ event }) => {
    const dispatch = useDispatch();
    const [faqs, setFaqs] = useState(event?.faqs || []);
    const [loadingBtnSuggestContent, setLoadingBtnSuggestContent] = useState(false);
    const [loadingBtnSave, setLoadingBtnSave] = useState(false);
    const [form] = Form.useForm();

    const handleClickSuggestContent = () => {
        if (event) {
            const request = {
                event_name: event.eventName,
                event_format: event.eventType,
                event_category: event.category,
                event_detail_info: event.description
            }
            setLoadingBtnSuggestContent(true);
            dispatch(generateEventFAQ(request))
                .unwrap()
                .then((response) => {
                    setFaqs(response.faqs);
                    form.setFieldValue("faqs", response.faqs);
                })
                .catch((error) => {
                    toast.error("Error generate FAQs");
                })
                .finally(() => {
                    setLoadingBtnSuggestContent(false);
                })
        }
    };

    const onFinish = (values) => {
        setLoadingBtnSave(true);
        const request = {
            faqs: values?.faqs,
            eventId: event.eventId,
        }
        dispatch(createOrUpdateEventFaq(request))
            .unwrap()
            .then((response) => {
                toast.success("Successfully add FAQs!");
            })
            .catch((error) => {
                toast.error("Error add FAQs!");
            })
            .finally(() => {
                setLoadingBtnSave(false);
            });
    };

    return (
        <div className="event-detail-info-section event-faq-container">
            <div className="event-detail-info-section-head">
                <div className="event-detail-info-section-title">
                    Frequently asked questions
                </div>
                <div className="event-detail-info-section-desc">
                    These sections help attendees find information and answer their questions - which means more ticket
                    sales and less time answering messages.
                </div>
            </div>
            <div className="event-faq-content">
                <Form
                    form={form}
                    name="event-faq-form"
                    onFinish={onFinish}
                    autoComplete="off"
                    variant="filled"
                    initialValues={{ "faqs": faqs }}
                >
                    {
                        faqs && <InputFAQItem faqItem={faqs}/>
                    }
                    <div className="event-detail-info-section-buttons">
                        <Button
                            htmlType="submit"
                            loading={loadingBtnSave}
                        >
                            Save
                        </Button>
                        <Button
                            className="highlight-button"
                            icon={<Lightning/>}
                            onClick={handleClickSuggestContent}
                            loading={loadingBtnSuggestContent}
                        >
                            Suggest content
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default CreateEventFAQ;
