import React from "react";
import InfoLayout from "../InfoLayout";
import { CalendarBlank } from "@phosphor-icons/react";
import { Form, DatePicker } from "antd";
import { disabledDate, disabledTime } from "../../../../../common/utils/dateTimeUtils";
const { RangePicker } = DatePicker;

function DateTimeInfo() {
    return (
        <InfoLayout
            icon={<CalendarBlank size={48} />}
            heading="Date and time"
            description="Tell event-goers when your event starts and ends so they can make plans to attend."
        >
            <Form.Item
                name="eventDateTime"
                label="Event Date and Time"
                rules={[
                    {
                        required: true,
                        message: "Please select the event date and time!",
                    },
                ]}
            >
                <RangePicker
                    size="large"
                    placement="topRight"
                    disabledDate={disabledDate}
                    disabledTime={disabledTime}
                    showTime={{
                        hideDisabledOptions: true,
                    }}
                    className="range-picker"
                    format="DD-MM-YYYY HH:mm"
                />
            </Form.Item>
        </InfoLayout>
    );
}

export default DateTimeInfo;
