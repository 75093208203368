import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import "./EventFAQ.scss";

export const EventFAQ = ({ eventFAQ }) => {
    const [faqItems, setFaqItems] = useState();

    useEffect(() => {
        if (eventFAQ?.length > 0) {
            const faqList = eventFAQ.map((item, index) => ({
                key: index,
                label: item.question,
                children: item.answer,
            }));
            setFaqItems(faqList);
        }
    }, [eventFAQ]);

    return (
        <div className="event-faq-container">
            <Collapse
                defaultActiveKey={[0]}
                accordion={true}
                bordered={false}
                ghost
                expandIcon={({ isActive }) => isActive
                    ? <CaretUp weight="bold"/>
                    : <CaretDown weight="bold"/>
                }
                expandIconPosition={"end"}
                items={faqItems}
            />
        </div>
    );
};
