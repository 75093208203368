import { Plus } from "@phosphor-icons/react";
import React from 'react';
import "./TopTool.scss"
import Search from "antd/es/input/Search";
import { Button } from "antd";

function TopTool({ onSearch, placeholder, handleOnClick, buttonText, orgBankExist }) {
    return (
        <div className="tools-container">
            <Search
                placeholder={placeholder}
                onChange={(e) => onSearch(e.target.value)}
                style={{ width: 300 }}
                size='large'
                enterButton
            />

            <div className="add-button-container">
                <Button
                    onClick={handleOnClick}
                    className={"add-button"}
                    disabled={!orgBankExist}
                    icon={<Plus/>}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
}

export default TopTool;