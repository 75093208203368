import { Heart } from "@phosphor-icons/react";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { FAVORITE } from "../../../../common/constants/LocalStorageConstants";
import { addEventToFavorite, removeEventFromFavorite } from "../../../../common/redux/actions/eventActions";
import "./ButtonLikeEvent.scss";

const ButtonLikeEvent = ({ event }) => {
    const [isLiked, setIsLiked] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    let likedList = (localStorage.getItem(FAVORITE) || "")
        .split(",")
        .map(item => item?.trim().toString());

    useEffect(() => {
        setIsLiked(
            likedList.includes(event?.eventId?.toString())
        );
    }, [likedList, event]);

    const handleClickLike = () => {
        const params = {
            username: user?.id,
            eventId: event?.eventId
        }
        try {
            if (isLiked) {
                dispatch(removeEventFromFavorite(params));
                likedList = likedList.filter(id => id !== event?.eventId?.toString());
            } else {
                dispatch(addEventToFavorite(params));
                likedList.push(event?.eventId?.toString());
            }
            localStorage.setItem(FAVORITE, likedList);
            setIsLiked(isLiked => !isLiked);
        } catch (error) {
            toast.error("Error like event!");
        }
    }

    return (
        <Button
            className={`button-like-event ${isLiked && "active"}`}
            shape="circle"
            onClick={handleClickLike}
            icon={<Heart weight={isLiked ? "fill" : "regular"}/>}
            danger={true}
        />
    );
};

export default ButtonLikeEvent;