import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import "./EventSideBar.scss";
import { Link } from "react-router-dom";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { EventType } from "../../../../../common/enums/Event";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import EventNavList from "../EventNavList";


function EventSideBar({ event, eventId }) {
    const [expandedItemId, setExpandedItemId] = useState(null);
    const [navListItem, setNavListItem] = useState([]);

    const eventNavListItem = [
        {
            id: 1,
            icon: <CaretRight weight="fill" color="#4b4d63"/>,
            contents: "Basic Info",
            type: "anchor",
            active: false,
            path: `basic-info`,
        },
        {
            id: 2,
            icon: <CaretRight weight="fill" color="#4b4d63"/>,
            contents: "Details",
            type: "anchor",
            active: false,
            path: `detail-info`,
        },
        {
            id: 3,
            icon: <CaretRight weight="fill" color="#4b4d63"/>,
            contents: "Online event",
            type: "anchor",
            active: false,
            path: `online-event`,
            layout: "online"
        },
        {
            id: 4,
            icon: <CaretRight weight="fill" color="#4b4d63"/>,
            contents: "Add ticket",
            type: "anchor",
            active: false,
            path: `tickets`,
        },
        {
            id: 5,
            icon: <CaretRight weight="fill" color="#4b4d63"/>,
            contents: "Publish",
            type: "anchor",
            active: false,
            path: `preview-publish`,
        },
        {
            id: 6,
            icon: null,
            contents: "Dashboard",
            type: "anchor",
            active: false,
            path: `dashboard`,
        },
        {
            id: 7,
            icon: null,
            contents: "Reservations",
            type: "anchor",
            active: false,
            path: "reservations"
        },
        {
            id: 8,
            icon: null,
            contents: "Manage Attendees",
            type: "anchor",
            active: false,
            path: "attendees",
            // layout: "offline"
        },
        {
            id: 9,
            icon: null,
            contents: "Hosting",
            type: "anchor",
            active: false,
            path: `host-online-event`,
            layout: "online"
        },
        {
            id: 10,
            icon: null,
            contents: "Feedbacks",
            type: "anchor",
            active: false,
            path: `feedbacks`,
        },
        {
            id: 11,
            icon: null,
            contents: "Recording",
            type: "anchor",
            active: false,
            path: "event-recording",
            layout: "online"
        },
    ];

    useEffect(() => {
        const filteredList = eventNavListItem.filter(item =>
            event?.eventType === EventType.OFFLINE ? item.layout !== "online" : item.layout !== "offline"
        );
        setNavListItem(filteredList);
        // eslint-disable-next-line
    }, [event]);

    return (
        <div className="event-sidebar">
            <div className="event-sidebar-conatiner">
                <section className="event-sidebar-desc">
                    <div className="event-description">
                        <Link to={`/organization/manage/events`} className="event-organizations">
                            <div className="event-description-link">
                                <CaretLeft
                                    size={20}
                                    className="caretleft-icon"
                                />
                                <span className="event-desc-text">
                                        Back to events
                                </span>
                            </div>
                        </Link>
                        <div className="event-description__title">
                            <h3 className="title-holder">
                                <Link
                                    to={`/organization/manage/events/${eventId}/dashboard`}
                                    className="event-nav-description-title"
                                >
                                    {event?.eventName}
                                </Link>
                            </h3>
                            <p className="event-date-time">
                                <span
                                    className="event-start-date"><strong>From:</strong> {formatDateRange(event?.startDateTime, DateTimePattern.STANDARD_DATE_TIME)}</span>
                                <span
                                    className="event-end-date"><strong>To:</strong> {formatDateRange(event?.endDateTime, DateTimePattern.STANDARD_DATE_TIME)}</span>
                            </p>
                        </div>
                        <EventNavList
                            items={navListItem}
                            expandedItemId={expandedItemId}
                            setExpandedItemId={setExpandedItemId}
                        />
                    </div>
                </section>
            </div>
        </div>
    );
}

export default EventSideBar;
