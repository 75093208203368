import { List } from "antd";
import "./NotificationList.scss";
import React from 'react';
import { useSelector } from "react-redux";
import NotificationItem from "../../components/NotificationItem";

function NotificationList() {
    const { notifications } = useSelector((state) => state.notification);

    return (
        <div className="notification-main-list">
            <List
                dataSource={notifications}
                renderItem={(item) => (
                    <List.Item key={item.notificationType}>
                        <NotificationItem notification={item}/>
                    </List.Item>
                )}
            />
        </div>);
}

export default NotificationList;