import { Check } from "@phosphor-icons/react";
import React from "react";
import "./TicketStep.scss";

const TicketStep = ({ order, title, icon, active, finished }) => {
    return (
        <div className={`ticket-step-container ${active ? "active" : ""} ${finished ? "finish" : ""}`}>
            {
                !finished
                    ? <div className="ticket-step-order">
                        {order}
                    </div>
                    : <div className="ticket-step-finish">
                        <Check weight="bold"/>
                    </div>
            }
            <div className="ticket-step-content">
                {title}
            </div>
        </div>
    );
};

export default TicketStep;