import { PencilSimple, Trash } from "@phosphor-icons/react";
import React from "react";
import { Button, Space, Table, Tag } from "antd";

function VoucherTable({ voucherData, deleteVoucherById, setSelectedVoucher, disabledAction }) {
    const statusHandler = (_, { voucherStatus }) => (
        <>
            <Tag color={voucherStatus === "active" ? "green" : "yellow"}>
                {voucherStatus.toUpperCase()}
            </Tag>
        </>
    );

    const columns = [
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "Discount",
            dataIndex: "discount",
            key: "discount",
            align: "right"
        },
        {
            title: "Uses",
            dataIndex: "numberOfAvailable",
            key: "numberOfAvailable",
        },
        {
            title: "Status",
            dataIndex: "voucherStatus",
            key: "voucherStatus",
            render: statusHandler
        },

        {
            title: "Start date",
            dataIndex: "startDate",
            key: "startDate",
        },
        {
            title: "End date",
            dataIndex: "endDate",
            key: "endDate",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        icon={<PencilSimple/>}
                        disabled={disabledAction}
                        onClick={() => selectVoucher(record)}
                    >
                        Update
                    </Button>
                    <Button
                        icon={<Trash/>}
                        disabled={disabledAction}
                        onClick={() => deleteVoucher(record)}
                        danger={true}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    const selectVoucher = (record) => {
        setSelectedVoucher(record)
    }

    const deleteVoucher = (record) => {
        deleteVoucherById(record)
    }


    return (
        <section className="voucher-table">
            <Table columns={columns} dataSource={voucherData}/>
        </section>
    );
}

export default VoucherTable;