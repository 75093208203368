import { AddToCalendarButton } from 'add-to-calendar-button-react';
import React, { useEffect, useState } from "react";

const AddCalendarButton = ({ event }) => {
    const [ticketDateTime, setTicketDateTime] = useState({
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null
    });

    const formatTime = (time) => {
        return time.split(':').slice(0, 2).join(':');
    };

    useEffect(() => {
        if (event) {
            const [startDate, startTime] = event?.eventStartTime.split(' ');
            const [endDate, endTime] = event?.eventEndTime.split(' ');
            setTicketDateTime({
                startDate: startDate,
                startTime: formatTime(startTime),
                endDate: endDate,
                endTime: formatTime(endTime)
            });
        }
    }, [event]);

    return (
        <AddToCalendarButton
            inline={true}
            name={event?.eventName}
            options={['Apple', 'Google', 'Outlook.com']}
            location={event?.eventLocation ? event?.eventLocation?.address : ""}
            startDate={ticketDateTime.startDate}
            endDate={ticketDateTime?.endDate}
            startTime={ticketDateTime?.startTime}
            endTime={ticketDateTime?.endTime}
            timeZone="Asia/Saigon"
            size={"5|5|5"}
            buttonStyle="default"
            hideCheckmark={true}
            styleLight="
                --font: Inter;
                --btn-font-weight: 400;
                --btn-background: #f0f2f4;
                --btn-border: none;
                --btn-border-radius: 8px;
                --btn-shadow: none;
                --btn-hover-shadow: none;
                --btn-active-shadow: none;
                --overlay-background: rgba(0, 0, 0, 0.35);
                --btn-padding-y: .35em;
            "
        ></AddToCalendarButton>
    );
};

export default AddCalendarButton;
