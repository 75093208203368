import axiosInstanceForAI from "../api/AiApi";

const contentService = {
    generateEventContent: async (request) => {
        try {
            return await axiosInstanceForAI.post(
                "/api/v1/content/generate/event-info",
                request
            );
        } catch (error) {
            console.error("Error generate event content:", error);
            throw error;
        }
    },
    generateEventFAQ: async (request) => {
        try {
            return await axiosInstanceForAI.post(
                "/api/v1/content/generate/event-faq",
                request
            );
        } catch (error) {
            console.error("Error generate event FAQ:", error);
            throw error;
        }
    },
};

export default contentService;
