import { Tag } from "antd";
import React from 'react';
import "./PreviewCard.scss";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import { EventType } from "../../../../../common/enums/Event";
import defaultThumbnail from "../../../../assets/images/default.jpg";

function PreviewCard({ event }) {

    return (
        <article className={"preview-card-container"}>
            <aside className={"preview-card-image"}>
                <img src={event?.thumbnailUrl}
                     alt=""
                     className="image-container"
                     onError={(e) => e.target.src = defaultThumbnail}/>
            </aside>
            <section className="preview-event-details">
                <h1 className="event-preview-title">{event?.eventName}</h1>
                <h2 className="event-preview-start-date">{formatDateRange(event?.startDateTime, DateTimePattern.CARD_DATE_TIME)}</h2>
                <h2 className="event-address">{event?.location?.address}</h2>
                <div className="event-type">
                    <Tag color={event?.eventType === EventType.ONLINE ? "green" : "blue"}
                         className="event-preview-type">
                        {event?.eventType}
                    </Tag>
                </div>
            </section>
        </article>
    );
}

export default PreviewCard;