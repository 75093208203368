import React from 'react';
import './CategoryTag.scss';

const CategoryTag = ({ id, title, icon, isSelected, handleToggleInterestCategory }) => {

    return (
        <div onClick={() => handleToggleInterestCategory(id)}
             className={`category-tag-container ${isSelected ? 'active' : ''}`}>
            <div className='tag-icon'>
                {icon}
            </div>
            <div className='tag-title'>
                {title}
            </div>
        </div>
    );
}

export default CategoryTag;