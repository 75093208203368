import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducers';


const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["event/createEvent/pending","auth/login/rejected", "bank/getBanksInfo/fulfilled"],
                ignoredPaths: [
                    "event.meta.arg.meta.arg.startDateTime",
                    "event.meta.arg.meta.arg.endDateTime",
                    "auth.error"
                ],
            },
        }),
});

export default store;
