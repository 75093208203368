import { createAsyncThunk } from '@reduxjs/toolkit';
import feedbackService from "../../services/feedbackService";
import { CREATE_EVENT_FEEDBACK, GET_FEEDBACK_FOR_EVENT_OF_USER, GET_FEEDBACK_ORG } from "../types/feedback";

export const createEventFeedback = createAsyncThunk(
    CREATE_EVENT_FEEDBACK,
    async (data, { rejectWithValue }) => {
        try {
            return feedbackService.createEventFeedback(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getFeedbackForEventOfUser = createAsyncThunk(
    GET_FEEDBACK_FOR_EVENT_OF_USER,
    async (data, { rejectWithValue }) => {
        try {
            return feedbackService.getFeedbackForEventOfUser(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getAllEventFeedbacks = createAsyncThunk(
    GET_FEEDBACK_ORG,
    async (data, { rejectWithValue }) => {
        try {
            return feedbackService.getAllEventFeedbacks(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);