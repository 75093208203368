import { Constants, createCameraVideoTrack, useMeeting, usePubSub, } from "@videosdk.live/react-sdk";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { scheduleSession } from "../../../../common/redux/actions/recordingAction";
import {
    setIsMeetingLeft,
    setMeetingId,
    setMicOn,
    setParticipantName, setSessionId,
    setToken,
    setWebcamOn
} from "../../../../common/redux/slice/videosdkSlice";
import { nameTructed, trimSnackBarText } from "../../../../common/utils/videosdkUtils";
import { useMeetingAppContext } from "../../../../common/videosdk/MeetingAppContextDef";
import notificationSound from "../../../assets/audios/notification.mp3"
import notificationCriticalErrSound from "../../../assets/audios/notification_critical_err.mp3";
import notificationErrSound from "../../../assets/audios/notification_err.mp3";
import { SidebarContainer } from "../ILSSideBar/SidebarContainer";
import { PresenterView } from "../PresenterView/PresenterView";
import WaitingToJoinScreen from "../WaitingToJoinScreen/WaitingToJoinScreen";
import HLSContainer from "./components/hlsViewContainer/HLSContainer";
import { ILSBottomBar } from "./components/ILSBottomBar";
import MemorizedILSParticipantView from "./components/ILSParticipantView";
import "./ILSContainer.scss";

export function ILSContainer({ meetingMode }) {
    const dispatch = useDispatch();
    const { micOn, selectedMic, webcamOn, selectedWebcam, meetingId } = useSelector((state) => state.videosdk);
    const {
        setAfterMeetingJoinedHLSState
    } = useMeetingAppContext();

    const [containerHeight, setContainerHeight] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    // eslint-disable-next-line
    const [meetingError, setMeetingError] = useState(false);
    // eslint-disable-next-line
    const [meetingErrorVisible, setMeetingErrorVisible] = useState(false);
    const mMeetingRef = useRef();
    const [localParticipantAllowedJoin, setLocalParticipantAllowedJoin] = useState(null);

    const containerRef = createRef();
    const containerHeightRef = useRef();
    const containerWidthRef = useRef();
    const meetingModeRef = useRef(meetingMode);

    useEffect(() => {
        containerHeightRef.current = containerHeight;
        containerWidthRef.current = containerWidth;
    }, [containerHeight, containerWidth]);

    useEffect(() => {
        containerRef.current?.offsetHeight && setContainerHeight(containerRef.current.offsetHeight);
        containerRef.current?.offsetWidth && setContainerWidth(containerRef.current.offsetWidth);

        window.addEventListener("resize", ({ target }) => {
            containerRef.current?.offsetHeight && setContainerHeight(containerRef.current.offsetHeight);
            containerRef.current?.offsetWidth && setContainerWidth(containerRef.current.offsetWidth);
        });
    }, [containerRef]);

    const _handleMeetingLeft = () => {
        dispatch(setIsMeetingLeft(true));
    };

    const _handleOnRecordingStateChanged = ({ status }) => {
        if (
            meetingModeRef.current === Constants.modes.CONFERENCE &&
            (status === Constants.recordingEvents.RECORDING_STARTED ||
                status === Constants.recordingEvents.RECORDING_STOPPED)
        ) {
            toast.info(`${
                status === Constants.recordingEvents.RECORDING_STARTED
                    ? "Meeting recording is started."
                    : "Meeting recording is stopped."
            }`);
        }
    };

    const _handleOnHlsStateChanged = (data) => {
        if (
            meetingModeRef.current === Constants.modes.CONFERENCE && // trigger on conference mode only
            (data.status === Constants.hlsEvents.HLS_STARTED ||
                data.status === Constants.hlsEvents.HLS_STOPPED)
        ) {
            toast.info(`${
                data.status === Constants.hlsEvents.HLS_STARTED
                    ? "Online event session is started."
                    : "Online event session is stopped."
            }`);
        }

        if (data.status === Constants.hlsEvents.HLS_STARTED) {
            setAfterMeetingJoinedHLSState("STARTED");
            dispatch(setSessionId(data?.id));

            // schedule to auto end session
            const payload = {
                roomId: meetingId,
                sessionId: data?.id,
            };
            dispatch(scheduleSession(payload));
        }

        if (data.status === Constants.hlsEvents.HLS_STOPPED) {
            setAfterMeetingJoinedHLSState("STOPPED");
        }
    };

    function onParticipantJoined(participant) {
        participant && participant.setQuality("high");
    }

    function onEntryResponded(participantId, name) {
        if (mMeetingRef.current?.localParticipant?.id === participantId) {
            if (name === "allowed") {
                setLocalParticipantAllowedJoin(true);
            } else {
                setLocalParticipantAllowedJoin(false);
                setTimeout(() => {
                    _handleMeetingLeft();
                }, 3000);
            }
        }
    }

    async function onMeetingJoined() {
        const { changeWebcam, changeMic, muteMic, disableWebcam } = mMeetingRef.current;

        if (webcamOn && selectedWebcam?.id) {
            await new Promise((resolve) => {
                disableWebcam();
                setTimeout(async () => {
                    const track = await createCameraVideoTrack({
                        optimizationMode: "motion",
                        encoderConfig: "h540p_w960p",
                        facingMode: "environment",
                        cameraId: selectedWebcam?.id,
                        multiStream: false,
                    });
                    changeWebcam(track);
                    resolve();
                }, 200);
            });
        }

        if (micOn && selectedMic?.id) {
            await new Promise((resolve) => {
                muteMic();
                setTimeout(() => {
                    changeMic(selectedMic?.id);
                    resolve();
                }, 200);
            });
        }
    }

    function onMeetingLeft() {
        dispatch(setToken(""));
        dispatch(setMeetingId(""));
        dispatch(setParticipantName(""));
        dispatch(setWebcamOn(false));
        dispatch(setMicOn(false));
        dispatch(setIsMeetingLeft(true));
        // dispatch(setMeetingStarted(false));
    }

    const _handleOnError = (data) => {
        const { code, message } = data;
        const joiningErrCodes = [4001, 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4009, 4010];
        const isJoiningError = joiningErrCodes.findIndex((c) => c === code) !== -1;
        const isCriticalError = `${code}`.startsWith("500");
        new Audio(isCriticalError
            ? notificationCriticalErrSound
            : notificationErrSound
        ).play();
        setMeetingErrorVisible(true);
        setMeetingError({
            code,
            message: isJoiningError ? "Unable to join meeting!" : message,
        });
    };

    const mMeeting = useMeeting({
        onParticipantJoined,
        onEntryResponded,
        onMeetingJoined,
        onMeetingLeft,
        onError: _handleOnError,
        onRecordingStateChanged: _handleOnRecordingStateChanged,
        onHlsStateChanged: _handleOnHlsStateChanged,
    });

    useEffect(() => {
        mMeetingRef.current = mMeeting;
    }, [mMeeting]);

    const isPresenting = !!mMeeting.presenterId;

    usePubSub("CHAT", {
        onMessageReceived: (data) => {
            const localParticipantId = mMeeting?.localParticipant?.id;
            const { senderId, senderName, message } = data;
            const isLocal = senderId === localParticipantId;
            if (!isLocal) {
                new Audio(notificationSound).play();
                toast.info(`${trimSnackBarText(
                    `${nameTructed(senderName, 15)} says: ${message}`
                )}`);
            }
        },
    });

    return (
        <div className="ils-container">
            {typeof localParticipantAllowedJoin === "boolean"
                ? localParticipantAllowedJoin
                    ? <>
                        <div className="ils-main">
                            {
                                meetingMode === Constants.modes.CONFERENCE
                                    ? <div className="ils-presenter-view">
                                        {
                                            isPresenting
                                                ? <PresenterView/>
                                                : <MemorizedILSParticipantView isPresenting={isPresenting}/>
                                        }
                                    </div>
                                    : <div className="hls-view">
                                        <HLSContainer/>
                                    </div>
                            }
                            <SidebarContainer/>
                        </div>
                        <div className="ils-bottom">
                            <ILSBottomBar meetingMode={meetingMode}/>
                        </div>
                    </>
                    : <></>
                : !mMeeting?.isMeetingJoined && <WaitingToJoinScreen/>
            }
        </div>
    );
}
