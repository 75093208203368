import { Button } from "antd";
import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import "./not_found.scss";
import not_found_img from "../../assets/images/404.png";

const NotFound = () => {
    const { setShowSidebar } = useOutletContext();

    useEffect(() => {
        setShowSidebar(false);
        document.title = "Not Found"
    });

    return (
        <div className="not-found-container">
            <div className="not-found-image">
                <img src={not_found_img} alt=""/>
            </div>
            <div className="not-found-text">
                <div className="not-found-hero">Page not found</div>
                <div className="not-found-desc">
                    Oops! Looks like you followed a bad link. If you think this is a problem with us, please tell us.
                </div>
                <Button type="primary" href="/">Go back home</Button>
            </div>
        </div>
    );
};

export default NotFound;
