import { UserOutlined } from '@ant-design/icons';
import {
    ArrowsLeftRight,
    BellSimple,
    CalendarBlank,
    CalendarPlus,
    Chat,
    ClockCounterClockwise,
    Heart,
    SignOut,
    Sparkle,
    Ticket,
    User,
    Users
} from "@phosphor-icons/react";
import { Avatar, Button, Dropdown, Flex } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
    FAVORITE,
    FOLLOWING_ORG,
    IS_LOGGED_IN,
    ORGANIZATION_PROFILE,
    PROFILE_MODE
} from "../../../../common/constants/LocalStorageConstants";
import { ApplicationMode } from "../../../../common/enums/ApplicationMode";
import { logout } from "../../../../common/redux/actions/authActions";
import { setMode } from "../../../../common/redux/slice/authSlice";
import logo from "../../../assets/icons/logo.svg";
import "./Header.scss";
import SearchInput from "../SearchInput/SearchInput";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";


const Header = ({ isLoggedIn }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [top, setTop] = useState(true);
    const { mode } = useSelector(state => state.auth)
    // eslint-disable-next-line
    const orgProfile = useMemo(() => sessionStorage.getItem(ORGANIZATION_PROFILE), [mode]);
    const { user } = useSelector((state) => state.auth);
    const screens = useBreakpoint();
    const isMobile = screens.xs;
    
    const avatarMenuItems = [
        {
            type: "divider",
        },
        {
            key: "tickets",
            label: (
                <Link to="/user/reservations">
                    <Flex align={"center"} gap={20} justify={"space-between"}>
                        Tickets
                        <Ticket/>
                    </Flex>
                </Link>
            ),
        },
        {
            key: "liked",
            label: (
                <Link to="/user/liked-events">
                    <Flex align={"center"} gap={20} justify={"space-between"}>
                        Liked
                        <Heart/>
                    </Flex>
                </Link>
            ),
        },
        {
            key: "following",
            label: (
                <Link to="/user/following-organizations">
                    <Flex align={"center"} gap={20} justify={"space-between"}>
                        Following
                        <Users/>
                    </Flex>
                </Link>
            ),
        },
        {
            key: "upcoming",
            label: (
                <Link to="/user/upcoming-events">
                    <Flex align={"center"} gap={20} justify={"space-between"}>
                        Upcoming
                        <CalendarBlank/>
                    </Flex>
                </Link>
            ),
        },
        {
            key: "past",
            label: (
                <Link to="/user/past-events">
                    <Flex align={"center"} gap={20} justify={"space-between"}>
                        Past
                        <ClockCounterClockwise/>
                    </Flex>
                </Link>
            ),
        },
        {
            type: "divider",
        },
        {
            key: "logout",
            label: (
                <Flex align={"center"} gap={20} justify={"space-between"}>
                    Sign out
                    <SignOut/>
                </Flex>
            ),
        },
    ];
    
    if (!isMobile) {
        avatarMenuItems.splice(6, 0,
            {
                key: "profile",
                label: (
                    <Link to="/account/profile">
                        <Flex align={"center"} gap={20} justify={"space-between"}>
                            Profile settings
                            <User/>
                        </Flex>
                    </Link>
                ),
            },
            {
                key: "preference",
                label: (
                    <Link to="/account/preference">
                        <Flex align={"center"} gap={20} justify={"space-between"}>
                            Interests
                            <Sparkle/>
                        </Flex>
                    </Link>
                ),
            },
            {
                type: "divider",
            },
            {
                key: "switch",
                label: (
                    <Flex align={"center"} gap={20} justify={"space-between"}>
                        Switch to Organizer
                        <ArrowsLeftRight/>
                    </Flex>
                ),
            }
        );
    }
    
    
    useEffect(() => {
        const scrollHandler = () => {
            window.scrollY > 10
                ? setTop(false)
                : setTop(true);
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);
    
    const handleEvent = ({ key }) => {
        switch (key) {
            case "logout":
                sessionStorage.removeItem(ORGANIZATION_PROFILE);
                sessionStorage.removeItem(PROFILE_MODE)
                localStorage.removeItem(FAVORITE);
                localStorage.removeItem(FOLLOWING_ORG);
                localStorage.removeItem(IS_LOGGED_IN)
                navigate("/")
                dispatch(logout())
                break;
            case "switch":
                dispatch(setMode(ApplicationMode.ORGANIZER))
                if (orgProfile) {
                    navigate("/organization/manage/home")
                } else {
                    navigate("/organization/manage/profile")
                }
                break;
            default:
                return
        }
    };
    
    const goToChatPage = () => {
        if (mode === ApplicationMode.ATTENDEE) {
            navigate('/messages')
        } else {
            navigate(`/organization/manage/messages`)
        }
    }
    
    const goToNotificationPage = () => {
        navigate(`notifications`)
    }
    
    const menuStyle = {
        boxShadow: 'none',
    };
    
    return (
        <div className={`header-container ${!top && "show-shadow"}`}>
            <div className="header-start">
                <Link className="header-logo" to="/">
                    <img src={logo} alt=""/>
                    <span>Eventure</span>
                </Link>
                <SearchInput/>
            </div>
            
            <div className="header-end">
                {isLoggedIn ? (
                    <>
                        <Link className={"create-button"} to="/organization/manage/createEvent">
                            <Button
                                type="default"
                                icon={<CalendarPlus size={20}/>}
                            >
                                Create event
                            </Button>
                        </Link>
                        <Button
                            className={"notification-button"}
                            onClick={goToNotificationPage}
                            icon={<BellSimple size={20}/>}
                        />
                        <Button
                            className={"chat-page-button"}
                            onClick={goToChatPage}
                            icon={<Chat size={20}/>}
                        />
                        <Dropdown
                            menu={{
                                items: avatarMenuItems,
                                onClick: handleEvent,
                            }}
                            placement="bottomRight"
                            trigger={["click"]}
                            dropdownRender={(menu) => (
                                <div className="header-dropdown">
                                    <div className="header-dropdown-top">
                                        {
                                            user?.user_metadata?.first_name
                                                ? <p className="header-dropdown-name">
                                                    {user?.user_metadata?.first_name + " " + user?.user_metadata?.last_name}
                                                </p>
                                                : (user?.user_metadata?.name?.length > 0 &&
                                                    <p className="header-dropdown-name">
                                                        {user?.user_metadata?.name}
                                                    </p>
                                                )
                                        }
                                        {user && <p className="header-dropdown-email">{user?.email}</p>}
                                    </div>
                                    {React.cloneElement(menu, { style: menuStyle })}
                                </div>
                            )}
                        >
                            <Avatar
                                shape="square"
                                className="header-avatar"
                                icon={<UserOutlined/>}
                            />
                        </Dropdown>
                    </>
                ) : (
                    <>
                        <Link to="/login">
                            <Button type="primary">Sign in</Button>
                        </Link>
                        
                        <Link to="register">
                            <Button type="text">Sign up</Button>
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default Header;
