import { Plus } from "@phosphor-icons/react";
import { Alert, Button, Form, Input } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import "./OrganizationCreateMeeting.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { MeetingProviders } from "../../../common/enums/MeetingProviders";
import {
    createMeetingCode,
    createMeetingRoom,
    getMeetingRoomForAttendee
} from "../../../common/redux/actions/meetingAction";
import { validateMeetingCodeFormat } from "../../../common/utils/videosdkUtils";
import eventureLogo from "../../assets/icons/logo.svg";
import googleMeetLogo from "../../assets/images/gg_meet.png";
import zoomLogo from "../../assets/images/zoom.png";
import MeetingBanner from "../../modules/components/MeetingBanner/MeetingBanner";


const OrganizationCreateMeeting = () => {
    const { eventId } = useParams();
    const [provider, setProvider] = useState(MeetingProviders.EVENTURE);
    const [loadingSaveBtn, setLoadingSaveBtn] = useState(false);
    const [loadingAddRoomBtn, setLoadingAddRoomBtn] = useState(false);
    const [inputMeetingLocator, setInputMeetingLocator] = useState();
    const dispatch = useDispatch();
    const { meetingCode, attendeeMeetingRoom } = useSelector((state) => state.meeting);
    const [form] = Form.useForm();

    useLayoutEffect(() => {
        document.title = "Online Event Settings | Eventure";
    }, []);

    const handleChangeProvider = (value) => {
        setProvider(value);
    }

    const handleCreateEventureMeetingCode = () => {
        setLoadingAddRoomBtn(true);
        dispatch(createMeetingCode())
            .unwrap()
            .then((res) => {
                toast.success("Successfully create room!")
            })
            .catch((err) => {
                toast.error("Error create room!")
            })
            .finally(() => {
                setLoadingAddRoomBtn(false);
            });
    };

    useEffect(() => {
        if (attendeeMeetingRoom && Object.keys(attendeeMeetingRoom).length > 0) {
            setInputMeetingLocator(attendeeMeetingRoom.locator);
            setProvider(attendeeMeetingRoom.meetingProvider);
            form.setFieldsValue({
                "room_locator": attendeeMeetingRoom?.locator,
                "room_title": attendeeMeetingRoom?.eventName || "",
                "room_description": attendeeMeetingRoom?.description
            })
        }
    }, [attendeeMeetingRoom, form]);

    useEffect(() => {
        if (meetingCode) {
            setInputMeetingLocator(meetingCode);
            form.setFieldValue("room_locator", meetingCode);
        }
        // eslint-disable-next-line
    }, [meetingCode]);

    const onFinish = (values) => {
        setLoadingSaveBtn(true);
        if (provider === MeetingProviders.EVENTURE && !validateMeetingCodeFormat(values.room_locator)) {
            setLoadingSaveBtn(false);
            toast.warning("Please add a Eventure room!");
            return;
        }
        const payload = {
            meetingId: attendeeMeetingRoom ? attendeeMeetingRoom.meetingId : null,
            eventId: eventId,
            provider: provider,
            locator: values.room_locator,
            title: values.room_title,
            description: values.room_description,
        }
        dispatch(createMeetingRoom(payload))
            .unwrap()
            .then((response) => {
                toast.success("Successfully save meeting room!");
                dispatch(getMeetingRoomForAttendee({ eventId: eventId }));
            })
            .catch((error) => {
                toast.error("Error save meeting room!")
            })
            .finally(() => {
                setLoadingSaveBtn(false);
            });
    }

    useEffect(() => {
        if (eventId) {
            dispatch(getMeetingRoomForAttendee({ eventId: eventId }));
        }
        // eslint-disable-next-line
    }, [eventId]);

    return (
        <div className="create-event-online-container">
            <div className="create-event-online-title">
                Online event settings
            </div>
            <div className="create-event-online-content">
                <div className="event-online-section event-online-provider">
                    <div className="event-online-section-title">Provider</div>
                    <div className="event-online-section-desc">
                        <Alert
                            closable={true}
                            type={"warning"}
                            message={"For a seamless event experience, we strongly recommend using our built-in online event hosting service."}
                        />
                        {
                            provider !== MeetingProviders.EVENTURE
                            && <Alert
                                closable={true}
                                type={"error"}
                                message={"If you choose to use an external provider for event hosting, we cannot be held responsible for attendee admission issues."}
                            />
                        }
                    </div>
                    <div className="event-online-provider-content">
                        <Button
                            type={provider === MeetingProviders.EVENTURE ? "primary" : "default"}
                            onClick={() => handleChangeProvider(MeetingProviders.EVENTURE)}
                            icon={<img width={18} src={eventureLogo} alt=""/>}
                        >
                            Eventure
                        </Button>
                        <Button
                            type={provider === MeetingProviders.ZOOM ? "primary" : "default"}
                            onClick={() => handleChangeProvider(MeetingProviders.ZOOM)}
                            icon={<img width={18} src={zoomLogo} alt=""/>}
                        >
                            Zoom
                        </Button>
                        <Button
                            type={provider === MeetingProviders.GOOGLE_MEET ? "primary" : "default"}
                            onClick={() => handleChangeProvider(MeetingProviders.GOOGLE_MEET)}
                            icon={<img width={18} src={googleMeetLogo} alt=""/>}
                        >
                            Google Meet
                        </Button>
                        <Button
                            type={provider === MeetingProviders.OTHER ? "primary" : "default"}
                            onClick={() => handleChangeProvider(MeetingProviders.OTHER)}
                        >
                            Other
                        </Button>
                    </div>
                </div>
                <div className="event-online-section event-online-information">
                    <div className="event-online-section-title">Meeting info</div>
                    <div className="event-online-information-content">
                        <Form
                            form={form}
                            onFinish={onFinish}
                            layout="vertical"
                            variant={"filled"}
                        >
                            <Form.Item
                                name="room_locator"
                                label={provider === MeetingProviders.EVENTURE ? "Room code" : "Room URL"}
                                rules={[{
                                    required: true,
                                    message: "Joining information cannot be empty!",
                                }]}
                            >
                                {
                                    provider === MeetingProviders.EVENTURE
                                        ? <div className="event-online-eventure-room-url">
                                            <Button
                                                type="primary"
                                                icon={<Plus/>}
                                                onClick={handleCreateEventureMeetingCode}
                                                loading={loadingAddRoomBtn}
                                            >
                                                Add room
                                            </Button>
                                            <Input
                                                variant="filled"
                                                placeholder="Room Code"
                                                value={inputMeetingLocator}
                                            />
                                        </div>
                                        : <Input placeholder="Room URL" value={inputMeetingLocator}/>
                                }
                            </Form.Item>
                            {/*<Form.Item name="room_title" label="Room title">*/}
                            {/*    <Input placeholder="Add title for your online event"/>*/}
                            {/*</Form.Item>*/}
                            <Form.Item name="room_description" label="Room description">
                                <Input.TextArea
                                    size={{ minRows: 2, maxRows: 2 }}
                                    placeholder="Add description"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loadingSaveBtn}
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                {
                    attendeeMeetingRoom
                    && <div className="event-online-section create-event-online-preview">
                        <div className="event-online-section-title">
                            Your event thumbnail
                        </div>
                        <div className="event-online-meeting-preview-content">
                            <MeetingBanner meeting={attendeeMeetingRoom}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default OrganizationCreateMeeting;
