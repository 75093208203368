import { Table, Tag } from "antd";
import React from "react";

const ReservedTickets = ({ data }) => {
    const columns = [
        {
            title: "Code",
            dataIndex: "ticketCode",
            key: "ticketCode",
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
        },
        {
            title: "Last",
            dataIndex: "lastName",
            key: "lastName",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Ticket Name",
            dataIndex: "ticketName",
            key: "ticketName",
        },
        {
            title: "Status",
            dataIndex: "used",
            key: "used",
            render: (used) => <Tag color={used ? "green" : "blue"}>
                {(used ? "checked-in" : "reserved").toUpperCase()}
            </Tag>
        },
    ];

    return (
        <div className="reserved-tickets-container">
            <Table
                className="reserved-tickets-table"
                pagination={false}
                dataSource={data}
                columns={columns}
            />
        </div>
    );
}

export default ReservedTickets;