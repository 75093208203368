import { Plus, X } from "@phosphor-icons/react";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FOLLOWING_ORG } from "../../../../common/constants/LocalStorageConstants";
import { followOrganization, unfollowOrganization } from "../../../../common/redux/actions/accountAction";
import "./ButtonFollowOrg.scss";

const ButtonFollowOrg = ({ organizer }) => {
    const { user } = useSelector((state) => state.auth);
    const [isFollowed, setIsFollowed] = useState(false);
    const dispatch = useDispatch();

    let followingList = (localStorage.getItem(FOLLOWING_ORG) || "")
        .split(",")
        .map(item => item?.trim().toString());

    useEffect(() => {
        setIsFollowed(
            followingList.includes(organizer?.id?.toString())
        );
    }, [followingList, organizer]);

    const handleClickFollow = () => {
        const params = {
            username: user?.id,
            organizerId: organizer?.id
        }
        try {
            if (isFollowed) {
                dispatch(unfollowOrganization(params));
                followingList = followingList.filter(id => id !== organizer?.id?.toString());
            } else {
                dispatch(followOrganization(params));
                followingList.push(organizer?.id.toString());
            }
            localStorage.setItem(FOLLOWING_ORG, followingList);
            setIsFollowed(isFollowed => !isFollowed);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Button
            className="org-v-card-btn"
            icon={isFollowed ? <X/> : <Plus/>}
            onClick={handleClickFollow}
        >
            {isFollowed ? "Unfollow" : "Follow"}
        </Button>
    );
};

export default ButtonFollowOrg;