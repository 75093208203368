export const CartModel = {
    cartReservationId: null,
    cartEventId: null,
    cartBuyer: {},
    cartTickets: [],
    cartVoucher: {},
    cartTotal: 0,
    cartSubtotal: 0,
    loading: null,
    success: null,
    error: null,
};
