import { Lightning, Plus, X } from "@phosphor-icons/react";
import { Button, Input, Tag, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./CreateEventTags.scss";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { generateEventContent } from "../../../../../common/redux/actions/contentAction";
import { createOrUpdateEventTags } from "../../../../../common/redux/actions/eventActions";

const CreateEventTags = ({ event }) => {

    const [tags, setTags] = useState(event?.tags || []);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    const dispatch = useDispatch();
    const [loadingBtnSuggestContent, setLoadingBtnSuggestContent] = useState(false);
    const [loadingBtnSave, setLoadingBtnSave] = useState(false);

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    useEffect(() => {
        editInputRef.current?.focus();
    }, [editInputValue]);

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && !tags.includes(inputValue)) {
            setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value);
    };

    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setEditInputValue('');
    };

    const handleSuggestTags = () => {
        if (event) {
            const request = {
                event_name: event.eventName,
                event_format: event.eventType,
                event_category: event.category,
                event_detail_info: event.description
            }
            setLoadingBtnSuggestContent(true);
            dispatch(generateEventContent(request))
                .unwrap()
                .then((response) => {
                    setTags(response.tags);
                })
                .catch((error) => {
                    toast.error("Error generate tags!");
                })
                .finally(() => {
                    setLoadingBtnSuggestContent(false);
                })
        }
    };

    const handleUpdateTags = () => {
        setLoadingBtnSave(true);
        const request = {
            tags: tags,
            eventId: event?.eventId
        }
        dispatch(createOrUpdateEventTags(request))
            .unwrap()
            .then((response) => {
                toast.success("Successfully add tags!");
            })
            .catch((error) => {
                toast.error("Error add tags!");
            })
            .finally(() => {
                setLoadingBtnSave(false);
            });
    }

    return (
        <div className="event-detail-info-section create-event-tags-container">
            <div className="event-detail-info-section-head">
                <div className="event-detail-info-section-title">
                    Event tags
                </div>
                <div className="event-detail-info-section-desc">
                    Add more details about your event and include what people can expect if they attend.
                </div>
            </div>

            <div className="create-event-tags-content">
                <div className="create-event-tags-content">
                    <div className="create-event-tags">
                        {
                            tags.map((tag, index) => {
                                if (editInputIndex === index) {
                                    return (
                                        <Input
                                            className="create-event-tag-input"
                                            ref={editInputRef}
                                            key={tag}
                                            value={editInputValue}
                                            onChange={handleEditInputChange}
                                            onBlur={handleEditInputConfirm}
                                            onPressEnter={handleEditInputConfirm}
                                        />
                                    );
                                }
                                const isLongTag = tag.length > 20;
                                const tagElem = <Tag
                                    key={tag}
                                    className="create-event-tag-item"
                                    closeIcon={<X weight="bold"/>}
                                    closable={true}
                                    style={{ userSelect: 'none' }}
                                    onClose={() => handleClose(tag)}
                                    bordered={false}
                                >
                                    #<span onDoubleClick={(e) => {
                                    setEditInputIndex(index);
                                    setEditInputValue(tag);
                                    e.preventDefault();
                                }}>
                                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                </span>
                                </Tag>;
                                return isLongTag
                                    ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip>
                                    : tagElem;
                            })
                        }
                        {
                            inputVisible
                                ? <Input
                                    className="create-event-tag-input"
                                    ref={inputRef}
                                    type="text"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onBlur={handleInputConfirm}
                                    onPressEnter={handleInputConfirm}
                                />
                                : <Tag className="create-event-tag-item tag-plus" icon={<Plus/>} onClick={showInput}>
                                    New Tag
                                </Tag>
                        }
                    </div>
                </div>
            </div>

            <div className="event-detail-info-section-buttons">
                <Button
                    onClick={handleUpdateTags}
                    loading={loadingBtnSave}
                >
                    Save
                </Button>
                <Button
                    className="highlight-button"
                    icon={<Lightning/>}
                    onClick={handleSuggestTags}
                    loading={loadingBtnSuggestContent}
                >
                    Suggest content
                </Button>
            </div>
        </div>
    );
};

export default CreateEventTags;
