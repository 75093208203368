import { ArrowsOutSimple, CalendarDots, CaretDoubleUp, CaretDown, X } from "@phosphor-icons/react";
import { Button, DatePicker, FloatButton, Select } from "antd";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { DateTimePattern } from "../../../common/enums/DateTimePattern";
import { SearchKeys } from "../../../common/enums/SearchKeys";
import { searchGenerics } from "../../../common/redux/actions/searchAction";
import { formatDateRange, getDateFromRange, getDateTimeObject } from "../../../common/utils/dateTimeUtils";
import { removeLocationStopWords } from "../../../common/utils/stringUtils";
import mapImg from "../../assets/images/maps.png";
import EventCardVertical from "../../modules/components/EventCardVertical/EventCardVertical";
import "./SearchResults.scss";
import Loading from "../../modules/components/Loading/Loading";
import NoDataFound from "../../modules/components/NoDataFound";
import OrganizationCardVertical from "../../modules/components/OrganizationCardVertical/OrganizationCardVertical";
import MasterListVertical from "../../modules/partials/MasterListVertical/MasterListVertical";
import SearchOnMap from "./partials/SearchOnMap";
import { eventCategoryItems, eventFormatItems, eventPriceItems, eventTimeItems } from "./searchOptions";


const SearchResults = () => {
    const defaultDateValue = "any_day";
    const defaultFormatValue = "any_format";
    const defaultPriceValue = "any_price";
    const defaultCategoryValue = [];
    const defaultLocationValue = "";

    // eslint-disable-next-line
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);

    const [searchSource, setSearchSource] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    const [eventResults, setEventResults] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectEventMarker, setSelectEventMarker] = useState();

    const [eventLocation, setEventLocation] = useState();
    const [eventCategories, setEventCategories] = useState([]);
    const [eventDateRange, setEventDateRange] = useState();
    const [eventStartDate, setEventStartDate] = useState("");
    const [eventEndDate, setEventEndDate] = useState("");
    const [eventFormat, setEventFormat] = useState();
    const [eventPrice, setEventPrice] = useState();
    const [datePlaceholder, setDatePlaceholder] = useState("Any day");

    const { setShowSidebar } = useOutletContext();
    const [searchOnMap, setSearchOnMap] = useState(false);
    const [resultItems, setResultItems] = useState([]);

    const [hoverMap, setHoverMap] = useState(false);
    const listRef = useRef(null);

    const dispatch = useDispatch();
    const { searchResults, hasMoreResults, loading } = useSelector((state) => state.search);

    useLayoutEffect(() => {
        setShowSidebar(false);
        document.title = "Discover more events | Eventure";
    });

    const handleFetchMoreResults = () => {
        fetchSearchResults(
            1500,
            searchQuery,
            eventStartDate,
            eventEndDate,
            eventFormat,
            eventPrice,
            eventCategories,
            eventLocation,
            offset
        );
    }

    const fetchSearchResults = (
        waitingTime,
        search_query,
        search_event_start_date,
        search_event_end_date,
        search_event_format,
        search_event_price,
        search_event_category,
        search_event_location,
        offsetValue
    ) => {
        const payload = {
            text: search_query,
            limit: offsetValue + 10,
            offset: offsetValue,
            startDateTime: search_event_start_date,
            endDateTime: search_event_end_date,
            eventType: search_event_format === defaultFormatValue ? null : search_event_format,
            isPaid: (search_event_price === defaultPriceValue) || !search_event_price ? null : search_event_price === "paid",
            category: search_event_category,
            location: search_event_location?.length === 0 ? null : search_event_location,
        };
        dispatch(searchGenerics(payload));
        setOffset(offset => offset + limit);
    }

    const initSearchValues = () => {
        const search_source = searchParams.get(SearchKeys.SEARCH_SOURCE) || "generics";
        setSearchSource(search_source);
        if (search_source === "events") return;

        const search_query = searchParams.get(SearchKeys.SEARCH_QUERY);
        const search_event_location = searchParams.get(SearchKeys.SEARCH_EVENT_LOCATION);
        const search_event_date_range = searchParams.get(SearchKeys.SEARCH_EVENT_DATE_RANGE);
        const search_event_format = searchParams.get(SearchKeys.SEARCH_EVENT_FORMAT);
        const search_event_price = searchParams.get(SearchKeys.SEARCH_EVENT_PRICE);
        const search_categories = searchParams.get(SearchKeys.SEARCH_CATEGORIES);

        let search_event_start_date = searchParams.get(SearchKeys.SEARCH_EVENT_START_DATE) || "";
        let search_event_end_date = searchParams.get(SearchKeys.SEARCH_EVENT_END_DATE) || "";

        if (search_event_date_range) {
            const { startDate, endDate } = getDateFromRange(search_event_date_range);
            search_event_start_date = startDate;
            search_event_end_date = endDate;
        }

        let search_event_category = [];
        if (search_categories) {
            search_event_category = search_categories.split(",").filter((item) => item.length > 0);
        }

        setSearchQuery(search_query);
        setEventLocation(search_event_location || defaultLocationValue);
        setEventDateRange(search_event_date_range || defaultDateValue);
        setEventStartDate(search_event_start_date);
        setEventEndDate(search_event_end_date);
        setEventFormat(search_event_format || defaultFormatValue);
        setEventPrice(search_event_price || defaultPriceValue);
        setEventCategories(search_event_category || defaultCategoryValue);

        setOffset(0);
        fetchSearchResults(
            0,
            search_query,
            search_event_start_date,
            search_event_end_date,
            search_event_format,
            search_event_price,
            search_event_category,
            search_event_location,
            0
        )
    }

    useEffect(() => {
        initSearchValues();
        // eslint-disable-next-line
    }, [searchParams, dispatch]);

    useEffect(() => {
        if (searchResults) {
            const newResultItems = offset - limit === 0 ? searchResults : [...resultItems, ...searchResults];
            setResultItems(newResultItems);
            const searchedEvents = newResultItems
                .filter((item) => item.eventDTO !== null)
                .map(item => item.eventDTO);
            setEventResults(searchedEvents);
        }
        // eslint-disable-next-line
    }, [searchResults]);

    const appendParam = (paramName, paramValue) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(paramName, paramValue);
        setSearchParams(newSearchParams);
    };

    const removeParam = (paramName) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(paramName);
        setSearchParams(newSearchParams);
    };

    const replaceParam = (oldParamNames, newParamName, newParamValue) => {
        const newSearchParams = new URLSearchParams(searchParams);
        oldParamNames.forEach((item) => {
            newSearchParams.delete(item);
        })
        newSearchParams.set(newParamName, newParamValue);
        setSearchParams(newSearchParams);
    };

    const handleChangeTime = (value) => {
        if (value === "any_day") {
            removeParam(SearchKeys.SEARCH_EVENT_DATE_RANGE);
        } else if (value === "custom") {
            removeParam(SearchKeys.SEARCH_EVENT_DATE_RANGE);
        } else {
            setEventStartDate("");
            setEventEndDate("");
            replaceParam(
                [
                    SearchKeys.SEARCH_EVENT_START_DATE,
                    SearchKeys.SEARCH_EVENT_END_DATE
                ],
                SearchKeys.SEARCH_EVENT_DATE_RANGE,
                value
            );
        }
    }

    const handleChangeFormat = (value) => {
        if (value === "any_format") {
            removeParam(SearchKeys.SEARCH_EVENT_FORMAT);
        } else {
            appendParam(SearchKeys.SEARCH_EVENT_FORMAT, value);
        }
    }

    const handleChangePrice = (value) => {
        if (value === "any_price") {
            removeParam(SearchKeys.SEARCH_EVENT_PRICE);
        } else {
            appendParam(SearchKeys.SEARCH_EVENT_PRICE, value);
        }
    }

    const handleChangeCategory = (value) => {
        if (value?.length === 0) {
            removeParam(SearchKeys.SEARCH_CATEGORIES);
        } else {
            appendParam(SearchKeys.SEARCH_CATEGORIES, value.join(","));
        }
    }

    const handleClearFilter = () => {
        const current_search_query = searchParams.get(SearchKeys.SEARCH_QUERY) || "";
        const current_search_location = searchParams.get(SearchKeys.SEARCH_EVENT_LOCATION) || "";

        let params = {};
        if (current_search_query.length > 0) {
            params[SearchKeys.SEARCH_QUERY] = current_search_query;
        }
        if (current_search_location.length > 0) {
            params[SearchKeys.SEARCH_EVENT_LOCATION] = current_search_location;
        }
        setSearchParams(params);
        setEventDateRange();
        setDatePlaceholder("Any day");
        setEventStartDate("");
        setEventEndDate("");
        setEventFormat(defaultFormatValue);
        setEventPrice(defaultPriceValue);
        setEventCategories(defaultCategoryValue);
    };

    const handleHighlightLocation = (item) => {
        if (searchOnMap) {
            setHoverMap(false);
            setSelectEventMarker(item?.eventDTO?.eventId);
        }
    }

    const handleChangeStartDate = (date, dateString) => {
        setEventDateRange();
        if (dateString) {
            let newDatePlaceholder = `From ${dateString}`;
            if (eventEndDate?.length > 0) {
                newDatePlaceholder = `${newDatePlaceholder} - To ${eventEndDate}`;
            }
            setDatePlaceholder(newDatePlaceholder);
            const startDate = formatDateRange(dateString, DateTimePattern.COMPLETED_DATE);
            replaceParam([SearchKeys.SEARCH_EVENT_DATE_RANGE], SearchKeys.SEARCH_EVENT_START_DATE, startDate);
        } else {
            if (eventEndDate) {
                setDatePlaceholder(`To ${eventEndDate}`);
            } else {
                setDatePlaceholder("Any day");
            }
            removeParam(SearchKeys.SEARCH_EVENT_START_DATE);
        }
    }

    const handleChangeEndDate = (date, dateString) => {
        setEventDateRange();
        if (dateString) {
            let newDatePlaceholder = `To ${dateString}`;
            if (eventStartDate?.length > 0) {
                newDatePlaceholder = `From ${eventStartDate} - ${newDatePlaceholder}`;
            }
            setDatePlaceholder(newDatePlaceholder);
            const endDate = formatDateRange(dateString, DateTimePattern.COMPLETED_DATE);
            replaceParam([SearchKeys.SEARCH_EVENT_DATE_RANGE], SearchKeys.SEARCH_EVENT_END_DATE, endDate);
        } else {
            if (eventStartDate) {
                setDatePlaceholder(`From ${eventStartDate}`);
            } else {
                setDatePlaceholder("Any day");
            }
            removeParam(SearchKeys.SEARCH_EVENT_END_DATE);
        }
    }

    const scrollToEvent = (activeEvent) => {
        const index = resultItems.findIndex((event) => event?.eventDTO?.eventId === activeEvent);
        if (listRef.current && index >= 0 && index < resultItems.length) {
            const targetEvent = listRef.current.children[index];
            const listRect = listRef.current.getBoundingClientRect();
            const targetRect = targetEvent.getBoundingClientRect();
            const scrollTop = targetRect.top - listRect.top;
            window.scrollTo({ top: scrollTop, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        if (selectEventMarker && hoverMap) {
            scrollToEvent(selectEventMarker);
        }
        // eslint-disable-next-line
    }, [selectEventMarker, hoverMap]);

    return (
        <div className="search-generics-container">
            <div className="search-generics-header">
                <div className="search-generics-title">
                    {
                        (searchSource && searchSource === "events")
                            ? <p>Events you may like</p>
                            : (eventLocation?.length > 0
                                ? <p>Events in <span>{removeLocationStopWords(eventLocation)}</span></p>
                                : <p>Results for <span>"{searchQuery}"</span></p>)
                    }
                </div>
                {
                    (searchSource && searchSource !== "events")
                    && <div className="search-generics-button-list">
                        <div className="search-generics-filter-time">
                            {
                                eventDateRange && <Select
                                    placeholder={datePlaceholder}
                                    variant="filled"
                                    value={eventDateRange}
                                    style={{ minWidth: 250 }}
                                    onChange={handleChangeTime}
                                    options={eventTimeItems}
                                    suffixIcon={<CaretDown weight="bold"/>}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <div className="filter-date-item">
                                                <p>Start date</p>
                                                <DatePicker
                                                    value={getDateTimeObject(eventStartDate, DateTimePattern.COMPLETED_DATE)}
                                                    style={{ width: "100%" }}
                                                    onChange={handleChangeStartDate}/>
                                            </div>
                                            <div className="filter-date-item">
                                                <p>End date</p>
                                                <DatePicker
                                                    value={getDateTimeObject(eventEndDate, DateTimePattern.COMPLETED_DATE)}
                                                    style={{ width: "100%" }}
                                                    onChange={handleChangeEndDate}
                                                />
                                            </div>
                                        </>
                                    )}
                                />
                            }
                        </div>
                        <div className="search-generics-filter-format">
                            {
                                eventFormat && <Select
                                    variant="filled"
                                    value={eventFormat}
                                    style={{ minWidth: 130 }}
                                    onChange={handleChangeFormat}
                                    options={eventFormatItems}
                                    suffixIcon={<CaretDown weight="bold"/>}
                                />
                            }
                        </div>
                        <div className="search-generics-filter-price">
                            {
                                eventPrice && <Select
                                    variant="filled"
                                    value={eventPrice}
                                    style={{ minWidth: 130 }}
                                    onChange={handleChangePrice}
                                    options={eventPriceItems}
                                    suffixIcon={<CaretDown weight="bold"/>}
                                />
                            }
                        </div>
                        <div className="search-generics-filter-category">
                            {
                                eventCategories && <Select
                                    variant="filled"
                                    placeholder="Any category"
                                    value={eventCategories}
                                    style={{ minWidth: 500, width: '100%' }}
                                    onChange={handleChangeCategory}
                                    options={eventCategoryItems}
                                    maxTagCount="responsive"
                                    mode="multiple"
                                    suffixIcon={<CaretDown weight="bold"/>}
                                />
                            }
                        </div>
                        <Button type="text" icon={<X/>} onClick={handleClearFilter}>
                            Clear filter
                        </Button>
                    </div>
                }
            </div>
            <div className="search-generics-main">
                <div ref={listRef} className="search-generics-list">
                    {
                        (searchSource && searchSource === "events")
                            ? <></>
                            : (
                                (resultItems && resultItems.length > 0)
                                    ? resultItems.map((item) => (
                                        item.eventDTO
                                            ? <div key={`event_${item.eventDTO.eventId}`}
                                                   className="search-generics-event-item"
                                                   onMouseEnter={() => handleHighlightLocation(item)}
                                                   onMouseLeave={() => handleHighlightLocation(null)}
                                            >
                                                <EventCardVertical
                                                    eventData={item.eventDTO}
                                                    selected={selectEventMarker === item.eventDTO.eventId}
                                                />
                                            </div>
                                            : <div key={`org_${item.organizationDTO.id}`}
                                                   className="search-generics-org-item">
                                                <OrganizationCardVertical organizer={item.organizationDTO}/>
                                            </div>
                                    ))
                                    : <NoDataFound
                                        icon={<CalendarDots size={60}/>}
                                        message="Sorry, there are no events results that match these filters!"
                                    />
                            )
                    }
                    {
                        hasMoreResults && <Button
                            type="text"
                            onClick={handleFetchMoreResults}
                        >
                            Load more results...
                        </Button>
                    }
                    {loading && <Loading/>}
                    {
                        (searchSource && searchSource === "events" && resultItems.length === 0)
                        && <div className="search-generics-other-events">
                            <MasterListVertical/>
                        </div>
                    }
                </div>
                <div className="search-generics-map">
                    {
                        !searchOnMap && <div className="search-generics-activate-map">
                            <Button
                                className="search-generics-activate-map-btn"
                                onClick={() => setSearchOnMap(true)}
                                disabled={(searchSource && searchSource === "events")}
                            >
                                Browse in map <ArrowsOutSimple/>
                            </Button>
                            <img src={mapImg} alt=""/>
                        </div>
                    }
                    {
                        (searchSource && searchSource === "events") && <p>Try search something to browse on map</p>
                    }
                    {
                        searchOnMap && <SearchOnMap
                            events={eventResults}
                            selectEventMarker={selectEventMarker}
                            setSelectEventMarker={setSelectEventMarker}
                            setSearchOnMap={setSearchOnMap}
                            setHoverMap={setHoverMap}
                        />
                    }
                </div>
            </div>
            <FloatButton.BackTop icon={<CaretDoubleUp/>}/>
        </div>
    );
};

export default SearchResults;