import { createSlice } from "@reduxjs/toolkit";
import { fetchMessagesForThread, sendMessage } from "../actions/messageAction";

const initialState = {
    loading: false,
    messages: [],
    error: null,
};


const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(sendMessage.pending ,(state, action) => {
                state.loading = true;
            })
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.loading = false;
              
            })
            .addCase(sendMessage.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(fetchMessagesForThread.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchMessagesForThread.fulfilled, (state, action) => {
                state.loading = false;
                state.messages = action.payload;
            })
            .addCase(fetchMessagesForThread.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.messages = [];
            })
    }
    
})

export default messageSlice.reducer;