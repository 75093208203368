import React from "react";
import "./EventForm.scss";
import BasicInfo from "../../blocks/BasicInfo";
import LocationInfo from "../../blocks/LocationInfo"
import DateTimeInfo from "../../blocks/DateTimeInfo";
import { Divider } from "antd";

function EventForm({ eventLocation, setEventLocation, isUpdateMode }) {
    return (
        <>
            <BasicInfo/>
            <Divider/>
            <LocationInfo
                eventLocation={eventLocation}
                setEventLocation={setEventLocation}
                isUpdateMode={isUpdateMode}
            />
            <Divider/>
            <DateTimeInfo/>
            <section className="separator"></section>
        </>
    );
}

export default EventForm;
