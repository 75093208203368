import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_ALL_NOTIFICATION } from "../types/notification";
import notificationService from "../../services/notificationService";


export const getAllNotifications = createAsyncThunk(
    GET_ALL_NOTIFICATION,
    async (data, {rejectWithValue}) => {
        try {
            return await notificationService.getAllNotifications(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)