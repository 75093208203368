import axios from "axios";
import axiosInstance from "../api";
import { VIDEOSDK_API_BASE_URL, VIDEOSDK_TOKEN } from "../constants/AppConfigConstants";


const meetingService = {
    createMeetingCode: async () => {
        try {
            const { roomId } = await axios.post(
                `${VIDEOSDK_API_BASE_URL}/v2/rooms`,
                {},
                {
                    headers: {
                        Authorization: VIDEOSDK_TOKEN,
                        "Content-Type": "application/json"
                    },
                }).then(res => res.data);
            return roomId;
        } catch (error) {
            console.error("Error create meeting code:", error);
            throw error;
        }
    },
    createMeetingRoom: async (payload) => {
        try {
            return await axiosInstance.post(
                `/api/v1/host/createOrUpdateMeetingRoom`,
                payload
            );
        } catch (error) {
            console.error("Error create meeting room:", error);
            throw error;
        }
    },
    getMeetingRoomForAttendee: async (params) => {
        try {
            return await axiosInstance.get(
                `/api/v1/audience/getMeetingInformation`,
                { params: params }
            );
        } catch (error) {
            console.error("Error get meeting room:", error);
            throw error;
        }
    }
};

export default meetingService;