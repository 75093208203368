import { Constants, useMeeting } from "@videosdk.live/react-sdk";
import { Button } from "antd";
import { useEffect, useMemo, useRef } from "react";
import useIsHls from "../../../../../../common/videosdk/hooks/useIsHls";

const HLSButton = () => {
    const { startHls, stopHls, hlsState } = useMeeting({});

    const isHls = useIsHls();

    const { isRequestProcessing } = useMemo(
        () => ({
            isRequestProcessing:
                hlsState === Constants.hlsEvents.HLS_STARTING ||
                hlsState === Constants.hlsEvents.HLS_STOPPING,
        }), [hlsState]);

    const { type, priority, gridSize } = useMemo(
        () => ({
            type: "SPOTLIGHT",
            priority: "PIN",
            gridSize: "12",
        }), []);

    const typeRef = useRef(type);
    const priorityRef = useRef(priority);
    const gridSizeRef = useRef(gridSize);
    const isHlsRef = useRef(isHls);

    useEffect(() => {
        typeRef.current = type;
    }, [type]);

    useEffect(() => {
        priorityRef.current = priority;
    }, [priority]);

    useEffect(() => {
        gridSizeRef.current = gridSize;
    }, [gridSize]);

    useEffect(() => {
        isHlsRef.current = isHls;
    }, [isHls]);

    const _handleStartHLS = () => {
        const type = typeRef.current;
        const priority = priorityRef.current;
        const gridSize = gridSizeRef.current;
        const layout = { type, priority, gridSize };
        startHls({ layout, theme: "DARK" });
    };

    const _handleClick = () => {
        const isHls = isHlsRef.current;
        if (isHls) stopHls();
        else _handleStartHLS();
    };

    return <Button
        size="large"
        type="primary"
        onClick={_handleClick}
        loading={isRequestProcessing}
    >
        {
            hlsState === Constants.hlsEvents.HLS_STARTED ||
            hlsState === Constants.hlsEvents.HLS_PLAYABLE
                ? "Stop session"
                : hlsState === Constants.hlsEvents.HLS_STARTING
                    ? "Starting session"
                    : hlsState === Constants.hlsEvents.HLS_STOPPED
                        ? "Start session"
                        : hlsState === Constants.hlsEvents.HLS_STOPPING
                            ? "Stopping session"
                            : "Start session"
        }
    </Button>;
};

export default HLSButton;