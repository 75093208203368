export const SearchKeys = Object.freeze({
    SEARCH_SOURCE: "source",
    SEARCH_QUERY: "query",
    SEARCH_CATEGORIES: "categories",
    SEARCH_EVENT_DATE_RANGE: "date_range",
    SEARCH_EVENT_START_DATE: "start_date",
    SEARCH_EVENT_END_DATE: "end_date",
    SEARCH_EVENT_FORMAT: "format",
    SEARCH_EVENT_PRICE: "price",
    SEARCH_EVENT_LOCATION: "location",
});