import { createAsyncThunk } from '@reduxjs/toolkit';
import statisticService from "../../services/statisticService";
import { COUNT_EVENT_PAGE_VIEW, GET_EVENT_STATISTIC, GET_ORGANIZATION_STATISTIC } from "../types/statistic";

export const countEventPageView = createAsyncThunk(
    COUNT_EVENT_PAGE_VIEW,
    async (data, { rejectWithValue }) => {
        try {
            return statisticService.countEventPageView(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getEventStatistic = createAsyncThunk(
    GET_EVENT_STATISTIC,
    async (data, { rejectWithValue }) => {
        try {
            return statisticService.getEventStatistic(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getOrganizationStatistic = createAsyncThunk(
    GET_ORGANIZATION_STATISTIC,
    async (data, { rejectWithValue }) => {
        try {
            return statisticService.getOrganizationStatistic(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);