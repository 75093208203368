import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSuggestedEvents } from "../../../../common/redux/actions/eventActions";
import recommend from "../../../assets/icons/recommend.svg";
import EventListHorizontal from "../../components/EventListHorizontal/EventListHorizontal";
import "./SuggestedEventList.scss";

const SuggestedList = ({ message }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { suggestedEvents } = useSelector((state) => state.event);

    useEffect(() => {
        const username = user?.id;
        if (username) {
            dispatch(getSuggestedEvents({ username: username }));
        }
    }, [user, dispatch]);

    return (
        <>
            {
                suggestedEvents?.length > 0 &&
                <div className="suggest-event-section-title">
                    <img src={recommend} alt=""/> {message}
                </div>
            }
            <div className="suggest-event-list-container">
                <div className="suggest-event-list-content">
                    {
                        suggestedEvents && <EventListHorizontal events={suggestedEvents}/>
                    }
                </div>
            </div>
        </>
    );
};

export default SuggestedList;
