export const OrganizationModel = {
    organization: null,
    organizations: null,
    coverUrl: null,
    organizer: null,
    loading: null,
    success: null,
    error: null,
    hasMoreOrganizations: false,
    organizationId: null
};
