import color from '../styles/color.scss';

const Button = {
    primaryShadow: "0 0 0 rgba(0, 0, 0, 0)",
    defaultShadow: "0 0 0 rgba(0, 0, 0, 0)",
    colorPrimary: color.secondaryColor,
    colorPrimaryActive: color.secondaryColor,
    colorPrimaryHover: color.secondaryColorHover,
    borderColorDisabled: null,
    defaultBg: color.slateColor6,
    defaultHoverBg: color.slateColor5,
    // defaultBorderColor: null,
    defaultHoverBorderColor: null,
    defaultActiveBorderColor: null,
    defaultHoverColor: color.greyColor4,
}

export default Button;