import React, { useEffect, useMemo, useState } from "react";
import Search from "antd/es/input/Search";
import OrdersList from "./partials/OrdersList";
import "./OrganizationOrders.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersById } from "../../../common/redux/actions/orderActions";
import { formatDateRange } from "../../../common/utils/dateTimeUtils";
import { DateTimePattern } from "../../../common/enums/DateTimePattern";


const formatOrderData = (ordersData) => {
    return ordersData?.map((order) => ({
        order: order?.orderCode,
        buyerEmail: order?.buyer?.email,
        orderDate: formatDateRange(order.orderDate, DateTimePattern.STANDARD_DATE_TIME),
        totalTicket: order?.totalTickets,
        grossAmount: `${order?.originalTotalAmount ? order?.originalTotalAmount.toLocaleString() : 0}`,
        netAmount: `${order?.totalAmount ? order?.totalAmount.toLocaleString() : 0}`,
    }))
}

function OrganizationOrders() {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const { orders } = useSelector(state => state.order);
    const [searchedName, setSearchedName] = useState("");

    useEffect(() => {
        if (eventId) {
            dispatch(getOrdersById({ eventId: eventId }));
        }
    }, [eventId, dispatch])


    const formattedData = useMemo(() => formatOrderData(orders), [orders]);

    const filteredData = useMemo(() => {
        return formattedData?.filter((item) =>
            item?.buyerEmail?.toLowerCase().includes(searchedName.toLowerCase()))
    }, [formattedData, searchedName]);

    const handleSearch = (value, _e, info) => {
        setSearchedName(value)
    }

    return (
        <div className="orders-page">
            <div className="orders-page-container">
                <h1 className="orders-heading">Reservations</h1>
                <div className="search-bar">
                    <Search
                        placeholder={"Search buyer"}
                        onChange={e => handleSearch(e.target.value)}
                        style={{ width: 300 }}
                        size="large"
                        enterButton
                    />
                </div>
                <OrdersList data={filteredData}/>
            </div>
        </div>);
}

export default OrganizationOrders;