import { Check, Copy } from "@phosphor-icons/react";
import { Button, Divider } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterShareButton,
    XIcon,
} from "react-share";
import { FACEBOOK_APP_ID } from "../../../../common/constants/AppConfigConstants";
import "./SocialShare.scss";
import messengerIcon from "../../../assets/images/messenger.png";

const SocialShare = ({ eventId }) => {
    const [copied, setCopied] = useState(false);
    const eventUrl = `${window.location.origin}/events/${eventId}`;

    const handleCopyEventLink = () => {
        navigator.clipboard.writeText(eventUrl).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    return (
        <div className="social-share-container">
            <div className="social-share-section-title">Link to event</div>
            <div className="social-share-url-content">
                <div className="social-share-url-bar">
                    <Link to={eventUrl} className="social-share-url-text">
                        {eventUrl}
                    </Link>
                </div>
                <Button
                    type="primary"
                    className="social-share-url-button"
                    icon={!copied ? <Copy/> : <Check/>}
                    onClick={handleCopyEventLink}
                >
                    {!copied ? "Copy" : "Copied"}
                </Button>
            </div>

            <Divider>or</Divider>

            <div className="social-share-section-title">Other platforms</div>
            <div className="social-share-button-list">
                <EmailShareButton url={eventUrl}>
                    <EmailIcon size={32} round={true}/>
                </EmailShareButton>
                <FacebookShareButton url={eventUrl}>
                    <FacebookIcon size={32} round={true}/>
                </FacebookShareButton>
                <FacebookMessengerShareButton url={eventUrl} appId={FACEBOOK_APP_ID}>
                    <img width={32} height={32} src={messengerIcon} alt={"messenger-icon"}/>
                </FacebookMessengerShareButton>
                <TwitterShareButton url={eventUrl}>
                    <XIcon size={32} round={true}/>
                </TwitterShareButton>
                <LinkedinShareButton url={eventUrl}>
                    <LinkedinIcon size={32} round={true}/>
                </LinkedinShareButton>
            </div>
        </div>
    );
}

export default SocialShare;