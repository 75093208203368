import { React, useEffect } from 'react';
import './UserInfoForm.scss';
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getUserProfile } from "../../../../../common/redux/actions/accountAction";
import Loading from "../../../../modules/components/Loading/Loading";
import ProfileInfo from "../../../Personal/components/ProfileInfo/ProfileInfo";

const UserInfoForm = () => {
    const { setPath } = useOutletContext();
    const { account, loading } = useSelector((state) => state.account);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        const username = user?.id;
        if (username) {
            const params = { username: username };
            dispatch(getUserProfile(params));
        }
    }, [dispatch, user]);

    return (
        <div className="user-info-form-container">
            <div className='info-form-title'>
                First, let us know a little bit about you
            </div>
            <div className='info-form-content'>
                {
                    loading
                        ? <Loading/>
                        : (account && <ProfileInfo account={account} isOnboarding={true} setPath={setPath}/>)
                }
            </div>
        </div>
    );
}

export default UserInfoForm;