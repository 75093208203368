import { House } from "@phosphor-icons/react";
import { Alert, Button, Form, Input, Tooltip } from "antd";
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { toast } from "sonner";
import { DateTimePattern } from "../../../common/enums/DateTimePattern";
import { getEventById } from "../../../common/redux/actions/eventActions";
import { createEventFeedback, getFeedbackForEventOfUser } from "../../../common/redux/actions/feedbackAction";
import { formatDateRange } from "../../../common/utils/dateTimeUtils";
import { rateLabels, rates } from "../../../common/utils/feedbackUtils";
import "./FeedbackEvent.scss";
import successful from "../../assets/icons/successful.svg";

const FeedbackEvent = () => {
    const { setShowSidebar } = useOutletContext();
    const { eventId } = useParams();
    const [showMsg, setShowMsg] = useState(false);
    const [rateValue, setRateValue] = useState(5);
    const [rateMessage, setRateMessage] = useState("Very good");
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { feedback } = useSelector((state) => state.feedback);
    const { event } = useSelector((state) => state.event);
    const { user } = useSelector((state) => state.auth);

    useLayoutEffect(() => {
        setShowSidebar(false);
        document.title = "Create feedback";
    });

    useEffect(() => {
        const username = user?.id;
        if (username && eventId) {
            const params = { username: username, eventId: eventId };
            dispatch(getFeedbackForEventOfUser(params));
            dispatch(getEventById({ eventId: eventId }));
        }
    }, [user, eventId, dispatch]);

    useEffect(() => {
        if (feedback) {
            setRateValue(feedback?.rating || 5);
            setRateMessage(rateLabels[feedback?.rating]);
        }
    }, [feedback]);

    const handleChangeFeedbackRate = (rateItem) => {
        setRateMessage(rateItem.message);
        setRateValue(rateItem.value);
        form.setFieldValue("feedback_rate", rateItem.value);
    }

    const onFinish = (values) => {
        const payload = {
            eventId: eventId,
            username: user?.id,
            comment: values.feedback_comment || "",
            rating: values?.feedback_rate || 5,
        };
        dispatch(createEventFeedback(payload))
            .unwrap()
            .then((response) => {
                // toast.success("Successfully create feedback for this event!");
                setShowMsg(true);
                const username = user?.id;
                if (username && eventId) {
                    const params = { username: username, eventId: eventId };
                    dispatch(getFeedbackForEventOfUser(params));
                }
            })
            .catch((error) => {
                toast.error("Failed to feedback for this event!");
            });
    }

    return (
        <div className="feedback-event-container">
            <div className="feedback-event-head">
                {
                    showMsg && <div className="feedback-event-message">
                        <Alert message="Successfully submit feedback for this event!" type="info" closable/>
                    </div>
                }
                {event && <div className="feedback-event-title">Feedback for
                    <p>{event?.eventName}</p>
                </div>}
                <div className="feedback-event-created-time">
                    {feedback
                        ? <p>Created at {formatDateRange(feedback?.timestamp, DateTimePattern.STANDARD_DATE_TIME)}</p>
                        : <p>Thoughtful and truthful feedback will be very valuable to the organizer.</p>}
                </div>
            </div>
            <div className="feedback-event-content">
                {feedback && <div className="feedback-event-thanks-message">
                    <img src={successful} alt="successfull_icon"/>
                    <p className="thanks-message-primary">Thanks for your feedback!</p>
                    <p className="thanks-message-text">
                        This will help organizer a lots to improve quality of the events.
                    </p>
                </div>}
                <Form
                    form={form}
                    layout="vertical"
                    variant="filled"
                    onFinish={onFinish}
                    initialValues={{
                        feedback_rate: feedback ? feedback.rating : 5,
                        feedback_comment: feedback ? feedback.comment : "",
                    }}
                >
                    <Form.Item
                        name="feedback_rate"
                        label="How do you feel about this event?"
                    >
                        <div className="feedback-event-rate">
                            <div className="feedback-event-rate-values">
                                {
                                    rates.map((item) => (
                                        <div key={item.key}>
                                            <Tooltip title={item.message} placement="bottom" arrow={false}>
                                                    <span
                                                        className={`feedback-event-rate-item ${item.value === rateValue && "selected"}`}
                                                        onClick={() => handleChangeFeedbackRate(item)}>
                                                        {item.icon}
                                                    </span>
                                            </Tooltip>
                                        </div>
                                    ))
                                }
                            </div>
                            <p className="feedback-event-rate-message">{rateMessage}</p>
                        </div>
                    </Form.Item>
                    <Form.Item
                        name="feedback_comment"
                        label="Share your thoughts..."
                    >
                        <Input.TextArea
                            placeholder="Add your comments"
                            autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                    </Form.Item>
                    <div className="feedback-event-button">
                        {
                            !feedback && <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        }
                        <Link to={"/"}>
                            <Button icon={<House/>}>Back to Home</Button>
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default FeedbackEvent;