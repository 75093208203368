import { PencilSimple, Trash } from "@phosphor-icons/react";
import { Button, Modal, Space, Table, Tag } from "antd";
import React, { useState } from "react";
import "./OrganizationEvents.scss";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { deleteEventById } from "../../../../../common/redux/actions/eventActions";
import dangerIcon from "../../../../assets/icons/danger.svg";

function OrganizationEvents({ updateEvent, createdEvents }) {
    const [deleteRecord, setDeleteRecord] = useState();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const dispatch = useDispatch();

    createdEvents = createdEvents.map((event) => ({
        ...event,
        ticketSold: `${event.ticketStatus?.soldQty} / ${event.ticketStatus.totalQty}`,
        eventName: <Link className="event-item-name"
                         to={`/organization/manage/events/${event.eventId}/dashboard`}>{event.eventName}</Link>
    }));

    const eventType = (_, { eventType }) => (
        <Tag color={eventType === "online" ? "green" : "blue"}>
            {eventType.toUpperCase()}
        </Tag>
    );

    const eventStatus = (_, { status }) => (
        <Tag color={handleEventStatus(status)}>
            {status?.toUpperCase()}
        </Tag>
    );
    
    const handleEventStatus = (status) => {
        switch (status) {
            case "Published":
                return "green";
            case "Draft":
                return "yellow";
            case "Ended":
                return "red";
            case "Happening":
                return "blue";
            default:
                return "green";
        }
    };

    const handleCheckDeleteEvent = (record) => {
        const currentDate = new Date();
        const eventStartDate = new Date(record?.startDateTime);
        const eventEndDate = new Date(record?.endDateTime);
        if (currentDate >= eventStartDate && currentDate < eventEndDate) {
            toast.error(`The event is currently ongoing and cannot be deleted.`)
            return;
        }
        showModalDelete();
        setDeleteRecord(record);
    };

    const columns = [
        {
            title: "Title",
            dataIndex: "eventName",
            key: "eventName",
        },
        {
            title: "Format",
            dataIndex: "eventType",
            key: "eventType",
            width: 90,
            render: eventType
        },
        {
            title: "Start Time",
            dataIndex: "startDateTime",
            key: "startDateTime",
            width: 170,
        },
        {
            title: "End Time",
            dataIndex: "endDateTime",
            key: "endDateTime",
            width: 170,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: eventStatus
        },
        {
            title: "Ticket Sold",
            dataIndex: "ticketSold",
            key: "ticketSold",
            width: 120,
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        icon={<PencilSimple/>}
                        onClick={() => updateEvent(record?.eventId)}
                    >
                        Update
                    </Button>
                    <Button
                        icon={<Trash/>}
                        onClick={() => handleCheckDeleteEvent(record)}
                        danger={true}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    const showModalDelete = () => {
        setOpenModalDelete(true);
    };

    const handleOkModalDelete = async () => {
        setOpenModalDelete(false);
        try {
            const res = await dispatch(deleteEventById(deleteRecord?.eventId)).unwrap()
            if (res) {
                toast.success("Delete successfully");
            }
        } catch (error) {
            toast.error(error || "Delete failed");
        }
    }

    const handleCancelModalDelete = () => {
        setOpenModalDelete(false);
    }

    return (
        <>
            <div className="event-list-container">
                <Table className={"event-table"} columns={columns} dataSource={createdEvents}/>
            </div>
            <Modal
                title="Do you want to delete this event?"
                open={openModalDelete}
                onOk={handleOkModalDelete}
                onCancel={handleCancelModalDelete}
                centered={true}
            >
                <div className="modal-delete-event">
                    <img className="modal-delete-event-icon" src={dangerIcon} alt="dander"/>
                    <p>When clicked the OK button, event will be deleted.</p>
                </div>
            </Modal>
        </>
    );
}

export default OrganizationEvents;
