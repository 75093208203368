import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import ThreadList from "./partials/ThreadList";
import "./ChatPage.scss";
import MainConversation from "./partials/MainConversation";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { ApplicationMode } from "../../../common/enums/ApplicationMode";
import { fetchThreadsForOrg, fetchThreadsForUser } from "../../../common/redux/actions/threadAction";
import supabase from "../../../common/supbaseClient";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessagesForThread } from "../../../common/redux/actions/messageAction";
import { Result } from "antd";
import { ChatText } from "@phosphor-icons/react";
import Loading from "../../modules/components/Loading/Loading";
import {getOrganizerProfile } from "../../../common/redux/actions/organizationActions";
import { getUserProfile } from "../../../common/redux/actions/accountAction";


const useThreadSubscription = (dispatch, mode, organizationId, user) => {
    const location = useLocation();
    const isManageEventPage = useMemo(() => location.pathname.includes("/organization/manage/"), [location.pathname]);
    const { setShowFooter } = useOutletContext();

    useEffect(() => {
        if (!isManageEventPage) {
            setShowFooter(false)
        }
    }, [isManageEventPage, setShowFooter])

    useEffect(() => {
        const fetchInitialThreads = async () => {
            const username = user?.id;
            try {
                if (mode === ApplicationMode.ATTENDEE) {
                    dispatch(fetchThreadsForUser(username));
                } else {
                    dispatch(fetchThreadsForOrg(organizationId));
                }
            } catch (error) {
                console.error("Error fetching threads:", error);
            }
        };

        fetchInitialThreads();

        const threadSubscription = supabase
            .channel("thread")
            .on('postgres_changes', {
                event: "INSERT", schema: "public", table: "thread"
            }, () => fetchInitialThreads())
            .subscribe();

        return () => threadSubscription.unsubscribe();
    }, [dispatch, mode, organizationId, user]);
};

const useMessageSubscription = (dispatch, threadId) => {
    useEffect(() => {
        const fetchInitialMessages = async () => {
            try {
                if (threadId) {
                    await dispatch(fetchMessagesForThread(threadId));
                }
            } catch (error) {
                console.error("Error fetching initial messages:", error);
            }
        };

        fetchInitialMessages();

        const messageSubscription = supabase
            .channel('message')
            .on('postgres_changes', {
                event: "INSERT", schema: 'public', table: 'message'
            }, () => fetchInitialMessages())
            .subscribe();

        return () => messageSubscription.unsubscribe();
    }, [dispatch, threadId]);
};

function ChatPage() {
    const { setShowSidebar } = useOutletContext();
    const { threadId } = useParams();
    const dispatch = useDispatch();
    const { organization, organizations } = useSelector((state) => state.organization);
    const { threads, loading } = useSelector(state => state.thread);
    const [mappedThreads, setMappedThreads] = useState();
    const [selectedThread, setSelectedThread] = useState();
    const { mode } = useSelector(state => state.auth);
    const { user } = useSelector(state => state.auth);
    const orgId = useMemo(() => organization?.organizationId, [organization]);


    useLayoutEffect(() => {
        document.title = "Messages | Eventure";
        if (setShowSidebar) setShowSidebar(false);
    });
    
    useEffect(() => {
        const fetchOrgInfoForThreads = async () => {
            const threadsWithOrgData = threads.filter(thread => thread.organizationData);
            if (threadsWithOrgData.length === threads.length) {
                return;
            }
            const orgInfoPromises = threads.map(async (thread) => {
                if (!thread.organizationData) {
                    const organizationId = thread.groupAdmin;
                    const orgInfo = await dispatch(getOrganizerProfile({ organizerId: organizationId })).unwrap();
                    const user = await dispatch(getUserProfile({username: thread?.user})).unwrap();
                    return orgInfo ? { ...thread, organizationData: orgInfo, user } : thread;
                }
                return thread;
            });
            
            const mappedThreads = await Promise.all(orgInfoPromises);
            setMappedThreads(mappedThreads);
            
            if (threadId) {
                const initialThread = mappedThreads?.find(thread => thread.id === threadId);
                if (initialThread) setSelectedThread(initialThread);
            }
            
        };
        
        fetchOrgInfoForThreads();
    }, [threads]);


    useThreadSubscription(dispatch, mode, orgId, user);
    useMessageSubscription(dispatch, threadId);

    const isNoMessages = useMemo(() => threads.length === 0 && !threadId && !loading, [threads, threadId, loading]);

    if (isNoMessages) {
        return (
            <div className={"no-message-yet"}>
                <Result
                    icon={<ChatText size={50}/>}
                    title="No messages yet"
                />
            </div>
        );
    }

    if (loading) {
        return (
            <Loading/>
        )
    }

    return (
        <div className={"message-page-container"}>
            <ThreadList mode={mode} threads={mappedThreads} setSeletedThread={setSelectedThread}/>
            {(selectedThread || threadId) && <MainConversation thread={selectedThread}/>}
        </div>
    );
}

export default ChatPage;