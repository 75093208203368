import { Webcam } from "@phosphor-icons/react";
import { Button, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { MeetingProviders } from "../../../../common/enums/MeetingProviders";
import "./MeetingBanner.scss";
import { checkinOnlineEvent } from "../../../../common/redux/actions/checkinAction";
import { getRemainingTimeMessage, getRemainingTime } from "../../../../common/utils/dateTimeUtils";
import { capitalizeFirstLetter } from "../../../../common/utils/stringUtils";

const MeetingBanner = ({ meeting }) => {
    const [loadingCheckinBtn, setLoadingCheckinBtn] = useState(false);
    const [timeMessage, setTimeMessage] = useState("");
    const [allowJoin, setAllowJoin] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    const handleClickJoin = () => {
        setLoadingCheckinBtn(true);
        const username = user?.id;;
        const params = {
            username: username,
            eventId: meeting.eventId,
        }
        dispatch(checkinOnlineEvent(params))
            .unwrap()
            .then((res) => {
                if (res) {
                    if (meeting?.meetingProvider === MeetingProviders.EVENTURE) {
                        navigate(`/attend-online-event/${meeting.eventId}/${meeting.locator}`);
                    } else {
                        window.open(meeting.locator, '_blank', 'noopener,noreferrer');
                    }
                } else {
                    toast.info("You are not admitted to join this online event");
                }
            })
            .catch((error) => {
                toast.error("Error check you in for this event!");
            })
            .finally(() => {
                setLoadingCheckinBtn(false);
            });
    }
    useEffect(() => {
        const updateRemainingTime = () => {
            const remainTime = getRemainingTime(meeting.startDateTime);

            if (remainTime.days <= 0 && remainTime.hours <= 0) {
                if (remainTime.minutes <= 30) setAllowJoin(true);
                if (remainTime.minutes <= 0) {
                    setTimeMessage("Happening now");
                    return;
                }
            }

            const remainTimeMessage = getRemainingTimeMessage(remainTime);
            setTimeMessage(`Start in ${remainTimeMessage}`);
        };
        let counter;
        if (meeting) {
            const now = Date.now();
            const eventEndTime = new Date(meeting.endDateTime).getTime();
            const diff = eventEndTime - now;
            if (diff < 0) {
                setTimeMessage("This event has ended.");
                setAllowJoin(false);
            } else {
                counter = setInterval(updateRemainingTime, 1000);
            }
        }
        return () => clearInterval(counter);
    }, [meeting]);

    return (
        <div className="meeting-banner-container">
            {meeting &&
                <>
                    <div className="meeting-banner-head">
                        <Link to={`/events/${meeting.eventId}`}
                              target={"_blank"}
                              className="meeting-banner-event-name">
                            {meeting?.eventName}
                        </Link>
                        <div className="meeting-banner-time-label">
                            <Tag bordered={false} color="processing">
                                {timeMessage}
                            </Tag>
                        </div>
                    </div>
                    <div className="meeting-banner-main">
                        <div className="meeting-banner-event-thumbnail">
                            <img src={meeting?.eventThumbnail} alt=""/>
                        </div>
                        <div className="meeting-banner-description">
                            {meeting.description}
                        </div>
                    </div>
                    <Button
                        loading={loadingCheckinBtn}
                        onClick={handleClickJoin}
                        type="primary"
                        icon={<Webcam/>}
                        disabled={!allowJoin}
                    >
                        Join via {capitalizeFirstLetter(
                        meeting.meetingProvider !== MeetingProviders.GOOGLE_MEET
                            ? meeting.meetingProvider
                            : "Google Meet"
                    )}
                    </Button>
                    {(!allowJoin && timeMessage !== "This event has ended.")
                        && <div className="meeting-banner-join-msg">
                            You will be able to join 30 minutes before the event start.
                        </div>}
                </>
            }
        </div>
    );
};

export default MeetingBanner;