import axiosInstance from "../api";

const eventService = {
    createEvent: async (eventData) => {
        try {
            return await axiosInstance.post(`/api/v1/host/createEvent`, eventData);
        } catch (error) {
            console.error("Error creating events:", error);
            throw error;
        }
    },
    getAllEvents: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/audience/getAllEvents`, request);
        } catch (error) {
            console.error("Error get all events:", error);
            throw error;
        }
    },
    getEventById: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/audience/getEventById`, {
                params: params,
            });
        } catch (error) {
            console.error("Error get event by id:", error);
            throw error;
        }
    },
    getFavoriteEvents: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/audience/getFavoriteEvents`,
                { params: params }
            );
        } catch (error) {
            console.error("Error get favorite events:", error);
            throw error;
        }
    },
    addEventToFavorite: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/audience/addEventToFavorite`, request);
        } catch (error) {
            console.error("Error add event to favorite:", error);
            throw error;
        }
    },
    removeEventFromFavorite: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/audience/removeFavoriteEvent`, request);
        } catch (error) {
            console.error("Error remove event from favorite:", error);
            throw error;
        }
    },
    getCreatedEvents: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/host/getAllEvents`, request);
        } catch (error) {
            console.error("Error get all events:", error);
            throw error;
        }
    },
    deleteEventbyId: async (id) => {
        try {
            return await axiosInstance.post(`/api/v1/host/deleteEventById`, id);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getFavoriteEventIds: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/audience/getFavoriteEventIds`,
                { params: params }
            );
        } catch (error) {
            // console.error("Error get favorite events:", error);
            // throw error;
        }
    },
    publishEventById: async (id) => {
        try {
            return await axiosInstance.post(`/api/v1/host/publishEvent`, id);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    unPublishEventById: async (id) => {
        try {
            return await axiosInstance.post(`/api/v1/host/unpublishEvent`, id);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getEventsByOrganizationId: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/audience/getEventsByOrganizationId`, request);
        } catch (error) {
            console.error("Error get events by organization id:", error);
            throw error;
        }
    },
    getEventsByFollowingOrganizations: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/audience/getEventsByFollowingOrganizations`, request);
        } catch (error) {
            console.error("Error get events by following organizations:", error);
            throw error;
        }
    },
    updateEvent: async (eventData) => {
        try {
            return await axiosInstance.post(`/api/v1/host/updateBasicEventInformation`, eventData);
        } catch (error) {
            console.error("Error updating events:", error);
            throw error;
        }
    },
    createOrUpdateEventTags: async (tagData) => {
        try {
            return await axiosInstance.post(`/api/v1/host/addEventTags`, tagData);
        } catch (error) {
            console.error("Error create/update event tags:", error);
            throw error;
        }
    },
    createOrUpdateEventFAQ: async (faqData) => {
        try {
            return await axiosInstance.post(`/api/v1/host/createOrUpdateEventFaq`, faqData);
        } catch (error) {
            console.error("Error create/update event FAQs:", error);
            throw error;
        }
    },
    createOrUpdateEventAgenda: async (agendaData) => {
        try {
            return await axiosInstance.post(`/api/v1/host/createOrUpdateEventAgenda`, agendaData);
        } catch (error) {
            console.error("Error create/update event agendas:", error);
            throw error;
        }
    },
    createOrUpdateEventSummary: async (payload) => {
        try {
            return await axiosInstance.post(`/api/v1/host/createOrUpdateEventSummary`, payload);
        } catch (error) {
            console.error("Error create/update event summary:", error);
            throw error;
        }
    },
    createOrUpdateEventDescription: async (payload) => {
        try {
            return await axiosInstance.post(`/api/v1/host/createOrUpdateEventDescription`, payload);
        } catch (error) {
            console.error("Error create/update event description:", error);
            throw error;
        }
    },
    getSuggestedEventsByUser: async (params) => {
        try {
            return await axiosInstance.get(
                `/api/v1/audience/getRecommendationEvents`,
                { params: params }
            );
        } catch (error) {
            // console.error("Error get recommended events:", error);
            // throw error;
        }
    },
    getPastEvent: async (payload) => {
        try {
            return await axiosInstance.post(`/api/v1/audience/getPastEvent`, payload);
        } catch (error) {
            console.error("Error get past event:", error);
            throw error;
        }
    },
    getEventByIdOrg: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/host/getEventById`, {params: params } );
        } catch (error) {
            console.error("Error get event by id:", error);
            throw error;
        }
    }
};

export default eventService;
