import { Button } from "antd";
import React from "react";
import failedIcon from "../../../../assets/icons/fail.svg";
import "./PaymentFailed.scss";

const PaymentFailed = () => {
    return (
        <div className="payment-failed-container">
            <div className="ticket-finish-message">
                <img src={failedIcon} alt="done"/>
                <p className="message-primary">Sorry</p>
                <p className="message-secondary">Your reservation was failed due to an unknown error!</p>
            </div>
            <Button href={"/"} type="primary" block={true}>Back to Home</Button>
        </div>
    );
};

export default PaymentFailed;