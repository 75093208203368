import { createSlice } from "@reduxjs/toolkit";
import { PaymentModel } from "../../models/paymentModel.model";
import { getMomoQrCode, purchaseFreeOrder, verifyFreeOrder, verifyMomoPaymentStatus } from "../actions/paymentAction";


const paymentSlice = createSlice({
    name: 'payment',
    initialState: PaymentModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMomoQrCode.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMomoQrCode.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
                state.momoQrCode = action.payload;
            })
            .addCase(getMomoQrCode.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(verifyMomoPaymentStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(verifyMomoPaymentStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
                state.paymentStatus = action.payload;
            })
            .addCase(verifyMomoPaymentStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(purchaseFreeOrder.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(purchaseFreeOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(purchaseFreeOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(verifyFreeOrder.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(verifyFreeOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(verifyFreeOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
    },
});

export default paymentSlice.reducer;
