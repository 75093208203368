import { CaretRight } from "@phosphor-icons/react";
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { IS_LOGGED_IN } from "../../../common/constants/LocalStorageConstants";
import { getInterestedCategories } from "../../../common/redux/actions/accountAction";
import EventListOfFollowingOrgs from "../../modules/components/EventListOfFollowingOrgs/EventListOfFollowingOrgs";
import SuggestedList from "../../modules/components/SuggestedEventList/SuggestedEventList";
import MasterList from '../../modules/partials/MasterList/MasterList';
import './Home.scss';
import FeaturedEvents from "./partials/FeaturedEvents";
import OrganizerMasterList from "./partials/OrganizerMasterList";

export default function Home() {
    const [checkOrgProfile, setCheckOrgProfile] = useState(false);
    const { setShowSidebar } = useOutletContext();
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = localStorage.getItem(IS_LOGGED_IN);

    useLayoutEffect(() => {
        setShowSidebar(false);
        document.title = "Eventure";
    });

    useEffect(() => {
        const username = user?.id;
        if (username) {
            const params = { username: username };
            dispatch(getInterestedCategories(params))
                .unwrap()
                .then((res) => {
                    if (res.length === 0) {
                        setCheckOrgProfile(true);
                    }
                });
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (user && checkOrgProfile)
            navigate("/onboarding/user-info");
    }, [user, checkOrgProfile, navigate]);

    return (
        <div className="home-container">
            <div className="featured-event-section">
                <FeaturedEvents/>
            </div>
            <div className="home-section section-master-list">
                <div className="home-section-header">
                    <Link to="/find?source=events" reloadDocument className="home-section-title">
                        Latest Events <CaretRight size={16} weight="bold"/>
                    </Link>
                </div>
                <MasterList/>
            </div>

            {
                isLoggedIn && <div className="home-section section-suggest-list">
                    <SuggestedList message={"Suggested Events"}/>
                </div>
            }

            <div className="home-section section-following-org-list">
                <div className="home-section-header">
                    <Link to="/organizers" reloadDocument className="home-section-title">
                        Featured Organizers <CaretRight size={16} weight="bold"/>
                    </Link>
                </div>
                <OrganizerMasterList/>
            </div>

            {
                isLoggedIn === "true" && <div className="home-section section-following-org-list">
                    <EventListOfFollowingOrgs/>
                </div>
            }
        </div>
    );
}
