import { createAsyncThunk } from '@reduxjs/toolkit';
import reportService from "../../services/reportService";
import { REPORT_EVENT } from "../types/report";

export const reportEvent = createAsyncThunk(
    REPORT_EVENT,
    async (data, { rejectWithValue }) => {
        try {
            return reportService.reportEvent(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);