import { Table } from "antd";
import React from "react";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";

const RecentOrdersEvent = ({ orders }) => {
    const columns = [
        {
            title: "Order",
            dataIndex: "orderId",
            key: "orderId",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (value) => Math.round(value).toLocaleString(),
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (value) => formatDateRange(value, DateTimePattern.TICKET_DATE_TIME),
        },
    ];

    return (
        <div className="recent-orders-event-container">
            <Table dataSource={orders} columns={columns}/>
        </div>
    );
}

export default RecentOrdersEvent;