import { createSlice } from "@reduxjs/toolkit";
import { getAllNotifications } from "../actions/notificationActions";

const initialState = {
    loading: false,
    notifications: [],
    notification: null,
    error: null,
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllNotifications.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAllNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.notifications = action.payload;
            })
            .addCase(getAllNotifications.rejected, (state, action) => {
                state.loading = false;
                state.notifications = [];
                state.error = action.payload;
            })
    }
    
})

export default notificationSlice.reducer;
