import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { Carousel } from 'antd';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./FeaturedEvents.scss";


const FeaturedEvents = () => {
    const { events } = useSelector((state) => state.event);
    const [hotEvents, setHotEvents] = useState([]);

    useEffect(() => {
        if (events && events.length > 0) {
            setHotEvents(events.slice(0, 5));
        }
    }, [events]);

    return (
        <>
            {
                hotEvents?.length > 0
                    ? <div className="featured-event-container">
                        <Carousel
                            autoplay
                            arrows
                            prevArrow={<CaretLeft/>}
                            nextArrow={<CaretRight/>}
                        >
                            {
                                hotEvents?.map((event) => (
                                    <Link
                                        to={`/events/${event.eventId}`}
                                        key={event?.eventId}
                                        className="featured-event-item"
                                    >
                                        <img src={event.thumbnailUrl} alt={""}/>
                                    </Link>
                                ))
                            }
                        </Carousel>
                    </div>
                    : <></>
            }
        </>
    );
};

export default FeaturedEvents;
