
export const rateLabels = {
    1: "Worst",
    2: "Bad",
    3: "Fine",
    4: "Look good",
    5: "Very good",
}

export const rates = [
    {
        key: 1,
        value: 1,
        message: "Worst",
        icon: "😓"
    },
    {
        key: 2,
        value: 2,
        message: "Bad",
        icon: "☹️"
    },
    {
        key: 3,
        value: 3,
        message: "Fine",
        icon: "🙄"
    },
    {
        key: 4,
        value: 4,
        message: "Look good",
        icon: "😃"
    },
    {
        key: 5,
        value: 5,
        message: "Very good",
        icon: "😍"
    }
];