import { Heart, ShareFat } from "@phosphor-icons/react";
import { Button, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FAVORITE } from "../../../../common/constants/LocalStorageConstants";
import { EventType } from "../../../../common/enums/Event";
import "./EventBanner.scss";
import { addEventToFavorite, removeEventFromFavorite } from "../../../../common/redux/actions/eventActions";
import SocialShare from "../../components/SocialShare";

const EventBanner = ({ eventData, showAttend }) => {
    const dispatch = useDispatch();
    const [isLiked, setIsLiked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const { eventId, eventName, eventType, minPrice, thumbnailUrl } = eventData;

    let likedList = (localStorage.getItem(FAVORITE) || "")
        .split(",")
        .map(item => item?.trim().toString());

    useEffect(() => {
        if (likedList?.length > 0) {
            setIsLiked(
                likedList.includes(eventData?.eventId?.toString())
            );
        }
    }, [likedList, eventData]);

    const handleClickLike = () => {
        const params = {
            username: user?.id,
            eventId: eventId
        }
        try {
            if (isLiked) {
                dispatch(removeEventFromFavorite(params));
                likedList = likedList.filter(id => id !== eventId?.toString());
            } else {
                dispatch(addEventToFavorite(params));
                likedList.push(eventId?.toString());
            }
            localStorage.setItem(FAVORITE, likedList);
            setIsLiked(isLiked => !isLiked);
        } catch (error) {
            console.error(error);
        }
    }
    const showModal = () => setIsModalOpen(true);
    const handleOk = () => setIsModalOpen(false);
    const handleCancel = () => setIsModalOpen(false);

    return (
        <>
            <div className="event-banner-container">
                <div className="event-banner-info">
                    {thumbnailUrl && (
                        <img
                            className="event-banner-avatar"
                            src={thumbnailUrl}
                            alt="card"
                        />
                    )}
                    <div className="event-banner-title">
                        {eventName && <div className="event-name">{eventName}</div>}
                        <div className="event-tag-list">
                            {eventType && (
                                <Tag bordered={false} color="blue">
                                    {eventType === EventType.ONLINE
                                        ? EventType.ONLINE.toUpperCase()
                                        : EventType.OFFLINE.toUpperCase()}
                                </Tag>
                            )}
                            {minPrice >= 0 && (
                                <Tag bordered={false}>
                                    {minPrice > 0 ? `Paid event`.toUpperCase() : `Free`.toUpperCase()}
                                </Tag>
                            )}
                        </div>
                    </div>
                </div>
                <div className="event-banner-button">
                    <Button
                        danger={true}
                        className="event-banner-button-like"
                        icon={<Heart weight={isLiked ? "fill" : "regular"}/>}
                        onClick={handleClickLike}
                    />
                    <Button
                        className="event-banner-button-share"
                        type="default"
                        shape="default"
                        icon={<ShareFat/>}
                        onClick={showModal}
                    />
                    {!showAttend && <Link to={`/events/${eventId}`}>
                        <Button
                            type="primary"
                            className="event-banner-button-view"
                        >
                            View event
                        </Button>
                    </Link>
                    }
                </div>
            </div>
            <Modal
                centered={true}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <SocialShare eventId={eventId}/>
            </Modal>
        </>
    );
};

export default EventBanner;
