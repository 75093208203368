import { Sparkle } from "@phosphor-icons/react";
import { Button, Tag } from "antd";
import React, { useEffect, useState } from 'react';
import "./VideoSidePanel.scss";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { RecordingStatus } from "../../../../../common/enums/RecordingStatus";
import { applySceneSearch, updateRecordingPrivacy } from "../../../../../common/redux/actions/recordingAction";

function VideoSidePanel({ recording, isExternal, fetchRecording }) {
    const [isPublished, setIsPublished] = useState(false);
    const [loadingPublishBtn, setLoadingPublishBtn] = useState(false);
    const [loadingApplyBtn, setLoadingApplyBtn] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (recording) {
            setIsPublished(recording?.recordingStatus === "Published");
        }
    }, [recording]);

    const handleUpdateRecordingPrivacy = () => {
        setLoadingPublishBtn(true);
        const message = isPublished ? "unpublish" : "publish";
        dispatch(updateRecordingPrivacy({
            eventId: recording?.eventId,
            recordingType: isExternal ? "external" : "internal"
        }))
            .unwrap()
            .then((res) => {
                if (res) {
                    setIsPublished(isPublished => !isPublished);
                    toast.success(`Successfully ${message} recording!`);
                }
            })
            .catch((err) => {
                toast.error(`Error ${message} recording!`);
            })
            .finally(() => {
                setLoadingPublishBtn(false);
            });
    }

    const handleApplySceneSearch = () => {
        setLoadingApplyBtn(true);
        dispatch(applySceneSearch({
            eventId: recording?.eventId,
            videoId: isExternal ? recording?.externalVideoId : recording?.videoId,
        }))
            .unwrap()
            .then((res) => {
                toast.info("Start processing to apply AI scene search");
                fetchRecording();
            })
            .catch((err) => {
                toast.error("Failed to process video!");
            })
            .finally(() => {
                setLoadingApplyBtn(false);
            });
    }

    return (
        <div className="video-info-panel">
            {/*<div className="video-info-section">*/}
            {/*    <div className="event-status-label">Title</div>*/}
            {/*    <div className="event-status-text">*/}
            {/*        <Input*/}
            {/*            allowClear={true}*/}
            {/*            variant="filled"*/}
            {/*            onChange={handleChangeTitle}*/}
            {/*            value={recordingTitle}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="video-info-section">
                <div className="event-status-label">Status</div>
                <div className="event-status-text">
                    <Tag color={isPublished ? "success" : "processing"}>
                        {isPublished ? RecordingStatus.PUBLISHED : RecordingStatus.PRIVATE}
                    </Tag>
                </div>
            </div>
            {/*<div className="video-info-section">*/}
            {/*    <div className="event-status-label">Use custom?</div>*/}
            {/*    <div className="event-status-text">*/}
            {/*        <Switch defaultChecked={recording?.publishRecordingFileType === "external"}*/}
            {/*                onChange={onToggleCustom}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="video-info-section">*/}
            {/*    <div className="event-status-label">Description</div>*/}
            {/*    <Editor*/}
            {/*        apiKey={process.env.REACT_APP_EDITOR_API}*/}
            {/*        onInit={(evt, editor) => editorRef.current = editor}*/}
            {/*        onEditorChange={handleChangeRecordingDescription}*/}
            {/*        value={recordingDesc}*/}
            {/*        init={initalConfig}*/}
            {/*        placeholder={"Additional information for your recording"}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className="video-info-buttons">
                {/*<Button*/}
                {/*    onClick={handleUpdateRecording}*/}
                {/*    loading={loadingUpdateBtn}*/}
                {/*>*/}
                {/*    Update*/}
                {/*</Button>*/}
                <Button
                    onClick={handleUpdateRecordingPrivacy}
                    loading={loadingPublishBtn}
                >
                    {!isPublished ? "Publish" : "Unpublish"}
                </Button>
                <Button
                    onClick={handleApplySceneSearch}
                    className="highlight-button"
                    icon={<Sparkle/>}
                    loading={loadingApplyBtn}
                >
                    Apply scene search
                </Button>
            </div>
        </div>
    );
}

export default VideoSidePanel;