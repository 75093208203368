import { Tabs } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import "./PastEventPage.scss";
import { getEventById, getPastEvent } from "../../../common/redux/actions/eventActions";
import EventBanner from "../../modules/partials/EventBanner/EventBanner";

const items = [
    {
        key: 'info',
        label: 'Info',
        children: <Outlet/>,
    },
    {
        key: 'feedback',
        label: 'Feedback',
        children: <Outlet/>,
    },
    {
        key: 'recording',
        label: 'Recording',
        children: <Outlet/>,
    },
];

const PastEventPage = () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("info");
    const [errMessage, setErrMessage] = useState();
    const { setShowSidebar } = useOutletContext();
    const { event, pastEvent, error } = useSelector((state) => state.event);
    const { user } = useSelector((state) => state.auth);
    const now = Date.now();

    useLayoutEffect(() => {
        document.title = "Past event | Eventure";
        setShowSidebar(false);
    });

    useEffect(() => {
        const username = user?.id;
        if (eventId && username) {
            dispatch(getPastEvent({ eventId: eventId, username: username }));
        }
    }, [eventId, user, dispatch]);

    const handleChangeTab = (key) => {
        navigate(`${key}`);
    };

    useEffect(() => {
        if (error) {
            dispatch(getEventById({ eventId: eventId }));
            setErrMessage(error);
        }
    }, [error, eventId, dispatch]);

    return (
        <div className="past-event-container">
            {
                pastEvent
                    ? (
                        new Date(pastEvent?.startDateTime).getTime() - now > 0
                            ? <div className="past-event-abnormal">
                                {event && <EventBanner eventData={event} showAttend={false}/>}
                                <div className="past-event-message">This event hasn't start yet!</div>
                            </div>
                            : new Date(pastEvent?.endDateTime).getTime() - now < 0
                                ? <div className="past-event-content">
                                    <EventBanner eventData={pastEvent} showAttend={false}/>
                                    <Tabs
                                        activeKey={activeTab}
                                        onChange={handleChangeTab}
                                        items={items.map((item) => ({
                                            ...item,
                                            children: <Outlet
                                                context={{
                                                    pastEvent,
                                                    eventId,
                                                    eventType: pastEvent?.eventType,
                                                    setActiveTab
                                                }}
                                            />
                                        }))}
                                    />
                                </div>
                                : <div className="past-event-abnormal">
                                    {event && <EventBanner eventData={event} showAttend={false}/>}
                                    <div className="past-event-message">This event is happening now!</div>
                                </div>
                    )
                    : <div className="past-event-abnormal">
                        {event && <EventBanner eventData={event} showAttend={false}/>}
                        <div className="past-event-message">{errMessage}</div>
                    </div>
            }
        </div>
    )
        ;
};

export default PastEventPage;