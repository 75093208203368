import { createAsyncThunk } from '@reduxjs/toolkit';
import accountService from "../../services/accountService";
import {
    CAN_BUY_MORE_TICKET,
    CREATE_OR_UPDATE_INTERESTED_CATEGORIES,
    FOLLOW_ORGANIZATION,
    GET_FOLLOWING_ORGANIZATION_IDS,
    GET_FOLLOWING_ORGANIZATIONS,
    GET_INTERESTED_CATEGORIES,
    GET_PAST_EVENTS,
    GET_PROFILE_STATISTIC,
    GET_RESERVATION_BY_ID,
    GET_RESERVATIONS_BY_USERNAME,
    GET_UPCOMING_EVENTS,
    GET_USER_PROFILE,
    IS_RESERVED_FREE_TICKET,
    REMOVE_USER_AVATAR,
    UNFOLLOW_ORGANIZATION,
    UPDATE_USER_AVATAR,
    UPDATE_USER_PROFILE
} from "../types/account";

export const getUserProfile = createAsyncThunk(
    GET_USER_PROFILE,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.getUserProfile(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateUserProfile = createAsyncThunk(
    UPDATE_USER_PROFILE,
    async (data, { rejectWithValue }) => {
        try {
            const response = await accountService.updateUserProfile(data);
            const params = { username: response };
            const user = await accountService.getUserProfile(params);
            return user;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateUserAvatar = createAsyncThunk(
    UPDATE_USER_AVATAR,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.updateUserAvatar(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const removeUserAvatar = createAsyncThunk(
    REMOVE_USER_AVATAR,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.removeUserAvatar(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getInterestedCategories = createAsyncThunk(
    GET_INTERESTED_CATEGORIES,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.getInterestedCategories(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const createOrUpdateInterestCategory = createAsyncThunk(
    CREATE_OR_UPDATE_INTERESTED_CATEGORIES,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.createOrUpdateInterestedCategories(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getFollowingOrganizationIds = createAsyncThunk(
    GET_FOLLOWING_ORGANIZATION_IDS,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.getFollowingOrganizationIds(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getFollowingOrganizations = createAsyncThunk(
    GET_FOLLOWING_ORGANIZATIONS,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.getFollowingOrganizations(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const followOrganization = createAsyncThunk(
    FOLLOW_ORGANIZATION,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.followOrganization(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const unfollowOrganization = createAsyncThunk(
    UNFOLLOW_ORGANIZATION,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.unfollowOrganization(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getPastEvents = createAsyncThunk(
    GET_PAST_EVENTS,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.getPastEvents(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getUpcomingEvents = createAsyncThunk(
    GET_UPCOMING_EVENTS,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.getUpcomingEvents(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);


export const getReservationsByUsername = createAsyncThunk(
    GET_RESERVATIONS_BY_USERNAME,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.getReservationsByUsername(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);


export const getReservationById = createAsyncThunk(
    GET_RESERVATION_BY_ID,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.getReservationById(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getProfileStatistic = createAsyncThunk(
    GET_PROFILE_STATISTIC,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.getProfileStatistic(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const canBuyMoreTicket = createAsyncThunk(
    CAN_BUY_MORE_TICKET,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.canBuyMoreTicket(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const isReservedFreeTicket = createAsyncThunk(
    IS_RESERVED_FREE_TICKET,
    async (data, { rejectWithValue }) => {
        try {
            return await accountService.isReservedFreeTicket(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);