import React from "react";
import "./EventAgenda.scss";
import AgendaItem from "../../../../modules/components/AgendaItem/AgendaItem";

export const EventAgenda = ({ eventAgenda }) => {
    return (
        <div className="event-agenda-container">
            {
                eventAgenda?.length > 0 &&
                eventAgenda.map((session, index) => (
                    <AgendaItem key={index} agendaItem={session} editable={false}/>
                ))
            }
        </div>
    );
};
