import React from "react";
import OrganizationCardVertical from "../OrganizationCardVertical/OrganizationCardVertical";
import "./OrganizationList.scss";

const OrganizationList = ({ organizations }) => {
    return (
        <div className="organization-list-container">
            {organizations.map((item) => (
                <OrganizationCardVertical key={item?.id} organizer={item}/>
            ))}
        </div>
    );
};

export default OrganizationList;