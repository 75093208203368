import { Alert, Form } from "antd";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import "./OrganizationSettingProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "sonner";
import { IS_LOGGED_IN, ORGANIZATION_PROFILE } from "../../../common/constants/LocalStorageConstants";
import useFetchOrganization from "../../../common/hooks/useFetchOrganization";
import { uploadMedia } from "../../../common/redux/actions/mediaAction";
import {
    updateOrganizationCover,
    updateOrganizationInformation,
    updateOrganizationProfile
} from "../../../common/redux/actions/organizationActions";
import OrgProfileAvatar from "./partials/OrgProfileAvatar/OrgProfileAvatar";
import OrgProfileCover from "./partials/OrgProfileCover/OrgProfileCover";
import OrgProfileInfo from "./partials/OrgProfileInfo/OrgProfileInfo";
import OrgProfileSocial from "./partials/OrgProfileSocial/OrgProfileSocial";


const OrganizationSettingProfile = () => {
    const { setActiveItem } = useOutletContext();
    const [loading, setLoading] = useState(false);
    const [profileForm] = Form.useForm();
    const [profileInfoForm] = Form.useForm();
    const [isProfileExist, setIsProfileExist] = useState(false);
    const [profileImage, setProfileImage] = useState();
    const [coverImage, setCoverImage] = useState();
    const [coverImageUrl, setCoverImageUrl] = useState();
    const [disabledSaveSocialBtn, setDisabledSaveSocialBtn] = useState(false);
    const dispatch = useDispatch();
    const { organization } = useSelector(state => state.organization);
    const { user } = useSelector((state) => state.auth);
    const username = user?.id;

    // eslint-disable-next-line
    const orgProfile = useMemo(() => JSON.parse(sessionStorage.getItem(ORGANIZATION_PROFILE)), [organization]);
    const navigate = useNavigate();
    const isLoggedIn = localStorage.getItem(IS_LOGGED_IN);

    useFetchOrganization(username, isLoggedIn)

    useLayoutEffect(() => {
        document.title = "Organization Settings | Eventure";
        setActiveItem("profile");
    });

    useEffect(() => {
        if (orgProfile) {
            setProfileImage(orgProfile.avatarUrl);
            setCoverImageUrl(orgProfile?.coverUrl);
        }
    }, [organization, orgProfile]);

    const updateSessionStorage = (updatedData) => {
        const orgLocal = sessionStorage.getItem(ORGANIZATION_PROFILE);
        const existingData = orgLocal !== "undefined" ? JSON.parse(orgLocal) : null;
        const updatedOrgProfile = existingData ? { ...existingData, ...updatedData } : updatedData;
        sessionStorage.setItem(ORGANIZATION_PROFILE, JSON.stringify(updatedOrgProfile));
    };

    const updateOrgCover = () => {
        dispatch(updateOrganizationCover({
            image: coverImage,
            organizationId: orgProfile?.organizationId,
        }))
            .unwrap()
            .then((res) => {
                if (res) {
                    setCoverImageUrl(res);
                }
            });
    };

    useEffect(() => {
        if (coverImage)
            updateOrgCover();
        // eslint-disable-next-line
    }, [coverImage]);

    const createUpdateOrgProfile = async (values) => {
        setLoading(true)
        if (!profileImage) {
            setLoading(false)
            return;
        }

        let orgMediaId = null;
        if (typeof profileImage !== "string") {
            orgMediaId = await dispatch(uploadMedia(profileImage)).unwrap().then(res => res.id);
        }

        const request = {
            username: username,
            avatarId: orgMediaId,
            name: values?.org_name,
            website: values?.org_website,
            bio: values?.org_bio,
            description: values?.org_description,
        }

        dispatch(updateOrganizationProfile(request))
            .unwrap()
            .then(res => {
                if (res) {
                    updateSessionStorage({
                        name: request.name,
                        website: request.website,
                        bio: request.bio,
                        description: request.description,
                    });
                    setTimeout(() => {
                        toast.success("Organization profile updated")
                        setLoading(false);
                        setIsProfileExist(true)
                    })
                }

            })
            .catch((error) => {
                toast.error("Error update profile");
            })
            .finally(() => {
                setLoading(false);
                if (!orgProfile?.organizationId) {
                    setTimeout(() => {
                        navigate(0);
                    }, 1500);
                }
            })
    }

    const updateSaveSocialButtonState = (form) => {
        const values = form.getFieldsValue();
        const hasValue = Object.values(values).some(value => value !== null && value !== undefined && value !== '');
        setDisabledSaveSocialBtn(!hasValue);
    };

    useEffect(() => {
        updateSaveSocialButtonState(profileInfoForm);
        // eslint-disable-next-line
    }, [profileForm]);

    const handleFormChange = () => {
        updateSaveSocialButtonState(profileInfoForm);
    };

    const createUpdateOrgInfo = async (values) => {
        const request = {
            organizationId: orgProfile ? orgProfile?.organizationId : null,
            email: values?.org_email || null,
            phone: values?.org_phone || null,
            facebook: values?.facebook || null,
            instagram: values?.instagram || null,
            twitter: values?.twitter || null,
            youtube: values?.youtube || null,
            linkedin: values?.linkedin || null
        }
        dispatch(updateOrganizationInformation(request))
            .unwrap()
            .then(res => {
                if (res) {
                    updateSessionStorage({
                        email: request.email,
                        phone: request.phone,
                        facebook: request.facebook,
                        instagram: request.instagram,
                        twitter: request.twitter,
                        youtube: request.youtube,
                        linkedin: request.linkedin
                    })
                    toast.success("Successfully save social info!");
                } else {
                    toast.error("Cannot save social info!")
                }
            })
            .catch((error) => {
                toast.error("Cannot save social info!")
            })
    }

    return (
        <div className="org-setting-profile-container">
            <div className="org-setting-profile-title">
                Organization Settings
            </div>
            {!organization && !orgProfile ?
                <Alert message={`You have to create organization profile to use "Organization" mode.`}
                       type="warning"
                       closable
                       className={'alert-bar'}
                /> : null}
            {
                (isProfileExist || organization || orgProfile) && <OrgProfileCover
                    coverUrl={coverImageUrl}
                    setCoverImage={setCoverImage}
                />
            }
            <OrgProfileAvatar
                avatarUrl={orgProfile?.avatarUrl}
                setProfileImage={setProfileImage}
            />
            <OrgProfileInfo
                orgData={orgProfile}
                profileForm={profileForm}
                onFinish={createUpdateOrgProfile}
                loading={loading}
            />
            {
                isProfileExist || organization || orgProfile
                    ? <OrgProfileSocial
                        disabled={disabledSaveSocialBtn}
                        orgData={orgProfile}
                        onFinish={createUpdateOrgInfo}
                        orgInfoForm={profileInfoForm}
                        handleFormChange={handleFormChange}
                    />
                    : null
            }
        </div>
    );
};

export default OrganizationSettingProfile;
