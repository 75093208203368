import { CalendarCheck, Ticket } from "@phosphor-icons/react";
import { Button } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { CART } from "../../../../../common/constants/LocalStorageConstants";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { verifyFreeOrder, verifyMomoPaymentStatus } from "../../../../../common/redux/actions/paymentAction";
import "./TicketFinish.scss";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import Loading from "../../../../modules/components/Loading/Loading";
import SuggestedList from "../../../../modules/components/SuggestedEventList/SuggestedEventList";
import PaymentFailed from "../../components/PaymentFailed/PaymentFailed";
import PaymentSuccessful from "../../components/PaymentSuccessful/PaymentSuccessful";

const TicketFinish = () => {
    const dispatch = useDispatch();
    const [paymentResult, setPaymentResult] = useState();
    const [searchParams] = useSearchParams();
    const { setCurrentStep, event } = useOutletContext();
    const { paymentStatus, loading } = useSelector((state) => state.payment);
    const { reservationId } = useParams();

    useEffect(() => {
        const verifyPayment = async () => {
            try {
                let request;
                if (!reservationId) {
                    request = {
                        requestId: searchParams.get("requestId"),
                        orderId: searchParams.get("orderId"),
                        transId: searchParams.get("transId"),
                        amount: searchParams.get("amount"),
                    };
                } else {
                    request = {
                        partnerCode: "",
                        requestId: "",
                        orderId: reservationId,
                        amount: 0,
                        orderInfo: "",
                        orderType: "",
                        transId: 0,
                        resultCode: 0,
                        message: "",
                        payType: "",
                        responseTime: 0,
                        extraData: "",
                        signature: ""
                    };
                }
                const data = await (reservationId ? dispatch(verifyFreeOrder(request)) : dispatch(verifyMomoPaymentStatus(request))).unwrap();
                setPaymentResult(data);
            } catch (error) {
                console.error(error);
            }
            localStorage.removeItem(CART);
        };
        verifyPayment();

    }, [reservationId, dispatch, searchParams]);

    useLayoutEffect(() => {
        setCurrentStep(4);
    }, [setCurrentStep]);

    return (
        <div className="ticket-finish-container">
            {
                loading && !reservationId
                    ? <Loading/>
                    : (
                        paymentStatus?.resultCode === "0" || reservationId
                            ? <div className="ticket-finish-success">
                                <div className="ticket-finish-payment-section">
                                    <PaymentSuccessful paymentResult={paymentResult}/>
                                </div>
                                <div className="ticket-finish-event-section">
                                    <div className="ticket-finish-event-section-title">
                                        You're going!
                                    </div>
                                    <div className="ticket-finish-event-info">
                                        <div className="ticket-finish-event-thumbnail">
                                            <img src={event?.thumbnailUrl} alt=""/>
                                        </div>
                                        <div className="ticket-finish-event-info-detail">
                                            <div className="ticket-finish-event-info-content">
                                                <Link to={`/events/${event.eventId}`} className="ticket-finish-event-title">
                                                    {event.eventName}
                                                </Link>
                                                <div className="ticket-finish-event-time">
                                                    <CalendarCheck/> {formatDateRange(event.startDateTime, DateTimePattern.CARD_DATE_TIME)}
                                                </div>
                                            </div>
                                            <div className="ticket-finish-event-button">
                                                <Link to={"/user/reservations"}>
                                                    <Button type="primary" block={true} icon={<Ticket/>}>
                                                        View reservation
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <div className="ticket-finish-fail">
                                <PaymentFailed/>
                            </div>
                    )
            }
            <div className="ticket-finish-suggest-list">
                <SuggestedList message={"Other events from your preferences"}/>
            </div>
        </div>
    );
};

export default TicketFinish;