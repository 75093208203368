import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import React, { useState } from "react";
import "./OrgProfileCover.scss";
import { toast } from "sonner";
import { getBase64 } from "../../../../../common/utils/fileUtils";


const OrgProfileCover = ({ coverUrl, setCoverImage }) => {
    const [loading, setLoading] = useState(false);

    const handleChange = async (info) => {
        setLoading(true)
        getBase64(info.file.originFileObj).then((res) => {
                if (res) {
                    setCoverImage(info?.file?.originFileObj);
                    setLoading(false);
                }
            }
        )
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{ marginTop: 8 }}>
                <p>Upload cover</p>
                <p>Accepted .png/.jpg/.jpeg file formats</p>
            </div>
        </button>
    );

    const customRequest = async ({ file, onSuccess, onError }) => {
        try {
            onSuccess();
        } catch (error) {
            onError();
            toast.error("Error uploading image");
        }
    };

    return (
        <div className="org-profile-cover-container">
            <Upload
                name="cover"
                listType="picture-card"
                className="cover-uploader"
                showUploadList={false}
                onChange={handleChange}
                customRequest={customRequest}
                accept={"image/png, image/jpeg,  image/gif"}
            >
                {coverUrl
                    ? <img
                        src={coverUrl}
                        alt="avatar"
                        style={{
                            width: "100%",
                            objectFit: "contain"
                        }}
                    />
                    : uploadButton
                }
            </Upload>
        </div>
    );
};

export default OrgProfileCover;
