import React from 'react';
import "./FixedFooter.scss"
function FixedFooter({action}) {
    return (
        <footer className={'event-footer-container'}>
            {action}
        </footer>
    );
}

export default FixedFooter;