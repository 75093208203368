import React from "react";
import Chart from "react-apexcharts";

const SalesByVoucher = ({ vouchers }) => {
    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: vouchers.map((voucher) => voucher.voucherCode),
        },
        yaxis: {
            title: {
                // text: 'quanity',
                show: false,
            },
            labels: {
                show: false,
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function(val) {
                    return Math.round(val);
                }
            }
        },
        colors: ["#147451"],
        grid: {
            show: false,
        }
    };

    const series = [{
        data: vouchers.map((voucher) => Math.round(voucher.numberOfUsedVouchers))
    }];

    return (
        <div className="sales-mtd-container">
            <Chart
                options={options}
                series={series}
                type="bar"
                height={320}
            />
        </div>
    );
}

export default SalesByVoucher;