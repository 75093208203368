import { createAsyncThunk } from '@reduxjs/toolkit';
import searchService from "../../services/searchService";
import { SEARCH_GENERICS } from "../types/search";

export const searchGenerics = createAsyncThunk(
    SEARCH_GENERICS,
    async (data, { rejectWithValue }) => {
        try {
            return await searchService.searchGenerics(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);