import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import OrgProfileSectionHead from "../../components/OrgProfileSectionHead/OrgProfileSectionHead";


const OrgProfileSocial = ({ orgInfoForm, onFinish, orgData, disabled, handleFormChange }) => {
    useEffect(() => {
        if (orgData) {
            orgInfoForm.setFieldsValue({
                org_email: orgData?.email,
                org_phone: orgData?.phone,
                facebook: orgData?.facebook,
                instagram: orgData?.instagram,
                twitter: orgData?.twitter,
                youtube: orgData?.youtube,
                linkedin: orgData?.linkedin
            })
        }
    }, [orgData, orgInfoForm]);
    
    return (
        <div className="org-profile-section org-profile-info-container">
            <OrgProfileSectionHead
                title="Social media"
                description="Let attendees know how to connect with you."
            />
            <div className="org-profile-social-content">
                <Form form={orgInfoForm} layout="vertical" onFinish={onFinish} onChange={handleFormChange} style={{ width: 500 }}>
                    <Form.Item name="org_email" label="Organizer email">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="org_phone" label="Organizer phone">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="facebook" label="Facebook">
                        <Input addonBefore="facebook.com/" placeholder="tung.bk.14"/>
                    </Form.Item>
                    <Form.Item name="instagram" label="Instagram">
                        <Input addonBefore="instagram.com/" placeholder="username"/>
                    </Form.Item>
                    <Form.Item name="twitter" label="Twitter">
                        <Input addonBefore="twitter.com/" placeholder="username"/>
                    </Form.Item>
                    <Form.Item name="youtube" label="Youtube">
                        <Input addonBefore="youtube.com/@" placeholder="username"/>
                    </Form.Item>
                    <Form.Item name="linkedin" label="LinkedIn">
                        <Input addonBefore="linkedin.com" placeholder="/in/npt"/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType={'submit'} disabled={disabled} type="primary">Save social info</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default OrgProfileSocial;
