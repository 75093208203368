import { Info } from "@phosphor-icons/react";
import { Badge } from "antd";
import React, { useState } from "react";
import "./TicketOption.scss";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { formatDateRange, getDateTimeObject } from "../../../../../common/utils/dateTimeUtils";


export const TicketOption = ({ title, description, dueDate, price, slot, startDate, isSoldOut }) => {
    const [showDetail, setShowDetail] = useState(false);

    const handleShowDetail = () => {
        setShowDetail(showDetail => !showDetail);
    }

    // eslint-disable-next-line
    const isTicketAvailable = () => {
        const currentDate = getDateTimeObject(new Date());
        const ticketStartDate = getDateTimeObject(startDate);

        return currentDate.isBefore(ticketStartDate);
    }

    const getTicketStatus = () => {
        if (price === 0 && !isSoldOut) return `Free`;
        if (isSoldOut) return `Sold out`;
        return `${price.toLocaleString()} VND`;
    }

    return (
        <>
            <div className={`ticket-option-container ${isSoldOut ? 'sold-out' : ''}`}>
                <div className="ticket-option-main" onClick={handleShowDetail}>
                    <div className="ticket-type"><Info size={20}/>{title}</div>
                    <div className="ticket-price">{getTicketStatus()} </div>
                </div>
                {
                    showDetail && <div className="ticket-option-detail">
                        <Badge className="ticket-slot" status="processing" text={`${slot} remaining`}/>
                        <Badge
                            className="ticket-due-date"
                            status="default"
                            text={`Available from ${formatDateRange(startDate, DateTimePattern.TICKET_DATE_TIME)} to ${formatDateRange(dueDate, DateTimePattern.TICKET_DATE_TIME)}`}
                        />
                        <div className="ticket-option-description">
                            {description}
                        </div>
                    </div>
                }
            </div>
        </>
    );
};