import { X } from "@phosphor-icons/react";
import { useMeeting } from "@videosdk.live/react-sdk";
import { Button } from "antd";
import React, { Fragment } from "react";
import { useMeetingAppContext } from "../../../../common/videosdk/MeetingAppContextDef";
import { ChatPanel } from "./ChatPanel";
import { ParticipantPanel } from "./ParticipantPanel";
import "./SidebarContainer.scss";

const SideBarTabView = ({ handleClose }) => {
    const { participants } = useMeeting();
    const { sideBarMode } = useMeetingAppContext();

    return (
        <div className="sidebar-tab-view-container">
            {
                sideBarMode && <div className="sidebar-card-header">
                    <p className="sidebar-card-header-title">
                        {sideBarMode === "PARTICIPANTS"
                            && `Participants (${new Map(participants)?.size})`}
                    </p>
                    <Button
                        type="text"
                        onClick={handleClose}
                        icon={<X color="white"/>}
                    />
                </div>
            }
            <div className="sidebar-card-content">
                {
                    sideBarMode === "PARTICIPANTS"
                        ? <ParticipantPanel/>
                        : sideBarMode === "CHAT"
                            ? <ChatPanel/>
                            : null
                }
            </div>
        </div>
    );
};

export function SidebarContainer() {
    const { sideBarMode, setSideBarMode } = useMeetingAppContext();
    const handleClose = () => {
        setSideBarMode(null);
    };

    return sideBarMode
        ? <SideBarTabView handleClose={handleClose}/>
        : <></>;
}
