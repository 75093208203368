import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { IS_LOGGED_IN, ORGANIZATION_PROFILE, PROFILE_MODE } from "../../../../common/constants/LocalStorageConstants";
import { ApplicationMode } from "../../../../common/enums/ApplicationMode";
import useFetchOrganization from "../../../../common/hooks/useFetchOrganization";
import { getAllNotifications } from "../../../../common/redux/actions/notificationActions";
import { setMode } from "../../../../common/redux/slice/authSlice";
import Footer from "../../partials/Footer";
import Header from "../Header/Header";
import MenuBottom from "../MenuBottom/MenuBottom";
import Sidebar from "../Sidebar/Sidebar";
import "./MainLayout.scss";

const MainLayout = () => {
    const [showSidebar, setShowSidebar] = useState(true);
    const [showFooter, setShowFooter] = useState(true);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { threadId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const onChatPage = useMemo(() => threadId ? 'on-chat-page' : '', [threadId]);
    const onMessagePage = useMemo(() => location.pathname.includes("/messages"), [location.pathname]);
    const username = user?.id;
    const isLoggedIn =  localStorage.getItem(IS_LOGGED_IN);
    const orgProfile =  sessionStorage.getItem(ORGANIZATION_PROFILE)
    const mode = useMemo(() => sessionStorage.getItem(PROFILE_MODE), [])
    
    useFetchOrganization(username, isLoggedIn)

    const fetchNotifications = useCallback(() => {
        if (isLoggedIn && username) {
            dispatch(getAllNotifications({ username: username }));
        }
    }, [dispatch, isLoggedIn, username]);

    useEffect(() => {
        fetchNotifications()
        // eslint-disable-next-line
    }, [isLoggedIn, username, dispatch]);
    

    useEffect(() => {
        if (isLoggedIn === "true") {
            dispatch(setMode(ApplicationMode.ATTENDEE))
        }
        // eslint-disable-next-line
    }, [dispatch]);
    
    
    return (
        <div
            className={`main-layout-container ${
                showSidebar ? "" : "no-sidebar"
            }`}
        >
            <div className="main-header">
                <Header isLoggedIn={isLoggedIn}/>
            </div>
            {showSidebar && (
                <div className="main-sidebar">
                    <Sidebar/>
                </div>
            )}
            <div className={`main-display ${onMessagePage ? 'message-page' : ''}`}>
                <Outlet context={{ showSidebar, setShowSidebar, showFooter, setShowFooter }}/>
            </div>
            {showFooter && (
                <div className="main-footer">
                    <Footer/>
                </div>
            )}

            <div className={`bottom-menu ${onChatPage}`}>
                <MenuBottom/>
            </div>
        </div>
    );
};

export default MainLayout;
