import { createAsyncThunk } from '@reduxjs/toolkit';
import eventService from '../../services/eventService';
import {
    ADD_EVENT_TO_FAVORITE,
    CREATE_EVENT,
    CREATE_OR_UPDATE_EVENT_AGENDA,
    CREATE_OR_UPDATE_EVENT_DESCRIPTION,
    CREATE_OR_UPDATE_EVENT_FAQ,
    CREATE_OR_UPDATE_EVENT_SUMMARY,
    CREATE_OR_UPDATE_EVENT_TAGS,
    DELETE_EVENT_BY_ID,
    GET_ALL_EVENTS,
    GET_CREATED_EVENTS,
    GET_EVENT_BY_ID, GET_EVENT_ID_ORG,
    GET_EVENTS_BY_FOLLOWING_ORGANIZATION,
    GET_EVENTS_BY_ORGANIZATION_ID,
    GET_FAVORITE_EVENT_IDS,
    GET_FAVORITE_EVENTS,
    GET_PAST_EVENT,
    GET_SUGGESTED_EVENTS,
    PUBLISH_EVENT,
    REMOVE_EVENT_FROM_FAVORITE,
    UNPUBLISH_EVENT,
    UPDATE_EVENT
} from "../types/event";

export const createEvent = createAsyncThunk(CREATE_EVENT, async (data, { rejectWithValue }) => {
    try {
        return await eventService.createEvent(data);
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getAllEvents = createAsyncThunk(GET_ALL_EVENTS, async (data, { rejectWithValue }) => {
    try {
        return await eventService.getAllEvents(data);
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getCreatedEvents = createAsyncThunk(GET_CREATED_EVENTS, async (data, { rejectWithValue }) => {
    try {
        const response = await eventService.getCreatedEvents(data);
        return response;
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getEventById = createAsyncThunk(GET_EVENT_BY_ID, async (data, { rejectWithValue }) => {
    try {
        return await eventService.getEventById(data);
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const deleteEventById = createAsyncThunk(DELETE_EVENT_BY_ID, async (id, { rejectWithValue }) => {
    try {
        return await eventService.deleteEventbyId(id);
    } catch (error) {
        if (error.message && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getFavoriteEvents = createAsyncThunk(GET_FAVORITE_EVENTS, async (data, { rejectWithValue }) => {
    try {
        return await eventService.getFavoriteEvents(data);
    } catch (error) {
        if (error.message && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addEventToFavorite = createAsyncThunk(ADD_EVENT_TO_FAVORITE, async (data, { rejectWithValue }) => {
    try {
        return await eventService.addEventToFavorite(data);
    } catch (error) {
        if (error.message && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const removeEventFromFavorite = createAsyncThunk(REMOVE_EVENT_FROM_FAVORITE, async (data, { rejectWithValue }) => {
    try {
        return await eventService.removeEventFromFavorite(data);
    } catch (error) {
        if (error.message && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getFavoriteEventIds = createAsyncThunk(GET_FAVORITE_EVENT_IDS, async (data, { rejectWithValue }) => {
    try {
        return await eventService.getFavoriteEventIds(data);
    } catch (error) {
        if (error.message && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const publishEvent = createAsyncThunk(PUBLISH_EVENT, async (id, { rejectWithValue }) => {
    try {
        return await eventService.publishEventById(id);
    } catch (error) {
        if (error.message && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const unPublishEvent = createAsyncThunk(UNPUBLISH_EVENT, async (id, { rejectWithValue }) => {
    try {
        return await eventService.unPublishEventById(id);
    } catch (error) {
        if (error.message && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getEventsByOrganizationId = createAsyncThunk(
    GET_EVENTS_BY_ORGANIZATION_ID,
    async (data, { rejectWithValue }) => {
        try {
            return await eventService.getEventsByOrganizationId(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getEventsByFollowingOrganizations = createAsyncThunk(
    GET_EVENTS_BY_FOLLOWING_ORGANIZATION,
    async (data, { rejectWithValue }) => {
        try {
            return await eventService.getEventsByFollowingOrganizations(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateEvent = createAsyncThunk(UPDATE_EVENT, async (data, { rejectWithValue }) => {
    try {
        return await eventService.updateEvent(data);
    } catch (error) {
        if (error.response && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const createOrUpdateEventFaq = createAsyncThunk(
    CREATE_OR_UPDATE_EVENT_FAQ,
    async (data, { rejectWithValue }) => {
        try {
            return await eventService.createOrUpdateEventFAQ(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const createOrUpdateEventAgenda = createAsyncThunk(
    CREATE_OR_UPDATE_EVENT_AGENDA,
    async (data, { rejectWithValue }) => {
        try {
            return await eventService.createOrUpdateEventAgenda(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const createOrUpdateEventTags = createAsyncThunk(
    CREATE_OR_UPDATE_EVENT_TAGS,
    async (data, { rejectWithValue }) => {
        try {
            return await eventService.createOrUpdateEventTags(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const createOrUpdateEventSummary = createAsyncThunk(
    CREATE_OR_UPDATE_EVENT_SUMMARY,
    async (data, { rejectWithValue }) => {
        try {
            return await eventService.createOrUpdateEventSummary(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const createOrUpdateEventDescription = createAsyncThunk(
    CREATE_OR_UPDATE_EVENT_DESCRIPTION,
    async (data, { rejectWithValue }) => {
        try {
            return await eventService.createOrUpdateEventDescription(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getSuggestedEvents = createAsyncThunk(
    GET_SUGGESTED_EVENTS,
    async (data, { rejectWithValue }) => {
        try {
            return await eventService.getSuggestedEventsByUser(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getPastEvent = createAsyncThunk(
    GET_PAST_EVENT,
    async (data, { rejectWithValue }) => {
        try {
            return await eventService.getPastEvent(data);
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue(error.message);
            }
        }
    });

export const getEventByIdOrg = createAsyncThunk(
    GET_EVENT_ID_ORG,
    async (params, { rejectWithValue }) => {
        try {
            return await eventService.getEventByIdOrg(params);
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);