import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import "./Onboarding.scss";


const Onboarding = () => {
    const { setShowSidebar } = useOutletContext();
    const [path, setPath] = useState("user-info");
    const navigate = useNavigate();

    useLayoutEffect(() => {
        setShowSidebar(false);
        document.title = "Onboarding | Eventure";
    });

    useEffect(() => {
        navigate(path);
    }, [path]);

    return (
        <div className='onboarding-container'>
            <div className='onboarding-head'>
                👋 Welcome to Eventure!
            </div>
            <Outlet context={{ path, setPath }}/>
        </div>
    );
}

export default Onboarding;