import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../redux/actions/channelActions";

export default function useCategory() {
    const dispatch = useDispatch();
    const { category, error } = useSelector((state) => state.channel);

    useEffect(() => {
        const loadEvents = async () => {
            try {
                await dispatch(getAllCategory());
            } catch (rejectWithValue) {
                console.error(rejectWithValue);
            }
        };
        loadEvents();
    }, [dispatch]);

    return { category, error };
}
