import { createSlice } from "@reduxjs/toolkit";
import { AccountModel } from "../../models/accountModel.model";
import {
    canBuyMoreTicket,
    createOrUpdateInterestCategory,
    followOrganization,
    getFollowingOrganizationIds,
    getFollowingOrganizations,
    getInterestedCategories,
    getPastEvents,
    getProfileStatistic,
    getReservationsByUsername,
    getReservationById,
    getUpcomingEvents,
    getUserProfile, isReservedFreeTicket,
    removeUserAvatar,
    unfollowOrganization,
    updateUserAvatar,
    updateUserProfile,
} from "../actions/accountAction";


const accountSlice = createSlice({
    name: 'account',
    initialState: AccountModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.account = action.payload;
                state.error = null;
                state.success = true;
            })
            .addCase(getUserProfile.rejected, (state, action) => {
                state.loading = false;
                state.account = {};
                state.error = action.payload;
                state.success = null;
            })
            .addCase(updateUserProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
                state.account = action.payload;
            })
            .addCase(updateUserProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(updateUserAvatar.pending, (state, action) => {
                state.error = null;
                state.success = null;
            })
            .addCase(updateUserAvatar.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
                state.avatar = action.payload;
            })
            .addCase(updateUserAvatar.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(removeUserAvatar.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(removeUserAvatar.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getInterestedCategories.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.success = null;
            })
            .addCase(getInterestedCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
                state.categories = action.payload;
            })
            .addCase(getInterestedCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(createOrUpdateInterestCategory.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.success = null;
            })
            .addCase(createOrUpdateInterestCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createOrUpdateInterestCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getFollowingOrganizationIds.fulfilled, (state, action) => {
                state.loading = false;
                state.followingOrganizationIds = action.payload;
                state.error = null;
            })
            .addCase(getFollowingOrganizationIds.rejected, (state, action) => {
                state.loading = false;
                state.followingOrganizationIds = [];
                state.error = action.payload;
            })
            .addCase(getFollowingOrganizations.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.followingOrganizations = [];
            })
            .addCase(getFollowingOrganizations.fulfilled, (state, action) => {
                state.loading = false;
                state.followingOrganizations = action.payload.organizationDtos;
                state.error = null;
                state.hasMoreOrganizations = action.payload.hasMoreOrganizations;
            })
            .addCase(getFollowingOrganizations.rejected, (state, action) => {
                state.loading = false;
                state.followingOrganizations = [];
                state.error = action.payload;
            })
            .addCase(followOrganization.pending, (state, action) => {
                state.error = null;
                state.success = null;
            })
            .addCase(followOrganization.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(followOrganization.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = false;
            })
            .addCase(unfollowOrganization.pending, (state, action) => {
                state.error = null;
                state.success = null;
            })
            .addCase(unfollowOrganization.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(unfollowOrganization.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = false;
            })
            .addCase(getPastEvents.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.pastEvents = [];
            })
            .addCase(getPastEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.pastEvents = action.payload.eventDtos;
                state.error = null;
            })
            .addCase(getPastEvents.rejected, (state, action) => {
                state.loading = false;
                state.pastEvents = [];
                state.error = action.payload;
            })
            .addCase(getUpcomingEvents.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.upcomingEvents = [];
            })
            .addCase(getUpcomingEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.upcomingEvents = action.payload.eventDtos;
                state.error = null;
            })
            .addCase(getUpcomingEvents.rejected, (state, action) => {
                state.loading = false;
                state.upcomingEvents = [];
                state.error = action.payload;
            })
            .addCase(getReservationsByUsername.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getReservationsByUsername.fulfilled, (state, action) => {
                state.loading = false;
                state.reservations = action.payload;
                state.error = null;
            })
            .addCase(getReservationsByUsername.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getReservationById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getReservationById.fulfilled, (state, action) => {
                state.loading = false;
                state.reservation = action.payload;
            })
            .addCase(getReservationById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getProfileStatistic.pending, (state) => {
                state.loading = true;
            })
            .addCase(getProfileStatistic.fulfilled, (state, action) => {
                state.loading = false;
                state.profileStatistic = action.payload;
                state.error = null;
                state.success = true;
            })
            .addCase(getProfileStatistic.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(canBuyMoreTicket.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(canBuyMoreTicket.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(canBuyMoreTicket.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(isReservedFreeTicket.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(isReservedFreeTicket.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(isReservedFreeTicket.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
    },
});

export default accountSlice.reducer;
