import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { IS_LOGGED_IN } from "../constants/LocalStorageConstants";
import Loading from "../../ui/modules/components/Loading/Loading";

export const ProtectedRoute = ({ redirectPath = "/login" }) => {
    const isLoggedIn = localStorage.getItem(IS_LOGGED_IN) === "true";
    const { user, loading } = useSelector((state) => state.auth);
    
    if(loading) {
        return <Loading/>
    }
    
    return user || isLoggedIn ? <Outlet /> : <Navigate to={redirectPath} replace />;
};
