import React, { useLayoutEffect, useMemo } from "react";
import "./OrganizationCreateEventDetail.scss";
import { useSelector } from "react-redux";
import CreateEventAgenda from "./partials/CreateEventAgenda/CreateEventAgenda";
import CreateEventDescription from "./partials/CreateEventDescription/CreateEventDescription";
import CreateEventFAQ from "./partials/CreateEventFAQ/CreateEventFAQ";
import CreateEventMedia from "./partials/CreateEventMedia/CreateEventMedia";
import CreateEventSummary from "./partials/CreateEventSummary/CreateEventSummary";
import CreateEventTags from "./partials/CreateEventTags/CreateEventTags";

const OrganizationCreateEventDetail = () => {
    const { event } = useSelector((state) => state.event);

    const eventDetails = useMemo(() => {
        return <>
            {
                event && <div className="event-detail-info-content">
                    <CreateEventMedia event={event}/>
                    <CreateEventDescription event={event}/>
                    <CreateEventSummary event={event}/>
                    <CreateEventTags event={event}/>
                    <CreateEventAgenda event={event}/>
                    <CreateEventFAQ event={event}/>
                </div>
            }
        </>;
    }, [event]);

    useLayoutEffect(() => {
        document.title = "Event Detail | Eventure";
    }, []);

    return (
        <div className="event-detail-info-container">
            <div className="event-detail-info-title">
                Event detail information
            </div>
            {eventDetails}
        </div>
    );
}

export default OrganizationCreateEventDetail;
