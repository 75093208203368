import { createSlice } from "@reduxjs/toolkit";
import { RecordingModel } from "../../models/recordingModel.model";
import {
    applySceneSearch,
    getHostRecordByEventId,
    getRecordByEventId,
    processRecording, scheduleSession,
    searchScenes,
    updateExternalRecording,
    updateRecording,
    updateRecordingPrivacy
} from "../actions/recordingAction";


const recordingSlice = createSlice({
    name: 'recording',
    initialState: RecordingModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHostRecordByEventId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getHostRecordByEventId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
                state.hostRecording = action.payload;
            })
            .addCase(getHostRecordByEventId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getRecordByEventId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRecordByEventId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
                state.recording = action.payload;
            })
            .addCase(getRecordByEventId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(updateRecording.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateRecording.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(updateRecording.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(updateRecordingPrivacy.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateRecordingPrivacy.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(updateRecordingPrivacy.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(processRecording.pending, (state) => {
                state.loading = true;
            })
            .addCase(processRecording.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(processRecording.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(searchScenes.pending, (state) => {
                state.loading = true;
            })
            .addCase(searchScenes.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.scenes = action.payload;
            })
            .addCase(searchScenes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(applySceneSearch.pending, (state) => {
                state.loading = true;
            })
            .addCase(applySceneSearch.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(applySceneSearch.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(updateExternalRecording.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateExternalRecording.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(updateExternalRecording.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(scheduleSession.pending, (state) => {
                state.loading = true;
            })
            .addCase(scheduleSession.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(scheduleSession.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
    },
});

export default recordingSlice.reducer;