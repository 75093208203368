import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import HeaderOrg from "../HeaderOrg/HeaderOrg";
import Sidebar from "../Sidebar/Sidebar";
import "./MainLayoutOrg.scss";
import { setMode } from "../../../../common/redux/slice/authSlice";
import { ApplicationMode } from "../../../../common/enums/ApplicationMode";
import { IS_LOGGED_IN, ORGANIZATION_PROFILE } from "../../../../common/constants/LocalStorageConstants";
import useFetchOrganization from "../../../../common/hooks/useFetchOrganization";


const MainLayoutOrg = () => {
    const [activeItem, setActiveItem] = useState("");
    const [showEventSidebar, setShowEventSidebar] = useState(true);
    // const [isProfileFetched, setIsProfileFetched] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    // const { organization } = useSelector((state) => state.organization)

    const username = user?.id;
    const isManageEventPage = useMemo(() => location.pathname.includes("/organization/manage/"), [location.pathname]);
    const isLoggedIn = localStorage.getItem(IS_LOGGED_IN);
    const orgProfile = JSON.parse(sessionStorage.getItem(ORGANIZATION_PROFILE))


    useFetchOrganization(username, isLoggedIn)

    useEffect(() => {
        if (!orgProfile) {
            navigate(`/organization/manage/profile`)
        }
        // eslint-disable-next-line
    }, [dispatch, navigate]);


    useEffect(() => {
        if (!orgProfile) {
            setShowEventSidebar(false)
        }
    }, [orgProfile, showEventSidebar]);


    useEffect(() => {
        dispatch(setMode(ApplicationMode.ORGANIZER))
    }, [dispatch]);

    return (
        <div className="main-layout-org-container">
            <div className="main-header-org">
                <HeaderOrg isLoggedIn={isLoggedIn}/>
            </div>
            {showEventSidebar && (
                <div className="main-sidebar-org">
                    <Sidebar activeItem={activeItem}/>
                </div>
            )}
            <div className={`main-display-org ${isManageEventPage ? 'manage-event-page' : ''}`}>
                <Outlet context={{ activeItem, setActiveItem, setShowEventSidebar, showEventSidebar }}/>
            </div>
        </div>
    );
};

export default MainLayoutOrg;
