import React from "react";
import TicketStep from "../../blocks/TicketStep/TicketStep";
import "./TicketSteppers.scss";

const getTicketStep = (eventId) => {
    return (
        [
            {
                id: 1,
                order: 1,
                title: "✨ Reservation Info",
                link: `/events/${eventId}/ticket-reservation/order-info`,
            },
            {
                id: 2,
                order: 2,
                title: "🛒 Payment",
                link: `/events/${eventId}/ticket-reservation/payment`,
            },
            {
                id: 3,
                order: 3,
                title: "✔️ Finish",
                link: `/events/${eventId}/ticket-reservation/finish`,
            },
        ]
    )
}
const TicketSteppers = ({ eventId, currentStep }) => {
    const ticketSteps = getTicketStep(eventId);
    return (
        <div className="ticket-steppers-container">
            {
                ticketSteps.map((item, index) => {
                    return (
                        <div className="ticket-steppers-list" key={item.id}>
                            <TicketStep
                                order={item.order}
                                title={item.title}
                                active={currentStep === item.order}
                                finished={currentStep > item.order}
                            />
                            {(index !== ticketSteps.length - 1)
                                && <div
                                    className={`ticket-steppers-divider ${currentStep > item.order && "finish"}`}></div>}
                        </div>
                    )
                })
            }
        </div>
    );
};

export default TicketSteppers;