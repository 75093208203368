import React from "react";
import "./EventSummary.scss";

export const EventSummary = ({ eventSummary }) => {
    return (
        <div className="event-summary-container">
            {eventSummary}
        </div>
    );
};
