import React from "react";
import "./EventOrganizer.scss";
import { Link } from "react-router-dom";
import defaultAvatar from "../../../../assets/images/default_avatar_v2.jpg";
import ButtonFollowOrg from "../../../../modules/components/ButtonFollowOrg";
import { IS_LOGGED_IN } from "../../../../../common/constants/LocalStorageConstants";

export const EventOrganizer = ({ organization }) => {
    const { id, organizationName, bio, avatarFileUrl } = organization;
    const isLoggedIn = localStorage.getItem(IS_LOGGED_IN) === "true";
    
    return (
        <div className="event-organizer-container">
            <div className="event-organizer-avatar">
                <img src={avatarFileUrl || defaultAvatar} alt=""/>
            </div>
            <div className="event-organizer-info">
                <Link to={`/organizers/${id}`} className="org-name">{organizationName}</Link>
                <div className="org-slogan">{bio}</div>
                {/*<div className="org-email">{email}</div>*/}
                {
                    isLoggedIn && (
                        <div className="org-info-btn">
                            <ButtonFollowOrg organizer={organization}/>
                        </div>
                    )
                }
            
            </div>
        </div>
    );
};
