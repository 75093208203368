import { Button, Form } from 'antd';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import './OrganizationCreateEvent.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "sonner";
import { DateTimePattern } from "../../../common/enums/DateTimePattern";
import { EventType } from "../../../common/enums/Event";
import { createEvent, getEventById, updateEvent } from '../../../common/redux/actions/eventActions';
import { uploadMedia } from '../../../common/redux/actions/mediaAction.js';
import { formatDateRange } from '../../../common/utils/dateTimeUtils.js';
import FixedFooter from "../../modules/components/FixedFooter";
import ImagesForm from "./blocks/ImagesForm";
import EventForm from './partials/EventForm';

function OrganizationCreateEvent() {
    const [thumbnailUrl, setThumbnailUrl] = useState();
    const [eventLocation, setEventLocation] = useState();
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { event, loading } = useSelector((state) => state.event);
    const { organization } = useSelector((state) => state.organization);
    const mediaLoading = useSelector((state) => state.media.loading);
    const orgId = useMemo(() => organization?.organizationId, [organization]);
    const { eventId } = useParams();
    const navigate = useNavigate();

    const isUpdateMode = useMemo(() => Boolean(event && eventId), [event, eventId]);


    const setFormValues = () => {
        if (isUpdateMode && event) {
            setThumbnailUrl(event.thumbnailUrl);
            setEventLocation(event.location)
            form.setFieldsValue({
                eventName: event.eventName,
                category: event.categories,
                eventType: event.eventType,
                eventDateTime: [formatDateRange(event.startDateTime), formatDateRange(event.endDateTime)],
            });
        }
    };

    useEffect(() => {
        setFormValues();
        // eslint-disable-next-line
    }, [event]);

    useLayoutEffect(() => {
        document.title = "Create event | Eventure";
    });

    const handleMediaChange = (newMediaUrls) => {
        setThumbnailUrl(newMediaUrls);
    };

    const createEventParams = (values, uploadedMedia) => {
        const {
            eventName,
            eventType,
            eventDateTime,
            category,
        } = values;

        const [startDateTime, endDateTime] = eventDateTime;

        return {
            eventName,
            eventType,
            organizationId: orgId,
            startDateTime: formatDateRange(startDateTime, DateTimePattern.COMPLETED_DATE_TIME),
            endDateTime: formatDateRange(endDateTime, DateTimePattern.COMPLETED_DATE_TIME),
            categories: category,
            location: eventLocation || null,
            thumbnailId: uploadedMedia,
            eventId: isUpdateMode ? Number(eventId) : null
        };
    };

    const onFinish = async (values) => {
        const {
            eventType,
            eventDateTime,
        } = values;
        const [startDateTime, endDateTime] = eventDateTime;

        if (!thumbnailUrl && !isUpdateMode) {
            toast.error(`Please upload image to create event`)
            return;
        }

        if (eventType === EventType.OFFLINE && !eventLocation) {
            toast.warning(`Please choose a location for an offline event`);
            return;
        }

        if (new Date(startDateTime) === new Date(endDateTime)) {
            toast.error(`Start date and end date cannot be the same.`);
            return null;
        }

        let uploadedMedia = null;
        if (typeof thumbnailUrl !== 'string') {
            const { payload: uploadedMediaResponse } = await dispatch(uploadMedia(thumbnailUrl));
            uploadedMedia = uploadedMediaResponse ? uploadedMediaResponse.id : null;
        }

        const params = createEventParams(values, uploadedMedia);
        const action = isUpdateMode ? updateEvent : createEvent;

        await dispatch(action(params))
            .unwrap()
            .then(res => {
                if (!isUpdateMode) {
                    toast.success(`Event has been created`)
                    setTimeout(() => {
                        navigate(`/organization/manage/events/${res}/basic-info`);
                        navigate(0);
                    }, 1000)
                } else {
                    dispatch(getEventById({ eventId: eventId }))
                    toast.success('Event updated successfully!');
                }
            })
            .catch(error => {
                toast.error(`Failed to create/update event`)
            })
    };

    const cancelCreateEvent = () => {
        form.resetFields();
        navigate('/');
    };

    const actionHandler = (
        <div className='buttons-holder'>
            <Form.Item>
                <Button className={"create-button"} type='primary' htmlType='submit' loading={mediaLoading || loading}>
                    {isUpdateMode ? "Update event " : "Create event"}
                </Button>
            </Form.Item>
            <Button className={"cancel-button"} onClick={cancelCreateEvent} type='default'>
                Cancel
            </Button>
        </div>
    );

    return (
        <div className="event-form-container">
            <Form
                form={form}
                requiredMark={true}
                onFinish={onFinish}
                className='content-container'
                layout='vertical'
                initialValues={{ location: "" }}
            >
                <div className='image-form'>
                    <ImagesForm isUpdateMode={isUpdateMode} setThumbnailUrl={handleMediaChange}/>
                </div>

                <div className='event-details-form'>
                    <EventForm setEventLocation={setEventLocation} eventLocation={eventLocation} isUpdateMode={isUpdateMode} />
                </div>

                <FixedFooter action={actionHandler}/>
            </Form>
        </div>
    );
}

export default OrganizationCreateEvent;
