import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncService from "../../services/asyncService";
import { GET_RELATED_EVENTS } from "../types/async";

export const getRelatedEvents = createAsyncThunk(
    GET_RELATED_EVENTS,
    async (data, { rejectWithValue }) => {
        try {
            return await asyncService.getRelatedEvents(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);