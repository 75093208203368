import { createSlice } from "@reduxjs/toolkit";
import { CartModel } from "../../models/cartModel.model";

const cartSlice = createSlice({
    name: 'cart',
    initialState: CartModel,
    reducers: {
        setCartReservationId: (state, action) => {
            state.cartReservationId = action.payload;
        },
        setCartEventId: (state, action) => {
            state.cartEventId = action.payload;
        },
        setCartBuyer: (state, action) => {
            state.cartBuyer = action.payload;
        },
        setCartTickets: (state, action) => {
            state.cartTickets = action.payload;
        },
        setCartVoucher: (state, action) => {
            state.cartVoucher = action.payload;
        },
        setCartSubtotal: (state, action) => {
            state.cartSubtotal = action.payload;
        },
        setCartTotal: (state, action) => {
            state.cartTotal = action.payload;
        },
    },
    extraReducers: (builder) => {
    },
});
export const {
    setCartReservationId,
    setCartEventId,
    setCartBuyer,
    setCartTickets,
    setCartVoucher,
    setCartSubtotal,
    setCartTotal
} = cartSlice.actions;

export default cartSlice.reducer;
