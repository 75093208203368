import { createSlice } from "@reduxjs/toolkit";
import { uploadMedia, uploadMultipleMedia } from "../actions/mediaAction";

const initialState = {
    loading: false,
    media: [],
    error: null,
};

const mediaSlice = createSlice({
    name: "media",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(uploadMedia.pending, (state, action) => {
                state.loading = true
            })
            .addCase(uploadMedia.fulfilled, (state, action) => {
                state.loading = false
                state.media = action.payload;
            })
            .addCase(uploadMedia.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(uploadMultipleMedia.pending, (state, action) => {
                state.loading = true
            })
            .addCase(uploadMultipleMedia.fulfilled, (state, action) => {
                state.loading = false
                state.media = action.payload;
            })
            .addCase(uploadMultipleMedia.rejected, (state, action) => {
                state.loading = false
            })
    },
});

export default mediaSlice.reducer;
