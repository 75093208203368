import React, { useEffect, useLayoutEffect } from "react";
import "./ReservationDetail.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { getReservationById } from "../../../common/redux/actions/accountAction";
import AddCalendarButton from "../../modules/components/AddCalendarButton/AddCalendarButton";
import TicketItem from "../../modules/components/TicketItem";
import ReservationOrderSection from "./components/ReservationOrderSection/ReservationOrderSection";

const ReservationDetail = () => {
    const { reservationCode } = useParams();
    const { setShowSidebar } = useOutletContext();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { reservation } = useSelector((state) => state.account);

    useLayoutEffect(() => {
        document.title = "Reservation Detail | Eventure";
        setShowSidebar(false);
    });

    useEffect(() => {
        const username = user?.id;
        if (username && reservationCode) {
            dispatch(getReservationById({
                username: username,
                orderId: reservationCode
            }));
        }
    }, [user, reservationCode, dispatch]);

    return (
        <div className="rsv-detail-container">
            {
                reservation && <div className="rsv-detail-header">
                    <div className="rsv-detail-title">
                        Your reservation for <Link to={`/events/${reservation.eventId}`}
                                                   className="rsv-detail-event-name">{reservation?.eventName}</Link>
                    </div>
                    <div className="rsv-detail-btn">
                        <AddCalendarButton event={reservation}/>
                    </div>
                </div>
            }
            {
                reservation &&
                <div className="rsv-detail">
                    <div className="rsv-detail-left">
                        <div className="ticket-section ticket-info">
                            <ReservationOrderSection
                                sectionTitle="Reservation Info"
                                sectionItems={[
                                    {
                                        label: "Total amount",
                                        value: `${reservation?.totalAmount?.toLocaleString()} VND`,
                                    },
                                    {
                                        label: "Payment Time",
                                        value: reservation?.paymentTime
                                    },
                                    {
                                        label: "Payment Method",
                                        value: reservation?.paymentMethod
                                    },
                                ]}
                            />
                        </div>
                        <div className="ticket-section admission-info">
                            <ReservationOrderSection
                                sectionTitle="Admission Info"
                                sectionItems={[
                                    {
                                        label: "First name",
                                        value: reservation?.firstName
                                    },
                                    {
                                        label: "Last name",
                                        value: reservation?.lastName
                                    },
                                    {
                                        label: "Email",
                                        value: reservation?.email
                                    },
                                    {
                                        label: "Phone",
                                        value: reservation?.phone
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="rsv-detail-right">
                        <div className="rsv-ticket-list-title">Tickets</div>
                        <div className="rsv-ticket-list">
                            {
                                (reservation && reservation?.tickets?.length > 0)
                                && reservation?.tickets.map((item, index) => (
                                    <TicketItem
                                        key={index}
                                        ticket={item}
                                        holder={reservation?.firstName + " " + reservation.lastName}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default ReservationDetail;
