import { MeetingProvider } from "@videosdk.live/react-sdk";
import React from "react";
import { useSelector } from "react-redux";
import { MeetingAppProvider } from "../../../../common/videosdk/MeetingAppContextDef";
import { ILSContainer } from "../../components/interactive-live-streaming/ILSContainer";

const LiveStreamPage = ({ token, meetingId, meetingMode }) => {
    const {
        participantName,
        selectedMic,
        selectedWebcam,
        micOn,
        webcamOn,
    } = useSelector((state) => state.videosdk);

    return (
        <MeetingAppProvider
            selectedMic={selectedMic}
            selectedWebcam={selectedWebcam}
            initialMicOn={micOn}
            initialWebcamOn={webcamOn}
        >
            <MeetingProvider
                config={{
                    meetingId,
                    micEnabled: micOn,
                    webcamEnabled: webcamOn,
                    name: participantName ? participantName : "Guest",
                    mode: meetingMode,
                    multiStream: false,
                }}
                token={token}
                reinitialiseMeetingOnConfigChange={true}
                joinWithoutUserInteraction={true}
            >
                <ILSContainer meetingMode={meetingMode}/>
            </MeetingProvider>
        </MeetingAppProvider>
    );
}

export default LiveStreamPage;