import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./SelectProvinceDistrict.scss";
import useAddress from "../../../../common/hooks/useAddress";

const SelectProvinceDistrict = ({ form, currentLocation }) => {
    const [locations, setLocations] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const { address, error } = useAddress();

    const buildDistricts = (locations, provinceName) => {
        if (provinceName) {
            const currentProvince = locations.filter(province => province.Name === provinceName)[0];
            const provinceDistricts = currentProvince?.Districts;
            if (provinceDistricts) {
                return provinceDistricts.map((district) => ({
                    id: district.Id,
                    name: district.Name,
                    label: district.Name
                }));
            }
        }
    };

    useEffect(() => {
        if (address?.length > 0) {
            const provincesData = address.map((province) => ({
                id: province.Id,
                name: province.Name,
                label: province.Name,
            }));
            setProvinces(provincesData);
            setLocations(address);
            if (currentLocation) {
                const districtsData = buildDistricts(address, currentLocation?.province);
                if (districtsData) setDistricts(districtsData);
            }
        }
    }, [address, currentLocation]);

    const handleChangeProvince = (value, key) => {
        if (value) {
            const districtsData = buildDistricts(locations, value);
            if (districtsData) setDistricts(districtsData);
        } else {
            setDistricts([]);
        }
        form.setFieldValue("district", null);
    }

    const handleChangeDistrict = (value) => {
        form.setFieldValue("district", value);
    }

    return (
        <div className="select-province-district-container">
            <Form.Item name="province">
                <Select
                    showSearch
                    placeholder="Select province"
                    onChange={(value) => handleChangeProvince(value)}
                    allowClear={true}
                >
                    {
                        provinces.map((province) => (
                            <Select.Option key={province.id} value={province.name}>
                                {province.label}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
            <Form.Item name="district">
                <Select
                    showSearch
                    placeholder="Select city/district"
                    onChange={(value) => handleChangeDistrict(value)}
                    allowClear={true}
                >
                    {
                        districts.map((district) => (
                            <Select.Option key={district.id} value={district.name}>
                                {district.label}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
        </div>
    );
};

export default SelectProvinceDistrict;