import axiosInstance from "../api";


const asyncService = {
    getRelatedEvents: async (payload) => {
        try {
            return await axiosInstance.post(`/api/v1/audience/getRelatedEvent`, payload);
        } catch (error) {
            console.error("Error get related events:", error);
            throw error;
        }
    },
}

export default asyncService;