import { CaretDown } from "@phosphor-icons/react";
import { Select } from "antd";
import Search from "antd/es/input/Search";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ORGANIZATION_PROFILE } from "../../../common/constants/LocalStorageConstants";
import { DateTimePattern } from "../../../common/enums/DateTimePattern";
import { getCreatedEvents } from "../../../common/redux/actions/eventActions";
import { formatDateRange } from "../../../common/utils/dateTimeUtils";
import Loading from "../../modules/components/Loading/Loading";
import { eventStatusItems } from "./eventFilterOptions";
import OrganizationEvents from "./partials/OrganizationEvents";
import "./OrganizationEventList.scss"

const countTicketStatus = (tickets) => {
    const soldQty = tickets.reduce((base, ticket) => base + ticket.soldQuantity, 0);
    const totalQty = tickets.reduce((base, ticket) => base + ticket.totalQuantity, 0);
    return { soldQty: soldQty, totalQty: totalQty };
};

function formatEventData(events, currentDate) {
    return events?.map((event) => ({
        ...event,
        key: event.eventId,
        startDateTime: formatDateRange(event.startDateTime, DateTimePattern.STANDARD_DATE_TIME),
        endDateTime: formatDateRange(event.endDateTime, DateTimePattern.STANDARD_DATE_TIME),
        status: formatEventStatus(event, currentDate),
        ticketStatus: countTicketStatus(event.tickets),
    }));
}

function formatEventStatus(event, currentDate) {
    const eventStartDate = new Date(event.startDateTime);
    const eventEndDate = new Date(event.endDateTime);

    if (currentDate >= eventStartDate && currentDate < eventEndDate) {
        return "Happening";
    } else if (currentDate > eventEndDate) {
        return "Ended";
    } else {
        return event.isPublished ? "Published" : "Draft";
    }
}

const OrganizationEventList = () => {
    const [searchData, setSearchData] = useState('');
    const [eventStatus, setEventStatus] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orgId = useMemo(() => JSON.parse(sessionStorage.getItem(ORGANIZATION_PROFILE)).organizationId, []);
    const { createdEvents, loading } = useSelector((state) => state.event);
    const { user } = useSelector((state) => state.auth);
    const username = user?.id;
    const currentDate = new Date();

    useLayoutEffect(() => {
        document.title = "Organization's events | Eventure";
    }, []);

    useEffect(() => {
        if (username && orgId) {
            const params = {
                username: username,
                categories: [],
                sortBy: "startDateTime",
                sortOrder: "ASC",
                pageSize: 20,
                pageNumber: 1
            };
            dispatch(getCreatedEvents(params));
        }
        // eslint-disable-next-line
    }, [username, orgId]);

    const updateEvent = useCallback((id) => {
        navigate(`/organization/manage/events/${id}/basic-info`);
    }, [navigate]);

    const onSearch = (value, _e, info) => {
        setSearchData(value);
    };

    // eslint-disable-next-line
    const formattedData = useMemo(() => formatEventData(createdEvents, currentDate), [createdEvents]);

    const filteredData = useMemo(() => {
        return formattedData?.filter((item) =>
            item.eventName.toLowerCase().includes(searchData.toLowerCase()) &&
            (eventStatus.length === 0 || eventStatus.includes(item.status.toLowerCase()))
        );
    }, [formattedData, searchData, eventStatus]);

    if (loading) {
        return (<Loading/>)
    }

    const handleChangeStatusFilter = (value) => {
        setEventStatus(value.map(status => status.toLowerCase()));
    }

    return (
        <div className="my-events-page">
            <div className="my-event-page-container">
                <h1 className="host-events-heading">Events</h1>
                <div className="search-bar">
                    <Search
                        placeholder={"Search event"}
                        onChange={e => onSearch(e.target.value)}
                        style={{ width: 300 }}
                        size="large"
                        enterButton
                    />
                    <div className="search-event-filter-status">
                        {
                            eventStatusItems && <Select
                                placeholder="All status"
                                value={eventStatus}
                                style={{ minWidth: 420 }}
                                onChange={handleChangeStatusFilter}
                                options={eventStatusItems}
                                mode="multiple"
                                suffixIcon={<CaretDown weight="bold"/>}
                                size="large"
                            />
                        }
                    </div>
                </div>
                {filteredData && <OrganizationEvents
                    createdEvents={filteredData}
                    updateEvent={updateEvent}
                />}
            </div>
        </div>
    );
}

export default OrganizationEventList;
