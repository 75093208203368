import axios from 'axios';
import { VIETQR_URL } from "../constants/AppConfigConstants";

const vietQrAxiosInstance = axios.create({
    baseURL: VIETQR_URL,
    headers: {
        'x-client-id': process.env.REACT_APP_VIETQR_CLIENT_ID,
        'x-api-key': process.env.REACT_APP_VIETQR_API_KEY,
        'Content-Type': 'application/json',
    }
})

export default vietQrAxiosInstance;