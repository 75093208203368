import axiosInstance from "../api";

const searchService = {
    searchGenerics: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/search/findGenerics`, request);
        } catch (error) {
            console.error("Error search events:", error);
            throw error;
        }
    },
};

export default searchService;
