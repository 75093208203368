import React from 'react';
import EventCardHorizontal from '../EventCardHorizontal/EventCardHorizontal';
import './EventListHorizontal.scss';

const EventListHorizontal = ({ events }) => {
    return (
        <div className='horizontal-event-list-container'>
            <div className='event-list-grid'>
                {
                    events?.map((event) => (
                        <EventCardHorizontal key={event.eventId} eventData={event}/>
                    ))
                }
            </div>
        </div>
    );
};

export default EventListHorizontal;