export const DateTimePattern = Object.freeze({
    STANDARD_DATE_TIME: "DD-MM-YYYY HH:mm",
    COMPLETED_DATE_TIME: "YYYY-MM-DD HH:mm:ss",
    COMPLETED_DATE: "YYYY-MM-DD",
    CARD_DATE_TIME: "ddd, DD/MM/YYYY - h:mm A",
    TICKET_DATE_TIME: "h:mm DD/MM/YYYY",
    BASIC_I8N_DATE_TIME: "DD-MM-YYYY",
    BASIC_TIME: "HH:mm:ss",
    SHORT_BASIC_TIME: "HH:mm",
});
