import { Button, Form, Input } from 'antd';
import React from 'react';
import './LoginFormFields.scss';
import { Link } from "react-router-dom";

function LoginFormFields({ form, onFinish, onFinishFailed, loading }) {
    return (
        <Form
            form={form}
            name="login"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            requiredMark={true}
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: "Please input your email!",
                    },
                ]}
            >
                <Input size="large"/>
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Please input your password!",
                    },
                ]}
            >
                <Input.Password size="large"/>
            </Form.Item>
            <Form.Item className="remember-me">
                <Link to={"../forgot-password"} className="login-form-forgot">
                    Forgot password?
                </Link>
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                    block={true}
                >
                    Log in
                </Button>
            </Form.Item>
        </Form>
    );
}

export default LoginFormFields;
