import React from 'react';
import { Table, Tag } from "antd";

function OrganizationTransactions({data}) {
    const statusHandler = (_, { status }) => {
        let color;
        switch (status) {
            case 'success':
                color = 'green';
                break;
            case 'processing':
                color = 'yellow';
                break;
            default:
                color = 'green';
                break;
        }
        
        return (
            <Tag color={color}>
                {status.toUpperCase()}
            </Tag>
        );
    }
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Payment',
            dataIndex: 'paymentType',
            key: 'paymentType',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: statusHandler
        },
    ]
    return (
        <Table columns={columns} dataSource={data}></Table>
    );
}

export default OrganizationTransactions;