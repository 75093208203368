import React from 'react';
import './SectionTitle.scss';


export const SectionTitle = ({ text }) => {
    return (
        <div className='section-title-text'>
            {text}
        </div>
    );
};