import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import "./TicketReservation.scss";
import { CART } from "../../../common/constants/LocalStorageConstants";
import { getEventById } from "../../../common/redux/actions/eventActions";
import {
    setCartBuyer,
    setCartEventId,
    setCartReservationId,
    setCartSubtotal,
    setCartTickets,
    setCartTotal,
    setCartVoucher
} from "../../../common/redux/slice/cartSlice";
import EventBanner from "../../modules/partials/EventBanner/EventBanner";
import TicketSteppers from "./components/TicketSteppers/TicketSteppers";

const TicketReservation = () => {
    const { eventId, reservationId } = useParams();
    const { setShowSidebar } = useOutletContext();
    const [currentStep, setCurrentStep] = useState(1);
    const dispatch = useDispatch();
    const { event } = useSelector((state) => state.event);

    useLayoutEffect(() => {
        setShowSidebar(false);
        document.title = "Ticket Reservation | Eventure";
    });

    useEffect(() => {
        return () => {
            localStorage.removeItem(CART);
        };
    }, []);

    useEffect(() => {
        const updateCartFromLocalStorage = () => {
            const cart = JSON.parse(localStorage.getItem(CART) || "{}");
            if (cart.eventId === eventId && cart.reservationId === reservationId) {
                dispatch(setCartBuyer(cart.buyer));
                dispatch(setCartTickets(cart.tickets));
                dispatch(setCartVoucher(cart.voucher));
                dispatch(setCartSubtotal(cart.subtotal || 0));
                dispatch(setCartTotal(cart.total || 0));
            } else {
                localStorage.setItem(CART, JSON.stringify({
                    reservationId: reservationId,
                    eventId: eventId,
                    buyer: {},
                    tickets: [],
                    voucher: {},
                    subTotal: 0,
                    total: 0
                }));
            }
        };

        if (reservationId) dispatch(setCartReservationId(reservationId));
        if (eventId) {
            dispatch(setCartEventId(eventId));
            dispatch(getEventById({ eventId: eventId }));
        }
        updateCartFromLocalStorage();
    }, [reservationId, eventId, dispatch]);

    return (
        <div className="ticket-rsv-container">
            <div className="ticket-rsv-head">
                {event && <EventBanner eventData={event}/>}
                <TicketSteppers eventId={eventId} currentStep={currentStep}/>
            </div>
            <div className="ticket-rsv-content">
                {event && <Outlet context={{ currentStep, setCurrentStep, event, reservationId }}/>}
            </div>
        </div>
    );
};

export default TicketReservation;