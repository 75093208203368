import {PASSWORD_REGEX} from "../constants/PasswordConstants";


export const validatePassword = (rule, value) => {
    const passwordRegex = PASSWORD_REGEX;
    if (!passwordRegex.test(value)) {
        return Promise.reject(
            'Password must contain at least one uppercase letter, one special character, and one number.'
        );
    }
    return Promise.resolve();
};

export const validateConfirmPassword = ({getFieldValue}) => ({
    validator(_, value) {
        if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
        }
        return Promise.reject('The two passwords do not match.');
    },
});