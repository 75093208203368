import { useEffect } from 'react';
import { ORGANIZATION_PROFILE } from "../constants/LocalStorageConstants";
import { getOrganizationProfile, isOrganizationRegistered } from "../redux/actions/organizationActions";
import { setOrganizationProfile } from "../redux/slice/organizationSlice";
import { useDispatch } from "react-redux"; // Import your Redux action

const useFetchOrganization = (username, isLoggedIn) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        const fetchOrganization = async () => {
            if (username && isLoggedIn === "true") {
                try {
                    const isOrgRegister = await dispatch(isOrganizationRegistered({ username })).unwrap();
                    if (isOrgRegister) {
                        const res = await dispatch(getOrganizationProfile({ username }));
                        if (res.payload) {
                            dispatch(setOrganizationProfile(res.payload));
                            sessionStorage.setItem(ORGANIZATION_PROFILE, JSON.stringify(res.payload));
                        }
                    }
                } catch (error) {
                    console.error("Error fetching organization profile:", error);
                }
            }
        };
        
        fetchOrganization();
    }, [username, isLoggedIn, dispatch]);
    
    return;
};

export default useFetchOrganization;
