import { createAsyncThunk } from '@reduxjs/toolkit';
import paymentService from "../../services/paymentService";
import { GET_MOMO_QR_CODE, PURCHASE_FREE_ORDER, VERIFY_FREE_ORDER, VERIFY_MOMO_PAYMENT_STATUS } from "../types/payment";

export const getMomoQrCode = createAsyncThunk(
    GET_MOMO_QR_CODE,
    async (data, { rejectWithValue }) => {
        try {
            return paymentService.getMomoQrCode(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const verifyMomoPaymentStatus = createAsyncThunk(
    VERIFY_MOMO_PAYMENT_STATUS,
    async (data, { rejectWithValue }) => {
        try {
            return paymentService.verifyMomoPaymentStatus(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const purchaseFreeOrder = createAsyncThunk(
    PURCHASE_FREE_ORDER,
    async (data, { rejectWithValue }) => {
        try {
            return await paymentService.purchaseFreeOrder(data);
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const verifyFreeOrder = createAsyncThunk(
    VERIFY_FREE_ORDER,
    async (data, { rejectWithValue }) => {
        try {
            return await paymentService.verifyFreeOrder(data);
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
