import React, { useEffect } from 'react';
import "./ThreadList.scss";
import Thread from "../../components/Thread";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApplicationMode } from "../../../../../common/enums/ApplicationMode";

function ThreadList({ threads, setSeletedThread, mode }) {
    const { threadId } = useParams();
    const navigate = useNavigate();
    const isMobileHidden = threadId ? 'hidden-mobile' : '';
    
    const selectMessage = (thread) => {
        setSeletedThread(thread)
        navigate(`${thread?.id}`)
    }
    
    return (
        <section className={`message-panel-container ${isMobileHidden}`}>
            <div className="messages-panel">
                <div className="messages-list">
                    {threads?.map((thread) => (
                        <div onClick={() => selectMessage(thread)} key={thread?.id}>
                            {mode === ApplicationMode.ATTENDEE ? (
                                <Thread
                                    key={thread?.id}
                                    avatar={thread?.organizationData?.avatarFileUrl}
                                    senderName={thread?.organizationData?.name}
                                />
                            ) : (
                                <Thread
                                    key={thread?.id}
                                    senderName={`${thread?.user?.firstName} ${thread?.user?.lastName}`}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ThreadList;