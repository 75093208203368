export const SearchModel = {
    searchQuery: null,
    searchCategories: ["any_category"],
    searchEventDateRange: "any_day",
    searchEventStartTime: null,
    searchEventEndTime: null,
    searchEventFormat: "any_format",
    searchEventPrice: "any_price",
    searchEventLocation: null,
    searchResults: null,
    loading: null,
    success: null,
    error: null,
    hasMoreResults: false,
};
