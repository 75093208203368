import { VIDEOSDK_API_BASE_URL, VIDEOSDK_TOKEN } from "../constants/AppConfigConstants";

export const getToken = () => {
    if (VIDEOSDK_TOKEN) {
        return VIDEOSDK_TOKEN;
    } else {
        console.error("Error: ", Error("Please add a token"));
    }
};

export const createMeeting = async ({ token }) => {
    const url = `${VIDEOSDK_API_BASE_URL}/v2/rooms`;
    const options = {
        method: "POST",
        headers: { Authorization: token, "Content-Type": "application/json" },
    };

    const { roomId } = await fetch(url, options)
        .then((response) => response.json())
        .catch((error) => console.error("error", error));

    return roomId;
};

export const validateMeeting = async ({ roomId, token }) => {
    const url = `${VIDEOSDK_API_BASE_URL}/v2/rooms/validate/${roomId}`;

    const options = {
        method: "GET",
        headers: { Authorization: token, "Content-Type": "application/json" },
    };

    const result = await fetch(url, options)
        .then((response) => response.json()) //result will have meeting id
        .catch((error) => console.error("error", error));

    return result ? result.roomId === roomId : false;
};
