import { Table } from "antd";
import React from "react";

function OrdersList({ data }) {
    const columns = [
        {
            title: "Reservation",
            dataIndex: "order",
            key: "order",
            // render: (order) => <a>{order}</a>
        },
        {
            title: "Buyer",
            dataIndex: "buyerEmail",
            key: "buyerEmail",
        },
        {
            title: "Date",
            dataIndex: "orderDate",
            key: "orderDate",
        },
        {
            title: "Total Ticket",
            dataIndex: "totalTicket",
            key: "totalTicket",
            align: "right"
        },
        {
            title: "Gross Amount (VND)",
            dataIndex: "grossAmount",
            key: "grossAmount",
            align: "right"
        },
        {
            title: "Net Amount (VND)",
            dataIndex: "netAmount",
            key: "netAmount",
            align: "right"
        },
    ];

    return (
        <div className="orders-list-container">
            <Table pagination={false} className={"order-table"} dataSource={data} columns={columns}/>
        </div>
    );
}

export default OrdersList;