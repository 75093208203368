import React from "react";
import "./EventCategories.scss";

export const EventCategories = ({ eventCategories }) => {
    return (
        <div className="event-categories-container">
            Categories: {eventCategories?.length > 0 &&
                eventCategories.map((category, index) => (
                        <div key={index}>{category}</div>
                    )
                )}
        </div>
    );
};
