import React from 'react';
import {useNavigate} from "react-router-dom";

const MenuItem = ({icon, title, path, active, onClick}) => {
    const navigate = useNavigate();
    
    const handleClick = () => {
        navigate(path);
        onClick && onClick(); // Call the onClick callback
    };
    
    return (
        <div className={`menu-item ${active ? "active" : ""}`} onClick={handleClick}>
            <div className='menu-item-icon'>
                {icon}
            </div>
            <div className='menu-item-title'>
                {title}
            </div>
        </div>
    );
};

export default MenuItem;