import { CalendarDots } from "@phosphor-icons/react";
import React, { useEffect, useLayoutEffect } from "react";
import "./UserUpcomingEvents.scss";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getUpcomingEvents } from "../../../../../common/redux/actions/accountAction";
import EventListVertical from "../../../../modules/components/EventListVertical/EventListVertical";
import Loading from "../../../../modules/components/Loading/Loading";
import NoDataFound from "../../../../modules/components/NoDataFound";

const UserUpcomingEvents = () => {
    const { setActiveItem } = useOutletContext();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { upcomingEvents, loading } = useSelector((state) => state.account);

    useLayoutEffect(() => {
        document.title = "User - Upcoming events";
        setActiveItem("upcoming-events");
    });

    useEffect(() => {
        const username = user?.id;
        if (username) {
            const params = { username: username };
            dispatch(getUpcomingEvents({ ...params }));
        }
    }, [user, dispatch]);

    return (
        <div className="user-upcoming-events-container">
            <div className="user-upcoming-events-head">Upcoming events</div>
            {
                loading || !upcomingEvents
                    ? <Loading/>
                    : (
                        upcomingEvents?.length > 0
                            ? <EventListVertical events={upcomingEvents}/>
                            : <NoDataFound icon={<CalendarDots size={60}/>} message="You're not going any events!"/>
                    )
            }
        </div>
    );
};

export default UserUpcomingEvents;
