import { usePubSub } from "@videosdk.live/react-sdk";
import { useEffect, useRef } from "react";
import { json_verify } from "../../../../common/utils/videosdkUtils";
import ChatMessage from "./ChatMessage";
import "./ChatMessages.scss";

const ChatMessages = () => {
    const listRef = useRef();
    const { messages } = usePubSub("CHAT");

    const scrollToBottom = (data) => {
        if (!data) {
            if (listRef.current) {
                listRef.current.scrollTop = listRef.current.scrollHeight;
            }
        } else {
            const { text } = data;
            if (json_verify(text)) {
                const { type } = JSON.parse(text);
                if (type === "CHAT") {
                    if (listRef.current) {
                        listRef.current.scrollTop = listRef.current.scrollHeight;
                    }
                }
            }
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return messages ? (
        <div className="hls-chat-messages-container" ref={listRef}>
            {messages.map((msg, i) => {
                const { senderId, senderName, message, timestamp } = msg;
                return (
                    <ChatMessage
                        key={`chat_item_${i}`}
                        {...{ senderId, senderName, text: message, timestamp }}
                    />
                );
            })}
        </div>
    ) : (
        <p>No messages</p>
    );
};

export default ChatMessages;