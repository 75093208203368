import { Constants } from "@videosdk.live/react-sdk";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getToken } from "../../../common/videosdk/videosdkServices";
import LiveStreamPage from "../../modules/partials/LiveStreamPage/LiveStreamPage";

const AttendLiveStream = () => {
    const { meetingId } = useParams();
    const [clientToken, setClientToken] = useState();
    const meetingMode = Constants.modes.VIEWER;

    useLayoutEffect(() => {
        document.title = "Attend Online Event";
    }, []);

    useEffect(() => {
        const token = getToken();
        if (token) setClientToken(token);
    }, []);

    return (
        <div className="attend-online-event-container">
            {
                (clientToken && meetingId) &&
                <LiveStreamPage token={clientToken}
                                meetingId={meetingId}
                                meetingMode={meetingMode}/>
            }
        </div>
    );
}


export default AttendLiveStream;