import axiosInstance from '../api';

const authService = {
    login: async (credentials) => {
        try {
            const response = await axiosInstance.post(
                `/api/v1/auth/authenticate`,
                credentials
            );
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    register: async (credentials) => {
        try {
            return await axiosInstance.post(`/api/v1/profile/register`, credentials);
        } catch (error) {
            throw error.response.data;
        }
    },
    verifyUserExist: async (email) => {
        try {
            return await axiosInstance.post(`/api/v1/profile/verifyUserExist`, email);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
};

export default authService;
