import { ArrowsInSimple } from "@phosphor-icons/react";
import { GoogleMap, InfoWindowF, MarkerF } from "@react-google-maps/api";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import "./SearchOnMap.scss";
import { GOOGLE_MAP_API } from "../../../../../common/constants/MapConstants";
import useGoogleMapsLoader from "../../../../../common/hooks/useGoogleMapsLoader";
import mapPin from "../../../../assets/icons/map-pin.svg";
import mapPinSelected from "../../../../assets/icons/selected-map-pin.svg";

const containerStyle = {
    width: "100%",
    height: "100%"
};

const SearchOnMap = ({ events, selectEventMarker, setSelectEventMarker, setSearchOnMap, setHoverMap }) => {
    const [activeMarker, setActiveMarker] = useState(null);

    // eslint-disable-next-line
    const { isLoaded, loadError } = useGoogleMapsLoader(GOOGLE_MAP_API)

    useEffect(() => {
        setActiveMarker(selectEventMarker);
    }, [selectEventMarker]);

    // const getAddress = (location) => {
    //     const address = [
    //         location?.address,
    //         location?.district,
    //         location?.province
    //     ].filter(Boolean).join(", ");
    // }

    const handleActiveMarker = (e, event) => {
        setActiveMarker(activeMarker ? null : event.eventId);
        setSelectEventMarker(event.eventId);
        setHoverMap(true);
    };

    const handleInactiveMarker = () => {
        setSelectEventMarker(null);
        setActiveMarker(null);
        setHoverMap(false);
    };

    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        const eventList = events.filter(event => event?.location);
        const eventLocations = eventList.map((event) => ({
            location: {
                lat: event?.location?.latitude,
                lng: event?.location?.longitude,
            }
        }));
        eventLocations.forEach(({ location }) => bounds.extend(location));
        map.fitBounds(bounds);
    };

    return (
        <div className="search-on-map-container">
            {
                isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            onClick={() => handleInactiveMarker()}
                            onLoad={handleOnLoad}
                        >
                            {events?.length > 0 && events.map((event, index) => (
                                <MarkerF
                                    key={index}
                                    position={{
                                        lat: event?.location?.latitude,
                                        lng: event?.location?.longitude,
                                    }}
                                    options={{
                                        icon: activeMarker === event.eventId
                                            ? mapPinSelected
                                            : mapPin,
                                    }}
                                    onClick={(e) => handleActiveMarker(e, event)}
                                >
                                    {activeMarker === event.eventId && <InfoWindowF
                                        position={{
                                            lat: event?.location?.latitude,
                                            lng: event?.location?.longitude,
                                        }}
                                        visible={true}
                                        onCloseClick={handleInactiveMarker}
                                    >
                                        <div className="event-address-map-info-window">
                                            <p className="event-address-map-event">{event?.eventName}</p>
                                            <p>{event?.location?.address}</p>
                                            <p>{event?.location?.district}</p>
                                            <p>{event?.location?.province}</p>
                                        </div>
                                    </InfoWindowF>}
                                </MarkerF>
                            ))}
                        </GoogleMap>
                    )
                    :
                    <></>
            }
            <Button
                className="search-on-map-minimize-btn"
                onClick={() => setSearchOnMap(false)}
            >
                Minimize map <ArrowsInSimple/>
            </Button>
        </div>
    );
};

export default SearchOnMap;