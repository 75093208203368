import React from 'react';
import "./PreviewEventDetails.scss"
import PreviewCard from "../../blocks/PreviewCard";

function PreviewEventDetails({event, organization}) {
    return (
        <section className={"preview-event-detail-container"}>
            <PreviewCard event={event}/>
            <div className="preview-organization">
                <p className="preview-organization-text">
                    Organized by <strong> {organization?.name}</strong>
                </p>
            </div>
        </section>
    );
}

export default PreviewEventDetails;