import axiosInstance from "../api";

const ticketService = {
    addTicket: async (data) => {
        try {
            return await axiosInstance.post(`/api/v1/host/addNewTicket`, data);
        } catch (error) {
            console.error("Error adding ticket:", error);
            throw error;
        }
    },
    getTicketsByEventId: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/host/getTicketsByEventId`, { params: params });
        } catch (error) {
            console.error(`Error getting ticket:`, error);
            throw error;
        }
    },
    deleteTicketById: async (ticketId) => {
        try {
            return await axiosInstance.post(`/api/v1/host/deleteTicketById`, ticketId);
        } catch (error) {
            console.error(`Error deleting ticket:`, error);
            throw error;
        }
    },
    updateTicket: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/host/updateTicket`, request);
        } catch (error) {
            throw error;
        }
    },
    getReservedTicketsByEventId: async (params) => {
        try {
            return await axiosInstance.get(
                `/api/v1/organization/getReservedTicketsByEventId`,
                { params: params }
            );
        } catch (error) {
            throw error;
        }
    },
    getTicketById: async (params) => {
        try {
            return await axiosInstance.get(
                `/api/v1/profile/getTicketById`,
                { params: params }
            );
        } catch (error) {
            throw error;
        }
    },
};

export default ticketService;
