import React from 'react';
import { BellRinging } from "@phosphor-icons/react";
import "./NotificationItem.scss";
import { Link } from "react-router-dom";
import { NotificationType } from "../../../../../common/enums/NotificationType";
import { formatTimeStamp } from "../../../../../common/utils/dateTimeUtils";

function NotificationItem({ notification, message, timeStamp, organizationAvatar }) {
    return (
        <div className="notification-item-container">
            <div className="notification-icon-container">
                {
                    notification?.organizationAvatar
                        ? <img src={notification?.organizationAvatar} alt="" className={"org-img"}/>
                        : <div className="notification-icon">
                            <BellRinging size={28} color={"#fff"}/>
                        </div>
                }
            </div>
            <div className="notification-content">
                <div className="notification-main-content">
                    <div className="notification-type">
                        {
                            notification?.notificationType === NotificationType.EVENT_UPDATE
                                ? "Update from your event!"
                                : (notification?.notificationType === NotificationType.NEW_EVENT_FROM_ORG
                                    ? "New event!"
                                    : "")
                        }
                    </div>
                    <Link target={"_blank"} to={`/events/${notification?.eventId}`} className="notification-event-link">
                        View event
                    </Link>
                    <div className="notification-content-text">
                        {notification?.notificationMessage}
                    </div>
                </div>
                <div className="notification-time">
                    {formatTimeStamp(notification?.timestamp)}
                </div>
            </div>
        </div>
    );
}

export default NotificationItem;