import { Table } from "antd";
import React from "react";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";

const RecentOrders = ({ orders }) => {

    const columns = [
        {
            title: "Order ID",
            dataIndex: "orderId",
            key: "orderId",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        // {
        //     title: "Quantity",
        //     dataIndex: "quantity",
        //     key: "quantity",
        // },
        {
            title: "Total Amount (VND)",
            dataIndex: "totalAmount",
            key: "totalAmount",
            align: "right",
            render: (value) => Math.round(value).toLocaleString(),
        },
        {
            title: "Time",
            dataIndex: "date",
            key: "date",
            render: (value) => formatDateRange(value, DateTimePattern.STANDARD_DATE_TIME),
        },
    ];

    return (
        <div className="recent-order-container">
            <Table dataSource={orders} columns={columns}/>
        </div>
    );
}

export default RecentOrders;