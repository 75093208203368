import { IconContext } from "@phosphor-icons/react";
import { ConfigProvider } from 'antd';
import './App.css';
import './ui/assets/styles/typography.scss';
import React from "react";
import { Toaster } from "sonner";
import Routes from "./common/routes";
import showInsetEffect from "./common/utils/buttonEffectUtils";
import { APP_THEME, ICON_THEME } from "./ui/assets/theme/app_theme";

function App() {
    return (
        <ConfigProvider
            theme={APP_THEME}
            wave={{ showEffect: showInsetEffect }}
        >
            <Toaster
                richColors
                closeButton
                position="top-right"
                offset={64}
                toastOptions={{ className: "toast-global-style" }}
            />
            <IconContext.Provider value={ICON_THEME}>
                <Routes/>
            </IconContext.Provider>
        </ConfigProvider>
    );
}

export default App;
