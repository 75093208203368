import axiosInstance from "../api";


const checkinService = {
    checkinOfflineEvent: async (payload) => {
        try {
            return await axiosInstance.post(
                `/api/v1/organization/checkinOfflineEvent`,
                payload
            );
        } catch (error) {
            console.error("Error checkin offline event:", error);
            throw error;
        }
    },
    checkinOnlineEvent: async (params) => {
        try {
            return await axiosInstance.get(
                `/api/v1/audience/checkIn`,
                { params: params }
            );
        } catch (error) {
            console.error("Error checkin online event:", error);
            throw error;
        }
    }
}

export default checkinService;