import { MapPinLine } from "@phosphor-icons/react";
import { Autocomplete } from "@react-google-maps/api";
import { Form, Input, Radio } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { GOOGLE_MAP_API } from "../../../../../common/constants/MapConstants";
import { EventType } from "../../../../../common/enums/Event";
import useGoogleMapsLoader from "../../../../../common/hooks/useGoogleMapsLoader";
import InfoLayout from "../InfoLayout";


function LocationInfo({ eventLocation, setEventLocation, isUpdateMode }) {
    const [eventType, setEventType] = useState();
    const [inputValue, setInputValue] = useState('');
    const autocompleteRef = useRef();
    const { isLoaded } = useGoogleMapsLoader(GOOGLE_MAP_API)

    const onLoadAutocomplete = useCallback((autocomplete) => {
        autocompleteRef.current = autocomplete;
    }, []);

    const handleEventTypeChange = useCallback((val) => {
        setEventType(val.target.value);
    }, []);

    useEffect(() => {
        if (eventLocation) {
            setInputValue(eventLocation?.address || '');
            setEventType(EventType.OFFLINE);
        }
    }, [eventLocation]);

    const getAddressComponent = useCallback((place, type) => {
        return place.address_components.find(
            component => component.types.includes(type)
        )?.long_name || '';
    }, []);

    const handlePlaceChanged = useCallback(() => {
        const place = autocompleteRef.current.getPlace();
        const { location } = place.geometry;

        const province = getAddressComponent(place, "administrative_area_level_1");
        const district = getAddressComponent(place, "administrative_area_level_2");

        const eLocation = {
            address: `${place?.name} ${place?.formatted_address}`,
            district,
            province,
            latitude: location.lat(),
            longitude: location.lng(),
        };
        setEventLocation(eLocation);
        // eslint-disable-next-line
    }, [getAddressComponent]);


    return (
        <InfoLayout
            icon={<MapPinLine size={48}/>}
            heading="Location"
            description="Help people in the area discover your event and
                        let attendees know where to show up."
        >
            {isUpdateMode && <div>You won't be able to change your location type!</div>}
            <Form.Item
                name="eventType"
                label="Event Type"
                rules={[
                    {
                        required: true,
                        message: "Please select the event type!",
                    },
                ]}
            >
                <Radio.Group size="large" onChange={handleEventTypeChange} disabled={isUpdateMode}>
                    <Radio.Button value={EventType.ONLINE}>Online</Radio.Button>
                    <Radio.Button value={EventType.OFFLINE}>
                        Offline
                    </Radio.Button>
                </Radio.Group>
            </Form.Item>

            {eventType === EventType.OFFLINE && isLoaded && (
                <>
                    <Autocomplete
                        onPlaceChanged={handlePlaceChanged}
                        onLoad={onLoadAutocomplete}
                    >
                        <Input
                            size="large"
                            value={inputValue}
                            // disabled={isUpdateMode}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="Enter location"
                        />
                    </Autocomplete>
                </>
            )}
        </InfoLayout>
    );
}

export default LocationInfo;
