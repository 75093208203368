import { Button, Form, Input, Tooltip } from "antd";
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import "./FeedbackForm.scss";
import { createEventFeedback, getFeedbackForEventOfUser } from "../../../../common/redux/actions/feedbackAction";
import { rates } from "../../../../common/utils/feedbackUtils";

const FeedbackForm = () => {
    const { eventId } = useParams();
    const [rateValue, setRateValue] = useState(5);
    const [rateMessage, setRateMessage] = useState("Very good");
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const handleChangeFeedbackRate = (rateItem) => {
        setRateMessage(rateItem.message);
        setRateValue(rateItem.value);
        form.setFieldValue("feedback_rate", rateItem.value);
    }

    const onFinish = (values) => {
        const payload = {
            eventId: eventId,
            username: user?.id,
            comment: values.feedback_comment || "",
            rating: values?.feedback_rate || 5,
        };
        dispatch(createEventFeedback(payload))
            .unwrap()
            .then((response) => {
                const username = user?.id;
                if (username && eventId) {
                    const params = { username: username, eventId: eventId };
                    dispatch(getFeedbackForEventOfUser(params));
                }
            })
            .catch((error) => {
                toast.error("Failed to feedback for this event!");
            });
    }

    return (
        <div className="feedback-form-container">
            <div className="feedback-form-content">
                <Form
                    form={form}
                    layout="vertical"
                    variant="filled"
                    onFinish={onFinish}
                    initialValues={{
                        feedback_rate: 5,
                        feedback_comment: "",
                    }}
                >
                    <Form.Item
                        name="feedback_rate"
                        label="How do you feel about this event?"
                    >
                        <div className="feedback-form-rate">
                            <div className="feedback-form-rate-values">
                                {
                                    rates.map((item) => (
                                        <div key={item.key}>
                                            <Tooltip title={item.message} placement="bottom" arrow={false}>
                                                    <span
                                                        className={`feedback-form-rate-item ${item.value === rateValue && "selected"}`}
                                                        onClick={() => handleChangeFeedbackRate(item)}>
                                                        {item.icon}
                                                    </span>
                                            </Tooltip>
                                        </div>
                                    ))
                                }
                            </div>
                            <p className="feedback-form-rate-message">{rateMessage}</p>
                        </div>
                    </Form.Item>
                    <Form.Item
                        name="feedback_comment"
                        label="Share your thoughts..."
                    >
                        <Input.TextArea
                            placeholder="Add your comments"
                            autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                    </Form.Item>
                    <div className="feedback-form-button">
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default FeedbackForm;