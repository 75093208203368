import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import "./PastEventRecording.scss";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getRecordByEventId } from "../../../../../common/redux/actions/recordingAction";
import SceneSearch from "../../../../modules/components/SceneSearch";

const PastEventRecording = () => {
    const [playingTimestamp, setPlayingTimestamp] = useState();
    const { eventId, setActiveTab } = useOutletContext();
    const dispatch = useDispatch();
    const { recording } = useSelector((state) => state.recording);
    const recordPlayerRef = useRef();

    useLayoutEffect(() => {
        setActiveTab("recording");
    }, [setActiveTab]);

    useEffect(() => {
        if (eventId) {
            dispatch(getRecordByEventId({ eventId: eventId }));
        }
    }, [eventId, dispatch]);

    useEffect(() => {
        if (recordPlayerRef.current && playingTimestamp) {
            recordPlayerRef.current.seekTo(playingTimestamp);
            setPlayingTimestamp(null);
        }
    }, [playingTimestamp]);

    return (
        <div className="past-event-recording-container">
            {
                (recording)
                    ? <div className="past-event-recording-wrapper">
                        <div className="past-event-recording-content">
                            <div className="past-event-video-head">
                                <div className="past-event-video-privacy">
                                    <p>* Only attendee of the event can view this recording</p>
                                </div>
                            </div>
                            <ReactPlayer
                                ref={recordPlayerRef}
                                className="past-event-video-player"
                                url={recording?.videoFileUrl}
                                controls={true}
                            />
                            {/*<div className="past-event-video-description">*/}
                            {/*    <div className="past-event-video-description-title">*/}
                            {/*        Description*/}
                            {/*    </div>*/}
                            {/*    /!*<div className="past-event-video-description-text">*!/*/}
                            {/*    /!*    {recording?.description}*!/*/}
                            {/*    /!*</div>*!/*/}
                            {/*    <div className="past-event-video-description-text"*/}
                            {/*         dangerouslySetInnerHTML={{ __html: recording?.description }}/>*/}
                            {/*</div>*/}
                        </div>
                        {recording?.videoStatus === "Completed" && <div className="past-event-recording-search">
                            <SceneSearch
                                videoId={recording?.videoId}
                                fileUrl={recording?.videoFileUrl}
                                setPlayingTimestamp={setPlayingTimestamp}
                            />
                        </div>}
                    </div>
                    : <div className="past-event-no-video">There is no recording for this event</div>
            }
        </div>
    );
};

export default PastEventRecording;
