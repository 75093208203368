import { Button, Form, Input, Modal, Radio, Space } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { REPORT_REASONS as reportReasons } from "../../../../../common/constants/ReportReasonContants";
import { reportEvent } from "../../../../../common/redux/actions/reportAction";
import "./ReportEvent.scss";

const ReportEvent = ({ eventId }) => {
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.report);
    const { user } = useSelector((state) => state.auth);

    const hideReportModal = () => {
        setIsReportModalOpen(false);
    }

    const showReportModal = () => {
        setIsReportModalOpen(true);
    }

    const onFinish = (values) => {
        const email = user?.user_metadata?.email;
        const payload = {
            userEmail: email,
            eventId: eventId,
            reportContent: values.report_content,
            reportReason: values.report_reason,
        };
        dispatch(reportEvent(payload))
            .unwrap()
            .then((response) => {
                toast.success("Successfully submit report!");
            })
            .catch((error) => {
                toast.error("Submit report failed!");
            })
            .finally(() => {
                setTimeout(() => {
                    form.resetFields();
                    hideReportModal();
                }, 2000);
            });
    };

    return (
        <div className="report-event-container">
            <Button size="small" onClick={showReportModal}>
                Report event
            </Button>
            <Modal
                title="Report Event"
                open={isReportModalOpen}
                centered={true}
                onCancel={hideReportModal}
                footer={null}
            >
                <div className="report-event-desc">
                    Please share more information why you are reporting this event.
                </div>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="report_reason"
                        label="Reason for report"
                        rules={[{
                            required: true,
                            message: 'Please input your report!',
                        }]}
                    >
                        <Radio.Group>
                            <Space direction="vertical">
                                {
                                    reportReasons.map((item, index) =>
                                        <Radio key={index} value={item}>{item}</Radio>
                                    )
                                }
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="report_content"
                        label="Description"
                        rules={[{
                            required: true,
                            message: 'Please input your report!',
                        }]}
                    >
                        <Input.TextArea
                            placeholder="Any information you can share will be very helpful"
                            autoSize={{ minRows: 4, maxRows: 4 }}
                        />
                    </Form.Item>
                    <Button
                        className="report-event-submit-btn"
                        type="text"
                        htmlType="submit"
                        danger={true}
                        block={true}
                        loading={loading}
                    >
                        Submit report
                    </Button>
                </Form>
            </Modal>
        </div>
    );
};

export default ReportEvent;