import { Button, Form, Input } from "antd";
import React, { forwardRef, useImperativeHandle } from "react";
import "./TicketBuyerInfo.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../../common/redux/actions/accountAction";
import { setCartBuyer } from "../../../../../common/redux/slice/cartSlice";
import { validatePhoneNumber } from "../../../../../common/validations/validators";

const TicketBuyerInfo = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((state) => state.auth);
    const { cartBuyer } = useSelector((state) => state.cart);

    const inputFirstName = Form.useWatch("first_name", form);
    const inputLastName = Form.useWatch("last_name", form);
    const inputPhone = Form.useWatch("phone", form);
    const inputEmail = Form.useWatch("email", form);

    useImperativeHandle(ref, () => ({
        handleInputBuyerInfo
    }));

    const handleFillBuyerInfo = () => {
        const username = authUser?.id;
        if (username) {
            const params = { username: username };
            dispatch(getUserProfile(params))
                .unwrap()
                .then((data) => {
                    form.setFieldsValue({
                        first_name: data?.firstName,
                        last_name: data?.lastName,
                        phone: data?.phone,
                        email: data?.email
                    });
                });
        }
    };

    const handleInputBuyerInfo = () => {
        const user = {
            firstName: inputFirstName,
            lastName: inputLastName,
            phone: inputPhone,
            email: inputEmail,
        };
        dispatch(setCartBuyer(user));
        return user;
    };

    return (
        <div className="ticket-buyer-info-container">
            <div className="ticket-buyer-info-head">
                <p>Update contact information</p>
                <Button ghost={true} onClick={handleFillBuyerInfo}>Fill with my information</Button>
            </div>
            <div className="ticket-buyer-info-form-container">
                <Form
                    className="ticket-buyer-info-form"
                    name="basic"
                    layout="vertical"
                    form={form}
                    initialValues={{
                        "first_name": cartBuyer?.firstName,
                        "last_name": cartBuyer?.lastName,
                        "phone": cartBuyer?.phone,
                        "email": cartBuyer?.email,
                    }}
                    onFinish={handleInputBuyerInfo}
                >
                    <Form.Item
                        label="First name"
                        name="first_name"
                        rules={[
                            { required: true }
                        ]}
                    >
                        <Input allowClear={true}/>
                    </Form.Item>
                    <Form.Item
                        label="Last name"
                        name="last_name"
                        rules={[
                            { required: true }
                        ]}
                    >
                        <Input allowClear={true}/>
                    </Form.Item>
                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                            { required: true },
                            { validator: validatePhoneNumber}
                        ]}
                    >
                        <Input allowClear={true}/>
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true },
                            { type: "email" }
                        ]}
                    >
                        <Input allowClear={true}/>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
});

export default TicketBuyerInfo;