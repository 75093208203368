import { ArrowLineRight } from "@phosphor-icons/react";
import { Button, QRCode, Tag } from "antd";
import React from "react";
import "./Ticket.scss";
import { Link } from "react-router-dom";
import { DateTimePattern } from "../../../../common/enums/DateTimePattern";
import { TicketStatus } from "../../../../common/enums/Ticket";
import { formatDateRange } from "../../../../common/utils/dateTimeUtils";
import { capitalizeFirstLetter } from "../../../../common/utils/stringUtils";
import logo from "../../../assets/icons/logo.svg";

const Ticket = ({ ticket, holder }) => {
    const now = new Date().getTime();
    const eventEnd = new Date(ticket?.eventEndTime).getTime();

    const location = ticket.eventLocation
        ? `${ticket.eventLocation?.district}, ${ticket.eventLocation?.province}`
        : "Online";

    return (
        <div className="ticket-container">
            <div className="ticket-name">
                {ticket?.ticketName} ticket
            </div>
            <div className="ticket-head">
                <div className="ticket-event-name">{ticket?.eventName}</div>
                <div className="ticket-event-time">
                    {formatDateRange(ticket?.eventStartTime, DateTimePattern.STANDARD_DATE_TIME)}
                </div>
                <div className="ticket-event-location">
                    {location}
                </div>
            </div>
            <div className="ticket-qr-code">
                <QRCode
                    errorLevel="H"
                    value={ticket?.qrCode}
                    icon={logo}
                    size={240}
                    bordered={false}
                />
            </div>
            <div className="ticket-info">
                <div className="ticket-info-item">
                    <div className="ticket-info-label">
                        Holder
                    </div>
                    <div className="ticket-info-content">
                        {holder}
                    </div>
                </div>
                <div className="ticket-info-item">
                    <div className="ticket-info-label">
                        Status
                    </div>
                    <div className="ticket-info-content">
                        <Tag bordered={false} color={
                            ticket?.used === false
                                ? "blue"
                                : "green"
                        }>
                            {ticket?.used
                                ? capitalizeFirstLetter(TicketStatus.ADMITTED)
                                : capitalizeFirstLetter(TicketStatus.ACTIVE)}
                        </Tag>
                    </div>
                </div>
            </div>
            <div className="ticket-detail-btn">
                {
                    now > eventEnd
                        ? <Tag bordered={false} color={"processing"}>This event has ended</Tag>
                        : (
                            !ticket?.eventLocation
                                ? <Link to={`/online-event/${ticket?.eventId}`}>
                                    <Button icon={<ArrowLineRight/>}>Attend online event</Button>
                                </Link>
                                : null
                        )
                }
            </div>
        </div>
    );
};

export default Ticket;