import { Lightning } from "@phosphor-icons/react";
import { Button, Input } from "antd";
import React, { useState } from "react";
import "./CreateEventSummary.scss";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { generateEventContent } from "../../../../../common/redux/actions/contentAction";
import { createOrUpdateEventSummary } from "../../../../../common/redux/actions/eventActions";

const CreateEventSummary = ({ event }) => {
    const [eventSummary, setEventSummary] = useState(event.summary);
    const dispatch = useDispatch();
    const [loadingBtnSave, setLoadingBtnSave] = useState(false);
    const [loadingBtnSuggestContent, setLoadingBtnSuggestContent] = useState(false);

    const handleClickSuggestContent = () => {
        if (event) {
            const request = {
                event_name: event.eventName,
                event_format: event.eventType,
            }
            setLoadingBtnSuggestContent(true);
            dispatch(generateEventContent(request))
                .unwrap()
                .then((response) => {
                    setEventSummary(response.summary);
                })
                .catch((error) => {
                    toast.error("Error generate content");
                })
                .finally(() => {
                    setLoadingBtnSuggestContent(false);
                })
        }
    };

    const handleSaveEventSummary = () => {
        const request = {
            eventId: event.eventId,
            summary: eventSummary,
        }
        dispatch(createOrUpdateEventSummary(request))
            .unwrap()
            .then((response) => {
                toast.success("Successfully update summary");
            })
            .catch((error) => {
                toast.error("Error update summary");
            })
            .finally(() => {
                setLoadingBtnSave(false);
            });
    }

    const handleChangeEventSummary = (e) => {
        setEventSummary(e.target.value);
    }

    return (
        <div className="event-detail-info-section event-summary-container">
            <div className="event-detail-info-section-head">
                <div className="event-detail-info-section-title">
                    Event summary
                </div>
                <div className="event-detail-info-section-desc">
                    Add more details about your event and include what people can expect if they attend.
                </div>
            </div>

            <div className="event-summary-content">
                <div className="event-summary">
                    <Input.TextArea
                        value={eventSummary}
                        placeholder="Short and quick intro about your event"
                        showCount
                        autoSize={{ minRows: 4, maxRows: 4 }}
                        onChange={handleChangeEventSummary}
                    />
                </div>
            </div>

            <div className="event-detail-info-section-buttons">
                <Button
                    onClick={handleSaveEventSummary}
                    loading={loadingBtnSave}
                >
                    Save
                </Button>
                <Button
                    className="highlight-button"
                    icon={<Lightning/>}
                    onClick={handleClickSuggestContent}
                    loading={loadingBtnSuggestContent}
                >
                    Suggest content
                </Button>
            </div>
        </div>
    );
};

export default CreateEventSummary
