import React from "react";
import EventNavItem from "../../blocks/EventNavItem";

function EventNavList({ eventId, items, expandedItemId, setExpandedItemId }) {
    return (
        <div className="event-nav-list-container">
            <ul className="event-nav-list">
                {items &&
                    items?.map((item) => (
                        <EventNavItem
                            icon={item.icon}
                            active={item.active}
                            contents={item.contents}
                            type={item.type}
                            path={item.path}
                            subItems={item.subItems}
                            expandedItemId={expandedItemId}
                            setExpandedItemId={setExpandedItemId}
                            id={item.id}
                            key={item.id}
                        />
                    ))}
            </ul>
        </div>
    );
}

export default EventNavList;
