import { createSlice } from '@reduxjs/toolkit';
import {
    login,
    loginWithGoogle,
    logout,
    register,
    sendResetPasswordMail,
    updatePassword
} from "../actions/authActions";
import { ApplicationMode } from "../../enums/ApplicationMode";
import { IS_LOGGED_IN, PROFILE_MODE } from "../../constants/LocalStorageConstants";

const initialState = {
    user: null,
    loading: false,
    error: null,
    success: false,
    session: null,
    mode: ApplicationMode.ATTENDEE,
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        authChanged(state, action) {
            state.session = action.payload;
            state.loading = false;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        authError(state, action) {
            state.error = action.payload;
        },
        setMode(state, action) {
            state.mode = action.payload;
            sessionStorage.setItem(PROFILE_MODE, action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload?.user;
                state.error = null;
                state.success = true;
                localStorage.setItem(IS_LOGGED_IN, "true")
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.user = null;
                state.error = action.payload;
                state.success = false;
            })
            .addCase(register.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(register.rejected, (state, action) => {
                state.loading = false;
                state.user = null;
                state.error = action.payload;
                state.success = false;
            })
            .addCase(logout.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(logout.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.user = null;
                state.loading = false;
                localStorage.removeItem(IS_LOGGED_IN)
            })
            .addCase(sendResetPasswordMail.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(sendResetPasswordMail.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(sendResetPasswordMail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updatePassword.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload;
                state.error = null;
            })
            .addCase(updatePassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(loginWithGoogle.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(loginWithGoogle.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(loginWithGoogle.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
        
    },
});

export default authSlice.reducer;
export const {
    setUser,
    authError,
    setMode,
    setLoading
} = authSlice.actions;
