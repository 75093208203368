import React from "react";
import "./AgendaItem.scss";
import { DateTimePattern } from "../../../../common/enums/DateTimePattern";
import { formatDateRange, getDateTimeObject } from "../../../../common/utils/dateTimeUtils";
import defaultAvatar2 from "../../../assets/images/default_avatar_v2.jpg";

const AgendaItem = ({ agendaItem }) => {
    const { sessionStartTime, sessionEndTime, sessionTitle, sessionDescription, hosts } = agendaItem;
    const startTime = getDateTimeObject(sessionStartTime, DateTimePattern.BASIC_TIME);
    const endTime = getDateTimeObject(sessionEndTime, DateTimePattern.BASIC_TIME);

    return (
        <div className="agenda-item-container">
            <div className="agenda-item-wrapper">
                <div className="agenda-item-time">
                    {formatDateRange(startTime, DateTimePattern.SHORT_BASIC_TIME)} - {formatDateRange(endTime, DateTimePattern.SHORT_BASIC_TIME)}
                </div>
                <div className="agenda-item-title">{sessionTitle}</div>
                <div className="agenda-item-hosts">
                    {
                        hosts?.length > 0 &&
                        hosts.map((host, index) => (
                            <div key={index} className="host-item">
                                <img src={host.hostAvatar || defaultAvatar2}
                                     onError={(e) => e.target.src = defaultAvatar2}
                                     alt=""/>
                                <div className="host-name">{host.hostname}</div>
                            </div>
                        ))
                    }
                </div>
                <div className="agenda-item-description">
                    {sessionDescription}
                </div>
            </div>
        </div>
    );
};

export default AgendaItem;