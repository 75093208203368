import { createAsyncThunk } from "@reduxjs/toolkit";
import channelService from "../../services/channelService";
import { GET_ALL_CATEGORY } from "../types/channel";

export const getAllCategory = createAsyncThunk(
    GET_ALL_CATEGORY,
    async (data, { rejectWithValue }) => {
        try {
            const response = await channelService.getAllCategory(data);
            return response;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
