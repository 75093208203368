import { BellSimple, ChatCircleText, Compass, House, Ticket } from "@phosphor-icons/react";
import React, { useCallback, useMemo, useState } from "react";
import "./MenuBottom.scss";
import { Link, useLocation } from "react-router-dom";

function MenuBottom() {
    const items = [
        {
            id: 1,
            icon: <House size={25} color="#7d7d7d"/>,
            path: "/",
        },
        {
            id: 2,
            icon: <Compass size={25} color="#7d7d7d"/>,
            path: "find?source=events",
        },
        {
            id: 3,
            icon: <Ticket size={25} color="#7d7d7d"/>,
            path: "user/reservations",
        },
        // {
        //     id: 4,
        //     icon: <Heart size={25} color="#7d7d7d"/>,
        //     path: "user/liked-events",
        // },
        {
            id: 4,
            icon: <BellSimple size={25} color="#7d7d7d"/>,
            path: "notifications",
        },
        {
            id: 5,
            icon: <ChatCircleText size={25} color="#7d7d7d"/>,
            path: "messages",
        }
    ];

    const [menuItems, setMenuItems] = useState(items);
    const location = useLocation();

    const updatedMenuItems = useMemo(() => {
        const currentPath = location.pathname;
        return menuItems.map(item => ({
            ...item,
            isActive: item.path === currentPath || `/${item.path}` === currentPath
        }));
    }, [location.pathname, menuItems]);

    const handleItemClick = useCallback((id) => {
        const updatedItems = menuItems.map(item => ({
            ...item,
            isActive: item.id === id
        }));
        setMenuItems(updatedItems);
    }, [menuItems]);

    return (
        <div className={"menu-bottom-container"}>
            {updatedMenuItems.map((menuItem) => (
                <Link key={menuItem.id}
                      onClick={() => handleItemClick(menuItem.id)}
                      to={menuItem.path}
                      className={menuItem.isActive ? "active" : ''}
                    // reloadDocument={menuItem.path === "find?source=events"}
                >
                    {React.cloneElement(menuItem.icon, { weight: menuItem.isActive ? "fill" : "regular" })}
                </Link>
            ))}
        </div>
    );
}

export default MenuBottom;
