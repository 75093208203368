import axiosInstance from "../api";

const accountService = {
    getUserProfile: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/profile/getUserProfile`, {
                params: params
            });
        } catch (error) {
            console.error("Error get user profile:", error);
            throw error;
        }
    },
    updateUserProfile: async (requests) => {
        try {
            return await axiosInstance.post(`/api/v1/profile/updateUserProfile`, requests);
        } catch (error) {
            console.error("Error update user profile:", error);
            throw error;
        }
    },
    updateUserAvatar: async (requests) => {
        try {
            return await axiosInstance.post(
                `/api/v1/profile/updateProfileAvatar`,
                requests,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
        } catch (error) {
            throw error;
        }
    },
    removeUserAvatar: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/profile/removeProfileAvatar`, {
                params: params
            });
        } catch (error) {
            throw error;
        }
    },
    getInterestedCategories: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/profile/getInterestedCategories`, {
                params: params
            });
        } catch (error) {
            throw error;
        }
    },
    createOrUpdateInterestedCategories: async (requests) => {
        try {
            return await axiosInstance.post(`/api/v1/profile/createOrUpdateInterestedCategory`, requests);
        } catch (error) {
            throw error;
        }
    },
    getFollowingOrganizationIds: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/organization/getFollowingOrganizationIds`, { params: params });
        } catch (error) {
            console.error("Error get following organizer ids:", error);
            throw error;
        }
    },
    getFollowingOrganizations: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/organization/getFollowingOrganizations`, { params: params });
        } catch (error) {
            console.error("Error get following organizers:", error);
            throw error;
        }
    },
    followOrganization: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/organization/followOrganization`, request);
        } catch (error) {
            console.error("Error follow organizer:", error);
            throw error;
        }
    },
    unfollowOrganization: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/organization/unfollowOrganization`, request);
        } catch (error) {
            console.error("Error unfollow organizer:", error);
            throw error;
        }
    },
    getPastEvents: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/audience/getPastEvents`, { params: params });
        } catch (error) {
            console.error("Error get past events:", error);
            throw error;
        }
    },
    getUpcomingEvents: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/audience/getUpcomingEvents`, { params: params });
        } catch (error) {
            console.error("Error get upcoming events:", error);
            throw error;
        }
    },
    getReservationsByUsername: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/profile/getReservationsByUsername`, { params: params });
        } catch (error) {
            console.error("Error get reservations:", error);
            throw error;
        }
    },
    getReservationById: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/profile/getReservationById`, { params: params });
        } catch (error) {
            console.error("Error get reservation:", error);
            throw error;
        }
    },
    getProfileStatistic: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/profile/getUserInformation`, { params: params });
        } catch (error) {
            console.error("Error get profile statistic:", error);
            throw error;
        }
    },
    canBuyMoreTicket: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/profile/canBuyMoreTicket`, request);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    isReservedFreeTicket: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/profile/isReservedFreeTicket`, request);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
};

export default accountService;
