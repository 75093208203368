import { Alert, Space, Tabs } from "antd";
import React, { useLayoutEffect, useMemo, useState } from "react";
import "./AddTicketPage.scss";
import { Link, useParams } from "react-router-dom";
import { ORGANIZATION_PROFILE } from "../../../common/constants/LocalStorageConstants";
import Ticket from "./partials/Ticket";
import Voucher from "./partials/Voucher";


function AddTicketPage() {
    // eslint-disable-next-line
    const [activeTab, setActiveTab] = useState("ticket");
    const { eventId } = useParams();
    const orgProfile = JSON.parse(sessionStorage.getItem(ORGANIZATION_PROFILE));
    const isBankInfoProvided = useMemo(() => orgProfile.bankAccount, [orgProfile.bankAccount]);

    useLayoutEffect(() => {
        document.title = "Event Voucher | Eventure";
    }, []);
    const items = [
        {
            key: "ticket",
            label: "Admission",
            children: <Ticket eventId={eventId} orgBankExist={isBankInfoProvided}/>,
        },
        {
            key: "voucher",
            label: "Promo codes",
            children: <Voucher eventId={eventId} isBankInfoProvided={isBankInfoProvided}/>,
        },
    ];

    const onChange = (key) => {
        setActiveTab(key)
    }

    return (
        <main className="tickets-page-container">
            <div className="ticket-page-wrapper">
                <div className="ticket-page-contents">
                    <h1 className="ticket-page-header">Tickets</h1>
                    {!isBankInfoProvided ? (
                        <Alert
                            message={`In order to create ticket organizer must have a bank account`}
                            type={"warning"}
                            action={
                                <Space>
                                    <Link className={`go-to-bank-link`} to={`/organization/manage/finance`}>
                                        Update bank account
                                    </Link>
                                </Space>
                            }
                        />
                    ) : null}
                    <div className="ticket-page-tabs">
                        <Tabs
                            defaultActiveKey="ticket"
                            items={items}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default AddTicketPage;
