import axiosInstance from "../api";

const paymentService = {
    getMomoQrCode: async (request) => {
        try {
            return await axiosInstance.post("/api/v1/payment/momo", request);
        } catch (error) {
            console.error("Error call payment service:", error);
            throw error;
        }
    },
    verifyMomoPaymentStatus: async(request) => {
        try {
            return await axiosInstance.post("/api/v1/payment/verifyMomoPaymentStatus", request);
        } catch (error) {
            console.error("Error call verify momo payment status:", error);
            throw error;
        }
    },
    purchaseFreeOrder: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/payment/purchaseFreeOrder`, request)
        } catch (error) {
            throw error;
        }
    },
    verifyFreeOrder: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/payment/verifyFreeOrder`, request)
        } catch (error) {
            throw error;
        }
    }
};

export default paymentService;
