import React, { useEffect } from 'react';
import { Button, Flex, Form, Input, Select } from "antd";
import "./BankSetting.scss";
function BankSetting({ form , banksInfo, onBanksChange, bankAccountName,setBankAccountNumber, bankAccountNumber, validateBankAccount, onSubmit, organization}) {
    
    useEffect(() => {
        if (bankAccountName) {
            form.setFieldsValue({
                bankAccountName: bankAccountName
            })
        }
    }, [bankAccountName]);
    
    useEffect(() => {
        if(organization?.bankName || organization?.bankAccount) {
            form.setFieldsValue({
                bankName: organization?.bankName,
                bankAccount: organization?.bankAccount
            })
        }
    }, [organization])
    
    
    return (
        <div className={"bank-setting"}>
            <Form onFinish={onSubmit}  form={form} layout="vertical" requiredMark={true} style={{ width: 500 }}
            >
                <Form.Item
                    name="bankName"
                    label="Bank name"
                    rules={[
                        {
                            required: true,
                            message: 'Missing required information'
                        }
                    ]}>
                    <Select
                        showSearch
                        placeholder="Select a bank"
                        options={banksInfo}
                        onChange={onBanksChange}
                    />
                </Form.Item>
                <Form.Item
                    name="bankAccount"
                    label="Bank account"
                    rules={[
                        {
                            required: true,
                            message: 'Missing required information'
                        }
                    ]}>
                    <Input onChange={(e) => setBankAccountNumber(e)} value={bankAccountNumber} />
                </Form.Item>
                <Form.Item
                    name="bankAccountName"
                    label="Bank account name"
                >
                    <Input value={bankAccountName} disabled={true}/>
                </Form.Item>
                <Flex gap={20}>
                    <Form.Item>
                        <Button  htmlType={'submit'} type="primary">Save info</Button>
                    </Form.Item>
                    <Button onClick={validateBankAccount} type="default">Validate bank account</Button>
                
                </Flex>
                
                
            </Form>
        </div>
    );
}

export default BankSetting;