const prefix = "event"

export const CREATE_EVENT = `${prefix}/createEvent`;
export const GET_ALL_EVENTS = `${prefix}/getAllEvents`;
export const GET_CREATED_EVENTS = `${prefix}/getCreatedEvents`;
export const GET_SUGGESTED_EVENTS = `${prefix}/getSuggestedEvents`;
export const GET_EVENT_BY_ID = `${prefix}/getEventById`;
export const GET_FAVORITE_EVENTS = `${prefix}/getFavoriteEvents`;
export const GET_FAVORITE_EVENT_IDS = `${prefix}/getFavoriteEventIds`;
export const ADD_EVENT_TO_FAVORITE = `${prefix}/addEventToFavorite`;
export const REMOVE_EVENT_FROM_FAVORITE = `${prefix}/removeEventFromFavorite`;
export const DELETE_EVENT_BY_ID = `${prefix}/deleteEventById`;
export const PUBLISH_EVENT = `${prefix}/publishEvent`;
export const UNPUBLISH_EVENT = `${prefix}/unPublishEvent`;
export const GET_EVENTS_BY_ORGANIZATION_ID = `${prefix}/getEventsByOrganizationId`;
export const GET_EVENTS_BY_FOLLOWING_ORGANIZATION = `${prefix}/getEventsByFollowingOrganizations`;
export const UPDATE_EVENT = `${prefix}/updateEvent`;
export const CREATE_OR_UPDATE_EVENT_FAQ = `${prefix}/createOrUpdateEventFaq`;
export const CREATE_OR_UPDATE_EVENT_AGENDA = `${prefix}/createOrUpdateEventAgenda`;
export const CREATE_OR_UPDATE_EVENT_TAGS = `${prefix}/createOrUpdateEventTags`;
export const CREATE_OR_UPDATE_EVENT_SUMMARY = `${prefix}/createOrUpdateEventSummary`;
export const CREATE_OR_UPDATE_EVENT_DESCRIPTION = `${prefix}/createOrUpdateEventDescription`;
export const GET_PAST_EVENT = `${prefix}/getPastEvent`;
export const GET_EVENT_ID_ORG = `${prefix}/getEventByIdOrg`;
