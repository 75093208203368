import { Button, Form, Modal, Result } from "antd";
import React, { useState } from 'react';
import './RegistrationForm.scss';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { loginWithGoogle, register } from "../../../../../common/redux/actions/authActions";
import googleLogo from "../../../../assets/icons/google-icon.svg";
import RegistrationFormFields from "../RegistrationFormFields";

function RegistrationForm() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.auth)
    const navigate = useNavigate();

    const onFinish = async (values) => {
        const credentials = {
            email: values.email,
            password: values.password,
            options: {
                data: {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    user_name: values.email,
                },
            },
        };
        try {
            const res = await dispatch(register(credentials)).unwrap()
            if (!res.meta?.rejectedWithValue) {
                setIsModalOpen(true)
            }
        } catch (error) {
            toast.error(error)
        }
    };

    // eslint-disable-next-line
    const handleLoginWithGoogle = async () => {
        try {
            await dispatch(loginWithGoogle())
        } catch (e) {
            console.error(e)
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        navigate("/login");
    };

    return (
        <div className="registrationContainer">
            <div className="signUptext">Sign up</div>
            <div className="signupDescription">
                <span className="text">Already a user?</span>
                <span className="loginLink">
                    <Link to="../login" className="link">
                        Login here
                    </Link>
                </span>
            </div>
            <div className="formContent">
                <RegistrationFormFields form={form} onFinish={onFinish} loading={loading}/>
                <div className="googleBtn">
                    <span className="continueText">Or continue with</span>
                    <Button
                        className="google-button"
                        block={true}
                        onClick={handleLoginWithGoogle}
                    >
                        <img className="google-icon" src={googleLogo} alt=""/>
                        Google
                    </Button>
                </div>
            </div>
            <Modal
                open={isModalOpen}
                footer={null}
                onCancel={handleCancel}
                centered
            >
                <Result
                    status="success"
                    title="Please check your mail to verify"
                />
            </Modal>
        </div>
    );
}

export default RegistrationForm;
