import { createSlice } from "@reduxjs/toolkit";
import { EventModel } from "../../models/eventModel.model";
import {
    addEventToFavorite,
    createEvent,
    createOrUpdateEventAgenda,
    createOrUpdateEventDescription,
    createOrUpdateEventFaq,
    createOrUpdateEventSummary,
    createOrUpdateEventTags,
    deleteEventById,
    getAllEvents,
    getCreatedEvents,
    getEventById, getEventByIdOrg,
    getEventsByFollowingOrganizations,
    getEventsByOrganizationId,
    getFavoriteEventIds,
    getFavoriteEvents,
    getPastEvent,
    getSuggestedEvents,
    publishEvent,
    removeEventFromFavorite,
    unPublishEvent,
    updateEvent,
} from "../actions/eventActions";


const eventSlice = createSlice({
    name: 'event',
    initialState: EventModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createEvent.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCreatedEvents.pending, (state) => {
                state.loading = true
            })
            .addCase(getEventById.pending, (state) => {
                state.loading = true
            })
            .addCase(getFavoriteEvents.pending, (state) => {
                state.loading = true
            })
            .addCase(getAllEvents.pending, (state) => {
                state.loading = true
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
                state.eventId = action.payload;
            })
            .addCase(createEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getAllEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.events = action.payload.eventDtos;
                state.error = null;
                state.success = true;
                state.hasMoreEvents = action.payload.hasMoreEvents;
            })
            .addCase(getAllEvents.rejected, (state, action) => {
                state.loading = false;
                state.events = [];
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getCreatedEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.createdEvents = action.payload?.eventDtos;
                state.error = null;
                state.success = true;
            })
            .addCase(getCreatedEvents.rejected, (state, action) => {
                state.loading = false;
                state.createdEvents = [];
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getEventById.fulfilled, (state, action) => {
                state.loading = false;
                state.event = action.payload;
                state.error = null;
                state.success = true;
            })
            .addCase(getEventById.rejected, (state, action) => {
                state.loading = false;
                state.event = {};
                state.error = action.payload;
                state.success = null;
            })
            .addCase(deleteEventById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload;
                state.error = null;
                state.createdEvents = state.createdEvents.filter((event) => event.eventId !== action.payload);
            })
            .addCase(deleteEventById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getFavoriteEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.favoriteEvents = action.payload.eventDtos;
                state.error = null;
                state.success = true;
            })
            .addCase(getFavoriteEvents.rejected, (state, action) => {
                state.loading = false;
                state.favoriteEvents = {};
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getFavoriteEventIds.fulfilled, (state, action) => {
                state.loading = false;
                state.favoriteEventIds = action.payload;
                state.error = null;
                state.success = true;
            })
            .addCase(getFavoriteEventIds.rejected, (state, action) => {
                state.loading = false;
                state.favoriteEventIds = [];
                state.error = action.payload;
                state.success = null;
            })
            .addCase(addEventToFavorite.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(addEventToFavorite.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(removeEventFromFavorite.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(removeEventFromFavorite.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(publishEvent.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(publishEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(publishEvent.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload;
            })
            .addCase(getEventsByOrganizationId.pending, (state) => {
                state.loading = true
            })
            .addCase(getEventsByOrganizationId.fulfilled, (state, action) => {
                state.loading = false;
                state.eventsByOrganizationIds = action.payload?.eventDtos;
                state.error = null;
                state.success = true;
            })
            .addCase(getEventsByOrganizationId.rejected, (state, action) => {
                state.loading = false;
                state.eventsByOrganizationIds = [];
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getEventsByFollowingOrganizations.pending, (state) => {
                state.loading = true
            })
            .addCase(getEventsByFollowingOrganizations.fulfilled, (state, action) => {
                state.loading = false;
                state.eventsOfFollowingOrgs = action.payload?.eventDtos;
                state.error = null;
                state.success = true;
            })
            .addCase(getEventsByFollowingOrganizations.rejected, (state, action) => {
                state.loading = false;
                state.eventsOfFollowingOrgs = [];
                state.error = action.payload;
                state.success = null;
            })
            .addCase(updateEvent.pending, (state) => {
                state.loading = true
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(createOrUpdateEventFaq.pending, (state) => {
                state.loading = true
            })
            .addCase(createOrUpdateEventFaq.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createOrUpdateEventFaq.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(createOrUpdateEventTags.pending, (state) => {
                state.loading = true
            })
            .addCase(createOrUpdateEventTags.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createOrUpdateEventTags.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(createOrUpdateEventAgenda.pending, (state) => {
                state.loading = true
            })
            .addCase(createOrUpdateEventAgenda.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createOrUpdateEventAgenda.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(createOrUpdateEventSummary.pending, (state) => {
                state.loading = true
            })
            .addCase(createOrUpdateEventSummary.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createOrUpdateEventSummary.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(createOrUpdateEventDescription.pending, (state) => {
                state.loading = true
            })
            .addCase(createOrUpdateEventDescription.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createOrUpdateEventDescription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(unPublishEvent.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(unPublishEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.error = null;
            })
            .addCase(unPublishEvent.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload;
            })
            .addCase(getSuggestedEvents.pending, (state) => {
                state.loading = true
            })
            .addCase(getSuggestedEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.suggestedEvents = action.payload?.eventDtos;
                state.error = null;
                state.success = true;
            })
            .addCase(getSuggestedEvents.rejected, (state, action) => {
                state.loading = false;
                state.suggestedEvents = [];
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getPastEvent.pending, (state) => {
                state.loading = true
            })
            .addCase(getPastEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.pastEvent = action.payload;
                state.error = null;
                state.success = true;
            })
            .addCase(getPastEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getEventByIdOrg.pending, (state) => {
                state.loading = true
            })
            .addCase(getEventByIdOrg.fulfilled, (state, action) => {
                state.loading = false;
                state.event = action.payload;
                state.error = null;
                state.success = true;
            })
            .addCase(getEventByIdOrg.rejected, (state, action) => {
                state.loading = false;
                state.event = {};
                state.error = action.payload;
                state.success = null;
            })
    },
});

export default eventSlice.reducer;
