import { createAsyncThunk } from "@reduxjs/toolkit";
import supabaseService from "../../supabase/supabaseService";
import { CHECK_THREAD, CREATE_NEW_THREAD, FETCH_THREADS_FOR_ORG, FETCH_THREADS_FOR_USER } from "../types/thread";

export const createNewThread = createAsyncThunk(
    CREATE_NEW_THREAD,
    async (threadInfo, { rejectWithValue }) => {
        try {
            return await supabaseService.createNewThread(threadInfo);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const fetchThreadsForUser = createAsyncThunk(
    FETCH_THREADS_FOR_USER,
    async (username, { rejectWithValue }) => {
        try {
            const { data, error } = await supabaseService.fetchThreadsForUser(username);
            if (data) {
                return data;
            }
            return rejectWithValue(error)
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const fetchThreadsForOrg = createAsyncThunk(
    FETCH_THREADS_FOR_ORG,
    async (orgId, { rejectWithValue }) => {
        try {
            const { data, error } = await supabaseService.fetchThreadsForOrg(orgId);
            if (data) {
                return data;
            }
            return rejectWithValue(error)
            
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const checkThread = createAsyncThunk(
    CHECK_THREAD,
    async (checkData, { rejectWithValue }) => {
        try {
            const { data, error } = await supabaseService.checkThread(checkData);
            if (data) {
                return data;
            }
            return rejectWithValue(error)
            
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);