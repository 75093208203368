import { createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../services/authenticateService";
import supabaseService from "../../supabase/supabaseService";
import {
    LOGIN,
    LOGIN_WITH_GOOGLE,
    LOGOUT,
    REGISTER,
    SEND_RESET_PASSWORD_MAIL,
    UPDATE_PASSWORD
} from "../types/auth";
import { IS_LOGGED_IN } from "../../constants/LocalStorageConstants";

export const login = createAsyncThunk(
    LOGIN,
    async (credentials, { rejectWithValue }) => {
        try {
            const res = await authService.verifyUserExist({email: credentials?.email})
            
            if (!res) {
                return rejectWithValue(`Cannot find user`)
            }
            
            const { data, error } = await supabaseService.loginWithPassword(credentials);

            if (error) {
                return rejectWithValue(error?.message);
            }
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const register = createAsyncThunk(
    REGISTER,
    async (credentials, { rejectWithValue }) => {
        try {
            const { data, error } = await supabaseService.register(credentials);
            if (error) {
                return rejectWithValue(error?.message);
            }
            const credentialsToSaveDb = {
                username: data?.user?.id,
                firstName: credentials.options?.data?.first_name,
                lastName: credentials.options?.data?.last_name,
                email: credentials.email,
            };
            
            // eslint-disable-next-line
            const authRes = await authService.register(credentialsToSaveDb);

            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const logout = createAsyncThunk(
    LOGOUT,
    async (data, { rejectWithValue }) => {
        try {
            const { error } = await supabaseService.logout();
            if (!error) {
                localStorage.removeItem(IS_LOGGED_IN)
            }
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const sendResetPasswordMail = createAsyncThunk(
    SEND_RESET_PASSWORD_MAIL,
    async (credentials, { rejectWithValue }) => {
        try {
            const {
                data,
                error
            } = await supabaseService.sendResetEmail(credentials.email, credentials.resetPasswordUrl);
            if (error) {
                return rejectWithValue(error);
            }
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updatePassword = createAsyncThunk(
    UPDATE_PASSWORD,
    async (credentials, { rejectWithValue }) => {
        try {
            const { data, error } = await supabaseService.updatePassword(credentials);
            if (error) {
                return rejectWithValue(error);
            }
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const loginWithGoogle = createAsyncThunk(
    LOGIN_WITH_GOOGLE,
    async (credentials, { rejectWithValue }) => {
        try {
            const { data, error } = await supabaseService.loginWithGoogle();
            if (error) {
                return rejectWithValue(error);
            }
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
