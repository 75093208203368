import { Input } from "antd";
import React, { useEffect, useLayoutEffect } from "react";
import "./PastEventFeedback.scss";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { getFeedbackForEventOfUser } from "../../../../../common/redux/actions/feedbackAction";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import { rateLabels, rates } from "../../../../../common/utils/feedbackUtils";
import FeedbackForm from "../../../../modules/components/FeedbackForm/FeedbackForm";

const PastEventFeedback = () => {
    const { eventId, setActiveTab } = useOutletContext();
    const dispatch = useDispatch();
    const { feedback } = useSelector((state) => state.feedback);
    const { user } = useSelector((state) => state.auth);

    useLayoutEffect(() => {
        setActiveTab("feedback");
    }, [setActiveTab]);

    useEffect(() => {
        const username = user?.id;
        if (username && eventId) {
            const params = { username: username, eventId: eventId };
            dispatch(getFeedbackForEventOfUser(params));
        }
    }, [user, eventId, dispatch]);

    return (
        <div className="past-event-feedback-container">
            <div className="past-event-feedback-head">
                {
                    feedback && <div className="past-event-feedback-created-time">
                        Created at {formatDateRange(feedback?.timestamp, DateTimePattern.STANDARD_DATE_TIME)}
                    </div>
                }
            </div>
            <div className="past-event-feedback-content">
                {
                    feedback
                        ? <div className="past-event-feedback-content-wrapper">
                            <div className="past-event-feedback-rate-section">
                                <p className="past-event-feedback-label">How do you feel about this event?</p>
                                <div className="past-event-feedback-rate">
                                    <div className="past-event-feedback-rate-values">
                                        {
                                            rates.map((item) => (
                                                <div key={item.key}>
                                            <span
                                                className={`past-event-feedback-rate-item ${item.value === feedback?.rating && "selected"}`}>
                                                {item.icon}
                                            </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <p className="past-event-feedback-rate-message">{rateLabels[feedback?.rating]}</p>
                                </div>
                            </div>
                            <div className="past-event-feedback-comment-section">
                                <p className="past-event-feedback-label">Share your thoughts...</p>
                                <Input.TextArea
                                    placeholder="Add your comments"
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                    value={feedback.comment}
                                    variant={"filled"}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        : <FeedbackForm/>
                }
            </div>
        </div>
    );
};

export default PastEventFeedback;