import { createSlice } from "@reduxjs/toolkit";
import { CheckinModel } from "../../models/checkinModel.model";
import { checkinOfflineEvent, checkinOnlineEvent } from "../actions/checkinAction";


const checkinSlice = createSlice({
    name: 'checkin',
    initialState: CheckinModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(checkinOfflineEvent.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkinOfflineEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(checkinOfflineEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(checkinOnlineEvent.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkinOnlineEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = action.payload;
            })
            .addCase(checkinOnlineEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
    },
});

export default checkinSlice.reducer;