import React, { useEffect, useLayoutEffect } from "react";
import "./TicketOrderDetail.scss";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { getTicketById } from "../../../common/redux/actions/ticketAction";
import Ticket from "../../modules/components/Ticket/Ticket";

const TicketOrderDetail = () => {
    const { ticketId } = useParams();
    const { setShowSidebar } = useOutletContext();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { ticket } = useSelector((state) => state.ticket);

    useEffect(() => {
        const username = user?.id;
        if (username && ticketId) {
            const params = {
                username: username,
                ticketId: ticketId
            };
            dispatch(getTicketById(params));
        }
    }, [user, ticketId, dispatch]);

    useLayoutEffect(() => {
        document.title = "Ticket detail | Eventure";
        setShowSidebar(false);
    });

    return (
        <div className="ticket-order-detail-container">
            {
                ticket && <div className="ticket-order-detail-header">
                    <Ticket
                        ticket={ticket}
                        holder={ticket?.firstName + " " + ticket?.lastName}
                    />
                </div>
            }
        </div>
    );
};

export default TicketOrderDetail;
