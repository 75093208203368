import { CaretRight } from "@phosphor-icons/react";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRelatedEvents } from "../../../../../common/redux/actions/asyncAction";
import EventListHorizontal from "../../../../modules/components/EventListHorizontal/EventListHorizontal";

const RelatedEvents = ({ event }) => {
    const dispatch = useDispatch();
    const { relatedEvents } = useSelector((state) => state.async);

    useEffect(() => {
        if (event) {
            const payload = {
                name: event?.eventName || "",
                tags: event?.tags || [],
            };
            dispatch(getRelatedEvents(payload));
        }
    }, [event, dispatch]);

    return (
        <div className='related-events-container'>
            {relatedEvents &&
                <>
                    <div className="related-events-header">
                        <div className="related-events-title">
                            🧩 Related Events
                        </div>
                    </div>
                    <div className="related-events-list">
                        <EventListHorizontal events={relatedEvents}/>
                    </div>
                </>
            }
        </div>
    );
};

export default RelatedEvents;