import { PencilSimple, Plus, TrashSimple } from "@phosphor-icons/react";
import { Button, Form, Input, Modal, TimePicker } from "antd";
import React, { useState } from "react";
import "./CreateEventAgenda.scss";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { createOrUpdateEventAgenda } from "../../../../../common/redux/actions/eventActions";
import { formatDateRange, getDateTimeObject } from "../../../../../common/utils/dateTimeUtils";
import AgendaItem from "../../../../modules/components/AgendaItem/AgendaItem";


const CreateEventAgenda = ({ event }) => {
    const [isModalAddAgendaOpen, setIsModalAddAgendaOpen] = useState(false);
    const [isModalUpdateAgendaOpen, setIsModalUpdateAgendaOpen] = useState(false);
    const [agendas, setAgendas] = useState(event?.agendas || []);
    const [loadingBtnSave, setLoadingBtnSave] = useState(false);
    const dispatch = useDispatch();
    const [formAdd] = Form.useForm();
    const [formUpdate] = Form.useForm();

    const showModalAdd = () => {
        setIsModalAddAgendaOpen(true);
    };
    const handleOkAdd = () => {
        setIsModalAddAgendaOpen(false);
    };
    const handleCancelAdd = () => {
        setIsModalAddAgendaOpen(false);
    };

    const showModalUpdate = () => {
        setIsModalUpdateAgendaOpen(true);
    };
    const handleOkUpdate = () => {
        setIsModalUpdateAgendaOpen(false);
    };
    const handleCancelUpdate = () => {
        setIsModalUpdateAgendaOpen(false);
    };

    const onFinishAddAgendaItem = (values) => {
        const agendaItem = {
            sessionStartTime: formatDateRange(values.session_start_time, DateTimePattern.BASIC_TIME),
            sessionEndTime: formatDateRange(values.session_end_time, DateTimePattern.BASIC_TIME),
            sessionTitle: values.session_title,
            sessionDescription: values.session_description,
            hosts: [{ hostName: values.session_host_name }]
        }
        setAgendas([...agendas, agendaItem]);
        setIsModalAddAgendaOpen(false);
        formAdd.resetFields();
    }

    const onFinishUpdateAgendaItem = (values) => {
        const updateIndex = values.session_index;
        const agendaItem = {
            sessionStartTime: formatDateRange(values.session_start_time, DateTimePattern.BASIC_TIME),
            sessionEndTime: formatDateRange(values.session_end_time, DateTimePattern.BASIC_TIME),
            sessionTitle: values.session_title,
            sessionDescription: values.session_description,
            hosts: [{ hostName: values.session_host_name }]
        }
        const newAgendas = [...agendas];
        newAgendas[updateIndex] = agendaItem;
        setAgendas(newAgendas);
        setIsModalUpdateAgendaOpen(false);
        formUpdate.resetFields();
    }

    const handleSaveAgenda = () => {
        setLoadingBtnSave(true);
        const nAgendas = agendas.map((item) => {
            return {
                ...item,
                sessionStartTime: item.sessionStartTime,
                sessionEndTime: item.sessionEndTime,
            }
        });
        const request = {
            eventId: event.eventId,
            agendas: nAgendas
        };
        dispatch(createOrUpdateEventAgenda(request))
            .unwrap()
            .then((response) => {
                toast.success("Successfully update agenda");
            })
            .catch((error) => {
                toast.error("Error update agenda");
            })
            .finally(() => {
                setLoadingBtnSave(false);
            });
    }

    const handleDeleteAgendaItem = (deleteId) => {
        const newAgendas = agendas.filter((item, index) => index !== deleteId);
        setAgendas(newAgendas);
    };

    const handleUpdateAgendaItem = (index) => {
        showModalUpdate();
        const agenda = agendas[index];
        formUpdate.setFieldsValue({
            session_index: index,
            session_start_time: getDateTimeObject(agenda.sessionStartTime, DateTimePattern.BASIC_TIME),
            session_end_time: getDateTimeObject(agenda.sessionEndTime, DateTimePattern.BASIC_TIME),
            session_title: agenda.sessionTitle,
            session_description: agenda.sessionDescription,
            session_host_name: agenda?.hosts ? agenda?.hosts[0]?.hostName : ""
        });
    };

    return (
        <>
            <div className="event-detail-info-section event-agenda-container">
                <div className="event-detail-info-section-head">
                    <div className="event-detail-info-section-title">
                        Event agenda
                    </div>
                    <div className="event-detail-info-section-desc">
                        Add an itinerary, schedule, or lineup to your event.
                        You can include a time, a description of what will happen, and who will host or perform during
                        the event. (Ex. Speaker, performer, artist, guide, etc.).
                    </div>
                </div>
                <div className="event-agenda-content">
                    {
                        agendas?.length > 0 && <div className="event-agenda-list">
                            {
                                agendas.map((session, index) => (
                                    <div key={index} className="event-agenda-item">
                                        <AgendaItem key={index} agendaItem={session}/>
                                        <div className="agenda-item-button">
                                            <Button
                                                type="text"
                                                icon={<PencilSimple/>}
                                                onClick={() => handleUpdateAgendaItem(index)}
                                            />
                                            <Button
                                                type="text"
                                                icon={<TrashSimple/>}
                                                danger={true}
                                                onClick={() => handleDeleteAgendaItem(index)}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <div className="event-agenda-new">
                        <Button
                            className="add-item-button"
                            icon={<Plus/>}
                            type="dashed"
                            block={true}
                            onClick={showModalAdd}
                        >
                            Add session
                        </Button>
                    </div>
                </div>
                <div className="event-agenda-buttons">
                    <Button
                        onClick={handleSaveAgenda}
                        loading={loadingBtnSave}
                    >
                        Save
                    </Button>
                </div>
            </div>
            <Modal
                title="Add new session"
                open={isModalAddAgendaOpen}
                onOk={handleOkAdd}
                onCancel={handleCancelAdd}
                footer={null}
            >
                <Form
                    form={formAdd}
                    layout="vertical"
                    onFinish={onFinishAddAgendaItem}
                    autoComplete="off"
                >
                    <Form.Item
                        name="session_title"
                        label="Title"
                        rules={[{
                            required: true,
                            message: "Please enter the session title!",
                        },]}
                    >
                        <Input placeholder="Short and informative"/>
                    </Form.Item>
                    <Form.Item
                        name="session_start_time"
                        label="Start time"
                    >
                        <TimePicker format={DateTimePattern.SHORT_BASIC_TIME}/>
                    </Form.Item>
                    <Form.Item
                        name="session_end_time"
                        label="End time"
                    >
                        <TimePicker format={DateTimePattern.SHORT_BASIC_TIME}/>
                    </Form.Item>
                    <Form.Item
                        name="session_description"
                        label="Description"
                    >
                        <Input.TextArea showCount maxLength={1000}/>
                    </Form.Item>
                    <Form.Item
                        name="session_host_name"
                        label="Host name"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                        >
                            Add
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Update session"
                open={isModalUpdateAgendaOpen}
                onOk={handleOkUpdate}
                onCancel={handleCancelUpdate}
                footer={null}
            >
                <Form
                    form={formUpdate}
                    layout="vertical"
                    onFinish={onFinishUpdateAgendaItem}
                    autoComplete="off"
                >
                    <Form.Item hidden={true} name="session_index">
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="session_title"
                        label="Title"
                        rules={[{
                            required: true,
                            message: "Please enter the session title!",
                        },]}
                    >
                        <Input placeholder="Short and informative"/>
                    </Form.Item>
                    <Form.Item
                        name="session_start_time"
                        label="Start time"
                    >
                        <TimePicker/>
                    </Form.Item>
                    <Form.Item
                        name="session_end_time"
                        label="End time"
                    >
                        <TimePicker/>
                    </Form.Item>
                    <Form.Item
                        name="session_description"
                        label="Description"
                    >
                        <Input.TextArea showCount maxLength={1000}/>
                    </Form.Item>
                    <Form.Item
                        name="session_host_name"
                        label="Host name"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit">
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CreateEventAgenda;
