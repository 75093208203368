import React, { useEffect, useState } from 'react';
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../../common/redux/actions/authActions";
import ForgotPasswordLayout from "../../modules/components/ForgotPasswordLayout";
import ResetPasswordForm from "./partials/ResetPasswordForm";
import { useNavigate } from "react-router-dom";

function ResetPassword(props) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const { loading, success } = useSelector(state => state.auth);

    useEffect(() => {
        if (success) {
            setIsModalOpen(true);
        }
    }, [success])

    const handleCloseModal = () => {
        setIsModalOpen(false);
        navigate('/')
    }

    const onFinish = (values) => {
        try {
            dispatch(updatePassword(values.password))
        } catch (error) {
            throw error
        }
    }

    return (
        <ForgotPasswordLayout
            heading={"Password reset"}
            description={"Create your new password"}
            form={<ResetPasswordForm
                form={form}
                onFinish={onFinish}
                loading={loading}/>}
            modalTitle={"Update successfully"}
            isModalOpen={isModalOpen}
            handleCancelModel={handleCloseModal}
            status={"success"}
        />
    );
}

export default ResetPassword;