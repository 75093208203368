import { Button, Modal } from 'antd';
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CancelReservationButton = ({ eventId }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => setIsModalOpen(true);

    const handleOk = () => {
        setIsModalOpen(false);
        navigate(`/events/${eventId}`);
    };
    const handleCancel = () => setIsModalOpen(false);

    return (
        <>
            <Button
                className="ticket-cart-button-cancel"
                block={true}
                onClick={showModal}
            >
                Cancel
            </Button>
            <Modal
                title="Are you sure to cancel this reservation?"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered={true}
            >
                Current reservation won't be able to restored!
            </Modal>
        </>
    );
};

export default CancelReservationButton;