import { BookmarkSimple, CalendarBlank, ClockCounterClockwise, Ticket, Users } from "@phosphor-icons/react";
import React, { useLayoutEffect, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import "./UserPage.scss";
import Menu from "../../modules/components/MainMenu/MainMenu";
import UserProfileHeader from "./components/UserProfileHeader/UserProfileHeader";


const userPageMenuItems = [
    {
        key: "liked-events",
        title: "Liked events",
        icon: <BookmarkSimple/>,
        path: "liked-events"
    },
    {
        key: "reservations",
        title: "Reservations",
        icon: <Ticket/>,
        path: "reservations"
    },
    {
        key: "upcoming-events",
        title: "Upcoming events",
        icon: <CalendarBlank/>,
        path: "upcoming-events"
    },
    {
        key: "past-events",
        title: "Past events",
        icon: <ClockCounterClockwise/>,
        path: "past-events"
    },
    {
        key: "following-organizations",
        title: "Following",
        icon: <Users/>,
        path: "following-organizations"
    },
];

const UserPage = () => {
    const { setShowSidebar } = useOutletContext();
    const [activeItem, setActiveItem] = useState("");

    useLayoutEffect(() => {
        setShowSidebar(false);
    });

    const handleMenuItemClick = (key) => {
        setActiveItem(key);
    };

    return (
        <div className="user-page-container">
            <div className="user-page-header">
                <UserProfileHeader/>
            </div>
            <div className="user-page-main">
                <div className="user-page-menu">
                    <div className="user-page-menu-title">
                        You
                    </div>
                    <Menu menuItems={userPageMenuItems} onMenuItemClick={handleMenuItemClick} activeItem={activeItem}/>
                </div>
                <div className="user-page-content">
                    <Outlet context={{ activeItem, setActiveItem }}/>
                </div>
            </div>
        </div>
    );
};

export default UserPage;
