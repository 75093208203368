import React, { useLayoutEffect } from "react";
import "./RegistrationPage.scss";
import useRedirectIfLoggedIn from "../../../common/hooks/useRedirectIfLoggedIn";
import HighlightReel from "../LoginPage/partials/HighlightReel/HighlightReel";
import RegistrationForm from "./partials/RegistrationForm";

function RegistrationPage() {
    const isLoggedIn = useRedirectIfLoggedIn();

    useLayoutEffect(() => {
        document.title = "Registration | Eventure";
    });

    if (isLoggedIn) {
        return null;
    }

    return (
        <div className="registration-wrapper">
            <div className="background-overlay"></div>
            <div className="registration-container">
                <div className="left-content">
                    <HighlightReel/>
                </div>
                <div className="right-content">
                    <RegistrationForm/>
                </div>
            </div>
        </div>
    );
}

export default RegistrationPage;
