import { createSlice } from "@reduxjs/toolkit";
import { getAllCategory } from "../actions/channelActions";

const initialState = {
    category: null,
    loading: null,
    error: null,
};

const channelSlice = createSlice({
    name: "channel",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllCategory.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.category = [];
            })
            .addCase(getAllCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.category = action.payload;
                state.error = null;
            })
            .addCase(getAllCategory.rejected, (state, action) => {
                state.loading = false;
                state.category = [];
                state.error = action.payload;
            });
    },
});

export default channelSlice.reducer;
