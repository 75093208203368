import axiosInstance from "../api";

const organizationService = {
    getAllOrganizers: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/organization/getAllOrganizers`, {
                params: params
            });
        } catch (error) {
            console.error("Error get all organizers:", error);
            throw error;
        }
    },
    getOrganizerProfile: async (params) => {
        try {
            return await axiosInstance.get(`/api/v1/organization/getOrganizerProfile`, {
                params: params
            });
        } catch (error) {
            console.error("Error get organizer profile:", error);
            throw error;
        }
    },
    updateOrganizationProfile: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/organization/updateOrganizationProfile`, request);
        } catch (error) {
            console.error("Error")
        }
    },
    updateOrganizationInformation: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/organization/updateOrganizationInformation`, request);
        } catch (error) {
            console.error("Error")
        }
    },
    getOrganizationProfile: async (username) => {
        try {
            return await axiosInstance.get(`/api/v1/organization/getOrganizationProfile`, {
                params: username
            });
        } catch (error) {
            console.error("Error")
        }
    },
    isOrganizationRegistered: async (username) => {
        try {
            return await axiosInstance.get(`/api/v1/organization/isOrganizationRegistered`, {
                params: username
            })
        } catch (error) {
            console.error(error)
        }
    },
    updateOrganizationCover: async (payload) => {
        try {
            const formData = new FormData();
            formData.append("image", payload.image);
            formData.append("organizationId", payload.organizationId);
            return await axiosInstance.post(
                `/api/v1/organization/updateOrganizationCover`,
                formData,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
        } catch (error) {
            console.error(error)
        }
    },
}


export default organizationService;