import { createAsyncThunk } from "@reduxjs/toolkit";
import voucherService from "../../services/voucherService";
import {
    CREATE_VOUCHER,
    DELETE_VOUCHER_BY_ID,
    GET_VOUCHER_BY_ID,
    GET_VOUCHERS_BY_EVENT_ID_FOR_ATTENDEE,
    UPDATE_VOUCHER,
    VERIFY_VOUCHER
} from "../types/voucher";

export const createVoucher = createAsyncThunk(
    CREATE_VOUCHER,
    async (data, { rejectWithValue }) => {
        try {
            return await voucherService.createVoucher(data)
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const deleteVoucherById = createAsyncThunk(
    DELETE_VOUCHER_BY_ID,
    async (request, { rejectWithValue }) => {
        try {
            return await voucherService.deleteVoucherById(request)
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getVoucherByEventId = createAsyncThunk(
    GET_VOUCHER_BY_ID,
    async (id, { rejectWithValue }) => {
        try {
            return await voucherService.getVouchersByEventId(id)
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateVoucher = createAsyncThunk(
    UPDATE_VOUCHER,
    async (request, { rejectWithValue }) => {
        try {
            return await voucherService.updateVoucher(request)
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue(error);
            }
        }
    }
);

export const verifyVoucher = createAsyncThunk(
    VERIFY_VOUCHER,
    async (params, { rejectWithValue }) => {
        try {
            return await voucherService.verifyVoucher(params)
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getVouchersByEventIdForAttendee = createAsyncThunk(
    GET_VOUCHERS_BY_EVENT_ID_FOR_ATTENDEE,
    async (params, { rejectWithValue }) => {
        try {
            return await voucherService.getVouchersByEventIdForAttendee(params)
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);