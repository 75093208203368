import { MicrophoneSlash, Waveform, WifiMedium } from "@phosphor-icons/react";
import { useParticipant } from "@videosdk.live/react-sdk";
import { useMemo } from "react";
import "./CornerDisplayName.scss";
import { nameTructed } from "../../../../common/utils/videosdkUtils";

export const CornerDisplayName = ({
                                      participantId,
                                      isPresenting,
                                      displayName,
                                      isLocal,
                                      micOn,
                                      screenShareStream,
                                      mouseOver,
                                      isActiveSpeaker,
                                  }) => {

        const show = useMemo(() => mouseOver, [mouseOver]);

        const {
            webcamStream,
            micStream,
        } = useParticipant(participantId);

        return (
            <>
                <div className={`corner-name-container ${show && "show"}`}>
                    {!micOn && !isPresenting ? (
                        <MicrophoneSlash color="#fff" weight="fill"/>
                    ) : micOn && isActiveSpeaker ? (
                        <Waveform color="#fff" weight="fill"/>
                    ) : null}

                    <p className="corner-name-message">
                        {isPresenting
                            ? isLocal
                                ? `You are presenting`
                                : `${nameTructed(displayName, 15)} is presenting`
                            : isLocal
                                ? "You"
                                : nameTructed(displayName, 26)}
                    </p>
                </div>

                {(webcamStream || micStream || screenShareStream) && (
                    <div className="corner-name-tool">
                        <WifiMedium size={24} weight={"bold"}/>
                    </div>
                )}
            </>
        );
    }
;