import React from 'react';
import {Button, Form, Input} from "antd";
import {Envelope} from "@phosphor-icons/react";
import {Link} from "react-router-dom";

function ForgotPasswordForm({form, onFinish,loading}) {
    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout={"vertical"}
            requiredMark={true}
        >
            <Form.Item
                name="email"
                rules={[
                    {
                        required: true,
                        message: "Please input your email!",
                    },
                    {
                        type: 'email',
                        message: 'Please input a valid Email!',
                    },
                ]}
            >
                <Input prefix={<Envelope/>} size="large"/>
            </Form.Item>
            <div className="send-mail-button-container">
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        block={true}
                        className={"send-mail-button"}
                    >
                        Send reset email
                    </Button>
                </Form.Item>
            </div>
            <div className="sign-in-link">
                <Link to={"../login"} className={"link-text"}>
                    Back to login
                </Link>
            </div>
        </Form>
    );
}

export default ForgotPasswordForm;