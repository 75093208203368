import Search from "antd/es/input/Search";
import React, { useState } from "react";
import "./SceneSearch.scss";
import { useDispatch, useSelector } from "react-redux";
import { searchScenes } from "../../../../common/redux/actions/recordingAction";
import { convertTimeframeToSeconds } from "../../../../common/utils/dateTimeUtils";
import VideoItem from "../VIdeoItem/VideoItem";

const SceneSearch = ({ fileUrl, videoId, setPlayingTimestamp }) => {
    const [loadingSearchButton, setLoadingSearchButton] = useState(false);
    const dispatch = useDispatch();
    const { scenes } = useSelector((state) => state.recording);

    const onSearch = (value, _e, info) => {
        if (value.length === 0) return;
        if (value.length > 0) {
            setLoadingSearchButton(true);
            const payload = {
                query: value,
                videoId: videoId
            }
            dispatch(searchScenes(payload))
                .unwrap()
                .finally(() => {
                    setLoadingSearchButton(false);
                });
        }
    }

    const handleSelectTimestamp = (timestamp) => {
        const time = convertTimeframeToSeconds(timestamp);
        setPlayingTimestamp(time);
    }

    return (
        <div className="scene-search-container">
            <Search
                // allowClear
                placeholder="Describe the moment you are looking for..."
                onSearch={onSearch}
                loading={loadingSearchButton}
                enterButton
            />
            <div className="scene-search-result">
                {
                    scenes && scenes.map((scene, index) =>
                        <div key={index} className="scene-search-video-item">
                            <VideoItem fileUrl={fileUrl} timeframeStr={scene.time_frame}/>
                            <p
                                className="scene-search-video-timestamp"
                                onClick={() => handleSelectTimestamp(scene.time_frame)}
                            >
                                {scene.time_frame}
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default SceneSearch;