import { Microphone, MicrophoneSlash, VideoCamera, VideoCameraSlash } from "@phosphor-icons/react";
import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import React from "react";
import "./ParticipantPanel.scss";
import { nameTructed } from "../../../../common/utils/videosdkUtils";
import useIsHls from "../../../../common/videosdk/hooks/useIsHls";

function ParticipantListItem({ participantId }) {
    const { micOn, webcamOn, displayName, isLocal, mode } = useParticipant(participantId);
    const isHls = useIsHls();

    return (
        <div className="participant-panel-list-item">
            <div className="participant-item-avatar">
                {displayName?.charAt(0).toUpperCase()}
            </div>
            <div className="participant-item-name">
                {isLocal ? "You" : nameTructed(displayName, 15)}
            </div>
            <div className="participant-item-mic">
                {micOn
                    ? <Microphone size={18}/>
                    : <MicrophoneSlash size={18}/>
                }
            </div>
            <div className="participant-item-cam">
                {webcamOn
                    ? <VideoCamera size={18}/>
                    : <VideoCameraSlash size={18}/>}
            </div>
        </div>
    );
}

export function ParticipantPanel() {
    const mMeeting = useMeeting();
    const participants = mMeeting.participants;

    return (
        <div className="participant-panel-container">
            <div className="participant-panel-list-wrapper">
                {[...participants.keys()].map((participantId, index) => {
                    return (
                        <ParticipantListItem
                            key={index}
                            participantId={participantId}
                        />
                    );
                })}
            </div>
        </div>
    );
}
