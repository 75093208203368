import { createSlice } from "@reduxjs/toolkit";
import { checkThread, createNewThread, fetchThreadsForOrg, fetchThreadsForUser } from "../actions/threadAction";

const initialState = {
    loading: false,
    threads: [],
    threadId: null,
    error: null,
};

const threadSlice = createSlice({
    name: "thread",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createNewThread.pending, (state,action) => {
                state.loading = false;
            })
            .addCase(createNewThread.fulfilled, (state, action) => {
                state.loading = false;
                state.threadId = action.payload;
            })
            .addCase(createNewThread.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchThreadsForOrg.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchThreadsForOrg.fulfilled, (state, action) => {
                state.loading = false;
                state.threads = action.payload;
            })
            .addCase(fetchThreadsForOrg.rejected, (state, action) => {
                state.loading = false;
                state.threads = [];
                state.error = null;
            })
            .addCase(fetchThreadsForUser.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchThreadsForUser.fulfilled, (state, action) => {
                state.loading = false;
                state.threads = action.payload;
            })
            .addCase(fetchThreadsForUser.rejected, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(checkThread.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(checkThread.fulfilled, (state, action) => {
                state.loading = false;
                state.threadId = action.payload?.[0]?.id;
            })
            .addCase(checkThread.rejected, (state, action) => {
                state.loading = false;
                state.threadId = null;
                state.error = action.payload
            })
            
    }
    
})

export default threadSlice.reducer;
