import { UserOutlined } from '@ant-design/icons';
import { ArrowsLeftRight, CalendarPlus, SignOut } from "@phosphor-icons/react";
import { Avatar, Button, Dropdown, Flex } from 'antd';
import React from 'react';
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
    FAVORITE,
    FOLLOWING_ORG,
    IS_LOGGED_IN,
    ORGANIZATION_PROFILE,
    PROFILE_MODE
} from "../../../../common/constants/LocalStorageConstants";
import { ApplicationMode } from "../../../../common/enums/ApplicationMode";
import { logout } from "../../../../common/redux/actions/authActions";
import { setMode } from "../../../../common/redux/slice/authSlice";
import logo from "../../../assets/icons/logo.svg";
import "./HeaderOrg.scss";

const avatarMenuItems = [
    {
        type: "divider",
    },
    {
        key: "switch",
        label: (
            <Link to="/">
                <Flex align={"center"} gap={20} justify={"space-between"}>
                    Switch to Attendee
                    <ArrowsLeftRight/>
                </Flex>
            </Link>
        ),
    },
    {
        key: "logout",
        label: (
            <Flex align={"center"} gap={20} justify={"space-between"}>
                Sign out
                <SignOut/>
            </Flex>
        ),
    },
];

const HeaderOrg = ({ isLoggedIn }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = ({ key }) => {
        switch (key) {
            case "logout":
                sessionStorage.removeItem(ORGANIZATION_PROFILE);
                sessionStorage.removeItem(PROFILE_MODE)
                localStorage.removeItem(FAVORITE);
                localStorage.removeItem(FOLLOWING_ORG);
                localStorage.removeItem(IS_LOGGED_IN)
                navigate("/")
                dispatch(logout())
                break;
            case "switch":
                dispatch(setMode(ApplicationMode.ATTENDEE))
                navigate("/")
                break;
            default:
                return
        }
    };


    return (
        <div className="header-org-container">
            <div className="header-org-start">
                <Link className="header-org-logo" to="/organization/manage/home">
                    <img src={logo} alt=""/>
                </Link>
            </div>

            <div className="header-org-end">
                {isLoggedIn ? (
                    <>
                        <Link to="/organization/manage/createEvent">
                            <Button
                                type="default"
                                icon={<CalendarPlus/>}
                            >
                                Create event
                            </Button>
                        </Link>
                        {/*<Button icon={<BellSimple/>}/>*/}
                        <Dropdown
                            menu={{
                                items: avatarMenuItems,
                                onClick: handleLogout,
                            }}
                            placement="bottomRight"
                            trigger={["click"]}
                        >
                            <Avatar
                                shape="square"
                                className="header-org-avatar"
                                icon={<UserOutlined/>}
                            />
                        </Dropdown>
                    </>
                ) : (
                    <>
                        <Link to="/login">
                            <Button type="primary">Sign in</Button>
                        </Link>

                        <Link to="register">
                            <Button type="text">Sign up</Button>
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default HeaderOrg;
