import { useMeeting } from "@videosdk.live/react-sdk";
import "./ChatMessage.scss";
import { formatAMPM, nameTructed } from "../../../../common/utils/videosdkUtils";

const ChatMessage = ({ senderId, senderName, text, timestamp }) => {
    const mMeeting = useMeeting();
    const localParticipantId = mMeeting?.localParticipant?.id;
    const localSender = localParticipantId === senderId;

    return (
        <div className={`chat-message-container ${localSender ? "local-sender" : ""}`}>
            <p className="chat-message-sender">
                {localSender ? "You" : nameTructed(senderName, 15)}
            </p>
            <div className={`chat-message-wrapper ${localSender ? "local-sender" : ""}`}>
                <div className="chat-message-content">
                    {text}
                </div>
            </div>
            <div className="chat-message-time">
                {formatAMPM(new Date(timestamp))}
            </div>
        </div>
    );
};

export default ChatMessage;