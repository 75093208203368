import { CalendarDots } from "@phosphor-icons/react";
import React, { useEffect, useLayoutEffect } from "react";
import "./UserPastEvents.scss";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getPastEvents } from "../../../../../common/redux/actions/accountAction";
import EventListVertical from "../../../../modules/components/EventListVertical/EventListVertical";
import Loading from "../../../../modules/components/Loading/Loading";
import NoDataFound from "../../../../modules/components/NoDataFound";

const UserPastEvents = () => {
    const { setActiveItem } = useOutletContext();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { pastEvents, loading } = useSelector((state) => state.account);

    useLayoutEffect(() => {
        document.title = "User - Past events";
        setActiveItem("past-events");
    });

    useEffect(() => {
        const username = user?.id;
        ;
        if (username) {
            const params = { username: username };
            dispatch(getPastEvents({ ...params }));
        }
    }, [user, dispatch]);

    return (
        <div className="user-past-events-container">
            <div className="user-past-events-head">Past events</div>
            {
                loading || !pastEvents
                    ? <Loading/>
                    : (
                        (pastEvents && pastEvents.length > 0)
                            ? <EventListVertical events={pastEvents} past={true}/>
                            : <NoDataFound icon={<CalendarDots size={60}/>} message="You haven't joined any events!"/>
                    )
            }
        < /div>
    );
};

export default UserPastEvents;
