import { createSlice } from "@reduxjs/toolkit";
import { getOrdersById } from "../actions/orderActions";

const initialState = {
    loading: false,
    orders: null,
    error: null,
    success: null
}

const orderSlice = createSlice({
    name: 'order',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOrdersById.pending, (state,action) => {
                state.loading = true;
            })
            .addCase(getOrdersById.fulfilled, (state,action) => {
                state.loading = false;
                state.orders = action.payload?.models;
            })
            .addCase(getOrdersById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
})

export default orderSlice.reducer;