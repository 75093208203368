import Button from "./button_token";
import token from "./global_token";
import Input from "./input_token";
import Menu from "./menu_token";
import Select from "./select_token";
import Upload from "./upload_token";
import Form from "./form_token";
import DatePicker from "./datepicker_token";

export const APP_THEME = {
    token: token,
    components: {
        Input: Input,
        Button: Button,
        Upload: Upload,
        Menu: Menu,
        Select: Select,
        Form: Form,
        DatePicker: DatePicker,
    },
}

export const ICON_THEME = {
    size: 16,
    mirrored: false,
}