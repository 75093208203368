import { CaretDown, CaretUp } from "@phosphor-icons/react";
import React from "react";
import "./EventNavItem.scss";
import { Link } from "react-router-dom";
import EventNavList from "../../partials/EventNavList/EventNavList";

function EventNavItem({
                          id,
                          active,
                          type,
                          path,
                          icon,
                          contents,
                          children,
                          subItems,
                          expandedItemId,
                          setExpandedItemId,
                          onClick,
                      }) {
    const handleClick = () => {
        setExpandedItemId(id === expandedItemId ? null : id);
    };
    return (
        <li className={`event-nav-item ${active && "active-event-nav-item"}`}>
            {type === "anchor" && (
                <Link to={path} className="event-nav-list-item-link">
                    <div className="list-item-link-holder">
                        {icon && (
                            <span className="list-item-link__icon">{icon}</span>
                        )}

                        <div className="list-item-link__contents">
                            <div className="nav-item__contents">
                                <div className="nav-list-item__text">
                                    {contents}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            )}
            {type === "expandedLink" && (
                <>
                    <div
                        className="event-nav-list-item-link"
                        onClick={handleClick}
                    >
                        <div className="list-item-link-holder expanded-link">
                            <div className="list-item-link__contents">
                                <div className="nav-item__contents">
                                    <div className="nav-list-item__text">
                                        {contents}
                                    </div>
                                </div>
                            </div>
                            <div className="nav-list-expanded-link__icon">
                                {expandedItemId === id ? (
                                    <CaretDown size={17} fill="#c2c2cc"/>
                                ) : (
                                    <CaretUp size={17} fill="#c2c2cc"/>
                                )}
                            </div>
                        </div>
                    </div>
                    {expandedItemId === id && (
                        <div className="event-nav-sub-list">
                            <EventNavList items={subItems}/>
                        </div>
                    )}
                </>
            )}
        </li>
    );
}

export default EventNavItem;
