import axiosInstance from "../api";

const notificationService = {
    getAllNotifications: async (params) => {
        try {
            return await axiosInstance.get(
                `/api/v1/profile/getAllNotifications`, {
                    params: params
                }
            );
        } catch (error) {
            console.error("Error get notification:", error);
            throw error;
        }
    },
};

export default notificationService;
