import { PencilSimple } from "@phosphor-icons/react";
import { Avatar, Button } from "antd";
import React, { useEffect, useState } from "react";
import "./UserProfileHeader.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProfileStatistic } from "../../../../../common/redux/actions/accountAction";
import ProfileStatItem from "../../blocks/ProfileStatItem/ProfileStatItem";


const UserProfileHeader = () => {
    const [userStatistic, setUserStatistic] = useState();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { profileStatistic } = useSelector((state) => state.account);

    useEffect(() => {
        const username = user?.id;
        if (username) {
            dispatch(getProfileStatistic({ username: username }));
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (profileStatistic) {
            const statList = [
                {
                    id: "orders",
                    label: `reservation${profileStatistic?.numberOfOrder > 1 ? "s" : ""}`,
                    value: profileStatistic.numberOfOrder || 0,
                },
                {
                    id: "likes",
                    label: `like${profileStatistic?.numberOfFavoriteEvent > 1 ? "s" : ""}`,
                    value: profileStatistic.numberOfFavoriteEvent || 0,
                },
                {
                    id: "following",
                    label: "following",
                    value: profileStatistic.numberOfFollowingOrganization || 0,
                },
            ];
            setUserStatistic(statList);
        }
    }, [profileStatistic]);

    return (
        <div className="user-profile-header-container">
            {profileStatistic && <div className="profile-avatar">
                <Avatar
                    className="profile-avatar-img"
                    shape="square"
                    size={120}
                >
                    {profileStatistic?.firstName?.toString().charAt(0)}
                </Avatar>
            </div>}
            {profileStatistic &&
                <div className="profile-content">
                    <div className="profile-info">
                        <div className="profile-bio">
                            <div className="profile-name">
                                {profileStatistic.firstName} {profileStatistic.lastName}
                            </div>
                            <div className="profile-email">
                                {profileStatistic.email}
                            </div>
                        </div>
                        <div className="profile-statistic">
                            {
                                userStatistic?.length > 0 && userStatistic.map((item, index) => (
                                    <ProfileStatItem
                                        key={item.id}
                                        label={item.label}
                                        value={item.value}
                                    />
                                ))
                            }
                        </div>
                    </div>
                    <div className="profile-edit-button">
                        <Link to="/account/profile">
                            <Button type="text" icon={<PencilSimple/>}>
                                Edit profile
                            </Button>
                        </Link>
                    </div>
                </div>
            }
        </div>
    );
};

export default UserProfileHeader;
