import axiosInstance from "../api";


const orderService = {
    getOrdersById: async (eventId) => {
        try {
            return await axiosInstance.get(`/api/v1/organization/getReservationsByEventId`,
                { params: eventId })
        } catch (error) {
            console.error(`error getting orders`, error);
            throw error;
        }
    }
}

export default orderService;