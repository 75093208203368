import {Button, Col, Form, Input, Row} from "antd";
import React from "react";
import "./RegistrationFormFields.scss";
import {validateConfirmPassword, validatePassword} from "../../../../../common/utils/passwordUtils";

function RegistrationFormFields({form, onFinish, loading}) {
    return (
        <Form
            form={form}
            name="registration_form"
            onFinish={onFinish}
            layout="vertical"
            requiredMark={true}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your first name!",
                            },
                        ]}
                    >
                        <Input size="large"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="lastName"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: "Please input your last name!",
                            },
                        ]}
                    >
                        <Input size="large"/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="email"
                label="Email"
                rules={[
                    {
                        type: "email",
                        message: "Please input a valid Email!",
                    },
                    {
                        required: true,
                        message: "Please input your Email!",
                    },
                ]}
            >
                <Input size="large"/>
            </Form.Item>

            <Form.Item
                name="password"
                label="Password"
                rules={[
                    {
                        required: true,
                        message: "Please input your password!",
                    },
                    {
                        validator: validatePassword,
                    },
                ]}
                hasFeedback
            >
                <Input.Password size="large"/>
            </Form.Item>

            <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={["password"]}
                rules={[
                    {
                        required: true,
                        message: "Please confirm your password!",
                    },
                    validateConfirmPassword,
                ]}
                hasFeedback
            >
                <Input.Password size="large"/>
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    className="register-form-button"
                    loading={loading}
                    block={true}
                >
                    Register
                </Button>
            </Form.Item>
        </Form>
    );
}

export default RegistrationFormFields;
