import { VideoCamera, VideoCameraSlash } from "@phosphor-icons/react";
import { createCameraVideoTrack, useMeeting } from "@videosdk.live/react-sdk";
import { Dropdown } from 'antd';
import React, { useEffect, useState } from "react";

const WebCamButton = ({ selectWebcamDeviceId, setSelectWebcamDeviceId }) => {
    const mMeeting = useMeeting();
    const [webcams, setWebcams] = useState([]);
    const localWebcamOn = mMeeting?.localWebcamOn;
    const changeWebcam = mMeeting?.changeWebcam;

    useEffect(() => {
        const getWebcams = async (mGetWebcams) => {
            const webcams = await mGetWebcams();
            webcams && webcams?.length && setWebcams(webcams);
        };
        getWebcams(mMeeting?.getWebcams);
    }, []);

    const handleClickWebCam = async () => {
        let track;
        if (!localWebcamOn) {
            track = await createCameraVideoTrack({
                optimizationMode: "motion",
                encoderConfig: "h540p_w960p",
                facingMode: "environment",
                multiStream: false,
                cameraId: selectWebcamDeviceId,
            });
        }
        mMeeting.toggleWebcam(track);
    }

    const handleMenuClick = async (e) => {
        const deviceId = e?.deviceId;
        setSelectWebcamDeviceId(deviceId);
        const track =
            await createCameraVideoTrack({
                optimizationMode: "motion",
                encoderConfig: "h540p_w960p",
                facingMode: "environment",
                multiStream: false,
                cameraId: deviceId,
            });
        changeWebcam(track);
    };

    const webCamMenu = {
        items: webcams.map(({ deviceId, label }, index) => ({
            key: `output_webcams_${deviceId}`,
            label: <>{label || `Webcam ${index + 1}`}</>,
        })),
        onClick: handleMenuClick,
    }
    return <Dropdown.Button size="large" trigger="click" type="primary" onClick={handleClickWebCam} menu={webCamMenu}>
        {
            localWebcamOn
                ? <VideoCamera size={24} weight="fill"/>
                : <VideoCameraSlash size={24} weight="fill"/>
        }
    </Dropdown.Button>;
};

export default WebCamButton;