import { createSlice } from "@reduxjs/toolkit";
import { MeetingModel } from "../../models/meetingModel";
import { createMeetingCode, createMeetingRoom, getMeetingRoomForAttendee } from "../actions/meetingAction";

const mediaSlice = createSlice({
    name: "meeting",
    initialState: MeetingModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createMeetingCode.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createMeetingCode.fulfilled, (state, action) => {
                state.loading = false;
                state.meetingCode = action.payload;
            })
            .addCase(createMeetingCode.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createMeetingRoom.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createMeetingRoom.fulfilled, (state, action) => {
                state.loading = false;
                state.media = action.payload;
                state.success = true;
            })
            .addCase(createMeetingRoom.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getMeetingRoomForAttendee.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getMeetingRoomForAttendee.fulfilled, (state, action) => {
                state.loading = false;
                state.attendeeMeetingRoom = action.payload;
                state.success = true;
            })
            .addCase(getMeetingRoomForAttendee.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});

export default mediaSlice.reducer;
