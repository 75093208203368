import { Screencast } from "@phosphor-icons/react";
import { useMeeting } from "@videosdk.live/react-sdk";
import { Button } from "antd";

const ScreenShareButton = () => {
    const { localScreenShareOn, toggleScreenShare, presenterId } = useMeeting();

    return <Button
        size="large"
        type={localScreenShareOn ? "default" : "primary"}
        icon={<Screencast size={24} weight="fill"/>}
        onClick={() => toggleScreenShare()}
        disabled={presenterId ? (!localScreenShareOn) : false}
    >
        Present
    </Button>
};

export default ScreenShareButton;