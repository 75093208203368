import { createSlice } from "@reduxjs/toolkit";
import { SearchModel } from "../../models/searchModel.model";
import { searchGenerics } from "../actions/searchAction";


const searchSlice = createSlice({
    name: 'search',
    initialState: SearchModel,
    reducers: {
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setSearchCategories: (state, action) => {
            state.searchCategories = action.payload;
        },
        setSearchEventDateRange: (state, action) => {
            state.searchEventDateRange = action.payload;
        },
        setSearchEventStartTime: (state, action) => {
            state.searchEventStartTime = action.payload;
        },
        setSearchEventEndTime: (state, action) => {
            state.searchEventEndTime = action.payload;
        },
        setSearchEventFormat: (state, action) => {
            state.searchEventFormat = action.payload;
        },
        setSearchEventPrice: (state, action) => {
            state.searchEventPrice = action.payload;
        },
        setSearchEventLocation: (state, action) => {
            state.searchEventLocation = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchGenerics.pending, (state) => {
                state.loading = true;
            })
            .addCase(searchGenerics.fulfilled, (state, action) => {
                state.loading = false;
                state.searchResults = action.payload.searchResultDTOS;
                state.error = null;
                state.success = true;
                state.hasMoreResults = action.payload.hasMoreResults;
            })
            .addCase(searchGenerics.rejected, (state, action) => {
                state.loading = false;
                state.searchResults = [];
                state.error = action.payload;
                state.success = null;
            })
    },
});
export const {
    setSearchQuery,
    setSearchCategories,
    setSearchEventDateRange,
    setSearchEventStartTime,
    setSearchEventEndTime,
    setSearchEventFormat,
    setSearchEventPrice,
    setSearchEventLocation
} = searchSlice.actions;

export default searchSlice.reducer;
