import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHECK_BANK_ACCOUNT, GET_BANKS_INFO, UPDATE_BANK_INFO } from "../types/bank";
import bankingService from "../../services/bankingService";


export const getBanksInfo = createAsyncThunk(
    GET_BANKS_INFO,
    async (data , {rejectWithValue} ) =>{
        try {
            return bankingService.getBanksInfo();
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const checkBankAccount = createAsyncThunk(
    CHECK_BANK_ACCOUNT,
    async (data , {rejectWithValue} ) =>{
        try {
            return bankingService.checkBankAccount(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)

export const updateOrganizationBankInfo = createAsyncThunk(
    UPDATE_BANK_INFO,
    async (data , {rejectWithValue} ) =>{
        try {
            return bankingService.updateOrganizationBank(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
)