import { Clock, MapPin, User } from "@phosphor-icons/react";
import { Ticket } from "@phosphor-icons/react/dist/ssr";
import { Button } from "antd";
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from "react";
import "./EventCTACard.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { setCartReservationId } from "../../../../../common/redux/slice/cartSlice";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import { canBuyMoreTicket } from "../../../../../common/redux/actions/accountAction";
import { EventType } from "../../../../../common/enums/Event";

export default function EventCTACard({ handleViewTickets, eventData, organizationEvent }) {
    const { eventId, startDateTime, location, organization, endDateTime, minPrice, tickets, eventType } = eventData;
    const eventStartDate = new Date(startDateTime)
    const eventEndDateTime = new Date(endDateTime)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [soldOut, setSoldOut] = useState(false);
    const [minAvailableTicket, setMinAvailableTicket] = useState(0);
    const currentDate = new Date();
    const isAllTicketsPast = tickets?.every(ticket => new Date(ticket.endDateAvailable) < currentDate);
    const isEventOpened = tickets?.some(ticket => new Date(ticket.startDateAvailable) < currentDate)
    const ticketSoldOut = tickets?.every(ticket => ticket?.soldQuantity === ticket?.totalQuantity);
    const isPastEvent = currentDate > eventEndDateTime;
    const { user } = useSelector((state) => state.auth);
    const username = user?.id;
    const [canUserBuyMoreTicket, setCanUserBuyMoreTicket] = useState(true);

    const mangeYourEvent = () => {
        navigate(`/organization/manage/events/${eventId}/basic-info`)
    }

    const goToTicketsPage = () => {
        navigate(`/user/reservations`);
    }


    const handleNavigateReserveTicket = () => {
        const reservationId = nanoid();
        const url = `/events/${eventId}/ticket-reservation/${reservationId}/order-info`;
        dispatch(setCartReservationId(reservationId));
        navigate(url);
    }
    
    const eventStatus = useMemo(() => {
        if (organizationEvent) return `Manage your event!`;
        if (!canUserBuyMoreTicket) return `View your ticket`;
        if (isPastEvent) return 'This event has ended';
        if (!isEventOpened) return 'This event will be opened soon!';
        if (!tickets?.length) return 'Not open for sale yet!';
        if (isAllTicketsPast) return 'Closed';
        if (ticketSoldOut) return 'Sold out';
        return 'Get Tickets!';
    }, [organizationEvent, canUserBuyMoreTicket, isEventOpened, isAllTicketsPast, ticketSoldOut, tickets?.length]);

    useEffect(() => {
        if (tickets?.length > 0) {
            const currentDate = new Date();
            const availableTickets = tickets.filter(
                ticket =>
                    new Date(ticket.startDateAvailable) <= currentDate &&
                    new Date(ticket.endDateAvailable) >= currentDate &&
                    ticket.totalQuantity !== ticket.soldQuantity
            );

            const allSold = tickets?.every(ticket => ticket.soldQuantity === ticket.totalQuantity);
            setSoldOut(allSold);

            if (availableTickets.length === 0) {
                setMinAvailableTicket(minPrice)
            } else {
                const minAvailable = Math.min(...availableTickets.map(ticket => ticket.price));
                setMinAvailableTicket(minAvailable);
            }

        }
        // eslint-disable-next-line
    }, [eventData, minPrice, tickets]);

    useEffect(() => {
        const checkUserTicket = async () => {
            try {
                const res = await dispatch(canBuyMoreTicket({ username: username, eventId: eventId })).unwrap();
                setCanUserBuyMoreTicket(res);
            } catch (error) {
                console.error(error)
            }
        }

        checkUserTicket();
    }, [username, eventId, dispatch]);

    const actionHandler = () => {
        if (!canUserBuyMoreTicket) {
            goToTicketsPage();
        } else if (organizationEvent) {
            mangeYourEvent();
        } else {
            handleNavigateReserveTicket();
        }
    }

    return (
        <div className="event-cta-card-container">
            <div className="event-cta-card-info-list">
                {startDateTime && (
                    <div className="info-list-item">
                        <div className="info-list-item-icon">
                            <Clock/>
                        </div>
                        <div className="info-list-item-text">
                            {formatDateRange(startDateTime, DateTimePattern.STANDARD_DATE_TIME)}
                        </div>
                    </div>
                )}
                {(location && eventType === EventType.OFFLINE) && (
                    <div className="info-list-item">
                        <div className="info-list-item-icon">
                            <MapPin/>
                        </div>
                        <div className="info-list-item-text">
                            {location?.address} {location?.district}{" "}
                            {location?.city}
                        </div>
                    </div>
                )}
                {eventType === EventType.ONLINE && (
                    <div className="info-list-item">
                        <div className="info-list-item-icon">
                            <MapPin/>
                        </div>
                        <div className="info-list-item-text">
                            Online
                        </div>
                    </div>
                )}
                {organization && (
                    <div className="info-list-item">
                        <div className="info-list-item-icon">
                            <User/>
                        </div>
                        <div className="info-list-item-text">
                            {organization?.organizationName}
                        </div>
                    </div>
                )}
            </div>
            <div className="cta-card-btn">
                {
                    minAvailableTicket > 0
                        ? <Button
                            className="ticket-option-btn"
                            size="large"
                            type="default"
                            block
                            onClick={handleViewTickets}
                        >
                            <Ticket weight="fill"/>
                            <div className="cta-card-btn-text">
                                {
                                    minAvailableTicket > 0
                                        ? <p>From <span
                                            className="highlight"> {minAvailableTicket.toLocaleString()} VND</span>
                                        </p>
                                        : <p>Free</p>
                                }
                            </div>
                        </Button>
                        : null
                }

                {/*<Link to={`/events/${eventId}/ticket-reservation/order-info`}>*/}
                <Button
                    className="get-ticket-btn"
                    size="large"
                    type="primary"
                    block={true}
                    onClick={actionHandler}
                    disabled={(soldOut || isAllTicketsPast || !isEventOpened || isPastEvent) && !organizationEvent && canUserBuyMoreTicket}
                >
                    <div className="cta-card-btn-text">{eventStatus}</div>
                </Button>
                {/*</Link>*/}
            </div>
        </div>
    );
}
