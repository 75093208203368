import { MicrophoneSlash, Screencast, Waveform } from "@phosphor-icons/react";
import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import { Button } from "antd";
import { useEffect, useMemo, useRef } from "react";
import ReactPlayer from "react-player";
import "./PresenterView.scss";
import { nameTructed } from "../../../../common/utils/videosdkUtils";
import { CornerDisplayName } from "../CornerDisplayName/CornerDisplayName";

export function PresenterView() {
    const mMeeting = useMeeting();
    const presenterId = mMeeting?.presenterId;
    const videoPlayer = useRef();

    const {
        micOn,
        isLocal,
        screenShareStream,
        screenShareAudioStream,
        screenShareOn,
        displayName,
        isActiveSpeaker,
        webcamOn,
    } = useParticipant(presenterId);

    const mediaStream = useMemo(() => {
        if (screenShareOn) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(screenShareStream.track);
            return mediaStream;
        }
    }, [screenShareStream, screenShareOn]);

    const audioPlayer = useRef();

    useEffect(() => {
        if (
            !isLocal &&
            audioPlayer.current &&
            screenShareOn &&
            screenShareAudioStream
        ) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(screenShareAudioStream.track);

            audioPlayer.current.srcObject = mediaStream;
            audioPlayer.current.play().catch((err) => {
                if (
                    err.message ===
                    "play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD"
                ) {
                    console.error("audio" + err.message);
                }
            });
        } else {
            audioPlayer.current.srcObject = null;
        }
    }, [screenShareAudioStream, screenShareOn, isLocal]);
    return (
        <div className="presenter-view-container">
            <audio autoPlay playsInline controls={false} ref={audioPlayer}/>
            <div className="presenter-view-video">
                <ReactPlayer
                    ref={videoPlayer}
                    playsinline
                    playIcon={<></>}
                    pip={false}
                    light={false}
                    controls={false}
                    muted={true}
                    playing={true}
                    url={mediaStream}
                    height="100%"
                    width="100%"
                    style={{
                        filter: isLocal ? "blur(1rem)" : undefined,
                    }}
                    onError={(err) => {
                        console.log(err, "presenter video error");
                    }}
                />
                <div
                    className="presenter-view-label"
                    style={{
                        transition: "all 200ms",
                        transitionTimingFunction: "linear",
                    }}
                >
                    {
                        !micOn
                            ? <MicrophoneSlash weight="fill" />
                            : micOn && isActiveSpeaker
                                ? <Waveform weight="fill"/>
                                : <></>
                    }
                    <p className="text-white">
                        {isLocal
                            ? `You are presenting`
                            : `${nameTructed(displayName, 15)} is presenting`}
                    </p>
                </div>
                {isLocal ? (
                    <>
                        <div className="presenter-view-modal">
                            <Screencast size={48} color="#fff" weight="fill"/>
                            <div className="presenter-view-modal-message">
                                You are presenting to everyone
                            </div>
                            <Button
                                type={"primary"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    mMeeting.toggleScreenShare();
                                }}
                            >
                                STOP PRESENTING
                            </Button>
                        </div>
                        <CornerDisplayName
                            {...{
                                isLocal,
                                displayName,
                                micOn,
                                webcamOn,
                                isPresenting: true,
                                participantId: presenterId,
                                isActiveSpeaker,
                            }}
                        />
                    </>
                ) : <></>
                }
            </div>
        </div>
    );
}
