import React, { useEffect, useLayoutEffect, useState } from "react";
import "./OrganizationCheckin.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EventType } from "../../../common/enums/Event";
import { getReservedTicketsByEventId } from "../../../common/redux/actions/ticketAction";
import QRScanner from "./partials/QRScanner/QRScanner";
import ReservedTickets from "./partials/ReservedTickets/ReservedTickets";

const OrganizationCheckin = () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const [fetchTickets, setFetchTickets] = useState(false);
    const { organization } = useSelector((state) => state.organization);
    const { reservedTickets } = useSelector((state) => state.ticket);
    const { event } = useSelector((state) => state.event);

    useLayoutEffect(() => {
        document.title = "Check-in attendees";
    });

    useEffect(() => {
        if (eventId) {
            dispatch(getReservedTicketsByEventId({ eventId: eventId }));
        }
    }, [eventId, dispatch]);

    useEffect(() => {
        if (fetchTickets) {
            dispatch(getReservedTicketsByEventId({ eventId: eventId }));
            setFetchTickets(false);
        }
        // eslint-disable-next-line
    }, [fetchTickets]);

    return (
        <div className="org-checkin-container">
            <div className="org-checkin-head">
                <div className="org-checkin-title">
                    Check-in Attendees
                </div>
                {/*<Search*/}
                {/*    className="org-checkin-search-input"*/}
                {/*    placeholder="Search ticket"*/}
                {/*    style={{ width: 300 }}*/}
                {/*    size="large"*/}
                {/*/>*/}
            </div>
            <div className="org-checkin-content">
                <div className="org-checkin-list">
                    {reservedTickets && <ReservedTickets data={reservedTickets}/>}
                </div>
                {
                    event?.eventType === EventType.OFFLINE
                    && <div className="org-checkin-scanner">
                        {organization && <QRScanner organization={organization} setFetchTickets={setFetchTickets}/>}
                    </div>
                }
            </div>
        </div>
    );
};

export default OrganizationCheckin;
