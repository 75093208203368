import { createAsyncThunk } from "@reduxjs/toolkit";
import organizationService from "../../services/organizationService";
import {
    GET_ALL_ORGANIZATIONS,
    GET_ORGANIZATION_PROFILE,
    GET_ORGANIZATION_PROFILE_BY_USERNAME,
    IS_ORG_REGISTERED,
    UPDATE_ORGANIZATION_COVER,
    UPDATE_ORGANIZATION_INFORMATION,
    UPDATE_ORGANIZATION_PROFILE,
} from "../types/organization";

export const getAllOrganizers = createAsyncThunk(
    GET_ALL_ORGANIZATIONS,
    async (data, { rejectWithValue }) => {
        try {
            return await organizationService.getAllOrganizers(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getOrganizerProfile = createAsyncThunk(
    GET_ORGANIZATION_PROFILE,
    async (data, { rejectWithValue }) => {
        try {
            return await organizationService.getOrganizerProfile(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateOrganizationProfile = createAsyncThunk(
    UPDATE_ORGANIZATION_PROFILE,
    async (data, { rejectWithValue }) => {
        try {
            return await organizationService.updateOrganizationProfile(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateOrganizationInformation = createAsyncThunk(
    UPDATE_ORGANIZATION_INFORMATION,
    async (data, { rejectWithValue }) => {
        try {
            return await organizationService.updateOrganizationInformation(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getOrganizationProfile = createAsyncThunk(
    GET_ORGANIZATION_PROFILE_BY_USERNAME,
    async (username, { rejectWithValue }) => {
        try {
            return await organizationService.getOrganizationProfile(username);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);


export const isOrganizationRegistered = createAsyncThunk(
    IS_ORG_REGISTERED,
    async (username, { rejectWithValue }) => {
        try {
            return await organizationService.isOrganizationRegistered(username);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateOrganizationCover = createAsyncThunk(
    UPDATE_ORGANIZATION_COVER,
    async (data, { rejectWithValue }) => {
        try {
            return await organizationService.updateOrganizationCover(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

