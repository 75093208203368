import React from "react";
import MenuItem from "./MenuItem";
import "./main_menu.scss";

const MainMenu = ({ menuItems, activeItem, onMenuItemClick }) => {
    return (
        <div className="main-menu-container">
            {menuItems.map((item) => (
                <MenuItem
                    key={item.title}
                    icon={item.icon}
                    title={item.title}
                    path={item.path}
                    active={item.key === activeItem}
                    onClick={() => onMenuItemClick(item.key)}
                />
            ))}
        </div>
    );
};

export default MainMenu;
