import { createSlice } from "@reduxjs/toolkit";
import { ContentModel } from "../../models/contentModel.model";
import { generateEventContent, generateEventFAQ } from "../actions/contentAction";


const contentSlice = createSlice({
    name: 'content',
    initialState: ContentModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(generateEventContent.pending, (state) => {
                state.loading = true;
            })
            .addCase(generateEventContent.fulfilled, (state, action) => {
                state.loading = false;
                state.eventContent = action.payload;
                state.error = null;
                state.success = true;
            })
            .addCase(generateEventContent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(generateEventFAQ.pending, (state) => {
                state.loading = true;
            })
            .addCase(generateEventFAQ.fulfilled, (state, action) => {
                state.loading = false;
                state.eventFAQ = action.payload;
                state.error = null;
                state.success = true;
            })
            .addCase(generateEventFAQ.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
    },
});


export default contentSlice.reducer;
