import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProvincesDistricts } from "../redux/actions/addressAction";

const useAddress = () => {
    const dispatch = useDispatch();
    const { address, error } = useSelector((state) => state.address);

    useEffect(() => {
        const fetchAddress = async () => {
            try {
                await dispatch(getProvincesDistricts());
            } catch (rejectWithValue) {
                console.error(rejectWithValue);
            }
        };
        fetchAddress();
    }, [dispatch]);

    return { address, error };
}

export default useAddress;