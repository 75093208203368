import { Eye } from "@phosphor-icons/react";
import { Button, Modal, Tag } from "antd";
import React, { useState } from "react";
import "./TicketItem.scss";
import { Link } from "react-router-dom";
import { DateTimePattern } from "../../../../common/enums/DateTimePattern";
import { TicketStatus } from "../../../../common/enums/Ticket";
import { formatDateRange } from "../../../../common/utils/dateTimeUtils";
import { capitalizeFirstLetter } from "../../../../common/utils/stringUtils";
import Ticket from "../Ticket/Ticket";

const TicketItem = ({ ticket, holder }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="ticket-item-container">
            <div className="ticket-item-left">
                <div className="ticket-item-content-top">
                    <Link to={`tickets/${ticket?.ticketId}`} className="ticket-item-code">
                        #{ticket?.ticketCode}
                    </Link>
                    <Link to={`/events/${ticket?.eventId}`}
                          className="ticket-item-ticket-name">
                        {ticket.ticketName}
                    </Link>
                </div>
                <div className="ticket-item-content-bottom">
                    <div className="ticket-item-event-name">
                        {ticket?.eventName}
                    </div>
                    <div className="ticket-item-event-location">
                        {ticket?.eventLocation
                            ? `${ticket?.eventLocation?.district}, ${ticket?.eventLocation?.province}`
                            : "Online"}
                    </div>
                    <div className="ticket-item-event-time">
                        {formatDateRange(ticket?.eventStartTime, DateTimePattern.STANDARD_DATE_TIME)}
                    </div>
                </div>
            </div>

            <div className="ticket-item-right">
                <div className="ticket-item-admission">
                    <div className="ticket-item-price">
                        {ticket?.ticketPrice.toLocaleString()}VND
                    </div>
                    <div className="ticket-item-tag">
                        <Tag bordered={false} color={
                            ticket.used === false
                                ? "blue"
                                : "green"
                        }>
                            {ticket?.used
                                ? capitalizeFirstLetter(TicketStatus.ADMITTED)
                                : capitalizeFirstLetter(TicketStatus.ACTIVE)}
                        </Tag>
                    </div>
                </div>
                <div className="ticket-item-open-btn">
                    <Button type="text" icon={<Eye/>} onClick={showModal}></Button>
                </div>
            </div>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                style={{ width: "fit-content" }}
            >
                <Ticket ticket={ticket} holder={holder}/>
            </Modal>
        </div>
    );
}

export default TicketItem;