import { createSlice } from "@reduxjs/toolkit";
import { StatisticModel } from "../../models/statisticModel.model";
import { countEventPageView, getEventStatistic, getOrganizationStatistic } from "../actions/statisticAction";

const statisticSlice = createSlice({
    name: 'statistic',
    initialState: StatisticModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(countEventPageView.pending, (state) => {
                state.loading = true;
            })
            .addCase(countEventPageView.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(countEventPageView.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getEventStatistic.pending, (state) => {
                state.loading = true;
            })
            .addCase(getEventStatistic.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.eventStatistic = action.payload;
                state.success = true;
            })
            .addCase(getEventStatistic.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getOrganizationStatistic.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOrganizationStatistic.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.organizationStatistic = action.payload;
                state.success = true;
            })
            .addCase(getOrganizationStatistic.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
    },
});

export default statisticSlice.reducer;
