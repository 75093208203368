export const EventModel = {
    eventId: null,
    event: null,
    events: null,
    createdEvents: null,
    suggestedEvents: null,
    eventsOfFollowingOrgs: null,
    eventsByOrganizationIds: null,
    favoriteEvents: null,
    favoriteEventIds: null,
    relatedEvents: null,
    pastEvent: null,
    loading: null,
    success: null,
    error: null,
    hasMoreEvents: false,
};
