import { CalendarDots } from "@phosphor-icons/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from "react-router-dom";
import { FAVORITE } from "../../../../../common/constants/LocalStorageConstants";
import { getFavoriteEventIds, getFavoriteEvents } from "../../../../../common/redux/actions/eventActions.js";
import default_img from "../../../../assets/images/default.jpg";
import EventListVertical from "../../../../modules/components/EventListVertical/EventListVertical";
import Loading from "../../../../modules/components/Loading/Loading";
import NoDataFound from "../../../../modules/components/NoDataFound/NoDataFound.jsx";
import "./UserLikedEvents.scss";

const UserLikedEvents = () => {
    const { setActiveItem } = useOutletContext();
    const [favEventList, setFavEventList] = useState([]);
    const dispatch = useDispatch();
    const { favoriteEvents, favoriteEventIds, loading } = useSelector((state) => state.event);
    const { user } = useSelector((state) => state.auth);

    useLayoutEffect(() => {
        document.title = "User - Liked events";
        setActiveItem("liked-events");
    });

    useEffect(() => {
        const username = user?.id;
        if (username) {
            const params = { username: username };
            dispatch(getFavoriteEventIds(params));
            dispatch(getFavoriteEvents({ ...params, limit: 100, offset: 1 }));
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (favoriteEventIds) {
            localStorage.setItem(FAVORITE, favoriteEventIds);
        }
        if (favoriteEvents) {
            const newEvents = favoriteEvents.map((item) => (
                {
                    ...item,
                    thumbnailUrl: item?.thumbnailUrl || default_img,
                    organization: {
                        name: item?.organization?.organizationName
                    },
                }
            ))
            setFavEventList(newEvents);
        }
    }, [favoriteEvents, favoriteEventIds]);

    return (
        <div className="user-liked-events-container">
            <div className="user-liked-events-head">Your liked events</div>
            {loading || !favEventList
                ? <Loading/>
                : ((favEventList && favEventList.length > 0)
                        ? <EventListVertical events={favEventList}/>
                        : <NoDataFound icon={<CalendarDots size={60}/>} message="You haven't like any events!"/>
                )}
        </div>
    );
};

export default UserLikedEvents;
