import axiosInstance from "../api";

const reportService = {
    reportEvent: async (request) => {
        try {
            return await axiosInstance.post("/api/v1/audience/reportEvent", request);
        } catch (error) {
            console.error("Error report event:", error);
            throw error;
        }
    }
};

export default reportService;
