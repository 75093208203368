import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/icons/logo.svg';
import highlightReel1 from '../../../../assets/images/highlight-reel-1.jpg';
import highlightReel2 from '../../../../assets/images/highlight-reel-2.jpg';
import highlightReel3 from '../../../../assets/images/highlight-reel-3.jpg';
import Highlight from '../../components/Highlight';
import './HighlightReel.scss';

const data = [
    {
        id: 1,
        imgSrc: highlightReel1,
        name: 'Events',
        category: ['Shows', 'seminars', 'workshop']
    },
    {
        id: 2,
        imgSrc: highlightReel2,
        name: 'Videos',
        category: ['Music', 'film', 'vlog']
    },
    {
        id: 3,
        imgSrc: highlightReel3,
        name: 'Streaming',
        category: ['Gaming', 'sport', 'webinar']
    }
];

function HighlightReel() {
    return (
        <div className="highlightContainer">
            <div className="iconHolder">
                <Link to={"/"}>
                    <img src={logo} alt="" className="icon"/>
                </Link>
                <span className="logoName">Eventure</span>
            </div>
            <div className="description">
                <span className="descriptionText">Never miss a beat!</span>
            </div>
            <div className="hightlightList">
                {data.map((data) => (
                    <Highlight key={data.id} name={data.name} imgSrc={data.imgSrc} category={data.category}></Highlight>
                ))}
            </div>
            <div className="discoverMore">
                <span className="discoverMoreText">Discover more</span>
            </div>
        </div>
    );
}

export default HighlightReel;
