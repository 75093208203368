import { CalendarCheck, Users } from "@phosphor-icons/react";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./OrganizationCardVertical.scss";
import defaultThumbnail from "../../../assets/images/default.jpg";
import defaultAvatar3 from "../../../assets/images/default_avatar_v3.jpg";
import ButtonFollowOrg from "../ButtonFollowOrg";

const OrganizationCardVertical = ({ organizer }) => {
    const { user } = useSelector((state) => state.auth);

    return (
        <div className="org-v-card-container">
            <div className="org-v-card-avatar">
                <img src={organizer.avatarFileUrl || defaultAvatar3}
                     alt=""
                     onError={(e) => e.target.src = defaultThumbnail}
                />
            </div>
            <div className="org-v-card-content">
                <div className="org-v-card-head">
                    <Link to={`/organizers/${organizer.id}`} className="org-v-card-name">
                        {organizer.name}
                    </Link>
                    <div className="org-v-card-bio">
                        {organizer.bio}
                    </div>
                </div>
                <div className="org-v-card-bottom">
                    <div className="org-v-card-statistic">
                        <div className="org-v-card-info org-v-card-follower">
                            <div className="org-v-card-info-label">
                                <Users/>
                            </div>
                            <p className="org-v-card-info-value">
                                {organizer.numberOfFollowers} followers
                            </p>
                        </div>
                        <div className="org-v-card-info org-v-card-event">
                            <div className="org-v-card-info-label">
                                <CalendarCheck/>
                            </div>
                            <p className="org-v-card-info-value">
                                {organizer.numberOfEvents} events
                            </p>
                        </div>
                    </div>
                    {user && <ButtonFollowOrg organizer={organizer}/>}
                </div>
            </div>
        </div>
    );
};

export default OrganizationCardVertical;