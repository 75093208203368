import { PaperPlaneTilt } from "@phosphor-icons/react";
import { Button, Input } from "antd";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import "./MainConversation.scss";
import { useDispatch, useSelector } from "react-redux";
import { PROFILE_MODE } from "../../../../../common/constants/LocalStorageConstants";
import { ApplicationMode } from "../../../../../common/enums/ApplicationMode";
import { sendMessage } from "../../../../../common/redux/actions/messageAction";
import MessageList from "../MessageList";

function MainConversation({ thread }) {
    const [content, setContent] = useState("");
    const dispatch = useDispatch();
    const { messages } = useSelector(state => state.message)
    const scrollRef = useRef();
    const [userScrolled, setUserScrolled] = useState(false);
    const mode = sessionStorage.getItem(PROFILE_MODE)


    const isUserScrolled = () => {
        const scrollContainer = scrollRef.current;
        return scrollContainer && scrollContainer.scrollTop <
            scrollContainer.scrollHeight - scrollContainer.clientHeight - 10;
    };

    const handleOnScroll = () => {
        setUserScrolled(isUserScrolled());
    };

    useEffect(() => {
        if (!userScrolled) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
        // eslint-disable-next-line
    }, [messages]);

    const handleSendMessage = () => {
        if (!content.trim()) {
            return
        }

        const msgData = [{
            created_at: new Date(),
            sender: mode,
            content: content,
            thread: thread?.id
        }]

        dispatch(sendMessage(msgData))
        setContent("")
    }

    const handleOnChange = useCallback((e) => {
        setContent(e.target.value);
    }, []);


    const handleKeyPress = useCallback((event) => {
        if (event.key === "Enter") {
            handleSendMessage();
        }
    }, [handleSendMessage]);
    

    return (
        <section className="message-chat-container">
            <div className="message-header">
                <div className="message-info">
                    <div className="sender-name">
                        {mode === ApplicationMode.ATTENDEE ? thread?.organizationData?.name : `${thread?.user?.firstName} ${thread?.user?.lastName}`}
                    </div>
                </div>
            </div>
            <div className="message-conversation" ref={scrollRef} onScroll={handleOnScroll}>
                {Array.isArray(messages) && messages.length > 0 && (
                    <MessageList messages={messages} mode={mode}/>
                )}
            </div>
            <div className="message-input">
                <Input value={content} placeholder="Type your message..." allowClear={true} onKeyPress={handleKeyPress}
                       onChange={handleOnChange}/>
                <Button type={"primary"} onClick={handleSendMessage}>
                    <PaperPlaneTilt size={18}/>
                </Button>
            </div>
        </section>
    );
}

export default MainConversation;