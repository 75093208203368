import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import './ImagesForm.scss';
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { getBase64 } from "../../../../../common/utils/fileUtils";

function ImagesForm({ setThumbnailUrl, isUpdateMode }) {
    // eslint-disable-next-line
    const [previewOpen, setPreviewOpen] = useState(false);
    // eslint-disable-next-line
    const [previewImage, setPreviewImage] = useState("");
    // eslint-disable-next-line
    const [previewTitle, setPreviewTitle] = useState("");
    const [imageUrl, setImageUrl] = useState();
    const [loading, setLoading] = useState(false);
    const { event } = useSelector((state) => state.event);


    useEffect(() => {
        if (isUpdateMode) {
            setImageUrl(event?.thumbnailUrl)
        }
        // eslint-disable-next-line
    }, [event]);

    // eslint-disable-next-line
    const handleCancel = () => setPreviewOpen(false);

    // eslint-disable-next-line
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const handleChange = async (info) => {
        setLoading(true)
        getBase64(info.file.originFileObj).then((res) => {
                if (res) {
                    setThumbnailUrl(info?.file?.originFileObj);
                    setImageUrl(info.file.url || res);
                    setLoading(false);
                }
            }
        )
    };


    const customRequest = async ({ file, onSuccess, onError }) => {
        try {
            setThumbnailUrl(file);
            onSuccess();
            toast.success("Image uploaded successfully!");
        } catch (error) {
            console.error("Error uploading image:", error);
            onError();
            toast.error("Error uploading image");
        }
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div
                style={{ marginTop: 8 }}
            >
                <p>Upload event thumbnail</p>
                <p>(Recommend ratio is 16:9)</p>
                <p>Accepted .png/.jpg/.jpeg file formats</p>
            </div>
        </button>
    );
    return (
        <div className="image-form-container">
            <Upload
                listType="picture-card"
                name="avatar"
                multiple={false}
                showUploadList={false}
                customRequest={customRequest}
                onChange={handleChange}
            >
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: '100%' }}
                        className={"banner-image"}
                    />
                ) : (
                    uploadButton
                )}
            </Upload>
        </div>
    );
}

export default ImagesForm;
