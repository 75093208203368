import { useJsApiLoader } from "@react-google-maps/api";

const libraries = ["places"];
const useGoogleMapsLoader = (apiKey) => {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: apiKey,
        libraries,
    });
    
    return { isLoaded, loadError };
};

export default useGoogleMapsLoader;