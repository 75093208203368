import axiosInstance from "../api";

const mediaService = {
    uploadMedia: async (media) => {
        try {
            const formData = new FormData();
            formData.append("image", media);
            return await axiosInstance.post(`/api/v1/host/uploadMedia`, formData, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "multipart/form-data",
                },
            });
        } catch (error) {
            console.error("Error upload media:", error);
            throw error;
        }
    },
    uploadMultipleMedia: async (payload) => {
        try {
            const formData = new FormData();
            payload.image.forEach(item => formData.append("image[]", item));
            formData.append("eventId", payload.eventId);
            return await axiosInstance.post(`/api/v1/host/uploadEventMedia`, formData, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "multipart/form-data",
                },
            });
        } catch (error) {
            console.error("Error upload media:", error);
            throw error;
        }
    },
};

export default mediaService;