import { CaretRight } from "@phosphor-icons/react";
import { Button, DatePicker, Form, Input, Radio } from "antd";
import dayjs from 'dayjs';
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./ProfileInfo.scss";
import { toast } from "sonner";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { Gender } from "../../../../../common/enums/Gender";
import { updateUserProfile } from "../../../../../common/redux/actions/accountAction";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import { capitalizeFirstLetter } from "../../../../../common/utils/stringUtils";
import SelectProvinceDistrict from "../../../../modules/components/SelectProvinceDistrict/SelectProvinceDistrict";

const ProfileInfo = ({ account, isOnboarding, setPath }) => {
    const [loadingSubmitBtn, setLoadingSubmitBtn] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onFinish = (values) => {
        setLoadingSubmitBtn(true);
        const handleUpdateProfile = () => {
            const requests = {
                username: account?.username,
                firstName: values?.first_name,
                lastName: values?.last_name,
                gender: values?.gender,
                phone: values?.phone,
                birthday: values?.birthday ? formatDateRange(values?.birthday, DateTimePattern.COMPLETED_DATE_TIME) : "",
                locationRequest: {
                    address: values?.address,
                    province: values?.province,
                    district: values?.district,
                    latitude: 0,
                    longitude: 0
                }
            };
            dispatch(updateUserProfile(requests))
                .unwrap()
                .then((response) => {
                    toast.success("Successfully update profile!");
                })
                .catch((error) => {
                    toast.error("Fail to update profile!");
                })
                .finally(() => {
                    setLoadingSubmitBtn(false);
                    if (isOnboarding) {
                        setPath("user-preference");
                    }
                });
        }

        handleUpdateProfile();
    };

    return (
        <div className="profile-info-container">
            <Form
                className="profile-info-form"
                form={form}
                onFinish={onFinish}
                layout="vertical"
                variant={isOnboarding ? "outlined" : "filled"}
                initialValues={{
                    'first_name': account?.firstName,
                    'last_name': account?.lastName,
                    'gender': account?.gender,
                    'birthday': account?.birthday ? dayjs(account?.birthday?.toString()) : "",
                    'phone': account?.phone,
                    'address': account?.locationModel?.address,
                    'province': account?.locationModel?.province,
                    'district': account?.locationModel?.district
                }}
            >
                <div className="profile-info-fullname">
                    <Form.Item name="first_name" label="First Name">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="last_name" label="Last Name">
                        <Input/>
                    </Form.Item>
                </div>

                <Form.Item name="gender" label="Gender">
                    <Radio.Group size="large">
                        <Radio value={Gender.MALE}>{capitalizeFirstLetter(Gender.MALE)}</Radio>
                        <Radio value={Gender.FEMALE}>{capitalizeFirstLetter(Gender.FEMALE)}</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="birthday" label="Birthday">
                    <DatePicker
                        className="info-form-birthday-date-picker"
                        placement="bottomRight"
                        format={DateTimePattern.BASIC_I8N_DATE_TIME}
                    />
                </Form.Item>

                <Form.Item name="phone" label="Phone">
                    <Input placeholder="+84 234 567 890"/>
                </Form.Item>

                <Form.Item name="address" label="Address">
                    <Input placeholder="Your address..."/>
                </Form.Item>

                <SelectProvinceDistrict
                    form={form}
                    currentLocation={account?.locationModel}
                />

                <Form.Item>
                    {
                        isOnboarding
                            ? <div className="profile-info-next-button">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={loadingSubmitBtn}
                                >
                                    Next step <CaretRight/>
                                </Button>
                            </div>
                            : <Button
                                htmlType="submit"
                                type="primary"
                                loading={loadingSubmitBtn}
                            >
                                Update profile
                            </Button>
                    }
                </Form.Item>
            </Form>
        </div>
    );
};

export default ProfileInfo;
