import {
    Airplane,
    BookBookmark,
    Calendar,
    Car,
    Confetti,
    Crosshair,
    GameController,
    HandHeart,
    Heart,
    Laptop,
    Leaf,
    MusicNotesSimple,
    PaintBrush, Panorama,
    Suitcase,
    TennisBall,
    Users
} from "@phosphor-icons/react";

export const categoryData = [
    {
        "id": 1,
        "title": "Art & Culture",
        "icon": <PaintBrush weight="fill"/>,
    },
    {
        "id": 2,
        "title": "Career",
        "icon": <Crosshair weight="fill"/>
    },

    {
        "id": 3,
        "title": "Business",
        "icon": <Suitcase weight="fill"/>,
    },
    {
        "id": 4,
        "title": "Game",
        "icon": <GameController weight="fill"/>,
    },
    {
        "id": 5,
        "title": "Health",
        "icon": <Heart weight="fill"/>,
    },
    {
        "id": 6,
        "title": "Technology",
        "icon": <Laptop weight="fill"/>,
    },
    {
        "id": 7,
        "title": "Travel",
        "icon": <Airplane weight="fill"/>,
    },
    {
        "id": 8,
        "title": "Cars",
        "icon": <Car weight="fill"/>,
    },
    {
        "id": 9,
        "title": "Daily life",
        "icon": <Calendar weight="fill"/>,
    },
    {
        "id": 10,
        "title": "Sport",
        "icon": <TennisBall weight="fill"/>,
    },
    {
        "id": 11,
        "title": "Education",
        "icon": <BookBookmark weight="fill"/>
    },
    {
        "id": 12,
        "title": "Music",
        "icon": <MusicNotesSimple weight="fill"/>
    },
    {
        "id": 13,
        "title": "Social activities",
        "icon": <HandHeart weight="fill"/>,
    },
    {
        "id": 14,
        "title": "Community",
        "icon": <Users weight="fill"/>
    },
    {
        "id": 15,
        "title": "Environment",
        "icon": <Leaf weight="fill"/>
    },
    {
        "id": 16,
        "title": "Dance",
        "icon": <Confetti weight="fill"/>
    },
    {
        "id": 17,
        "title": "Exhibition",
        "icon": <Panorama weight="fill"/>
    },
]