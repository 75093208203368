import { Alert, Button } from "antd";
import React, { useEffect, useState } from "react";
import "./OrganizationHostOnlineEvent.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MeetingProviders } from "../../../common/enums/MeetingProviders";
import { getMeetingRoomForAttendee } from "../../../common/redux/actions/meetingAction";
import eventureLogo from "../../assets/icons/logo.svg";
import defaultLogo from "../../assets/images/default_avatar.jpg";
import googleMeetLogo from "../../assets/images/gg_meet.png";
import zoomLogo from "../../assets/images/zoom.png";
import { DevicePreview } from "./partials/DevicePreview/DevicePreview";
import HostJoinEvent from "./partials/HostJoinEvent/HostJoinEvent";

const OrganizationHostOnlineEvent = () => {
    const { eventId } = useParams();
    const [videoTrack, setVideoTrack] = useState(null);
    const dispatch = useDispatch();
    const { attendeeMeetingRoom } = useSelector((state) => state.meeting);
    const [providerLogo, setProviderLogo] = useState();

    useEffect(() => {
        if (eventId) {
            dispatch(getMeetingRoomForAttendee({ eventId: eventId }));
        }
    }, [eventId, dispatch]);

    useEffect(() => {
        if (attendeeMeetingRoom) {
            if (attendeeMeetingRoom.meetingProvider === MeetingProviders.EVENTURE) {
                setProviderLogo(eventureLogo);
            } else if (attendeeMeetingRoom.meetingProvider === MeetingProviders.ZOOM) {
                setProviderLogo(zoomLogo);
            } else if (attendeeMeetingRoom.meetingProvider === MeetingProviders.GOOGLE_MEET) {
                setProviderLogo(googleMeetLogo);
            } else {
                setProviderLogo(defaultLogo);
            }
        }
    }, [attendeeMeetingRoom]);

    const handleNavigateExternalProvider = () => {
        window.open(attendeeMeetingRoom?.locator, '_blank');
    }

    return (
        <div className="host-online-event-container">
            <div className="host-online-event-title">Host online event</div>
            <div className="host-online-event-content">
                {
                    attendeeMeetingRoom
                        ? (
                            attendeeMeetingRoom?.meetingProvider !== MeetingProviders.EVENTURE
                                ? <div className="host-online-event-external">
                                    <div className="host-online-event-head">
                                        <div className="host-online-event-external-message">
                                            You have choose another provider to host your online event.
                                        </div>
                                        <Alert
                                            closable={true}
                                            type={"error"}
                                            message={"If you choose to use an external provider for event hosting, we cannot be held responsible for attendee admission issues."}
                                        />
                                    </div>

                                    <div className="host-event-external-content">
                                        <div className="host-provider-logo">
                                            <img src={providerLogo} alt=""/>
                                        </div>
                                        <div className="host-event-join">
                                            <div className="host-event-locator">
                                                {attendeeMeetingRoom.locator}
                                            </div>
                                            <Button type="primary" onClick={handleNavigateExternalProvider}>
                                                Start event
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                : <div className="host-online-event-by-eventure">
                                    <DevicePreview/>
                                    <div className="host-online-event-detail">
                                        <div className="host-online-event-detail-content">
                                            <HostJoinEvent
                                                videoTrack={videoTrack}
                                                setVideoTrack={setVideoTrack}
                                                meetingRoom={attendeeMeetingRoom}
                                            />
                                        </div>
                                    </div>
                                </div>
                        )
                        : <div>This event hasn't had any meeting room!</div>
                }
            </div>
        </div>
    );
}


export default OrganizationHostOnlineEvent;