import { createAsyncThunk } from "@reduxjs/toolkit";
import { FETCH_MESSAGES_FOR_THREAD, SEND_MESSAGE, SUBSCRIBE_TO_MESSAGES } from "../types/message";
import supabaseService from "../../supabase/supabaseService";


export const fetchMessagesForThread = createAsyncThunk(
    FETCH_MESSAGES_FOR_THREAD,
    async (threadId, { rejectWithValue }) => {
        try {
            const {data, error} =  await supabaseService.fetchMessagesForThread(threadId);
            if(data) {
                return data;
            }
            return error;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const sendMessage = createAsyncThunk(
    SEND_MESSAGE,
    async (messageData, { rejectWithValue }) => {
        try {
            const {data, error} = await supabaseService.sendMessage(messageData);
            if (data) {
                return data
            }
            return error;
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const subscribeToMessages = createAsyncThunk(
    SUBSCRIBE_TO_MESSAGES,
    async (data, { rejectWithValue }) => {
        try {
            return await supabaseService.sendMessage(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);
