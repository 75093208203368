import React, { useLayoutEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import './PersonalPreference.scss';
import ProfilePreference from "../../components/ProfilePreference/ProfilePreference";

const PersonalPreference = () => {
    const { setActiveItem } = useOutletContext();

    useLayoutEffect(() => {
        document.title = "Settings - Interests";
        setActiveItem("preference");
    });

    return (
        <div className="personal-preference-container">
            <div className='user-interest-head'>
                <p className='user-interest-title'>
                    Choose your interests
                </p>
                <p className='user-interest-desc'>
                    Get personalized event recommendation
                </p>
            </div>
            <ProfilePreference/>
        </div>
    );
}

export default PersonalPreference;