import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import "./ManageEventLayout.scss"
import EventSideBar from "../../../pages/OrganizationCreateEvent/partials/EventSideBar";
import { getEventByIdOrg } from "../../../../common/redux/actions/eventActions";

function ManageEventLayout() {
    const { eventId } = useParams();
    const { event } = useSelector((state) => state.event);
    const { organization } = useSelector((state) => state.organization);
    const orgId = organization?.organizationId;
    const dispatch = useDispatch()
    
    useEffect(() => {
        const fetchEventOrg = async () => {
            try {
                const params = {
                    eventId: eventId,
                    organizationId: orgId
                };
                await dispatch(getEventByIdOrg(params));
                
            } catch (error) {
                console.error(error)
            }
        }
        
        fetchEventOrg();
    }, [eventId, dispatch, orgId]);

    return (
        <div className="manage-event-layout">
            <div className="side-nav">
                <EventSideBar event={event} eventId={eventId}/>
            </div>
            <div className="main-event-layout">
                <Outlet/>
            </div>
        </div>
    );
}

export default ManageEventLayout;
