import React from "react";
import "./ReservationOrderSection.scss";

const ReservationOrderSection = ({ sectionTitle, sectionItems }) => {
    return (
        <div className="ticket-order-section-container">
            <div className="ticket-section-title">{sectionTitle}</div>
            <div className="ticket-section-content">
                {
                    sectionItems.map((item, index) => (
                        <div key={index} className="ticket-section-item">
                            <div className="ticket-section-item-label">{item.label}</div>
                            <div className="ticket-section-item-value">
                                {item.value}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>

    );
};

export default ReservationOrderSection;
