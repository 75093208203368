import { SearchOutlined } from '@ant-design/icons';
import { CaretDown } from "@phosphor-icons/react";
import { Button, Input, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { SearchKeys } from "../../../../common/enums/SearchKeys";
import useAddress from "../../../../common/hooks/useAddress";
import { setSearchEventLocation, setSearchQuery } from "../../../../common/redux/slice/searchSlice";
import "./SearchInput.scss";

const SearchInput = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [provinces, setProvinces] = useState([]);
    const [searchMainText, setSearchMainText] = useState("");
    const [searchLocationText, setSearchLocationText] = useState("");
    const { address } = useAddress();

    useEffect(() => {
        if (address?.length > 0) {
            const provincesData = address.map((province) => ({
                id: province.Id,
                name: province.Name,
                label: province.Name,
            }));
            setProvinces(provincesData);
        }
    }, [address]);

    // display value on input fields
    useEffect(() => {
        if (searchParams) {
            const searchQuery = searchParams.get(SearchKeys.SEARCH_QUERY);
            const searchLocation = searchParams.get(SearchKeys.SEARCH_EVENT_LOCATION);
            setSearchMainText(searchQuery);
            setSearchLocationText(searchLocation);
        }
    }, [searchParams]);

    const handleSearch = () => {
        const searchText = (searchMainText || "").trim();
        const searchLocation = (searchLocationText || "").trim();
        if (searchText.length === 0 && searchLocation.length === 0) return;

        let params = {};
        if (searchText.length > 0) {
            params[SearchKeys.SEARCH_QUERY] = searchText;
            dispatch(setSearchQuery(searchText));
        }
        if (searchLocation.length > 0) {
            params[SearchKeys.SEARCH_EVENT_LOCATION] = searchLocation;
            dispatch(setSearchEventLocation(searchLocation));
        }
        setSearchParams(params);

        const searchProp = createSearchParams(params);
        navigate({ pathname: "/find", search: `?${searchProp}` });
    };

    const handleInputMain = (e) => {
        setSearchMainText(e.target.value);
    }

    const handleInputLocation = (value) => {
        setSearchLocationText(value);
    }

    return (
        <Space.Compact className="header-search-input" block>
            <Input
                className="header-search-main"
                placeholder="Search events, organizers"
                allowClear
                size="large"
                value={searchMainText}
                onChange={handleInputMain}
                onPressEnter={handleSearch}
            />
            <Select
                className="header-search-location"
                showSearch
                allowClear
                size="large"
                placeholder="City/Province"
                onChange={(value) => handleInputLocation(value)}
                suffixIcon={<CaretDown weight="bold"/>}
                value={searchLocationText}
            >
                {
                    provinces.map((province) => (
                        <Select.Option key={province.id} value={province.name}>
                            {province.label}
                        </Select.Option>
                    ))
                }
            </Select>
            <Button
                className="header-search-button"
                type="primary"
                size="large"
                icon={<SearchOutlined/>}
                onClick={handleSearch}
            />
        </Space.Compact>
    );
};

export default SearchInput;
