import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DateTimePattern } from "../enums/DateTimePattern";

dayjs.extend(customParseFormat);

const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};

export const disabledDate = (current) => {
    return current && current < new Date().setHours(0, 0, 0, 0);
};

export const disabledTime = (date, type) => {
    const current = new Date();
    if (type === 'start' && current.toDateString() === new Date(date).toDateString()) {
        return {
            disabledHours: () => {
                const currentHour = current.getHours();
                const disabledHours = [];
                for (let i = 0; i < currentHour; i++) {
                    disabledHours.push(i);
                }
                return disabledHours;
            },
            disabledMinutes: () => {
                const currentHour = current.getHours();
                const currentMinute = current.getMinutes();
                const disabledMinutes = [];
                if (currentHour === new Date(date).getHours()) {
                    for (let i = 0; i < currentMinute; i++) {
                        disabledMinutes.push(i);
                    }
                }
                return disabledMinutes;
            },
        };
    }
    return {};

};

export const disabledRangeTime = (_, type) => {
    if (type === 'start') {
        return {
            disabledHours: () => range(0, 60).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }
    return {
        disabledHours: () => range(0, 60).splice(20, 4),
        disabledMinutes: () => range(0, 31),
        disabledSeconds: () => [55, 56],
    };
};

export const formatDateRange = (dateString, format) => {
    return format ? dayjs(dateString).format(format) : dayjs(dateString);
};

export const getDateTimeObject = (dateString, format) => {
    if (dateString === "") return dateString;
    return format ? dayjs(dateString, format) : dayjs(dateString);
};

export const getDateFromRange = (dateRange) => {
    const today = new dayjs();
    let startDate = today, endDate;
    switch (dateRange) {
        case "today":
            endDate = today.add(1, "day");
            break;
        case "tomorrow":
            startDate = today.add(1, "day");
            endDate = today.add(2, "day");
            break;
        case "this_week":
            endDate = today.endOf("week").add(1, "day");
            break;
        case "this_month":
            endDate = today.endOf("month");
            break;
        default:
            break;
    }
    return {
        startDate: formatDateRange(startDate, DateTimePattern.COMPLETED_DATE),
        endDate: formatDateRange(endDate, DateTimePattern.COMPLETED_DATE),
    };
}

export const formatTimeStamp = (timestamp) => {
    const date = new Date(timestamp);
    const currentDate = new Date();
    const diffTime = Math.floor(currentDate - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `Today at ${hours}:${minutes}`;
    } else {
        const _diffDays = Math.max(diffDays, 1);
        return `${_diffDays} days ago`;
    }
}

export const getRemainingTimeMessage = (times) => {
    const { days, hours, minutes } = times;
    let message = "";

    if (days > 0) message += `${days}d `;
    if (hours > 0) message += `${hours}h `;
    if (minutes > 0) message += `${minutes}m`;

    return message;
}

export const getRemainingTime = (timestamp) => {
    const now = Date.now();
    const eventTime = new Date(timestamp).getTime();
    const difference = eventTime - now;

    // Calculate remaining days, hours, and minutes
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    // Format the message based on remaining time
    return { days, hours, minutes };
}

export const convertTimeframeToSeconds = (timeframe) => {
    const timeParts = timeframe.split(":");

    // Check for valid format (HH:MM:SS)
    if (timeParts.length !== 3) {
        throw new Error("Invalid timeframe format. Expected HH:MM:SS");
    }

    // Convert each part to number and multiply by corresponding factors (hours * 3600, minutes * 60)
    return timeParts.reduce((totalSeconds, part, index) => {
        const value = parseInt(part, 10); // Parse string to integer (base 10)
        const factor = [3600, 60, 1][index]; // Factors for hours, minutes, seconds
        return totalSeconds + value * factor;
    }, 0);
}
