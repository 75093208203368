import { createSlice } from "@reduxjs/toolkit";
import { VideosdkModel } from "../../models/videosdkModel.model";


const videosdkSlice = createSlice({
    name: 'videosdk',
    initialState: VideosdkModel,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setParticipantName: (state, action) => {
            state.participantName = action.payload;
        },
        setMeetingId: (state, action) => {
            state.meetingId = action.payload;
        },
        setSessionId: (state, action) => {
            state.sessionId = action.payload;
        },
        setMeetingMode: (state, action) => {
            state.meetingMode = action.payload;
        },
        setMicOn: (state, action) => {
            state.micOn = action.payload;
        },
        setSelectedMic: (state, action) => {
            state.selectedMic = action.payload;
        },
        setMicDeviceId: (state, action) => {
            state.micDeviceId = action.payload;
        },
        setWebcamOn: (state, action) => {
            state.webcamOn = action.payload;
        },
        setSelectedWebcam: (state, action) => {
            state.selectedWebcam = action.payload;
        },
        setWebcamDeviceId: (state, action) => {
            state.webcamDeviceId = action.payload;
        },
        setIsMeetingStarted: (state, action) => {
            state.isMeetingStarted = action.payload;
        },
        setIsMeetingLeft: (state, action) => {
            state.isMeetingLeft = action.payload;
        },
    },
    extraReducers: (builder) => {
    },
});
export const {
    setToken,
    setParticipantName,
    setMeetingId,
    setSessionId,
    setMeetingMode,
    setMicOn,
    setSelectedMic,
    setMicDeviceId,
    setWebcamOn,
    setSelectedWebcam,
    setWebcamDeviceId,
    setIsMeetingStarted,
    setIsMeetingLeft,
} = videosdkSlice.actions;

export default videosdkSlice.reducer;
