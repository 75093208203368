import { createSlice } from "@reduxjs/toolkit";
import { AsyncModel } from "../../models/asyncModel.model";
import { getRelatedEvents } from "../actions/asyncAction";

const asyncSlice = createSlice({
    name: "async",
    initialState: AsyncModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRelatedEvents.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getRelatedEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.relatedEvents = action.payload?.eventDtos;
                state.success = true;
            })
            .addCase(getRelatedEvents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.relatedEvents = [];
            })
    }
})

export default asyncSlice.reducer;
