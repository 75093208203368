import { Button, Input } from 'antd';
import React from "react";
import "./HostJoinEvent.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    setIsMeetingLeft,
    setIsMeetingStarted,
    setMeetingId,
    setParticipantName,
    setToken
} from "../../../../../common/redux/slice/videosdkSlice";
import { getToken, validateMeeting } from "../../../../../common/videosdk/videosdkServices";

const HostJoinEvent = ({ videoTrack, setVideoTrack, meetingRoom }) => {
    const dispatch = useDispatch();
    const { participantName } = useSelector((state) => state.videosdk);
    const navigate = useNavigate();

    const handleClickJoin = async (id) => {
        const token = await getToken();
        const valid = await validateMeeting({ roomId: id, token });

        if (valid) {
            dispatch(setToken(token));
            dispatch(setMeetingId(id));
            if (videoTrack) {
                videoTrack.stop();
                setVideoTrack(null);
            }
            dispatch(setIsMeetingStarted(true));
            dispatch(setIsMeetingLeft(false));
            dispatch(setParticipantName(participantName));
            navigate(`/host-live-stream/${meetingRoom?.eventId}/rooms/${id}`);
        } else alert("Invalid Meeting Id");
    }

    const handleJoinStreamingRoom = () => {
        handleClickJoin(meetingRoom?.locator);
    }

    const handleChangeParticipantName = (e) => {
        dispatch(setParticipantName(e.target.value));
    }

    return (
        <div className="host-join-event-container">
            <Input
                placeholder="Enter display name"
                value={participantName}
                onChange={handleChangeParticipantName}
            />
            {meetingRoom && <Input
                placeholder="Room"
                value={meetingRoom?.locator}
            />}
            <Button
                type="primary"
                disabled={participantName.length < 3}
                onClick={handleJoinStreamingRoom}
                block={true}
            >
                Start hosting online event
            </Button>
        </div>
    );
}

export default HostJoinEvent;