import { Bank, CreditCard, Tag, Wallet, X } from "@phosphor-icons/react";
import { Button, Input, Space } from 'antd';
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "sonner";
import { v4 as uuidv4 } from 'uuid';
import { CART } from "../../../../../common/constants/LocalStorageConstants";
import { PaymentMethod } from "../../../../../common/enums/PaymentMethod";
import { getMomoQrCode } from "../../../../../common/redux/actions/paymentAction";
import { getVouchersByEventIdForAttendee, verifyVoucher } from "../../../../../common/redux/actions/voucherAction";
import { setCartTotal, setCartVoucher } from "../../../../../common/redux/slice/cartSlice";
import momo from "../../../../assets/images/momo.png";
import vnpay from "../../../../assets/images/vnpay.png";
import CancelReservationButton from "../../blocks/CancelReservationButton/CancelReservationButton";
import TicketCart from "../../components/TicketCart/TicketCart";
import "./TicketPayment.scss";
import VoucherItem from "../../components/VoucherItem/VoucherItem";
import { VoucherRuleCondition } from "../../../../../common/enums/VoucherRuleCondition";

const TicketPayment = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const { setCurrentStep, event, reservationId } = useOutletContext();
    const [voucher, setVoucher] = useState({});
    const [userVouchers, setUserVouchers] = useState([]);
    const [inputVoucher, setInputVoucher] = useState("");
    const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.MOMO);
    const [loadingVoucher, setLoadingVoucher] = useState(false);
    const [loadingPaymentButton, setLoadingPaymentButton] = useState(false);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { vouchers } = useSelector((state) => state.voucher);

    const {
        cartReservationId,
        cartEventId,
        cartBuyer,
        cartTickets,
        cartVoucher,
        cartSubtotal,
        cartTotal,
    } = useSelector((state) => state.cart);

    useLayoutEffect(() => {
        setCurrentStep(2);
    }, [setCurrentStep]);

    useEffect(() => {
        const params = { eventId: eventId };
        dispatch(getVouchersByEventIdForAttendee(params));
    }, [eventId, dispatch]);

    useEffect(() => {
        setUserVouchers(vouchers);
    }, [vouchers]);

    useEffect(() => {
        if (cartVoucher) {
            setVoucher(cartVoucher);
        }
    }, [cartVoucher]);

    const handleSelectVoucher = (selectedVoucher) => {
        handleApplyVoucher(selectedVoucher?.code);
    }

    const handlePaymentByMoMo = () => {
        setLoadingPaymentButton(true);
        const username = user?.id;
        if (username) {
            const ticketItems = cartTickets.map((item) => ({
                ticketId: item?.id,
                quantity: item?.quantity,
                price: item?.price
            }));
            const request = {
                orderId: uuidv4(reservationId),
                eventId: event?.eventId,
                username: username,
                voucherCode: voucher?.code,
                items: ticketItems,
                userInfo: {
                    firstName: cartBuyer?.firstName,
                    lastName: cartBuyer?.lastName,
                    phone: cartBuyer?.phone,
                    email: cartBuyer?.email
                }
            };
            dispatch(getMomoQrCode(request))
                .unwrap()
                .then((momoQrCodeUrl) => {
                    window.location.href = momoQrCodeUrl;
                })
                .catch((rejectedValue) => {
                    toast.error("Error make payment via MoMO! Please try again.");
                });
        }
        setLoadingPaymentButton(false);
    }

    const handleInputVoucher = (e) => {
        const inputVoucherVal = e.target.value;
        setInputVoucher(inputVoucherVal);
    };

    const handleSelectPaymentMethod = (value) => {
        setPaymentMethod(value);
    };

    const handleApplyVoucher = (selectedVoucher) => {
        setLoadingVoucher(true);
        const voucherCode = inputVoucher || selectedVoucher;
        if (voucherCode) {
            const params = { voucherCode: voucherCode, eventId: event?.eventId };
            dispatch(verifyVoucher(params))
                .unwrap()
                .then((data) => {
                    const maxDiscountValue = data?.maxDiscountValue;
                    let valueOff = data?.amountOff || (data?.percentOff * cartSubtotal / 100);
                    if (data?.percentOff && maxDiscountValue > 0) {
                        valueOff = Math.min(valueOff, maxDiscountValue);
                    }
                    
                    const totalValue = cartSubtotal - valueOff;
                    const currentVoucher = { ...data, valueOff: valueOff};

                    if (data?.conditionType === VoucherRuleCondition.MINIMUM_NUMBER && cartTickets?.length < data?.conditionValue) {
                        toast.error(`You cart must have at least ${data?.conditionValue} tickets to use this voucher.`);
                        setLoadingVoucher(false);
                        return;
                    }

                    if (data?.conditionType === VoucherRuleCondition.MINIMUM_AMOUNT && cartSubtotal < data?.conditionValue) {
                        toast.error(`Your cart must have at least ${data?.conditionValue.toLocaleString()}VND total to use this voucher.`);
                        setLoadingVoucher(false);
                        return;
                    }

                    dispatch(setCartVoucher(currentVoucher));
                    dispatch(setCartTotal(totalValue));

                    setVoucher(currentVoucher);
                    setInputVoucher("");

                    localStorage.setItem(CART, JSON.stringify({
                        reservationId: cartReservationId,
                        eventId: cartEventId,
                        buyer: cartBuyer,
                        tickets: cartTickets,
                        voucher: currentVoucher,
                        subtotal: cartSubtotal,
                        total: totalValue
                    }));

                    toast.success("Voucher applied successfully!");
                })
                .catch((error) => {
                    toast.error("This voucher has been expired or invalid!");
                });
        }
        setLoadingVoucher(false);
    };

    const isVoucherInvalid = (voucherItem) => {
        const { conditionType, conditionValue } = voucherItem || {};
        const numberBuyTicket = cartTickets?.filter(cartTicket => cartTicket.quantity !== 0).length;

        switch (conditionType) {
            case VoucherRuleCondition.NONE:
                return false;
            case VoucherRuleCondition.MINIMUM_NUMBER:
                return numberBuyTicket < conditionValue;
            case VoucherRuleCondition.MINIMUM_AMOUNT:
                return cartSubtotal < conditionValue;
            default:
                return false;
        }
    };

    const handleEditOrder = () => {
        navigate("../order-info", { relative: "path" });
    }

    const handleRemoveVoucher = () => {
        dispatch(setCartVoucher({}));
        dispatch(setCartTotal(cartSubtotal));
        setVoucher({});

        localStorage.setItem(CART, JSON.stringify({
            reservationId: cartReservationId,
            eventId: cartEventId,
            buyer: cartBuyer,
            tickets: cartTickets,
            voucher: {},
            subtotal: cartSubtotal,
            total: cartTotal
        }));
        toast.info("Voucher application cancelled!")
    };

    return (
        <div className="ticket-payment-container">
            <div className="ticket-payment-main">
                <div className="ticket-payment-section ticket-payment-method">
                    <div className="ticket-payment-section-title">
                        Payment method
                    </div>
                    <div className="ticket-payment-method-select">
                        <Button
                            onClick={() => handleSelectPaymentMethod(PaymentMethod.MOMO)}
                            type={paymentMethod === PaymentMethod.MOMO ? "primary" : "default"}
                            className="ticket-payment-method-btn"
                        >
                            <img src={momo} alt="momo"/> MoMo
                        </Button>
                        <Button
                            onClick={() => handleSelectPaymentMethod(PaymentMethod.VNPAY)}
                            type={paymentMethod === PaymentMethod.VNPAY ? "primary" : "default"}
                            className="ticket-payment-method-btn"
                            disabled={true}
                        >
                            <img src={vnpay} alt="vnpay"/> VNPay
                        </Button>
                        <Button
                            onClick={() => handleSelectPaymentMethod(PaymentMethod.CARD)}
                            type={paymentMethod === PaymentMethod.CARD ? "primary" : "default"}
                            icon={<CreditCard/>}
                            className="ticket-payment-method-btn"
                            disabled={true}
                        >
                            Credit/Debit card
                        </Button>
                        <Button
                            onClick={() => handleSelectPaymentMethod(PaymentMethod.INTERNET_BANK)}
                            type={paymentMethod === PaymentMethod.INTERNET_BANK ? "primary" : "default"}
                            icon={<Bank/>}
                            className="ticket-payment-method-btn"
                            disabled={true}
                        >
                            Internet banking
                        </Button>
                    </div>
                </div>
                <div className="ticket-payment-section ticket-payment-note">
                    <p className="highlight">Refund policy:</p>
                    Eventure cannot refund event fees paid through VNPay. <br/>
                    To request a refund, please contact the event organizer or VNPay.<br/>
                    Please refer to our Payment Policies for additional information.
                </div>
                <div className="ticket-payment-section ticket-payment-voucher">
                    <div className="ticket-payment-section-title">
                        Promotion code
                    </div>
                    <div className="ticket-payment-voucher-input">
                        <Space.Compact block={true}>
                            <Input
                                placeholder="Enter code"
                                allowClear={true}
                                value={inputVoucher}
                                onChange={handleInputVoucher}
                                onPressEnter={handleApplyVoucher}
                            />
                            <Button
                                type="primary"
                                onClick={handleApplyVoucher}
                                loading={loadingVoucher}
                                disabled={inputVoucher.length === 0}
                            >
                                Apply
                            </Button>
                        </Space.Compact>
                    </div>
                    {
                        userVouchers?.length > 0 && (
                            <div className="ticket-payment-voucher-list">
                                <div className="ticket-payment-voucher-list-title">Select a voucher</div>
                                <div className="ticket-payment-voucher-list-content">
                                    {
                                        userVouchers.map((voucherItem) => (
                                            <VoucherItem key={voucherItem.id}
                                                         handleSelectVoucher={handleSelectVoucher}
                                                         selectedVoucher={voucher}
                                                         voucher={voucherItem}
                                                         invalidVoucher={isVoucherInvalid(voucherItem)}/>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                        Object.keys(voucher).length > 0 && <div className="ticket-payment-applied-voucher">
                            <p className="ticket-payment-applied-voucher-title">Applied promotion</p>
                            <div className="ticket-payment-applied-voucher-list">
                                <div key={voucher?.id} className="ticket-payment-applied-voucher-item">
                                    <Tag className="applied-voucher-icon" weight="fill"/>
                                    <div className="applied-voucher-content">
                                        <div className="applied-voucher-code">{voucher?.code.toUpperCase()}</div>
                                        <div className="applied-voucher-value">
                                            (-{voucher?.valueOff?.toLocaleString()} VND)
                                        </div>
                                        <Button size="small"
                                                type="text"
                                                className="applied-voucher-remove-btn"
                                                onClick={handleRemoveVoucher}
                                                icon={<X size={12} weight="bold"/>}>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </div>
            <div className="ticket-rsv-navigation">
                <div className="ticket-cart">
                    <TicketCart/>
                </div>
                <div className="ticket-cart-button">
                    <Button
                        className="ticket-cart-button-continue"
                        type="primary"
                        onClick={handlePaymentByMoMo}
                        block={true}
                        loading={loadingPaymentButton}
                        icon={<Wallet/>}
                    >
                        Pay with {paymentMethod}
                    </Button>
                    <Button
                        className="ticket-cart-button-edit-order"
                        block={true}
                        onClick={handleEditOrder}
                    >
                        Edit reservation
                    </Button>
                    {eventId && <CancelReservationButton eventId={eventId}/>}
                </div>
            </div>
        </div>
    );
};

export default TicketPayment;