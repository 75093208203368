import React from "react";
import "./UserInterest.scss";
import { useOutletContext } from "react-router-dom";
import ProfilePreference from "../../../Personal/components/ProfilePreference/ProfilePreference";

const UserInterest = () => {
    const { setPath } = useOutletContext();

    return (
        <div className="user-interest-container">
            <div className="user-interest-head">
                <p className="user-interest-desc">
                    Choose your interests to get personalized event recommendation
                </p>
            </div>
            <div className='user-interest-content'>
                <ProfilePreference isOnboarding={true} setPath={setPath}/>
            </div>
        </div>
    );
}

export default UserInterest;