import React from "react";
import successfulIcon from "../../../../assets/icons/successful.svg";
import "./PaymentSuccessful.scss";

const PaymentSuccessful = ({ paymentResult }) => {
    return (
        <div className="payment-successful-container">
            <div className="ticket-finish-message">
                <img src={successfulIcon} alt="done"/>
                <p className="message-primary">Thank you</p>
                <p className="message-secondary">Your reservation was successful!</p>
            </div>
            <div className="ticket-finish-purchase-info">
                <div className="ticket-finish-purchase-info-title">
                    Reservation information
                </div>
                <div className="ticket-finish-purchase-info-list">
                    {paymentResult?.fullname?.length > 0 && <div className="purchase-info-item">
                        <div className="purchase-info-label">Full name</div>
                        <div className="purchase-info-value"> {paymentResult?.fullname}</div>
                    </div>}
                    {paymentResult?.phone?.length > 0 && <div className="purchase-info-item">
                        <div className="purchase-info-label">Phone</div>
                        <div className="purchase-info-value">{paymentResult?.phone}</div>
                    </div>}
                    {paymentResult?.email?.length > 0 && <div className="purchase-info-item">
                        <div className="purchase-info-label">Email</div>
                        <div className="purchase-info-value">{paymentResult?.email}</div>
                    </div>}
                    <div className="purchase-info-item">
                        <div className="purchase-info-label">Total</div>
                        <div className="purchase-info-value">{paymentResult?.amount?.toLocaleString()} VND</div>
                    </div>
                </div>
            </div>
            <div className="ticket-finish-note">
                <p className="highlight">Notice that:</p>
                <p>E-ticket will be sent to your email after successfully reserving tickets.</p>
                <p>Use your purchased ticket code to check-in at the event.</p>
                <p>Safeguard your ticket information and QR code for your benefits.</p>
            </div>
        </div>
    );
};

export default PaymentSuccessful;