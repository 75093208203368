import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Sticker } from "@phosphor-icons/react";
import { Segmented } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import "./UserReservations.scss";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getReservationsByUsername } from "../../../../../common/redux/actions/accountAction";
import Loading from "../../../../modules/components/Loading/Loading";
import NoDataFound from "../../../../modules/components/NoDataFound";
import ReservationItem from "../../../../modules/components/ReservationItem/ReservationItem";

const reservationItems = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Upcoming',
        value: 'upcoming',
        icon: <CalendarOutlined/>,
    },
    {
        label: 'Past',
        value: 'past',
        icon: <ClockCircleOutlined/>,
    },
]

const UserReservations = () => {
    const { setActiveItem } = useOutletContext();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { reservations, loading } = useSelector((state) => state.account);
    const [filteredReservations, setFilterReservations] = useState([]);
    const [reservationOption, setReservationOption] = useState("all");

    useLayoutEffect(() => {
        document.title = "User - Reservations | Eventure";
        setActiveItem("reservations");
    });

    useEffect(() => {
        const username = user?.id;
        if (username) {
            dispatch(getReservationsByUsername({ username: username }));
        }
    }, [user, dispatch]);

    useEffect(() => {
        const currentDate = new Date();
        const filterReservations = reservations?.filter(reservation => {
            const reservationTime = new Date(reservation?.eventTime);
            switch (reservationOption) {
                case "upcoming":
                    return reservationTime > currentDate;
                case "past":
                    return reservationTime <= currentDate;
                default:
                    return true;
            }
        });
        setFilterReservations(filterReservations || []);
    }, [reservationOption, reservations]);

    return (
        <div className="user-rsv-container">
            <div className="user-rsv-head">Your reservations</div>
            <div className="user-rsv-content">
                <div className="user-rsv-list-header">
                    <div className="user-rsv-list-filter">
                        <Segmented
                            size="middle"
                            options={reservationItems}
                            onChange={setReservationOption}
                        />
                    </div>
                </div>
                <div className="user-rsv-list">
                    {
                        loading || !filteredReservations
                            ? <Loading/>
                            : (
                                (filteredReservations && filteredReservations.length > 0)
                                    ? filteredReservations.map((item, index) => (
                                        <ReservationItem key={index} reservation={item}/>
                                    ))
                                    : <NoDataFound
                                        icon={<Sticker size={60}/>}
                                        message="You don't have any reservations!"
                                    />
                            )
                    }
                </div>
            </div>
        </div>
    );
};

export default UserReservations;
