import axiosInstance from "../api";


const transactionService = {
    getTransactionsByOrganizationId : async (eventId) => {
        try {
            return await axiosInstance.get(`/api/v1/organization/getTransactionsByOrganizationId`, {params: eventId});
        } catch (e) {
            throw e;
        }
    }
}

export default transactionService