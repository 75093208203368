import { Constants, } from "@videosdk.live/react-sdk";
import React, { Fragment } from "react";
import { useMeetingAppContext } from "../../../../../common/videosdk/MeetingAppContextDef";
import ChatButton from "./ChatButton/ChatButton";
import HLSButton from "./HLSButton/HLSButton";
import LeaveButton from "./LeaveButton/LeaveButton";
import MeetingIdCopyButton from "./MeetingIdCopyButton/MeetingIdCopyButton";
import MicButton from "./MicButton/MicButton";
import ParticipantsButton from "./ParticipantsButton/ParticipantsButton";
import RecordingButton from "./RecordingButton/RecordingButton";
import ScreenShareButton from "./ScreenShareButton/ScreenShareButton";
import WebCamButton from "./WebCamButton/WebCamButton";
import "./ILSBottomBar.scss";

export function ILSBottomBar({ meetingMode}) {
    const { sideBarMode, setSideBarMode } = useMeetingAppContext();

    return <div className="ils-bottom-bar-container">
        <MeetingIdCopyButton/>
        <div className="ils-bottom-bar-main-buttons">
            {meetingMode === Constants.modes.CONFERENCE && (
                <>
                    <HLSButton/>
                    <MicButton/>
                    <WebCamButton/>
                    <ScreenShareButton/>
                    <RecordingButton/>
                </>
            )}
            <LeaveButton meetingMode={meetingMode}/>
        </div>
        <div className="ils-bottom-bar-extend-buttons">
            <ChatButton sideBarMode={sideBarMode} setSideBarMode={setSideBarMode}/>
            <ParticipantsButton
                meetingMode={meetingMode}
                sideBarMode={sideBarMode}
                setSideBarMode={setSideBarMode}
            />
        </div>
    </div>;
}
