import { CalendarBlank, ChartBar, Users, Wallet } from "@phosphor-icons/react";
import React, { useEffect, useLayoutEffect, useState } from 'react';
import "./OrganizationHome.scss";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationStatistic } from "../../../common/redux/actions/statisticAction";
import defaultThumbnail from "../../assets/images/default.jpg";
import defaultAvatar from "../../assets/images/default_avatar.jpg";
import OrgPeople from "./partials/OrgPeople/OrgPeople";
import RecentOrders from "./partials/RecentOrders/RecentOrders";

const orgStatisticItems = [
    {
        key: "totalEvents",
        icon: <CalendarBlank size={24} weight="fill"/>,
        title: "Events",
        value: 0,
    },
    {
        key: "totalFollower",
        icon: <Users size={24} weight="fill"/>,
        title: "Followers",
        value: 0,
    },
    {
        key: "netSale",
        icon: <ChartBar size={24} weight="fill"/>,
        title: "Net Sales (latest year)",
        value: 0,
    },
    {
        key: "acc_balance",
        icon: <Wallet size={24} weight="fill"/>,
        title: "Account Balance",
        value: 0,
    }
]

const OrganizationHome = () => {
    const [orgIndicators, setOrgIndicators] = useState();
    const { organizationStatistic } = useSelector((state) => state.statistic);
    const { organization } = useSelector((state) => state.organization);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        document.title = "Organization dashboard | Eventure";
    }, []);

    useEffect(() => {
        if (organization) {
            dispatch(getOrganizationStatistic({ organizationId: organization.organizationId }));
        }
    }, [organization, dispatch]);

    useEffect(() => {
        if (organizationStatistic) {
            const netSale = organizationStatistic.netSale;
            const orders = organizationStatistic.order.volume;
            let _netSale = `${netSale.toLocaleString()} VND`;
            let indicators = {
                totalEvents: organizationStatistic?.totalEvents,
                totalFollower: organizationStatistic?.totalFollower,
                netSale: _netSale,
                orders: orders,
            };
            orgStatisticItems.forEach((item) => {
                if (indicators.hasOwnProperty(item.key)) {
                    item.value = indicators[item.key];
                }
            });
            setOrgIndicators(orgStatisticItems);
        }
    }, [organizationStatistic]);

    return (
        <div className="org-home-container">
            <div className="org-home-head">
                <div className="org-home-head-message">
                    Organization Dashboard
                </div>
                <div className="org-home-head-content">
                    {organization && <div className="org-home-card org-home-profile-card">
                        <div className="org-home-profile-avatar">
                            <img src={organization?.avatarUrl || defaultAvatar}
                                 alt=""
                                 onError={(e) => e.target.src = defaultThumbnail}/>
                        </div>
                        <div className="org-home-profile-content">
                            <div className="org-home-profile-name">
                                {organization?.name}
                            </div>
                            <div className="org-home-profile-host-account">
                                {organization?.bio}
                            </div>
                            <div className="org-home-profile-host-email">
                                {organization?.email}
                            </div>
                        </div>
                    </div>}
                    <div className="org-home-statistic">
                        {orgIndicators && orgIndicators.map((item) => (
                            <div key={item.key} className="org-home-card org-home-statistic-card">
                                <div className="org-home-statistic-card-icon">
                                    {item.icon}
                                </div>
                                <div className="org-home-statistic-card-content">
                                    <div className="org-home-statistic-card-title">
                                        {item.title}
                                    </div>
                                    <div className="org-home-statistic-card-value">
                                        {item.value}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/*<div className="org-home-create-event-buttons">*/}
            {/*    <div className="org-home-create-event-card org-home-create-event-manual">*/}
            {/*        <div className="create-event-title">Start from scratch</div>*/}
            {/*        <div className="create-event-desc">Add all your event details, create new tickets, vouchers, etc.*/}
            {/*        </div>*/}
            {/*        <Link to="/organization/manage/createEvent">*/}
            {/*            <Button>Create event</Button>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*    <div className="org-home-create-event-card org-home-create-event-auto">*/}
            {/*        <div className="create-event-title">Create my event faster with AI</div>*/}
            {/*        <div className="create-event-desc">Answer a few quick questions to generate an event.*/}
            {/*        </div>*/}
            {/*        <Button type="primary">Create event with AI</Button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="org-home-list">
                {organizationStatistic && <div className="org-home-section">
                    <div className="org-home-section-title">
                        Recent reservations
                    </div>
                    <div className="org-home-section-content">
                        <RecentOrders orders={organizationStatistic?.order?.orders}/>
                    </div>
                </div>}

                <div className="org-home-section">
                    <div className="org-home-section-title">
                        People <span className="org-home-section-title-desc">(from followers)</span>
                    </div>
                    <div className="org-home-section-content">
                        <OrgPeople people={organizationStatistic?.followers}/>
                    </div>
                </div>

                {/*<div className="org-home-section">*/}
                {/*    <div className="org-home-section-title">*/}
                {/*        Sales by month*/}
                {/*    </div>*/}
                {/*    <div className="org-home-section-content">*/}
                {/*        <SalesMTD/>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        </div>
    );
}

export default OrganizationHome;