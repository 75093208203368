import { ShareAltOutlined, WifiOutlined } from "@ant-design/icons";
import { Button, Modal, Tag } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DateTimePattern } from "../../../../common/enums/DateTimePattern";
import { EventType } from "../../../../common/enums/Event";
import { formatDateRange } from "../../../../common/utils/dateTimeUtils";
import "./EventCardVertical.scss";
import defaultThumbnail from "../../../assets/images/default.jpg";
import ButtonLikeEvent from "../ButtonLikeEvent/ButtonLikeEvent";
import SocialShare from "../SocialShare";

const EventCardVertical = ({ selected, eventData, past }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user } = useSelector((state) => state.auth);

    const {
        eventId,
        eventName,
        thumbnailUrl,
        eventType,
        startDateTime,
        location,
        minPrice,
        organization,
        categories
    } = eventData;
    const eventLink = past ? `/past-events/${eventId}/feedback` : `/events/${eventId}`;

    const address = [
        location?.district,
        location?.province
    ].filter(Boolean).join(", ");

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className={`v-card-container ${selected && "selected"}`}>
                <Link className="v-card-cover-image" to={`/events/${eventId}`}>
                    <img src={thumbnailUrl} alt="v-card" onError={(e) => e.target.src = defaultThumbnail}/>
                </Link>
                <div className="v-card-info">
                    <div className="v-card-info-main">
                        <Link to={eventLink} className="v-card-info-title">
                            {eventName}
                        </Link>
                        {
                            organization?.organizationName &&
                            <div className="v-card-organizer">
                                By <Link to={`/organizers/${organization?.id}`}
                                         className="v-card-organizer-name">{organization?.organizationName}</Link>
                            </div>
                        }
                        {
                            categories?.length > 0
                                ? <div className="v-card-category-list">
                                    {categories?.map((cate) => (
                                        <div key={cate} className="v-card-category-item">
                                            {cate}
                                        </div>
                                    ))}
                                </div>
                                : <></>
                        }
                    </div>
                    <div className="v-card-info-more">
                        <div className="v-card-info-detail">
                            {
                                startDateTime && <div className="v-card-info-detail-time">
                                    {formatDateRange(startDateTime, DateTimePattern.CARD_DATE_TIME)}
                                </div>
                            }
                            {
                                address && <div className="v-card-info-detail-place">
                                    {address}
                                </div>
                            }
                            {
                                !address && <div className="v-card-info-detail-place-online">
                                    Online
                                </div>
                            }
                            <div className={`v-card-pricing ${(!minPrice || minPrice === 0) && "free"}`}>
                                {
                                    (!minPrice || minPrice === 0)
                                        ? <p className="highlight ">Free</p>
                                        : <p>From <span className="highlight">{minPrice.toLocaleString()}VND</span></p>
                                }
                            </div>
                        </div>
                        <div className="v-card-button">
                            <div className="v-card-info-tags">
                                {
                                    eventType === EventType.ONLINE
                                        ?
                                        <Tag bordered={false} icon={<WifiOutlined/>}>
                                            Online
                                        </Tag>
                                        : <></>
                                }
                            </div>
                            <Button
                                className="v-card-button-item"
                                shape="circle"
                                icon={<ShareAltOutlined/>}
                                onClick={showModal}
                            />
                            {user && <ButtonLikeEvent event={eventData}/>}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                centered={true}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <SocialShare eventId={eventId}/>
            </Modal>
        </>
    );
};

export default EventCardVertical;