import React from 'react';
import {Button, Form, Input} from "antd";
import {Link} from "react-router-dom";
import {validateConfirmPassword, validatePassword} from "../../../../../common/utils/passwordUtils";

function ResetPasswordForm({form, loading, onFinish}) {
    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout={"vertical"}
            requiredMark={true}
        >
            <Form.Item
                name='password'
                label='Password'
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                    {
                        validator: validatePassword,
                    },
                ]}
                hasFeedback
            >
                <Input.Password size='large'/>
            </Form.Item>
            <Form.Item
                name='confirmPassword'
                label='Confirm New Password'
                dependencies={['password']}
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    validateConfirmPassword,
                ]}
                hasFeedback
            >
                <Input.Password size='large'/>
            </Form.Item>
            <div className="send-mail-button-container">
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        block={true}
                        className={"send-mail-button"}
                    >
                        Update password
                    </Button>
                </Form.Item>
            </div>
            <div className="sign-in-link">
                <Link to={"../login"} className={"link-text"}>
                    Back to login
                </Link>
            </div>
        </Form>
    );
}

export default ResetPasswordForm;