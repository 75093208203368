import { ExclamationCircleFilled } from "@ant-design/icons";
import { Form, Modal } from "antd";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { VoucherStatus } from "../../../../../common/enums/Voucher";
import { VoucherRuleCondition } from "../../../../../common/enums/VoucherRuleCondition";
import {
    createVoucher,
    deleteVoucherById,
    getVoucherByEventId,
    updateVoucher
} from "../../../../../common/redux/actions/voucherAction";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import TopTool from "../../components/TopTool";
import VoucherDrawer from "./partials/VoucherDrawer/VoucherDrawer";
import VoucherTable from "./partials/VoucherTable";

const { confirm } = Modal;


function Voucher({ eventId, isBankInfoProvided }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [selectedVoucher, setSelectedVoucher] = useState();
    const { vouchers } = useSelector((state) => state.voucher)
    const { user } = useSelector((state) => state.auth);
    const [searchData, setSearchData] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isAmountOffFilled, setIsAmountOffFilled] = useState(false);
    const [isPercentOffFilled, setIsPercentOffFilled] = useState(false);
    const [conditionType, setConditionType] = useState(VoucherRuleCondition.NONE);
    const { event } = useSelector(state => state.event)


    useEffect(() => {
        dispatch(getVoucherByEventId({ eventId: eventId }));
        // eslint-disable-next-line
    }, [dispatch]);

    const showDrawer = () => {
        setOpenDrawer(true)
    }
    const closeDrawer = () => {
        setOpenDrawer(false)
        setSelectedVoucher(null)
        form.resetFields();
        form.setFieldsValue({
            amountOff: '',
            percentOff: '',
            conditionValue: '',
        })
        setIsAmountOffFilled(false);
        setIsPercentOffFilled(false);
        setConditionType(VoucherRuleCondition.NONE)
    }
    const onFinish = async (values) => {
        const eventEndDate = new Date(event?.endDateTime);
        const voucherStartDate = values?.voucherDateTime?.[0] ? formatDateRange(values.voucherDateTime[0], DateTimePattern.COMPLETED_DATE_TIME) : null;
        const voucherEndDate = values?.voucherDateTime?.[1] ? formatDateRange(values.voucherDateTime[1], DateTimePattern.COMPLETED_DATE_TIME) : null;

        if (new Date(voucherStartDate) > eventEndDate) {
            toast.error(`Voucher start date must be before the end date of the event`)
            return;
        }

        if (new Date(voucherEndDate).getTime() < new Date(voucherStartDate).getTime()) {
            toast.error(`Voucher start date must before voucher end date. `)
            return;
        }

        const voucher = {
            eventId: Number(eventId),
            amountOff: values?.amountOff ? Number(values?.amountOff) : null,
            percentOff: values?.percentOff ? Number(values?.percentOff) : null,
            code: values.code,
            endDate: voucherEndDate,
            startDate: voucherStartDate,
            totalQuantity: Number(values?.totalQuantity),
            conditionType: values.conditionType,
            conditionValue: values.conditionValue,
            maxDiscountValue: values?.maxDiscountValue || 0
        }

        const action = selectedVoucher ? updateVoucher : createVoucher;

        dispatch(action(voucher))
            .unwrap()
            .then((res) => {
                if (res) {
                    const message = selectedVoucher ? "Update successfully" : "Voucher has been created";
                    toast.success(message);
                    setTimeout(() => {
                        closeDrawer();
                        dispatch(getVoucherByEventId({ eventId: eventId }))
                    })
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    const getStatus = (voucher) => {
        const startDate = voucher.startDate ? new Date(voucher.startDate) : null;
        const endDate = voucher.endDate ? new Date(voucher.endDate) : null;
        const currentDate = new Date();

        if (endDate && currentDate > endDate) return VoucherStatus.INACTIVE;
        if (startDate && currentDate > startDate) return VoucherStatus.ACTIVE;
        return VoucherStatus.ACTIVE;
    };

    const formatData = (voucher) => {
        const formattedStartDate = voucher.startDate ? formatDateRange(voucher.startDate, DateTimePattern.STANDARD_DATE_TIME) : `Start now`;
        const formattedEndDate = voucher.endDate ? formatDateRange(voucher.endDate, DateTimePattern.STANDARD_DATE_TIME) : `Until event end`;

        return {
            key: voucher?.id,
            code: voucher.code,
            discount: voucher?.amountOff ? `${voucher.amountOff.toLocaleString()}` : `${voucher.percentOff} %`,
            numberOfAvailable: voucher?.numberOfAvailable ? `${voucher.numberOfUsages}/${voucher.numberOfAvailable}` : `${voucher.numberOfSold}/Unlimited`,
            voucherStatus: getStatus(voucher),
            startDate: formattedStartDate,
            endDate: formattedEndDate,
        };
    };

    const formattedData = vouchers?.map(formatData);
    const deleteVoucher = (id) => {
        const username = user?.id;
        if (username) {
            const req = {
                username: username,
                voucherId: id
            }
            dispatch(deleteVoucherById(req))
        }
    }
    const onSearch = (value, _e, info) => {
        setSearchData(value);
    };

    const filteredData = formattedData.filter((item) =>
        item.code.toLowerCase().includes(searchData.toLowerCase())
    );

    const selectVoucher = (voucher) => {
        setSelectedVoucher(vouchers.find(e => e.code.toLowerCase() === voucher.code.toLowerCase()))
        setOpenDrawer(true);
    }

    const deleteVoucherInTable = (voucher) => {
        confirm({
            title: "Are you sure you want to delete this voucher?",
            icon: <ExclamationCircleFilled/>,
            content: "When clicked the OK button, voucher will be deleted",
            onOk() {
                try {
                    deleteVoucher(voucher.key)
                    toast.success("Delete successfully")
                } catch (error) {
                    toast.error("Delete failed")
                }
            },
            onCancel() {
            },
        });
    };

    return (
        <div className={"voucher-container"}>
            <TopTool
                placeholder={"Voucher code"}
                onSearch={onSearch}
                handleOnClick={showDrawer}
                buttonText={"Add new voucher"}
                orgBankExist={isBankInfoProvided}
            />
            <VoucherTable
                setSelectedVoucher={selectVoucher}
                voucherData={filteredData}
                disabledAction={!isBankInfoProvided}
                deleteVoucherById={deleteVoucherInTable}/>
            <VoucherDrawer
                onClose={closeDrawer}
                openDrawer={openDrawer}
                form={form}
                voucherData={selectedVoucher}
                setIsAmountOffFilled={setIsAmountOffFilled}
                setIsPercentOffFilled={setIsPercentOffFilled}
                isAmountOffFilled={isAmountOffFilled}
                isPercentOffFilled={isPercentOffFilled}
                setConditionType={setConditionType}
                conditionType={conditionType}
                onFinish={onFinish}/>
        </div>
    );
}

export default Voucher;