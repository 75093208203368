import { Form } from "antd";
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { sendResetPasswordMail } from "../../../common/redux/actions/authActions";
import { isProduction } from "../../../common/utils/envUtils";
import ForgotPasswordLayout from "../../modules/components/ForgotPasswordLayout";
import ForgotPasswordForm from "./partials/ForgotPasswordForm";

function ForgotPassword() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { loading } = useSelector(state => state.auth)
    const navigate = useNavigate();
    const resetPasswordUrl = isProduction ? `${process.env.REACT_APP_PUBLIC_URL}/reset-password` : `${process.env.REACT_APP_HOST_URL}/reset-password`;


    const handleCloseModal = () => {
        setIsModalOpen(false);
        navigate('/login')
    }
    const onFinish = (values) => {
        const data = {
            email: values.email,
            resetPasswordUrl: resetPasswordUrl
        }
        try {
            dispatch(sendResetPasswordMail(data))
                .unwrap()
                .then(() => {
                    setIsModalOpen(true);
                })
        } catch (error) {
            toast.error(error)
        }
    }

    return (
        <ForgotPasswordLayout
            heading={"Forgot password"}
            description={" Enter your email and we will send you a link to reset your password "}
            form={<ForgotPasswordForm
                form={form}
                onFinish={onFinish}
                loading={loading}/>}
            modalTitle={"Check your mail"}
            isModalOpen={isModalOpen}
            handleCancelModel={handleCloseModal}
            status={"success"}
        />
    );
}

export default ForgotPassword;