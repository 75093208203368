import { useParticipant } from "@videosdk.live/react-sdk";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import "./ParticipantView.scss";
import { CornerDisplayName } from "../CornerDisplayName/CornerDisplayName";

export function ParticipantView({ participantId }) {
    const {
        displayName,
        webcamStream,
        micStream,
        webcamOn,
        micOn,
        isLocal,
        mode,
        isActiveSpeaker,
    } = useParticipant(participantId);

    const micRef = useRef(null);
    const [mouseOver, setMouseOver] = useState(false);

    useEffect(() => {
        if (micRef.current) {
            if (micOn && micStream) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(micStream.track);
                micRef.current.srcObject = mediaStream;
                micRef.current
                    .play()
                    .catch((error) =>
                        console.error("videoElem.current.play() failed", error)
                    );
            } else {
                micRef.current.srcObject = null;
            }
        }
    }, [micStream, micOn]);

    const webcamMediaStream = useMemo(() => {
        if (webcamOn && webcamStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(webcamStream.track);
            return mediaStream;
        }
    }, [webcamStream, webcamOn]);

    return mode === "CONFERENCE"
        ? <div
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            className="participant-view-container video-cover"
        >
            <audio ref={micRef} autoPlay muted={isLocal}/>
            {
                webcamOn
                    ? <ReactPlayer
                        //
                        playsinline
                        playIcon={<></>}
                        //
                        pip={false}
                        light={false}
                        controls={false}
                        muted={true}
                        playing={true}
                        //
                        url={webcamMediaStream}
                        //
                        height={"100%"}
                        width={"100%"}
                        onError={(err) => {
                            console.log(err, "participant video error");
                        }}
                    />
                    : <div className="participant-view-content">
                        <div className="participant-view-avatar">
                            {String(displayName).charAt(0).toUpperCase()}
                        </div>
                    </div>
            }
            <CornerDisplayName
                {...{
                    isLocal,
                    displayName,
                    micOn,
                    webcamOn,
                    isPresenting: false,
                    participantId,
                    mouseOver,
                    isActiveSpeaker,
                }}
            />
        </div>
        : null;
}
