import { Sparkle, UserCircle } from "@phosphor-icons/react";
import React, { useLayoutEffect, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import Menu from "../../modules/components/MainMenu/MainMenu";
import "./Personal.scss";

const accountItems = [
    {
        key: "profile",
        title: "Profile",
        icon: <UserCircle weight="fill"/>,
        path: "profile"
    },
    // {
    //     key: "account",
    //     title: "Account",
    //     icon: <ShieldCheckered weight="fill"/>,
    //     path: "account"
    // },
    {
        key: "preference",
        title: "Preferences",
        icon: <Sparkle weight="fill"/>,
        path: "preference"
    },
];

const Personal = () => {
    const { setShowSidebar } = useOutletContext();
    const [activeItem, setActiveItem] = useState("");

    useLayoutEffect(() => {
        setShowSidebar(false);
    });
    
    const handleMenuItemClick = (key) => {
        setActiveItem(key);
    };
    
    
    return (
        <div className="personal-container">
            <div className="personal-menu">
                <div className="personal-menu-title">
                    Personal Settings
                </div>
                <Menu menuItems={accountItems} onMenuItemClick={handleMenuItemClick} activeItem={activeItem}/>
            </div>
            <div className="personal-section-content">
                <Outlet context={{ activeItem, setActiveItem }}/>
            </div>
        </div>
    );
};

export default Personal;
