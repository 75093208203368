import { Users } from "@phosphor-icons/react";
import { useMeeting } from "@videosdk.live/react-sdk";
import { Button } from "antd";
import { VideoSDKSideBarModes as sideBarModes } from "../../../../../../common/enums/VideoSDKSidebarMode";
import "./ParticipantButton.scss";

const ParticipantsButton = ({ meetingMode, sideBarMode, setSideBarMode }) => {
    const { participants } = useMeeting();

    const handleParticipantSideBar = () => {
        setSideBarMode((s) =>
            s === sideBarModes.PARTICIPANTS ? null : sideBarModes.PARTICIPANTS
        );
    }

    return <Button
        className="bottom-bar-participant-button"
        size="large"
        type={sideBarMode === sideBarModes.PARTICIPANTS ? "default" : "primary"}
        onClick={handleParticipantSideBar}
    >
        <Users size={24} weight="fill"/> {new Map(participants)?.size}
    </Button>
};

export default ParticipantsButton;