import { CalendarBlank, ClockCounterClockwise } from "@phosphor-icons/react";
import { Segmented } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./OrganizerProfileEvents.scss";
import { getEventsByOrganizationId } from "../../../../../common/redux/actions/eventActions";
import EventListVertical from "../../../../modules/components/EventListVertical/EventListVertical";

const eventListOptions = [
    {
        label: (<div className="list-option-segment"><CalendarBlank/> Upcoming</div>),
        value: 'upcoming',
    },
    {
        label: (<div className="list-option-segment"><ClockCounterClockwise/> Past</div>),
        value: 'past',
    }
]
const OrganizerProfileEvents = ({ organization, organizationId }) => {
    const dispatch = useDispatch();
    const { eventsByOrganizationIds } = useSelector((state) => state.event);
    const [filteredEvents, setFilteredEvents] = useState([])
    const [listOption, setListOption] = useState('upcoming');
    
    
    useEffect(() => {
        // const currentDate = new Date();
        // const filteredDate = formatDateRange(currentDate, DateTimePattern.COMPLETED_DATE_TIME);
        //
        // const payload = {
        //     organizationId: organizationId,
        //     startDate: listOption === 'upcoming' ? filteredDate : null,
        //     endDate: listOption === 'past' ? filteredDate : null,
        //     categories: [],
        //     eventType: null,
        //     sortBy: "startDateTime",
        //     sortOrder: "ASC",
        //     pageSize: 20,
        //     pageNumber: 1
        // };
        //
        const payload = {
            organizationId: organizationId,
            startDate:  null,
            endDate: null,
            categories: [],
            eventType: null,
            sortBy: "startDateTime",
            sortOrder: "ASC",
            pageSize: 20,
            pageNumber: 1
        };
        dispatch(getEventsByOrganizationId(payload));
        // eslint-disable-next-line
    }, [dispatch, listOption]);
    
    useEffect(() => {
        const currentDate = new Date();
        const filterEvents = eventsByOrganizationIds?.filter(event => {
            const eventStartDate = new Date(event?.startDateTime);
            const eventEndDate = new Date(event?.startDateTime);
            return listOption === "upcoming" ? eventStartDate > currentDate : eventEndDate <= currentDate;
        });
        
        setFilteredEvents(filterEvents || []);
        
    }, [listOption, eventsByOrganizationIds]);
    


    return (
        <div className="organizer-profile-content-container">
            <div className="organizer-profile-event-list-head">
                <div className="organizer-profile-event-title">
                    {organization.name}'s events
                </div>
                <div className="organizer-profile-event-list-option">
                    <Segmented options={eventListOptions} value={listOption} onChange={setListOption}/>
                </div>
            </div>
            <div className="organizer-profile-event-list">
                <EventListVertical events={filteredEvents}/>
            </div>
        </div>
    );
};

export default OrganizerProfileEvents;