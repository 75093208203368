import { Tag } from "@phosphor-icons/react";
import { Button } from "antd";
import React from "react";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { VoucherRuleCondition } from "../../../../../common/enums/VoucherRuleCondition";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import "./VoucherItem.scss";

const VoucherItem = ({ voucher, handleSelectVoucher, selectedVoucher, invalidVoucher }) => {
    return (
        <div
            className={`voucher-item-container ${selectedVoucher?.code === voucher?.code ? "selected" : ''} ${invalidVoucher ? 'invalid' : ''}`}>
            <div className="voucher-item-first">
                <Tag weight="fill" size={20}/>
            </div>
            <div className="voucher-item-mid">
                <div className="voucher-item-off">
                    {voucher.amountOff ? `${voucher?.amountOff.toLocaleString()}VND` : `${voucher.percentOff}%`} off
                    <span className="voucher-item-condition">
                    {voucher.conditionType === VoucherRuleCondition.MINIMUM_AMOUNT
                        && ` | At least ${voucher.conditionValue.toLocaleString()}VND`}
                        {voucher.conditionType === VoucherRuleCondition.MINIMUM_NUMBER
                            && ` | For at least ${voucher.conditionValue.toLocaleString()} tickets`}
                    </span>
                </div>
                <div className="voucher-item-info">
                    <div className="voucher-item-code">
                        Code <span>{voucher.code.toUpperCase()}</span> {voucher?.maxDiscountValue > 0 && (<span>{`max ${voucher?.maxDiscountValue.toLocaleString()} VND`}</span>)}
                    </div>
                    <div className="voucher-item-due">
                        Valid till <span>{formatDateRange(voucher.endDate, DateTimePattern.STANDARD_DATE_TIME)}</span>
                    </div>
                </div>
            </div>
            <div className="voucher-item-last">
                <Button
                    className="voucher-item-button"
                    size="small"
                    type="text"
                    onClick={() => handleSelectVoucher(voucher)}
                    disabled={selectedVoucher?.code === voucher?.code}
                >
                    {
                        selectedVoucher?.code === voucher?.code
                            ? "Applied"
                            : "Select"
                    }
                </Button>
            </div>
        </div>
    );
};

export default VoucherItem;