import { createSlice } from "@reduxjs/toolkit";
import { getTransactionsByOrganizationId } from "../actions/transactionActions";

const initialState = {
    loading: false,
    transactions: [],
    error: null,
    success: null,
}

const transactionSlice = createSlice({
    name: 'transaction',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTransactionsByOrganizationId.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getTransactionsByOrganizationId.fulfilled, (state,action) => {
                state.loading = false;
                state.transactions = action.payload;
            })
            .addCase(getTransactionsByOrganizationId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
        
    }
})

export default transactionSlice.reducer;
