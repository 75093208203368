import { createSlice } from "@reduxjs/toolkit";
import { checkBankAccount, getBanksInfo, updateOrganizationBankInfo } from "../actions/bankAction";

const initialState = {
    loading: false,
    banks: [],
    error: null,
    success: null,
}

const bankSlice = createSlice({
    name: 'bank',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBanksInfo.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getBanksInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.banks = action.payload?.data?.data;
            })
            .addCase(getBanksInfo.rejected, (state, action) => {
                state.loading = false;
                state.banks = [];
                state.error = action.payload;
            })
            .addCase(checkBankAccount.pending, (state,action) => {
                state.loading = true;
            })
            .addCase(checkBankAccount.fulfilled, (state,action) => {
                state.loading = false;
                state.success = action.payload;
            })
            .addCase(checkBankAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateOrganizationBankInfo.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateOrganizationBankInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload;
            })
            .addCase(updateOrganizationBankInfo.rejected, (state,action) => {
                state.loading = false;
                state.error = action.payload;
            })
         
    }
})

export default bankSlice.reducer;
