import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import "./OrganizerProfile.scss";
import { getOrganizerProfile } from "../../../common/redux/actions/organizationActions";
import { checkThread, createNewThread } from "../../../common/redux/actions/threadAction";
import OrganizerProfileEvents from "./partials/OrganizerProfileEvents/OrganizerProfileEvents";
import OrganizerProfileHeader from "./partials/OrganizerProfileHeader/OrganizerProfileHeader";

const OrganizerProfile = () => {
    const { organizerId } = useParams();
    const { setShowSidebar } = useOutletContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { organizer } = useSelector((state) => state.organization);
    const { threadId } = useSelector((state) => state.thread)
    const { user } = useSelector(state => state.auth);

    useLayoutEffect(() => {
        document.title = "Organization profile | Eventure";
        setShowSidebar(false);
    });

    useEffect(() => {
        const params = {
            organizerId: organizerId,
        };
        dispatch(getOrganizerProfile(params));
    }, [organizerId, dispatch]);

    useEffect(() => {
        dispatch(checkThread({
            orgId: organizerId,
            username: user?.id
        }));
    }, [user, organizerId, dispatch]);


    const startChatting = async () => {
        const username = user?.id;
        const threadData = [{
            created_at: new Date(),
            updated_at: new Date(),
            user: username,
            groupAdmin: organizerId,
        }]
        dispatch(createNewThread(threadData))
            .unwrap()
            .then(res => {
                navigate(`/messages/${res}`)
            })
    }

    const goToChatPage = () => {
        navigate(`/messages/${threadId}`)
    }

    return (
        <div className="organizer-profile-container">
            {
                organizer &&
                <OrganizerProfileHeader
                    threadId={threadId}
                    goToChatPage={goToChatPage}
                    startChatting={startChatting}
                    organizer={organizer}
                />
            }
            {
                organizer &&
                <OrganizerProfileEvents organization={organizer} organizationId={organizerId}/>
            }
        </div>
    );
};

export default OrganizerProfile;