import vietQrAxiosInstance from "../api/vietQrApi";
import axiosInstance from "../api";


const bankingService = {
    getBanksInfo: async () => {
        try {
            return await vietQrAxiosInstance.get(`/v2/banks`);
        } catch (error) {
            console.error(error)
            throw error;
        }
    },
    checkBankAccount: async (bankInfo) => {
        try {
            return await vietQrAxiosInstance.post(`/v2/lookup`, bankInfo);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    updateOrganizationBank: async (bankInfo) => {
        try {
            return await axiosInstance.post(`/api/v1/organization/updateOrganizationBankInfo`, bankInfo)
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export default bankingService;