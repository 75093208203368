import React from "react";
import "./TicketCart.scss";
import { useSelector } from "react-redux";

const TicketCart = () => {
    const {
        cartBuyer, cartTickets, cartVoucher, cartSubtotal, cartTotal
    } = useSelector((state) => state.cart);

    return (
        <div className="ticket-cart-container">
            {
                Object.keys(cartBuyer).length > 0
                && <div className="ticket-cart-section ticket-cart-buyer">
                    <div className="ticket-cart-section-title">
                        Buyer Information
                    </div>
                    <div className="ticket-cart-buyer-info">
                        <div className="ticket-cart-buyer-info-item">
                            <div className="info-item-label">
                                Name
                            </div>
                            <div className="info-item-content">
                                {cartBuyer.firstName} {cartBuyer.lastName}
                            </div>
                        </div>
                        <div className="ticket-cart-buyer-info-item">
                            <div className="info-item-label">
                                Phone
                            </div>
                            <div className="info-item-content">
                                {cartBuyer.phone}
                            </div>
                        </div>
                        <div className="ticket-cart-buyer-info-item">
                            <div className="info-item-label">
                                Email
                            </div>
                            <div className="info-item-content">
                                {cartBuyer.email}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="ticket-cart-section ticket-cart-order">
                <div className="ticket-cart-section-title">
                    Your purchase
                </div>
                <div className="ticket-cart-form">
                    <div className="ticket-cart-form-head">
                        <p>Ticket type</p>
                        <p>Quantity</p>
                    </div>
                    <div className="ticket-cart-form-item-list">
                        {cartTickets.map((item, index) => (
                                item?.quantity > 0 && <div key={index} className="ticket-cart-form-item">
                                    <div className="cart-item-ticket-type">{item?.title}</div>
                                    <div className="cart-item-quantity">{item?.quantity}</div>
                                    <div className="cart-item-unit-price">{item?.price.toLocaleString()} VND</div>
                                    <div className="cart-item-price">
                                        {(item?.quantity * item?.price).toLocaleString()} VND
                                    </div>
                                </div>)
                        )}
                    </div>
                    <div className="ticket-cart-form-footer">
                        <div className="ticket-cart-form-footer-item ticket-cart-subtotal">
                            <p className="form-footer-label">Subtotal</p>
                            <p className="form-footer-content">{cartSubtotal.toLocaleString()} VND</p>
                        </div>
                        {cartVoucher?.valueOff && <div className="ticket-cart-form-footer-item ticket-cart-voucher">
                            <p className="form-footer-label">Discount</p>
                            <p className="form-footer-content">-{cartVoucher?.valueOff.toLocaleString() || 0} VND</p>
                        </div>}
                        <div className="ticket-cart-form-footer-item ticket-cart-total">
                            <p className="form-footer-label">Total</p>
                            <p className="form-footer-content">{cartTotal.toLocaleString() || 0} VND</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketCart;