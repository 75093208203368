import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrganizers } from "../../../../../common/redux/actions/organizationActions";
import OrganizerCard from "../../../../modules/components/OrganizerCard";
import "./OrganizerMasterList.scss";

const OrganizerMasterList = () => {
    const dispatch = useDispatch();
    const { organizations } = useSelector((state) => state.organization);

    useEffect(() => {
        const params = {
            pageNumber: 1,
            pageSize: 5
        };
        dispatch(getAllOrganizers(params));
    }, [dispatch]);

    return (
        <div className="organizer-master-list-container">
            {
                (organizations && organizations.length > 0)
                    ? organizations.map((item) =>
                        <OrganizerCard key={item?.id} organizer={item}/>
                    )
                    : <></>
            }
        </div>
    );
};

export default OrganizerMasterList;