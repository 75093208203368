import { EnvelopeSimple, FacebookLogo, InstagramLogo, XLogo } from "@phosphor-icons/react";
import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import logo from "../../../assets/icons/logo.svg";

const Footer = () => {

    return (
        <div className="footer-container">
            <div className="footer-main">
                <div className="footer-main-left">
                    <img className="footer-main-logo" src={logo} alt="eventure logo"/>
                    <div className="footer-main-sections">
                        <Link to="/" className="footer-main-section-item">About us</Link>
                        <Link to="/" className="footer-main-section-item">Contribute</Link>
                        <Link to="/" className="footer-main-section-item">Help</Link>
                    </div>
                </div>
                <div className="footer-main-right">
                    <div className="footer-main-social-icon">
                        <EnvelopeSimple size={18}/>
                    </div>
                    <div className="footer-main-social-icon">
                        <FacebookLogo size={18}/>
                    </div>
                    <div className="footer-main-social-icon">
                        <XLogo size={18}/>
                    </div>
                    <div className="footer-main-social-icon">
                        <InstagramLogo size={18}/>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                &copy; 2024 Dolphin Ltd. All rights reserved.
            </div>
        </div>
    );
};

export default Footer;