import { Alert, Button, Input, Space } from "antd";
import React, { useEffect, useLayoutEffect, useState } from 'react';
import "./OrganizationEventRecording.scss";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { getHostRecordByEventId, updateExternalRecording } from "../../../common/redux/actions/recordingAction";
import VideoSidePanel from "./partials/VideoSidePanel";

function OrganizationEventRecording() {
    const [loadingExtButton, setLoadingExtButton] = useState(false);
    const [recordingCustomUrl, setRecordingCustomUrl] = useState();
    const [isExternal, setIsExternal] = useState(false);
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const { hostRecording } = useSelector((state) => state.recording);

    useLayoutEffect(() => {
        document.title = "Event's recording | Eventure";
        // sessionStorage.clear();
    }, []);

    const fetchRecording = () => {
        if (eventId) {
            dispatch(getHostRecordByEventId({ eventId: eventId }));
        }
    };

    useEffect(() => {
        fetchRecording();
        // eslint-disable-next-line
    }, [eventId, dispatch]);

    useEffect(() => {
        if (hostRecording) setIsExternal(hostRecording.publishRecordingFileType === "external");
        if (hostRecording?.externalFileUrl) {
            setRecordingCustomUrl(hostRecording.externalFileUrl);
        } else {
            setRecordingCustomUrl("");
        }
    }, [hostRecording]);

    const handleChangeCustomUrl = (e) => {
        setRecordingCustomUrl(e.target.value);
    }

    const handleSaveCustomRecording = () => {
        if (recordingCustomUrl.length === 0) return;
        setLoadingExtButton(true);
        if (recordingCustomUrl?.length !== 0) {
            dispatch(updateExternalRecording({
                eventId: eventId,
                fileUrl: recordingCustomUrl
            }))
                .unwrap()
                .then((res) => {
                    if (res) {
                        toast.success("Successfully update external recording link!");
                        dispatch(getHostRecordByEventId({ eventId: eventId }));
                    }
                })
                .catch((err) => {
                    toast.error("Fail to update external recording link!");
                })
                .finally(() => {
                    setLoadingExtButton(false);
                });
        }
    }

    return (
        <div className="event-recording-page-container">
            <div className="event-recording-page-title">
                Event recording
            </div>
            <div className="recording-input">
                <Space.Compact block={true}>
                    <Input
                        placeholder="Paste your custom URL if you want to share your custom recordings"
                        value={recordingCustomUrl}
                        onChange={handleChangeCustomUrl}
                    />
                    <Button
                        loading={loadingExtButton}
                        type={"primary"}
                        onClick={handleSaveCustomRecording}
                    >
                        Save
                    </Button>
                </Space.Compact>
            </div>
            {hostRecording && <div className="event-recording-videos">
                <div
                    className={`event-recording-internal ${!isExternal && "selected"} ${!hostRecording?.videoFileUrl && "disable"}`}
                    onClick={() => setIsExternal(false)}>
                    <p>Use default recording</p>
                    <div className="recording-internal-video">
                        {
                            hostRecording?.videoFileUrl
                                ? <ReactPlayer
                                    url={hostRecording?.videoFileUrl}
                                    controls={true}
                                />
                                : <p className="recording-blank-message">No internal recording video</p>
                        }
                    </div>
                    {hostRecording?.videoStatus
                        && <Alert type="warning"
                                  message={`AI process status: ${hostRecording?.videoStatus}`}></Alert>}
                </div>
                <div
                    className={`event-recording-external ${isExternal && "selected"} ${!hostRecording?.externalVideoFileUrl && "disable"}`}
                    onClick={() => setIsExternal(true)}>
                    <p>Use external recording</p>
                    <div className="recording-external-video">
                        {
                            hostRecording?.externalVideoFileUrl
                                ? <ReactPlayer
                                    url={hostRecording?.externalVideoFileUrl}
                                    controls={true}
                                />
                                : <p className="recording-blank-message">No external recording video</p>
                        }
                    </div>
                    {hostRecording?.externalVideoStatus &&
                        <Alert type="warning"
                               message={`AI process status: ${hostRecording?.externalVideoStatus}`}></Alert>}
                </div>
            </div>
            }
            {
                hostRecording
                    ? <div className="event-recording-page-content">
                        <VideoSidePanel recording={hostRecording} isExternal={isExternal} fetchRecording={fetchRecording}/>
                    </div>
                    : <div className="event-recording-empty-message">
                        No recording for this event
                    </div>
            }
        </div>
    );
}

export default OrganizationEventRecording;