import { ShareAltOutlined, WifiOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { DateTimePattern } from '../../../../common/enums/DateTimePattern';
import { EventType } from "../../../../common/enums/Event";
import { formatDateRange } from '../../../../common/utils/dateTimeUtils';
import './EventCardHorizontal.scss';
import defaultThumbnail from "../../../assets/images/default.jpg";
import ButtonLikeEvent from "../ButtonLikeEvent/ButtonLikeEvent";
import SocialShare from "../SocialShare";

const EventCardHorizontal = ({ eventData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const {
        eventId,
        eventName,
        thumbnailUrl,
        eventType,
        categories,
        startDateTime,
        location,
        minPrice
    } = eventData;

    const address = [
        location?.district,
        location?.province,
    ].filter(Boolean).join(", ");

    const showModal = () => setIsModalOpen(true);
    const handleOk = () => setIsModalOpen(false);
    const handleCancel = () => setIsModalOpen(false);

    return (
        <>
            <div className='h-card-container'>
                <div className='h-card-head'>
                    {
                        eventType === EventType.ONLINE
                            ? <div className='h-card-tag h-card-event-format'>
                                <WifiOutlined/> Online
                            </div>
                            : <></>
                    }
                    <Link to={`/events/${eventId}`}>
                        <img className='h-card-cover-image' src={thumbnailUrl} alt="card"
                             onError={(e) => e.target.src = defaultThumbnail}/>
                    </Link>
                    <div className='h-card-button'>
                        <Button
                            className="h-card-button-item"
                            shape="circle"
                            icon={<ShareAltOutlined/>}
                            onClick={showModal}
                        />
                        {user && <ButtonLikeEvent event={eventData}/>}
                    </div>
                </div>
                <div className='h-card-info'>
                    <div className='h-card-info-title'>
                        <Link
                            className='h-card-info-title-link'
                            to={`/events/${eventId}`}
                            aria-label={eventName}
                            title={eventName}
                        >
                            {eventName}
                        </Link>
                    </div>
                    <div className='h-card-info-detail'>
                        <div className='h-card-info-detail-time'>
                            {formatDateRange(startDateTime, DateTimePattern.CARD_DATE_TIME)}
                        </div>
                        {
                            address && <div className='h-card-info-detail-place'>
                                {address}
                            </div>
                        }
                    </div>
                </div>
                <div className='h-card-bottom'>
                    <div className={`h-card-tag h-card-pricing ${(!minPrice || minPrice === 0) && "free"}`}>
                        {
                            (!minPrice || minPrice === 0)
                                ? <p className='highlight'>Free</p>
                                : <p>From <span className='highlight'>{minPrice?.toLocaleString()}VND</span></p>
                        }
                    </div>
                    {
                        categories?.length > 0
                            ? <div className='h-card-category-list'>
                                {categories?.map((cate) => (
                                    <Link key={cate} to="/" className='h-card-category-item'>
                                        {cate}
                                    </Link>
                                ))}
                            </div>
                            : <></>
                    }
                </div>
            </div>
            <Modal
                centered={true}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <SocialShare eventId={eventId}/>
            </Modal>
        </>
    );
};

export default EventCardHorizontal;