import { ArticleMedium } from "@phosphor-icons/react";
import React from "react";
import { Form, Input, Select } from "antd";
import useCategory from "../../../../../common/hooks/useCategory";
import InfoLayout from "../InfoLayout";
const { Option } = Select;

function BasicInfo() {
    const { category } = useCategory();
    
    return (
        <InfoLayout
            heading="Basic Info"
            description="Name your event and tell event-goers why
                                    they should come. Add details that highlight
                                    what makes it unique."
            icon={<ArticleMedium size={48}/>}
        >
            <Form.Item
                name="eventName"
                label="Event name"
                rules={[
                    {
                        required: true,
                        message: "Please enter the event name!",
                    },
                ]}
            >
                <Input size="large" placeholder="Be clear and descriptive"/>
            </Form.Item>
            <Form.Item
                name="category"
                label="Event Category"
                rules={[
                    {
                        required: true,
                        message: "Please select the event category!",
                    },
                ]}
            >
                <Select size="large" mode="multiple" placement={"bottomLeft"}>
                    {category &&
                        category.map((category) => (
                            <Option key={category.id} value={category.name}>
                                {category.name}
                            </Option>
                        ))}
                </Select>
            </Form.Item>
        </InfoLayout>
    );
}

export default BasicInfo;
