const prefix = "account"

export const GET_USER_PROFILE = `${prefix}/getUserProfile`;
export const UPDATE_USER_PROFILE = `${prefix}/updateUserProfile`;
export const GET_PROFILE_STATISTIC = `${prefix}/getProfileStatistic`;

export const UPDATE_USER_AVATAR = `${prefix}/updateUserAvatar`;
export const REMOVE_USER_AVATAR = `${prefix}/removeUserAvatar`;

export const GET_INTERESTED_CATEGORIES = `${prefix}/getInterestedCategories`;
export const CREATE_OR_UPDATE_INTERESTED_CATEGORIES = `${prefix}/createOrUpdateInterestedCategory`;

export const GET_FOLLOWING_ORGANIZATION_IDS = `${prefix}/getFollowingOrganizationIds`;
export const GET_FOLLOWING_ORGANIZATIONS = `${prefix}/getFollowingOrganizations`;
export const FOLLOW_ORGANIZATION = `${prefix}/followOrganization`;
export const UNFOLLOW_ORGANIZATION = `${prefix}/unfollowOrganization`;

export const GET_PAST_EVENTS = `${prefix}/getPastEvents`;
export const GET_UPCOMING_EVENTS = `${prefix}/getUpcomingEvents`;
export const CAN_BUY_MORE_TICKET = `${prefix}/canBuyMoreTicket`;
export const IS_RESERVED_FREE_TICKET = `${prefix}/isReservedFreeTicket`;

export const GET_RESERVATIONS_BY_USERNAME = `${prefix}/getReservationsByUsername`;
export const GET_RESERVATION_BY_ID = `${prefix}/getReservationById`;