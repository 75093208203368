import { Alert, Badge, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { CART } from "../../../../../common/constants/LocalStorageConstants";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { getTicketsByEventId } from "../../../../../common/redux/actions/ticketAction";
import { setCartSubtotal, setCartTickets, setCartTotal } from "../../../../../common/redux/slice/cartSlice";
import "./TicketOptionForm.scss";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import { EventType } from "../../../../../common/enums/Event";
import { isReservedFreeTicket } from "../../../../../common/redux/actions/accountAction";
import { TicketType } from "../../../../../common/enums/ticketType";


const TicketOptionForm = ({ event }) => {
    const dispatch = useDispatch();
    const [ticketOptions, setTicketOptions] = useState([]);
    const [viewTicket, setViewTicket] = useState();
    const { tickets } = useSelector((state) => state.ticket);
    const { cartTickets } = useSelector((state) => state.cart);
    const [showTicketModal, setShowTicketModal] = useState(false);
    const currentDate = new Date();
    const { user } = useSelector((state) => state.auth);
    const username = user?.id;
    const isEventOnline = event?.eventType === EventType.ONLINE;

    useEffect(() => {
        if (event?.eventId) {
            const params = { eventId: event?.eventId };
            dispatch(getTicketsByEventId(params))
        }
    }, [event, dispatch]);

    useEffect(() => {
        const loadTickets = async () => {
            if (tickets?.length > 0) {
                const lsCart = localStorage.getItem(CART);
                const cartTickets = JSON.parse(lsCart)?.tickets || {};
                const updatedTicketList = await Promise.all(tickets.map(async (item) => {
                    const curQty = cartTickets?.length > 0 ? cartTickets.find(ticketItem => ticketItem.id === item.id)?.quantity : 0;
                    const updatedItem = {
                        ...item,
                        soldOut: item?.soldQuantity === item?.totalQuantity,
                        quantity: curQty,
                        status: (event?.eventType === EventType.ONLINE)
                            ? (curQty >= 0 ? "active" : "disabled")
                            : "active"
                    };

                    // Check if the ticket is free
                    if (item.ticketType === "free") {
                        const req = { username: username, ticketId: item.id };
                        const isReserved = await dispatch(isReservedFreeTicket(req)).unwrap();
                        updatedItem.status = isReserved ? "disabled" : "active";
                    }
                    return updatedItem;
                }));
                dispatch(setCartTickets(updatedTicketList));
                setTicketOptions(updatedTicketList);
            }
        };

        loadTickets();
        // eslint-disable-next-line
    }, [tickets, dispatch, username]);
    const getStatus = (item) => {
        const maxQuantity = event?.eventType === EventType.ONLINE || item?.ticketType === TicketType.FREE ? 1 : 4;

        if (item.soldOut) {
            return 'Sold out';
        }

        if (new Date(item.startDateAvailable) > currentDate) {
            return 'Coming soon';
        }

        if (new Date(item.endDateAvailable) < currentDate) {
            return 'Sale ended';
        }

        return <InputNumber size="large" min={0} max={maxQuantity} placeholder="0"
                            defaultValue={item?.quantity}
                            disabled={item?.status === "disabled"}
                            onChange={(value) => handleChangeQuantity(item, value)}/>;
    };

    const checkValidTicket = (item) => {
        return item.soldOut || new Date(item.startDateAvailable) > currentDate || new Date(item.endDateAvailable) < currentDate;
    }

    const handleChangeQuantity = (item, value) => {
        let currentQuantity = value;
        const updatedTickets = ticketOptions.map(ticket => ({
            ...ticket,
            status: (event?.eventType === EventType.ONLINE && currentQuantity >= 0 && currentQuantity)
                ? (ticket.id === item.id ? "active" : "disabled")
                : "active"
        }));

        setTicketOptions(updatedTickets);

        if (value > item.totalQuantity) {
            currentQuantity = item.totalQuantity;
            toast.warning(`You can't choose more than ${item.totalQuantity} tickets for ${item.title}.`);
        } else if (value > 4) {
            currentQuantity = 4;
            toast.warning(`You can't choose more than 4 tickets at once.`);
        }
        const currentTickets = cartTickets.map((ticket) => {
            const newQuantity = ticket.id === item?.id ? currentQuantity : ticket?.quantity;
            return {
                ...ticket,
                amount: ticket?.price * newQuantity, quantity: newQuantity
            };
        });

        let subtotalVal = currentTickets.reduce((total, ticket) => total + ticket.amount, 0);
        let totalVal = subtotalVal;

        dispatch(setCartTickets(currentTickets));
        dispatch(setCartSubtotal(subtotalVal));
        dispatch(setCartTotal(totalVal));
    };


    const handleViewTicket = (ticketItem) => {
        setViewTicket(ticketItem);
        setShowTicketModal(true);
    }

    const handleCancel = () => {
        setViewTicket(null);
        setShowTicketModal(false);
    };

    return (<>
            <div className="ticket-option-form-container">
                {event?.eventType === EventType.ONLINE && (
                    <Alert
                        message={`Online events allow the purchase of only one ticket per attendee.`}
                        type={"warning"}
                    />)
                }
                <div className="ticket-option-form-head">
                    <div className="ticket-option-col-1 ticket-option-col-title">Option</div>
                    <div className="ticket-option-col-2 ticket-option-col-title">Price</div>
                    <div className="ticket-option-col-3 ticket-option-col-title">Quantity</div>
                </div>
                {
                    ticketOptions?.length > 0 && ticketOptions?.map((item) => (
                        <div key={item?.id}
                             className={`ticket-option-form-item ${checkValidTicket(item) ? "sold-out" : ""}`}>
                            <div
                                className="ticket-option-col-1 ticket-type"
                                onClick={() => handleViewTicket(item)}
                            >
                                {item.title}
                            </div>
                            <div className="ticket-option-col-2 ticket-price">{item?.price?.toLocaleString()} VND</div>
                            <div className="ticket-option-col-3 ticket-slot">
                                {
                                    getStatus(item)
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <Modal
                className="ticket-option-detail"
                title="Ticket info"
                open={showTicketModal}
                onCancel={handleCancel}
                footer={null}
            >
                <Badge className="ticket-detail-slot" status="processing"
                       text={`${viewTicket?.totalQuantity - viewTicket?.soldQuantity} remaining`}/>
                <Badge
                    className="ticket-detail-due-date"
                    status="default"
                    text={`Sales end on ${formatDateRange(viewTicket?.endDateAvailable, DateTimePattern.TICKET_DATE_TIME)}`}
                />
                <div className="ticket-detail-description">
                    {viewTicket?.description}
                </div>
            </Modal>
        </>
    );
};

export default TicketOptionForm;