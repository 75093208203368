import { createContext, useContext, useState, } from "react";

export const MeetingAppContext = createContext();
export const useMeetingAppContext = () => useContext(MeetingAppContext);

export const MeetingAppProvider = ({ children }) => {
    const [sideBarMode, setSideBarMode] = useState(null);
    const [afterMeetingJoinedHLSState, setAfterMeetingJoinedHLSState] = useState(null);

    return (
        <MeetingAppContext.Provider
            value={{
                // states
                afterMeetingJoinedHLSState,
                sideBarMode,
                // setters
                setAfterMeetingJoinedHLSState,
                setSideBarMode,
            }}
        >
            {children}
        </MeetingAppContext.Provider>
    );
};
