import { Microphone, MicrophoneSlash } from "@phosphor-icons/react";
import { useMeeting } from "@videosdk.live/react-sdk";
import { Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMicDeviceId } from "../../../../../../common/redux/slice/videosdkSlice";

const MicButton = () => {
    const mMeeting = useMeeting();
    const [mics, setMics] = useState([]);
    const localMicOn = mMeeting?.localMicOn;
    const changeMic = mMeeting?.changeMic;
    const dispatch = useDispatch();

    useEffect(() => {
        const getMics = async (mGetMics) => {
            const mics = await mGetMics();
            mics && mics?.length && setMics(mics);
        };

        getMics(mMeeting.getMics)
    }, []);

    const handleClickMic = async () => {
        mMeeting.toggleMic();
    }

    const handleMenuClick = async (e) => {
        const deviceId = e?.deviceId;
        dispatch(setMicDeviceId(deviceId));
        changeMic(deviceId);
    };

    const micMenu = {
        items: mics.map(({ deviceId, label }, index) => ({
            key: `mics_${deviceId}`,
            label: <>{label || `Mic ${index + 1}`}</>,
        })),
        onClick: handleMenuClick,
    }

    return <Dropdown.Button size="large" trigger="click" type="primary" onClick={handleClickMic} menu={micMenu}>
        {
            localMicOn
                ? <Microphone size={24} weight="fill"/>
                : <MicrophoneSlash size={24} weight="fill"/>
        }
    </Dropdown.Button>;
};

export default MicButton;