import { Copy } from "@phosphor-icons/react";
import { Button, Input, List, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import "./Feedbacks.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DateTimePattern } from "../../../common/enums/DateTimePattern";
import { getAllEventFeedbacks } from "../../../common/redux/actions/feedbackAction";
import { formatDateRange } from "../../../common/utils/dateTimeUtils";


function Feedbacks() {
    const [feedbackFormUrl, setFeedbackFormUrl] = useState();
    const { feedbacks } = useSelector((state) => state.feedback);
    const { eventId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllEventFeedbacks({ eventId: eventId }))
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (eventId) {
            const feedbackUrl = `${window.location.origin}/events/${eventId}/feedback`;
            setFeedbackFormUrl(feedbackUrl);
        }
    }, [eventId]);

    const handleCopyFeedbackUrl = () => {
        navigator.clipboard.writeText(feedbackFormUrl);
    };

    return (
        <div className="feedback-page">
            <div className="feedback-container">
                <div className="feedback-header">
                    <h1 className="feedback-heading">
                        Feedbacks
                    </h1>
                </div>
                <div className="feedback-link">
                    <div className="feedback-link-title">Link to feedback form</div>
                    <Space.Compact>
                        <Input style={{ width: 500 }} className="feedback-link-text" value={feedbackFormUrl}/>
                        <Button type={"primary"} icon={<Copy/>} onClick={handleCopyFeedbackUrl}/>
                    </Space.Compact>
                </div>
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={feedbacks.length > 0}
                    dataSource={feedbacks}
                    renderItem={(item) => (
                        <List.Item
                            key={item.id}
                            extra={<div>{`${item.rating}/5`}</div>
                            }
                        >
                            <List.Item.Meta
                                description={item.comment}
                            />
                            <div>{formatDateRange(item.startDateAvailable, DateTimePattern.BASIC_I8N_DATE_TIME)}</div>
                        </List.Item>
                    )}
                />
            </div>

        </div>

    );
}

export default Feedbacks;