import React, { useEffect, useRef, useState } from "react";
import "./WaitingToJoinScreen.scss";

const WaitingToJoinScreen = () => {
    const waitingMessages = [
        { index: 0, text: "Creating a room for you..." },
        { index: 1, text: "Almost there..." },
    ];
    const [message, setMessage] = useState(waitingMessages[0]);
    const intervalRef = useRef(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setMessage((s) =>
                s.index === waitingMessages.length - 1
                    ? s
                    : waitingMessages[s.index + 1]
            );
        }, 3000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    return (
        <div className="wait-screen-container">
            {message.text}
        </div>
    );
};

export default WaitingToJoinScreen;
