export const eventStatusItems = [
    // {
    //     key: 'all',
    //     value: 'all',
    //     label: <p>All status</p>,
    // },
    {
        key: 'draft',
        value: 'draft',
        label: <p>Draft</p>,
    },
    {
        key: 'published',
        value: 'published',
        label: <p>Published</p>,
    },
    {
        key: 'happening',
        value: 'happening',
        label: <p>Happening</p>,
    },
    {
        key: 'ended',
        value: 'ended',
        label: <p>Ended</p>,
    }
]