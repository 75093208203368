import React from 'react';
import "./Thread.scss";
import defaultAvatar from "../../../../assets/images/default_avatar_v2.jpg";


function Thread({senderName, avatar}) {
    return (
        <div className={"message-container"}>
            <div className="image-container">
               <img src={avatar ? avatar : defaultAvatar} alt=""/>
            </div>
            <div className="message-info">
                <div className="sender-name">
                    {senderName}
                </div>
            </div>
        </div>
    );
}

export default Thread;