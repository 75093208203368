import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainLayout from "../../ui/modules/components/MainLayout/MainLayout";
import MainLayoutOrg from "../../ui/modules/components/MainLayoutOrg/MainLayoutOrg";
import ManageEventLayout from "../../ui/modules/components/ManageEventLayout/ManageEventLayout";
import AddTicketPage from "../../ui/pages/AddTicketPage";
import AttendLiveStream from "../../ui/pages/AttendLiveStream";
import ChatPage from "../../ui/pages/ChatPage";
import EventDetail from "../../ui/pages/EventDetail/EventDetail";
import FeedbackEvent from "../../ui/pages/FeedbackEvent";
import Feedbacks from "../../ui/pages/Feedbacks";
import ForgotPassword from "../../ui/pages/ForgotPassword";
import Home from "../../ui/pages/Home/Home";
import HostLiveStream from "../../ui/pages/HostLiveStream";
import LoginPage from "../../ui/pages/LoginPage";
import NotFound from "../../ui/pages/NotFound/NotFound";
import NotificationPage from "../../ui/pages/NotificationPage";
import Onboarding from "../../ui/pages/Onboarding/Onboarding";
import UserInfoForm from "../../ui/pages/Onboarding/partials/UserInfoForm/UserInfoForm";
import UserInterest from "../../ui/pages/Onboarding/partials/UserInterest/UserInterest";
import OnlineEventPage from "../../ui/pages/OnlineEventPage";
import OrganizationCheckin from "../../ui/pages/OrganizationCheckin";
import OrganizationCreateEvent from "../../ui/pages/OrganizationCreateEvent";
import OrganizationCreateEventDetail from "../../ui/pages/OrganizationCreateEventDetail";
import OrganizationCreateMeeting from "../../ui/pages/OrganizationCreateMeeting";
import OrganizationEventDashboard from "../../ui/pages/OrganizationEventDashboard";
import OrganizationEventList from "../../ui/pages/OrganizationEventList";
import OrganizationEventRecording from "../../ui/pages/OrganizationEventRecording";
import OrganizationFinance from "../../ui/pages/OrganizationFinance";
import OrganizationHome from "../../ui/pages/OrganizationHome";
import OrganizationHostOnlineEvent from "../../ui/pages/OrganizationHostOnlineEvent";
import OrganizationOrders from "../../ui/pages/OrganizationOrders";
import OrganizationSettingProfile from "../../ui/pages/OrganizationSettingProfile/OrganizationSettingProfile";
import OrganizerList from "../../ui/pages/OrganizerList";
import OrganizerProfile from "../../ui/pages/OrganizerProfile";
import PastEventPage from "../../ui/pages/PastEventPage";
import PastEventFeedback from "../../ui/pages/PastEventPage/partials/PastEventFeedback/PastEventFeedback";
import PastEventInfo from "../../ui/pages/PastEventPage/partials/PastEventInfo";
import PastEventRecording from "../../ui/pages/PastEventPage/partials/PastEventRecording";
import Personal from "../../ui/pages/Personal";
import PersonalPreference from "../../ui/pages/Personal/partials/PersonalPreference/PersonalPreference";
import PersonalProfile from "../../ui/pages/Personal/partials/PersonalProfile/PersonalProfile";
import PublishPage from "../../ui/pages/PublishPage";
import RegistrationPage from "../../ui/pages/Registration/RegistrationPage";
import ReservationDetail from "../../ui/pages/ReservationDetail/ReservationDetail";
import ResetPassword from "../../ui/pages/ResetPassword";
import SearchResults from "../../ui/pages/SearchResults";
import TicketOrderDetail from "../../ui/pages/TicketOrderDetail/TicketOrderDetail";
import TicketReservation from "../../ui/pages/TicketReservation";
import TicketFinish from "../../ui/pages/TicketReservation/partials/TicketFinish/TicketFinish";
import TicketOrderInfo from "../../ui/pages/TicketReservation/partials/TicketOrderInfo/TicketOrderInfo";
import TicketPayment from "../../ui/pages/TicketReservation/partials/TicketPayment/TicketPayment";
import UserLikedEvents from "../../ui/pages/UserPage/partials/LikedEvents/UserLikedEvents";
import UserFollowing from "../../ui/pages/UserPage/partials/UserFollowing/UserFollowing";
import UserPastEvents from "../../ui/pages/UserPage/partials/UserPastEvents/UserPastEvents";
import UserReservations from "../../ui/pages/UserPage/partials/UserReservations/UserReservations";
import UserUpcomingEvents from "../../ui/pages/UserPage/partials/UserUpcomingEvents/UserUpcomingEvents";
import UserPage from "../../ui/pages/UserPage/UserPage";
import { FAVORITE, FOLLOWING_ORG, IS_LOGGED_IN } from "../constants/LocalStorageConstants";
import { getFollowingOrganizationIds } from "../redux/actions/accountAction";
import { getFavoriteEventIds } from "../redux/actions/eventActions";
import { authError, setUser } from "../redux/slice/authSlice";
import supabase from "../supbaseClient";
import { ProtectedRoute } from "./ProtectedRoute";
import authService from "../services/authenticateService";
import { logout } from "../redux/actions/authActions";
import { toast } from "sonner";

const Routes = () => {
    const { session, user } = useSelector((state) => state.auth);
    const { favoriteEvents, success } = useSelector((state) => state.event);
    const { followingOrganizations: followOrgs } = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const username = user?.id;

    useEffect(() => {
        const checkDataLocalStorage = (actionType, data, fetchedData, storageKey) => {
            if (data === null && username) {
                const params = { username };
                dispatch(actionType(params));
                if (success && fetchedData?.length > 0) {
                    localStorage.setItem(storageKey, fetchedData);
                }
            }
        };

        const storedFavoriteEvents = localStorage.getItem(FAVORITE);
        const storedFollowingOrgs = localStorage.getItem(FOLLOWING_ORG);

        checkDataLocalStorage(getFavoriteEventIds, storedFavoriteEvents, favoriteEvents, FAVORITE);
        checkDataLocalStorage(getFollowingOrganizationIds, storedFollowingOrgs, followOrgs, FOLLOWING_ORG);
        // eslint-disable-next-line
    }, [user, dispatch]);


    useEffect(() => {
        const initializeSession = async () => {
            try {
                const { data: { session } } = await supabase.auth.getSession();
                if (session) {
                    const request = {
                        email: session.user.email
                    }
                    const res = await authService.verifyUserExist(request);
                    if (!res) {
                        const req = {
                            firstName: session.user?.user_metadata?.name,
                            lastName: "",
                            username: session.user?.id,
                            email: session.user?.email
                        }
                        await authService.register(req)
                    }
                    dispatch(setUser(session.user));
                    localStorage.setItem(IS_LOGGED_IN, "true")
                }
            } catch (error) {
                toast.error(error?.response?.data)
                localStorage.removeItem(IS_LOGGED_IN)
                dispatch(logout())
                dispatch(authError(error.message));
            }
        };

        initializeSession();
    }, [dispatch]);

    // Define public routes accessible to all users
    const routesForPublic = [
        {
            element: <MainLayout/>,
            children: [
                {
                    path: "*",
                    element: <NotFound/>,
                },
                {
                    path: "/",
                    element: <Home/>,
                },
                {
                    path: "/find",
                    element: <SearchResults/>,
                },
                {
                    path: "/events/:eventId",
                    element: <EventDetail/>,
                },
                {
                    path: "/organizers",
                    element: <OrganizerList/>,
                },
                {
                    path: "/organizers/:organizerId",
                    element: <OrganizerProfile/>,
                },
                {
                    path: "/notifications",
                    element: <NotificationPage/>,
                },
            ],
        },
        {
            path: "/login",
            element: <LoginPage/>,
        },
        {
            path: "/register",
            element: <RegistrationPage/>,
        },
        {
            path: "/forgot-password",
            element: <ForgotPassword/>,
        },
        {
            path: "/reset-password",
            element: <ResetPassword/>,
        },
    ];

    // Define routes accessible only to authenticated users
    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute/>, // Wrap the component in ProtectedRoute
            children: [
                {
                    element: <MainLayout/>,
                    children: [
                        {
                            path: "/onboarding",
                            element: <Onboarding/>,
                            children: [
                                {
                                    path: "user-info",
                                    element: <UserInfoForm/>,
                                },
                                {
                                    path: "user-preference",
                                    element: <UserInterest/>,
                                }
                            ]
                        },
                        {
                            path: "/account",
                            element: <Personal/>,
                            children: [
                                {
                                    path: "profile",
                                    element: <PersonalProfile/>,
                                },
                                // {
                                //     path: "account",
                                //     element: <PersonalAccount/>,
                                // },
                                {
                                    path: "preference",
                                    element: <PersonalPreference/>,
                                },
                            ],
                        },
                        {
                            path: "/user",
                            element: <UserPage/>,
                            children: [
                                {
                                    path: "liked-events",
                                    element: <UserLikedEvents/>,
                                },
                                {
                                    path: "reservations",
                                    element: <UserReservations/>,
                                },
                                {
                                    path: "upcoming-events",
                                    element: <UserUpcomingEvents/>,
                                },
                                {
                                    path: "past-events",
                                    element: <UserPastEvents/>,
                                },
                                {
                                    path: "following-organizations",
                                    element: <UserFollowing/>,
                                },
                            ],
                        },
                        {
                            path: "/reservations/:reservationCode",
                            element: <ReservationDetail/>,
                        },
                        {
                            path: "/reservations/:reservationCode/tickets/:ticketId",
                            element: <TicketOrderDetail/>,
                        },
                        {
                            path: "/events/:eventId/ticket-reservation/:reservationId?",
                            element: <TicketReservation/>,
                            children: [
                                {
                                    path: "order-info",
                                    element: <TicketOrderInfo/>,
                                },
                                {
                                    path: "payment",
                                    element: <TicketPayment/>,
                                },
                                {
                                    path: "finish",
                                    element: <TicketFinish/>,
                                    children: [
                                        {
                                            path: ":reservationId",
                                            element: <TicketFinish/>
                                        }
                                    ]
                                },
                            ],
                        },
                        {
                            path: "messages",
                            element: <ChatPage/>,
                            children: [
                                {
                                    path: ":threadId",
                                    element: <ChatPage/>
                                }
                            ]
                        },
                        {
                            path: "online-event/:eventId",
                            element: <OnlineEventPage/>,
                        },
                        {
                            path: "past-events/:eventId",
                            element: <PastEventPage/>,
                            children: [
                                {
                                    path: "info",
                                    element: <PastEventInfo/>
                                },
                                {
                                    path: "feedback",
                                    element: <PastEventFeedback/>
                                },
                                {
                                    path: "recording",
                                    element: <PastEventRecording/>
                                }
                            ]
                        },
                        {
                            path: "/events/:eventId/feedback",
                            element: <FeedbackEvent/>,
                        },
                    ]
                },
                {
                    path: "host-live-stream/:eventId/rooms/:studioCode",
                    element: <HostLiveStream/>,
                },
                {
                    path: "attend-online-event/:eventId/:meetingId",
                    element: <AttendLiveStream/>,
                },
                {
                    path: "/organization/manage",
                    element: <MainLayoutOrg/>,
                    children: [
                        {
                            path: "createEvent",
                            element: <OrganizationCreateEvent/>,
                        },
                        {
                            path: "profile",
                            element: <OrganizationSettingProfile/>,
                        },
                        {
                            path: "messages/",
                            element: <ChatPage/>,
                            children: [
                                {
                                    path: ":threadId",
                                    element: <ChatPage/>
                                }
                            ]
                        },
                        {
                            path: "finance",
                            element: <OrganizationFinance/>,
                        },
                        {
                            path: "events/:eventId",
                            element: <ManageEventLayout/>,
                            children: [
                                {
                                    path: "dashboard",
                                    element: <OrganizationEventDashboard/>,
                                },
                                {
                                    path: "basic-info",
                                    element: <OrganizationCreateEvent/>,
                                },
                                {
                                    path: "detail-info",
                                    element: <OrganizationCreateEventDetail/>,
                                },
                                {
                                    path: "online-event",
                                    element: <OrganizationCreateMeeting/>,
                                },
                                {
                                    path: "tickets",
                                    element: <AddTicketPage/>,
                                },
                                {
                                    path: "preview-publish",
                                    element: <PublishPage/>
                                },
                                {
                                    path: "event-recording",
                                    element: <OrganizationEventRecording/>
                                },
                                {
                                    path: "host-online-event",
                                    element: <OrganizationHostOnlineEvent/>,
                                },
                                {
                                    path: "reservations",
                                    element: <OrganizationOrders/>
                                },
                                {
                                    path: "feedbacks",
                                    element: <Feedbacks/>
                                },
                                {
                                    path: "attendees",
                                    element: <OrganizationCheckin/>
                                }
                            ]
                        },
                        {
                            path: "events",
                            element: <OrganizationEventList/>,
                        },
                        {
                            path: "home",
                            element: <OrganizationHome/>
                        },
                    ],
                },
            ],
        },
    ];

    // Define routes accessible only to non-authenticated users
    const routesForNotAuthenticatedOnly = [
        {
            path: "/",
            element: <div>Home Page</div>,
        },
    ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
        ...routesForPublic,
        ...(!session ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router}>{/* <Navbar /> */}</RouterProvider>;
};

export default Routes;
