import React from "react";
import "./EventTags.scss";

export const EventTags = ({ eventTags }) => {
    return (
        <div className="event-tags-container">
            {
                eventTags.map((tag, index) => (
                    <div key={index} className="tag-item">
                        # {tag}
                    </div>
                ))
            }
        </div>
    );
};
