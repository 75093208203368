import axios from 'axios';
import { AI_API_BASE_URL } from "../constants/AppConfigConstants";


const axiosInstanceForAI = axios.create({
    baseURL: AI_API_BASE_URL,
    headers: {
        "content-type": "application/json",
    },
});

axiosInstanceForAI.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstanceForAI;
