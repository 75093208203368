import { createAsyncThunk } from '@reduxjs/toolkit';
import meetingService from "../../services/meetingService";
import { CREATE_MEETING_CODE, CREATE_MEETING_ROOM, GET_MEETING_ROOM_FOR_ATTENDEE } from "../types/meeting";


export const createMeetingCode = createAsyncThunk(
    CREATE_MEETING_CODE,
    async (data, { rejectWithValue }) => {
        try {
            return await meetingService.createMeetingCode(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const createMeetingRoom = createAsyncThunk(
    CREATE_MEETING_ROOM,
    async (data, { rejectWithValue }) => {
        try {
            return await meetingService.createMeetingRoom(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getMeetingRoomForAttendee = createAsyncThunk(
    GET_MEETING_ROOM_FOR_ATTENDEE,
    async (data, { rejectWithValue }) => {
        try {
            return await meetingService.getMeetingRoomForAttendee(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);