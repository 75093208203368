import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { convertTimeframeToSeconds } from "../../../../common/utils/dateTimeUtils";

const VideoItem = ({ fileUrl, timeframeStr }) => {
    const [isPlay, setIsPlay] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const playerRef = useRef();
    const timeframe = convertTimeframeToSeconds(timeframeStr);
    const startTime = timeframe - 0;
    const endTime = timeframe;

    useEffect(() => {
        if (playerRef.current && startTime) {
            playerRef.current.seekTo(startTime);
            setIsPlay(true);
        }
    }, [startTime]);

    const onReady = useCallback(() => {
        if (!isReady) {
            playerRef.current.seekTo(startTime, "seconds");
            setIsReady(true);
            setIsPlay(true);
        }
        // eslint-disable-next-line
    }, [isReady]);

    const onProgress = (e) => {
        if (playerRef.current && playerRef.current.getCurrentTime() >= endTime) {
            playerRef.current.seekTo(startTime);
        }
    };

    return (
        fileUrl
            ? <ReactPlayer
                ref={playerRef}
                onReady={onReady}
                playing={isPlay}
                url={fileUrl}
                controls={true}
                onProgress={onProgress}
                loop={true}
                volume={0}
            />
            : null
    );
}

export default VideoItem;