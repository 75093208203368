import { createSlice } from "@reduxjs/toolkit";
import { TicketModel } from "../../models/ticketModel.model";
import {
    addTicket,
    deleteTicketById,
    getReservedTicketsByEventId, getTicketById,
    getTicketsByEventId,
    updateTicket
} from "../actions/ticketAction";

const ticketSlice = createSlice({
    name: 'ticket',
    initialState: TicketModel,
    reducers: {
        setTickets(state, action) {
            state.tickets = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addTicket.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(addTicket.fulfilled, (state, action) => {
                state.ticketId = action.payload;
                state.loading = false;
            })
            .addCase(addTicket.rejected, (state, action) => {
                state.ticketId = null;
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getTicketsByEventId.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getTicketsByEventId.fulfilled, (state, action) => {
                state.loading = false;
                state.tickets = action.payload;
            })
            .addCase(getTicketsByEventId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteTicketById.pending, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteTicketById.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload === true) {
                    state.tickets = state.tickets.filter((ticket) => ticket.id !== action.meta.arg)
                }
            })
            .addCase(deleteTicketById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateTicket.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateTicket.fulfilled, (state, action) => {
                state.loading = false;
                state.ticketId = action.meta.arg.ticketId;
            })
            .addCase(updateTicket.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getReservedTicketsByEventId.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getReservedTicketsByEventId.fulfilled, (state, action) => {
                state.loading = false;
                state.reservedTickets = action.payload;
            })
            .addCase(getReservedTicketsByEventId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.reservedTickets = [];
            })
            .addCase(getTicketById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getTicketById.fulfilled, (state, action) => {
                state.loading = false;
                state.ticket = action.payload;
            })
            .addCase(getTicketById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    },
});
export default ticketSlice.reducer;
export const { setTickets } = ticketSlice.actions;
