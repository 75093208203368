import { Scanner, useDeviceList } from "@yudiel/react-qr-scanner";
import { Button, Select } from "antd";
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { checkinOfflineEvent } from "../../../../../common/redux/actions/checkinAction";
import "./QRScanner.scss";

const QRScanner = ({ organization, setFetchTickets }) => {
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [stopScanning, setStopScanning] = useState(false);
    const [enableScanner, setEnableScanner] = useState(false);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [defaultDevice, setDefaultDevice] = useState();
    const [deviceId, setDeviceId] = useState();
    const deviceList = useDeviceList();
    // const deviceList = [];

    useEffect(() => {
        if (deviceList?.length > 0) {
            // console.log(deviceList)
            const devices = deviceList.map((item, index) => ({
                key: index,
                value: item.deviceId,
                label: item.label,
            }));
            setDeviceOptions(devices);
            if (devices.length > 0) setDefaultDevice(devices[0].label);
        }
    }, [deviceList]);

    const handleScanResult = (text, result) => {
        const payload = {
            organizationId: organization?.organizationId,
            qrCode: text,
        };
        dispatch(checkinOfflineEvent(payload))
            .unwrap()
            .then((response) => {
                toast.info("Successfully checked-in!");
                setFetchTickets(true);
            })
            .catch((error) => {
                toast.error("Invalidate ticket!");
            })
            .finally(() => {
                setStopScanning(true);
                setEnableScanner(false);
            });
    }

    const handleScanError = (error) => {
        toast.error("Error scan QR Code");
    }

    const handleOpenScanner = () => {
        setEnableScanner(enableScanner => !enableScanner);
    }

    const handleSelectDevice = (value) => {
        setDeviceId(value);
    }

    return (
        <div className="qr-scanner-container">
            {(defaultDevice && deviceOptions)
                && <div className="qr-scanner-control">
                    <Button
                        className="qr-scanner-button"
                        onClick={handleOpenScanner}
                    >
                        {enableScanner ? "Close scanner" : "Open scanner"}
                    </Button>
                    <Select
                        style={{ width: 200 }}
                        onChange={handleSelectDevice}
                        placeholder={"Select device"}
                        defaultValue={defaultDevice}
                        options={deviceOptions}
                    />
                </div>}
            <div className="qr-scanner-cam">
                <Scanner
                    onResult={handleScanResult}
                    onError={handleScanError}
                    deviceId={deviceId}
                    enabled={enableScanner}
                    stopScanning={stopScanning}
                />
            </div>
        </div>
    );
}

export default QRScanner;