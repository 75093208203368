export const AccountModel = {
    account: null,
    avatar: null,
    categories: null,
    followingOrganizationIds: null,
    followingOrganizations: null,
    hasMoreOrganizations: false,
    pastEvents: null,
    upcomingEvents: null,
    reservations: null,
    reservation: null,
    profileStatistic: null,
    loading: null,
    success: null,
    error: null,
};
