import React, { useLayoutEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import "./NotificationPage.scss";
import NotificationList from "./partials/NotificationList";

function NotificationPage() {
    const { setShowSidebar } = useOutletContext();
    useLayoutEffect(() => {
        if (setShowSidebar) setShowSidebar(false);
    });

    return (
        <div className="notification-page-container">
            <div className="notification-header">
                Notifications
            </div>
            <NotificationList/>
        </div>
    );
}

export default NotificationPage;