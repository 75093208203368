import React, { useLayoutEffect } from "react";
import useRedirectIfLoggedIn from "../../../common/hooks/useRedirectIfLoggedIn";
import "./LoginPage.scss";
import HighlightReel from "./partials/HighlightReel/HighlightReel";
import LoginForm from "./partials/LoginForm/LoginForm";

function LoginPage() {
    const isLoggedIn = useRedirectIfLoggedIn();

    useLayoutEffect(() => {
        document.title = "Login | Eventure";
    });

    if (isLoggedIn) {
        return null;
    }

    return (
        <div className="login-wrapper">
            <div className="background-overlay"></div>
            <div className="login-container">
                <div className="left-content">
                    <HighlightReel/>
                </div>
                <div className="right-content">
                    <LoginForm/>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
