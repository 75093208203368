import axiosInstance from "../api";

const statisticService = {
    countEventPageView: async (params) => {
        try {
            return await axiosInstance.get(
                "/api/v1/audience/viewEventPage",
                { params: params }
            );
        } catch (error) {
            console.error("Error count page view:", error);
            throw error;
        }
    },
    getEventStatistic: async (params) => {
        try {
            return await axiosInstance.get(
                "/api/v1/organization/getEventStatistic",
                { params: params }
            );
        } catch (error) {
            console.error("Error get event statistics:", error);
            throw error;
        }
    },
    getOrganizationStatistic: async (params) => {
        try {
            return await axiosInstance.get(
                "/api/v1/organization/getOrganizationStatistic",
                { params: params }
            );
        } catch (error) {
            console.error("Error get org statistics:", error);
            throw error;
        }
    }
};

export default statisticService;
