import { Bicycle, Car, Eye, EyeSlash, MapPin, MapTrifold, PersonSimpleWalk } from "@phosphor-icons/react";
import { GoogleMap, InfoWindowF, MarkerF} from "@react-google-maps/api";
import { Button, Space, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DIRECTION_BASE_URL, GOOGLE_MAP_API, SEARCH_BASE_URL } from "../../../../../common/constants/MapConstants";
import { TravelMode } from "../../../../../common/enums/TravelMode";
import mapPin from "../../../../assets/icons/map-pin.svg";
import "./EventAddress.scss";
import useGoogleMapsLoader from "../../../../../common/hooks/useGoogleMapsLoader";

const { Paragraph } = Typography;

const containerStyle = {
    width: "100%",
    height: "100%"
};

export const EventAddress = ({ location }) => {
    const [displayMap, setDisplayMap] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);
    
    const { isLoaded } = useGoogleMapsLoader(GOOGLE_MAP_API)
    
    const center = {
        lat: location?.latitude,
        lng: location?.longitude
    };

    const address = [
        location?.address,
        location?.district,
        location?.province
    ].filter(Boolean).join(", ");

    const mapUrl = `${SEARCH_BASE_URL}&query=${encodeURIComponent(address)}`;

    const showMarker = (e) => setActiveMarker(activeMarker ? null : e);
    const hideMarker = () => setActiveMarker(null);

    const handleGetDirection = (travelMode) => {
        const url = new URL(DIRECTION_BASE_URL);
        url.searchParams.set("destination", address);
        url.searchParams.set("destination_place_id", address);
        url.searchParams.set("travelmode", travelMode);
        window.open(url?.toString(), "_blank");
    }

    const handleDisplayMap = () => {
        setDisplayMap(displayMap => !displayMap);
    };

    return (
        <div className="event-address-container">
            <div className="event-address-info">
                <MapPin weight="fill" />
                <div className='event-address-main'>
                    <Paragraph copyable={{ text: address }}>{address}</Paragraph>
                    <div className='event-address-btn'>
                        <Button type="default" icon={<MapTrifold />}>
                            <Link
                                to={mapUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View on google map
                            </Link>
                        </Button>
                        <Button
                            type="default"
                            icon={displayMap ? <EyeSlash /> : <Eye />}
                            onClick={handleDisplayMap}
                        >
                            {displayMap ? "Hide" : "Show"} map
                        </Button>
                    </div>
                </div>
            </div>
            {displayMap &&
                <div className="event-address-map">
                    <div className="event-address-direction">
                        <p>How to get there</p>
                        <Space>
                            <Button
                                type="text"
                                icon={<Car />}
                                onClick={() => handleGetDirection(TravelMode.DRIVING)}
                            />
                            <Button
                                type="text"
                                icon={<PersonSimpleWalk />}
                                onClick={() => handleGetDirection(TravelMode.WALKING)}
                            />
                            <Button
                                type="text"
                                icon={<Bicycle />}
                                onClick={() => handleGetDirection(TravelMode.BICYCLING)}
                            />
                        </Space>
                    </div>
                    <div className="event-address-map-graphic">
                        {
                            isLoaded ? (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={15}
                                >
                                    <MarkerF
                                        position={center}
                                        icon={mapPin}
                                        onClick={showMarker}
                                        onMouseOver={showMarker}
                                    >
                                        {activeMarker && <InfoWindowF
                                            position={center}
                                            visible={true}
                                            marker={activeMarker}
                                            onCloseClick={hideMarker}
                                        >
                                            <div className="event-address-map-info-window">
                                                <p>{address}</p>
                                                <Link
                                                    to={mapUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    View on google map
                                                </Link>
                                            </div>
                                        </InfoWindowF>}
                                    </MarkerF>
                                </GoogleMap>
                            )
                                :
                                <></>
                        }
                    </div>
                </div>
            }
        </div>
    )
        ;
};
