import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_TRANSACTION } from "../types/transaction";
import transactionService from "../../services/transactionService";

export const getTransactionsByOrganizationId = createAsyncThunk(
    GET_TRANSACTION,
    async (orgId, { rejectWithValue }) => {
        try {
            return await transactionService.getTransactionsByOrganizationId(orgId)
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    })
