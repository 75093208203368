import React from "react";
import PlayerViewer from "./PlayerViewer";
import "./HLSContainer.scss";

const MotionPlayer = () => {
    return (
        <div style={{ height: `100%`, width: `100%` }}>
            <PlayerViewer/>
        </div>
    );
};

export const MemoizedMotionPlayer = React.memo(MotionPlayer);

const HLSContainer = () => {
    return <div className="hls-container">
        <MemoizedMotionPlayer/>
    </div>;
};

export default HLSContainer;
