import { Button } from "antd";
import React, { useLayoutEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import "./PastEventInfo.scss";

const PastEventInfo = () => {
    const { eventId, setActiveTab } = useOutletContext();

    useLayoutEffect(() => {
        setActiveTab("info");
    }, [setActiveTab]);

    return (
        <div className="past-event-info">
            <p>You've join this event.</p>
            <p> We hope you enjoyed the event! </p>
            <Link to={`/events/${eventId}`}>
                <Button>View event detail</Button>
            </Link>
        </div>
    );
};

export default PastEventInfo;