import { Table } from "antd";
import React, { useEffect, useState } from 'react';
import "./OrgPeople.scss";

const OrgPeople = ({ people }) => {
    const [followers, setFollowers] = useState([]);

    useEffect(() => {
        if (people) {
            const listPeople = people?.map((item, index) => ({
                email: item,
                index: index + 1,
                title: "follower"
            }));
            setFollowers(listPeople);
        }
    }, [people]);

    const columns = [
        {
            title: 'No.',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '',
            dataIndex: 'title',
            key: 'title',
        },
    ];

    return (
        <div className="org-people-container">
            <Table dataSource={followers} columns={columns}/>
        </div>
    );
}

export default OrgPeople;