import { ExclamationCircleFilled } from "@ant-design/icons";
import { Form, Modal } from "antd";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import {
    addTicket,
    deleteTicketById,
    getTicketsByEventId,
    updateTicket
} from "../../../../../common/redux/actions/ticketAction";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import TopTool from "../../components/TopTool";
import TicketDrawer from "./partials/TicketDrawer";
import TicketTable from "./partials/TicketTable";
import { TicketType } from "../../../../../common/enums/ticketType";

const { confirm } = Modal;

function Ticket({ eventId, orgBankExist }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [searchData, setSearchData] = useState('');
    const [ticketType, setTicketType] = useState(TicketType.PAID)
    const { loading, tickets } = useSelector((state) => state.ticket)
    const { event } = useSelector(state => state.event)
    
    useEffect(() => {
        dispatch(getTicketsByEventId({ eventId: eventId }));
    }, [eventId, dispatch]);
    
    const closeDrawer = () => {
        setOpenDrawer(false)
        setSelectedTicket(null)
        form.resetFields();
    }
    
    const onSearch = (value, _e, info) => {
        setSearchData(value);
    };
    
    const showDrawer = () => {
        setOpenDrawer(true)
    }
    
    const deleteTicket =  (ticket) => {
        confirm({
            title: "Are you sure you want to delete this ticket?",
            icon: <ExclamationCircleFilled/>,
            content: "When clicked the OK button, ticket will be deleted",
            async onOk() {
                try {
                    const res = await dispatch(deleteTicketById(ticket.id)).unwrap();
                    if(res) {
                        toast.success("Delete successfully")
                        closeDrawer();
                    }
                } catch (error) {
                    toast.error(error || "Failed to delete ticket!");
                    closeDrawer();
                }
            },
            onCancel() {
            },
        });
    };
    
    const onFinish = async (values) => {
        const eventEndDate = new Date(event?.endDateTime);
        const ticketStartDate = formatDateRange(values.ticketDateTime[0], DateTimePattern.COMPLETED_DATE_TIME);
        const ticketEndDate = formatDateRange(values.ticketDateTime[1], DateTimePattern.COMPLETED_DATE_TIME);

        if (new Date(ticketStartDate) > eventEndDate) {
            toast.error(`Ticket start date must be before the end date of the event`)
            return;
        }
        
        const ticketData = {
            title: values.ticketTitle,
            description: values.ticketDescription,
            quantity: Number(values.ticketQuantity),
            startDate: ticketStartDate,
            endDate: ticketEndDate,
            price: ticketType === TicketType.FREE ? 0 : Number(values.ticketPrice),
            eventId: Number(eventId),
            ticketType: values.ticketType
        }
        
        const action = selectedTicket ? updateTicket : addTicket;
        const actionData = selectedTicket ? { ...ticketData, ticketId: Number(selectedTicket.id) } : ticketData;
        if (selectedTicket) {
            delete actionData.eventId;
        }
        
        try {
            const res = await dispatch(action(actionData)).unwrap();
            if (res) {
                const message = selectedTicket ? 'Update successfully' : 'Ticket has been created successfully!';
                toast.success(message);
                setTimeout(() => {
                    closeDrawer();
                    dispatch(getTicketsByEventId({ eventId: eventId }));
                }, 1000);
            }
        } catch (error) {
            toast.error(error || 'An error occurred');
            closeDrawer();
        }
    }
    
    const handleChangeTicketType = (type) => {
        setTicketType(type)
    }
    
    const formattedTicketData = tickets?.map((ticket) => {
        const data = {
            ...ticket,
            key: ticket.id,
            startDateAvailable: formatDateRange(ticket.startDateAvailable, DateTimePattern.STANDARD_DATE_TIME),
            endDateAvailable: formatDateRange(ticket.endDateAvailable, DateTimePattern.STANDARD_DATE_TIME),
            remainingTickets: `${Number(ticket.totalQuantity) - Number(ticket.soldQuantity)}/${ticket.totalQuantity}`,
            price: ticket?.price.toLocaleString()
        }
        return data;
    })
    
    const filteredData = formattedTicketData.filter((item) =>
        item.title.toLowerCase().includes(searchData.toLowerCase())
    );
    
    const selectTicket = (ticket) => {
        setSelectedTicket(tickets?.find(orgTicket => orgTicket.id === ticket.id))
        setOpenDrawer(true);
    }
    
    useEffect(() => {
        if (selectedTicket) {
            setTicketType(selectedTicket.ticketType)
        }
    }, [selectedTicket]);
    
    
    return (
        <section className="ticket-list-table">
            <TopTool
                placeholder={"Ticket"}
                onSearch={onSearch}
                handleOnClick={showDrawer}
                buttonText={"Add new ticket"}
                orgBankExist={orgBankExist}
            />
            <TicketTable
                data={filteredData}
                setSelectedTicket={selectTicket}
                deleteTicket={deleteTicket}
                disabledAction={!orgBankExist}
                tickets={tickets}/>
            <TicketDrawer
                onClose={closeDrawer}
                openDrawer={openDrawer}
                form={form}
                onFinish={onFinish}
                handleChangeTicketType={handleChangeTicketType}
                loading={loading}
                ticketType={ticketType}
                ticketData={selectedTicket}/>
        </section>
    );
}

export default Ticket;