import { createSlice } from "@reduxjs/toolkit";
import { createEventFeedback, getAllEventFeedbacks, getFeedbackForEventOfUser } from "../actions/feedbackAction";

const initialState = {
    feedback: null,
    feedbacks: [],
    loading: null,
    success: null,
    error: null,
}

const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFeedbackForEventOfUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFeedbackForEventOfUser.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
                state.feedback = action.payload;
            })
            .addCase(getFeedbackForEventOfUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(createEventFeedback.pending, (state) => {
                state.loading = true;
            })
            .addCase(createEventFeedback.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(createEventFeedback.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
            .addCase(getAllEventFeedbacks.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllEventFeedbacks.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.feedbacks = action.payload;
                state.success = true;
            })
            .addCase(getAllEventFeedbacks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            });
    },
});

export default feedbackSlice.reducer;