import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import "./OnlineEventPage.scss";
import { getMeetingRoomForAttendee } from "../../../common/redux/actions/meetingAction";
import MeetingBanner from "../../modules/components/MeetingBanner/MeetingBanner";
import MeetingSummary from "./partials/MeetingSummary/MeetingSummary";

const OnlineEventPage = () => {
    const { setShowSidebar } = useOutletContext();
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const { attendeeMeetingRoom } = useSelector((state) => state.meeting);

    useLayoutEffect(() => {
        document.title = "Online event | Eventure";
        setShowSidebar(false);
    });

    useEffect(() => {
        if (eventId) {
            dispatch(getMeetingRoomForAttendee({ eventId: eventId }));
        }
    }, [eventId, dispatch]);

    return (
        <div className="online-event-container">
            <div className="online-event-page-title">
                Online event
            </div>
            {
                attendeeMeetingRoom
                    ? <div className="online-event-content">
                        <MeetingBanner meeting={attendeeMeetingRoom}/>
                        <MeetingSummary meeting={attendeeMeetingRoom}/>
                    </div>
                    : <div className="online-event-message">
                        Organizer hasn't created streaming session for this event
                    </div>
            }
        </div>
    );
};

export default OnlineEventPage;
