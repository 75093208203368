import { Spin } from "antd";
import "./Loading.scss";

const Loading = () => {
    return (
        <div className="loading-container">
            <Spin/>
        </div>
    );
};

export default Loading;
