import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvents } from "../../../../common/redux/actions/eventActions";
import EventCardVertical from "../../components/EventCardVertical/EventCardVertical";
import Loading from "../../components/Loading/Loading";
import "./MasterListVertical.scss";

function MasterListVertical() {
    const [allEvents, setAllEvents] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const dispatch = useDispatch();
    const { events, hasMoreEvents } = useSelector((state) => state.event);

    const fetchEvents = (waitingTime) => {
        setTimeout(() => {
            const params = {
                username: null,
                startDate: null,
                endDate: null,
                categories: [],
                eventType: null,
                sortBy: "startDateTime",
                sortOrder: "ASC",
                pageSize: 8,
                pageNumber: pageNumber
            };
            dispatch(getAllEvents(params));
            setPageNumber(pageNumber => pageNumber + 1);
        }, [waitingTime]);
    }

    useEffect(() => {
        fetchEvents(0);
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (events) {
            setAllEvents([...allEvents, ...events]);
        }
        // eslint-disable-next-line
    }, [events]);

    return (
        <div className="master-list-vertical-container">
            <InfiniteScroll
                dataLength={allEvents.length}
                next={() => fetchEvents(1500)}
                hasMore={hasMoreEvents}
                loader={<Loading/>}
            >
                <div className="vertical-event-list">
                    {
                        allEvents?.map((event) =>
                            <EventCardVertical key={event.eventId} eventData={event}/>)
                    }
                </div>
            </InfiniteScroll>
        </div>
    );
};

export default MasterListVertical;
