import { Button } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import "./TicketOrderInfo.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "sonner";
import { CART } from "../../../../../common/constants/LocalStorageConstants";
import CancelReservationButton from "../../blocks/CancelReservationButton/CancelReservationButton";
import TicketBuyerInfo from "../../components/TicketBuyerInfo/TicketBuyerInfo";
import TicketCart from "../../components/TicketCart/TicketCart";
import TicketOptionForm from "../../components/TicketOptionForm/TicketOptionForm";
import { v4 as uuidv4 } from "uuid";
import { purchaseFreeOrder } from "../../../../../common/redux/actions/paymentAction";

const TicketOrderInfo = () => {
    const { setCurrentStep, event } = useOutletContext();
    const navigate = useNavigate();
    const [isEventFree, setIsEventFree] = useState(false);
    const {
        cartReservationId,
        cartEventId,
        cartTickets,
        cartVoucher,
        cartSubtotal,
        cartTotal
    } = useSelector((state) => state.cart);
    const { user } = useSelector((state) => state.auth);
    const username = user?.id;
    const dispatch = useDispatch();
    
    useEffect(() => {
        setIsEventFree(cartTotal === 0)
    }, [cartTotal]);
    
    useLayoutEffect(() => {
        setCurrentStep(1);
    }, [setCurrentStep]);

    const buyerInfoRef = React.createRef();

    const handleFreeOrder = async () => {
        const hasTickets = cartTickets.some(ticket => ticket.quantity !== 0);
        const freeTicket = cartTickets?.find(ticket => ticket.price === 0);
        if (!hasTickets) {
            toast.info("Please select at least 1 ticket to register");
            return;
        }
        
        const buyerInfo = buyerInfoRef.current.handleInputBuyerInfo();
        const isAllInfoFilled = !!(buyerInfo?.firstName?.trim()
            && buyerInfo?.lastName?.trim()
            && buyerInfo?.phone?.trim()
            && buyerInfo?.email?.trim());
        
        if (!isAllInfoFilled) {
            toast.info("Please fill all contact information");
            return;
        }
        
        const request = {
            orderId: uuidv4(),
            username: username,
            voucherCode: null,
            eventId: event?.eventId,
            items: [
                {
                    ticketId: freeTicket?.id,
                    quantity: freeTicket?.quantity,
                    price: freeTicket?.price
                }
            ],
            userInfo: buyerInfo,
        }
        try {
            const res = await dispatch(purchaseFreeOrder(request)).unwrap();
            if (res) {
                toast.success(`Purchase successfully`)
                setTimeout(() => {
                    navigate(`../finish/${request?.orderId}`, { relative: "path" });
                }, 1500)
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const handleCheckout = () => {
        const hasTickets = cartTickets.some(ticket => ticket.quantity !== 0);
        if (!hasTickets) {
            toast.info("Please select at least 1 ticket before going to next step");
            return;
        }

        const buyerInfo = buyerInfoRef.current.handleInputBuyerInfo();
        const isAllInfoFilled = !!(buyerInfo?.firstName?.trim()
            && buyerInfo?.lastName?.trim()
            && buyerInfo?.phone?.trim()
            && buyerInfo?.email?.trim());

        if (!isAllInfoFilled) {
            toast.info("Please fill all contact information");
            return;
        }

        localStorage.setItem(CART, JSON.stringify({
            reservationId: cartReservationId,
            eventId: cartEventId,
            buyer: buyerInfo,
            tickets: cartTickets,
            voucher: cartVoucher,
            subtotal: cartSubtotal,
            total: cartTotal
        }));

        navigate("../payment", { relative: "path" });
    };

    return (
        <div className="ticket-order-info-container">
            <div className="ticket-order-info-content">
                <TicketOptionForm event={event}/>
                <TicketBuyerInfo ref={buyerInfoRef}/>
            </div>
            <div className="ticket-rsv-navigation">
                <div className="ticket-cart">
                    <TicketCart/>
                </div>
                <div className="ticket-cart-button">
                    <Button
                        className="ticket-cart-button-continue"
                        type="primary"
                        block={true}
                        onClick={isEventFree ? handleFreeOrder : handleCheckout}
                    >
                        {isEventFree ? 'Register' : 'Continue'}
                    </Button>
                    {event && <CancelReservationButton eventId={event?.eventId}/>}
                </div>
            </div>
        </div>
    );
};

export default TicketOrderInfo;