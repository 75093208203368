import React from "react";
import "./InfoLayout.scss";

function InfoLayout({ icon, heading, description, children }) {
    return (
        <div className="layout-info-container">
            <div className="layout-info-icon">{icon}</div>
            <div className="layout-info-content">
                <div className="info-text">
                    <h1>{heading}</h1>
                    <div className="info-description">
                        <p className="info-description-text">
                            <span>{description}</span>
                        </p>
                    </div>
                </div>
                <div className="info-formfields">{children}</div>
            </div>
        </div>
    );
}

export default InfoLayout;
