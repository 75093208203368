import React from "react";
import ChatInput from "./ChatInput";
import ChatMessages from "./ChatMessages";
import "./ChatPanel.scss";

export function ChatPanel() {
    return (
        <div className="chat-panel-container">
            <div className="chat-panel-message-list">
                <ChatMessages/>
            </div>
            <div className="chat-panel-input">
                <ChatInput/>
            </div>
        </div>
    );
}
