import { Constants } from "@videosdk.live/react-sdk";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setMeetingId, setToken } from "../../../common/redux/slice/videosdkSlice";
import { createMeeting, getToken } from "../../../common/videosdk/videosdkServices";
import LiveStreamPage from "../../modules/partials/LiveStreamPage/LiveStreamPage";

const HostLiveStream = () => {
    const { eventId, studioCode } = useParams();
    const { token, meetingId } = useSelector((state) => state.videosdk);
    const dispatch = useDispatch();
    const meetingMode = Constants.modes.CONFERENCE;

    const handleClickCreateMeeting = async (_studioCode) => {
        const token = getToken();
        if (token) {
            dispatch(setToken(token));
            if (_studioCode) {
                dispatch(setMeetingId(_studioCode));
            } else {
                const _meetingId = await createMeeting({ token });
                if (_meetingId) dispatch(setMeetingId(_meetingId));
            }
        }
    }

    useEffect(() => {
        if (studioCode) {
            handleClickCreateMeeting(studioCode);
        } else {
            handleClickCreateMeeting("");
        }
    }, [studioCode]);

    return (
        <div className="">
            {
                (token && meetingId) && <LiveStreamPage token={token} meetingId={meetingId} meetingMode={meetingMode}/>
            }
        </div>
    );
}

export default HostLiveStream;