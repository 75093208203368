import axiosInstance from "../api";

const voucherService = {
    createVoucher: async (data) => {
        try {
            return await axiosInstance.post(`/api/v1/host/createVoucher`, data);
        } catch (error) {
            console.error("Error create voucher:", error);
            throw error;
        }
    },
    getVouchersByEventId: async (eventId) => {
        try {
            return await axiosInstance.get(`/api/v1/host/getVouchersByEventId`, {
                params: eventId,
            })
        } catch (error) {
            console.error(`Error getting vouchers:`, error);
            throw error;
        }
    },
    deleteVoucherById: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/host/deleteVoucherById`, request);
        } catch (error) {
            console.error(`Error deleting voucher:`, error);
            throw error;
        }
    },
    verifyVoucher: async (params) => {
        try {
            return await axiosInstance.get(
                `/api/v1/audience/verifyVoucher`,
                { params: params }
            );
        } catch (error) {
            console.error(`Error verify voucher:`, error);
            throw error;
        }
    },
    updateVoucher: async (request) => {
        try {
            return await axiosInstance.post(`/api/v1/host/updateVoucher`, request);
        } catch (error) {
            throw error;
        }
    },
    getVouchersByEventIdForAttendee: async (params) => {
        try {
            return await axiosInstance.get(
                `/api/v1/audience/getVouchersByEventId`,
                { params: params }
            );
        } catch (error) {
            throw error;
        }
    }
};

export default voucherService;
