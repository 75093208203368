import { Record } from "@phosphor-icons/react";
import { Constants, useMeeting } from "@videosdk.live/react-sdk";
import { Button } from "antd";
import { useEffect, useMemo, useRef } from "react";
import useIsRecording from "../../../../../../common/videosdk/hooks/useIsRecording";

const RecordingButton = () => {
    const { startRecording, stopRecording, recordingState } = useMeeting();
    const isRecording = useIsRecording();
    const isRecordingRef = useRef(isRecording);

    useEffect(() => {
        isRecordingRef.current = isRecording;
    }, [isRecording]);

    const { isRequestProcessing } = useMemo(
        () => ({
            isRequestProcessing:
                recordingState === Constants.recordingEvents.RECORDING_STARTING ||
                recordingState === Constants.recordingEvents.RECORDING_STOPPING,
        }),
        [recordingState]
    );

    const _handleClick = () => {
        const isRecording = isRecordingRef.current;
        if (isRecording) stopRecording();
        else startRecording();
    };

    return <Button
        size="large"
        type={isRecording ? "default" : "primary"}
        icon={<Record size={24} weight="fill"/>}
        loading={isRequestProcessing}
        onClick={_handleClick}
    >
        {!isRecording && "REC"}
    </Button>;
};

export default RecordingButton;