import { CaretLeft, StarFour } from "@phosphor-icons/react";
import { Button } from "antd";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { categoryData } from "../../../../../common/constants/CategoryData";
import {
    createOrUpdateInterestCategory,
    getInterestedCategories
} from "../../../../../common/redux/actions/accountAction";
import CategoryTag from '../../../../modules/components/CategoryTag/CategoryTag';
import "./ProfilePreference.scss";

const ProfilePreference = ({ isOnboarding, setPath }) => {
    const [interestedCategories, setInterestedCategories] = useState([]);
    const [loadingUpdatePreferenceButton, setLoadingUpdatePreferenceButton] = useState(false);
    const dispatch = useDispatch();
    const { categories } = useSelector((state) => state.account);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const username = user?.id;
        if (username) {
            const params = { username: username };
            dispatch(getInterestedCategories(params));
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (categories) {
            const filteredList = categoryData.filter(
                obj => categories.some(searchObj => searchObj.id === obj.id)
            ).map(obj => obj.id);
            setInterestedCategories(filteredList);
        }
    }, [categories]);

    const handleUpdatePreference = () => {
        if (interestedCategories.length < 3) {
            toast.error("Please select at least 3 categories");
            return;
        }
        setLoadingUpdatePreferenceButton(true);
        const username = user?.id;
        if (username) {
            const request = {
                username: username,
                category: interestedCategories
            };
            dispatch(createOrUpdateInterestCategory(request))
                .unwrap()
                .then((data) => {
                    if (data) {
                        toast.success("Successfully update your preferences!");
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        if (isOnboarding) {
                            setPath("/");
                        }
                    }, 1500);
                    setLoadingUpdatePreferenceButton(false);
                });
        }
    };

    const handleToggleInterestCategory = (categoryId) => {
        setInterestedCategories((prevInterestedCategories) =>
            prevInterestedCategories.includes(categoryId)
                ? prevInterestedCategories.filter((item) => item !== categoryId)
                : [...prevInterestedCategories, categoryId]
        );
    };

    return (
        <div className="profile-preference-container">
            <div className={`user-interest-tag ${!isOnboarding && "align-right-tags"}`}>
                {categoryData.map((item) => (
                    <CategoryTag
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        icon={item.icon}
                        isSelected={interestedCategories.includes(item?.id)}
                        handleToggleInterestCategory={handleToggleInterestCategory}
                    />
                ))}
            </div>
            {
                isOnboarding
                    ? <div className="profile-preference-button">
                        <Button
                            href={"/onboarding/user-info"}
                            icon={<CaretLeft/>}
                        >
                            Back
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleUpdatePreference}
                            loading={loadingUpdatePreferenceButton}
                            icon={<StarFour/>}
                        >
                            Explore Eventure
                        </Button>
                    </div>
                    : <Button
                        onClick={handleUpdatePreference}
                        loading={loadingUpdatePreferenceButton}
                    >
                        Save changes
                    </Button>
            }
        </div>
    );
}

export default ProfilePreference;