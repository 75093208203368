import axios from 'axios';
import { API_BASE_URL } from "../constants/AppConfigConstants";


const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        "content-type": "application/json",
    },
});


axiosInstance.interceptors.response.use(
    (response) => {
        // Modify response data if needed
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
