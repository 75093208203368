import React from 'react';
import EventCardVertical from "../EventCardVertical/EventCardVertical";
import './EventListVertical.scss';

const EventListVertical = ({ events, past = false }) => {
    return (
        <div className='vertical-event-list-container'>
            {
                events?.map((event) => (
                    <EventCardVertical key={event.eventId} eventData={event} past={past}/>
                ))
            }
        </div>
    );
};

export default EventListVertical;