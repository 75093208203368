import color from '../styles/color.scss';

const token = {
    colorPrimary: color.primaryColor,
    colorPrimaryHover: color.primaryColorHover,
    fontFamily: 'Inter',
    lineWidth: 1,
    colorBorder: color.greyColor3,
    borderRadius: 8,
    colorBgMask: color.darkBackgroundTransparent1,
}

export default token;