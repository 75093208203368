import { PencilSimple } from "@phosphor-icons/react";
import { Button, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { removeUserAvatar, updateUserAvatar } from "../../../../../common/redux/actions/accountAction";
import defaultThumbnail from "../../../../assets/images/default.jpg";
import defaultAvatar from "../../../../assets/images/default_avatar.jpg";
import "./ProfileAvatar.scss";

const ProfileAvatar = ({ account }) => {
    const [avatarUrl, setAvatarUrl] = useState(defaultAvatar);
    const [loadingUpdateAvatarBtn, setLoadingUpdateAvatarBtn] = useState(false);
    const [loadingRemoveAvatarBtn, setLoadingRemoveAvatarBtn] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setAvatarUrl(account?.avatar ? account.avatar : defaultAvatar);
    }, [account]);

    const handleUpdateAvatar = async ({ file }) => {
        setLoadingUpdateAvatarBtn(true);
        const formData = new FormData();
        formData.append("username", account?.username);
        formData.append("newAvatar", file);
        dispatch(updateUserAvatar(formData))
            .unwrap()
            .then((response) => {
                setAvatarUrl(response);
                toast.success("Successfully update avatar!");
            })
            .catch((error) => {
                toast.success("Fail to update avatar!");
            });
        setLoadingUpdateAvatarBtn(false);
    };

    const handleRemoveAvatar = () => {
        setLoadingRemoveAvatarBtn(true);
        const params = { username: account?.username };
        dispatch(removeUserAvatar(params))
            .unwrap()
            .then((response) => {
                toast.success("Successfully remove avatar!");
                setAvatarUrl(defaultAvatar);
            })
            .catch((error) => {
                toast.error("Error remove avatar");
            });
        setLoadingRemoveAvatarBtn(false);
    }

    return (
        <div className="profile-avatar-container">
            <div className="profile-avatar-image">
                <img src={avatarUrl} alt="" onError={(e) => e.target.src = defaultThumbnail}/>
            </div>
            <div className="profile-avatar-btn">
                <Upload
                    name="avatar"
                    showUploadList={false}
                    customRequest={handleUpdateAvatar}
                    disabled={loadingUpdateAvatarBtn}
                >
                    <Button
                        icon={<PencilSimple/>}
                        loading={loadingUpdateAvatarBtn}
                    >
                        Edit
                    </Button>
                </Upload>
                <Button
                    type="text"
                    loading={loadingRemoveAvatarBtn}
                    onClick={handleRemoveAvatar}
                >
                    Remove
                </Button>
            </div>
        </div>
    );
};

export default ProfileAvatar;
