import { createSlice } from "@reduxjs/toolkit";
import { ReportModel } from "../../models/reportModel.model";
import { reportEvent } from "../actions/reportAction";

const reportSlice = createSlice({
    name: 'report',
    initialState: ReportModel,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(reportEvent.pending, (state) => {
                state.loading = true;
            })
            .addCase(reportEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(reportEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.success = null;
            })
    },
});

export default reportSlice.reducer;
