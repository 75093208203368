import { Money, Percent, Ticket } from "@phosphor-icons/react";
import { Button, DatePicker, Drawer, Form, Input, InputNumber, Select } from "antd";
import React, { useEffect } from 'react';
import { VoucherRuleCondition } from "../../../../../../../common/enums/VoucherRuleCondition";
import { disabledDate, disabledTime, formatDateRange } from "../../../../../../../common/utils/dateTimeUtils";
import "./VoucherDrawer.scss"

function VoucherDrawer({
                           openDrawer,
                           form,
                           onFinish,
                           loading,
                           onClose,
                           voucherData,
                           isAmountOffFilled,
                           setIsAmountOffFilled,
                           isPercentOffFilled,
                           setIsPercentOffFilled,
                           setConditionType,
                           conditionType,
                       }) {
    const { RangePicker } = DatePicker;

    useEffect(() => {
        if (voucherData) {
            const startDate = voucherData?.startDate ? formatDateRange(voucherData.startDate) : '';
            const endDate = voucherData?.endDate ? formatDateRange(voucherData.endDate) : '';

            const amount_off = voucherData?.amountOff ? voucherData?.amountOff : '';
            const percent_off = voucherData?.percentOff ? voucherData?.percentOff : '';

            setIsAmountOffFilled(!!amount_off);
            setIsPercentOffFilled(!!percent_off);

            if (voucherData.conditionType !== VoucherRuleCondition.NONE) {
                setConditionType(voucherData.conditionType)
            }

            form.setFieldsValue({
                code: voucherData?.code,
                totalQuantity: voucherData?.numberOfAvailable,
                amountOff: amount_off,
                percentOff: percent_off,
                voucherDateTime: [startDate, endDate],
                conditionType: voucherData.conditionType,
                conditionValue: voucherData.conditionValue,
                maxDiscountValue: voucherData.maxDiscountValue
            });
        }
        // eslint-disable-next-line
    }, [voucherData, form]);

    const handleOnClose = () => {
        onClose()
    }

    const handleAmountOffChange = (value) => {
        setIsAmountOffFilled(!!value);
    };

    const handlePercentOffChange = (value) => {
        setIsPercentOffFilled(!!value);
    };

    const handleChangeConditionType = (value) => {
        setConditionType(value);
        if (conditionType === VoucherRuleCondition.NONE) {
            form.setFieldsValue({
                conditionValue: null,
            })
        }
    };

    return (
        <Drawer open={openDrawer} onClose={handleOnClose} width={450}>
            <div className="voucher-drawer-contents">
                <div className="voucher-drawer-form-container">
                    <Form form={form} layout="vertical" requiredMark={true} onFinish={onFinish}>
                        <Form.Item
                            label="Voucher code"
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: "Missing voucher code",
                                },
                            ]}
                        >
                            <Input placeholder="Title" size="large"/>
                        </Form.Item>
                        <Form.Item
                            label="Quantity of voucher"
                            name="totalQuantity"
                            rules={[
                                {
                                    required: true,
                                    message: "Missing voucher code",
                                },
                            ]}
                        >
                            <InputNumber min={1} placeholder="quantity" size="large"/>
                        </Form.Item>
                        <div className="amount-discount">
                            <div className="amount-discount-text">Discount amount</div>
                            <div className="amount-discount-inputs">
                                <Form.Item
                                    name="amountOff"
                                    className={"amount-off"}
                                    rules={[
                                        {
                                            required: !isPercentOffFilled,
                                            message: ""
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        prefix={<Money size={18}/>}
                                        size="large"
                                        type="number"
                                        min={1000}
                                        disabled={isPercentOffFilled}
                                        onChange={handleAmountOffChange}
                                    />
                                </Form.Item>
                                <div className="or-text">Or</div>
                                <Form.Item
                                    name="percentOff"
                                    className={"percent-off"}
                                    rules={[
                                        {
                                            required: !isAmountOffFilled,
                                            message: ""
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        size="large"
                                        prefix={<Percent size={18}/>}
                                        type="number"
                                        min={0}
                                        max={100}
                                        disabled={isAmountOffFilled}
                                        onChange={handlePercentOffChange}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        {isPercentOffFilled && (
                            <Form.Item
                                label="Maximum discount amount"
                                name="maxDiscountValue"
                                rules={[
                                    {
                                        required: true,
                                        message: ""
                                    },
                                ]}
                            >
                                <InputNumber
                                    size="large"
                                    prefix={<Money size={18}/>}
                                    type="number"
                                    min={1000}
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            label="Voucher rule"
                            name="conditionType"
                            rules={[
                                {
                                    required: true,
                                    message: "Please choose your rule for voucher"
                                }
                            ]}
                        >
                            <Select onChange={handleChangeConditionType} initialvalues={VoucherRuleCondition.NONE}>
                                <Select.Option value={VoucherRuleCondition.NONE}>None</Select.Option>
                                <Select.Option value={VoucherRuleCondition.MINIMUM_NUMBER}>Apply voucher on minimum
                                    ticket quantity</Select.Option>
                                <Select.Option value={VoucherRuleCondition.MINIMUM_AMOUNT}>Apply voucher on minimum
                                    amount</Select.Option>
                            </Select>
                        </Form.Item>
                        {conditionType === VoucherRuleCondition.MINIMUM_NUMBER ||
                        conditionType === VoucherRuleCondition.MINIMUM_AMOUNT ? (
                            <Form.Item
                                label={"Minimum value to apply voucher"}
                                name="conditionValue"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please choose number for your rule"
                                    }
                                ]}>
                                <InputNumber
                                    prefix={conditionType === VoucherRuleCondition.MINIMUM_NUMBER ?
                                        <Ticket size={18}/> : <Money size={18}/>}
                                    size="large"
                                    type="number"
                                    min={conditionType === VoucherRuleCondition.MINIMUM_NUMBER ? 1 : 1000}
                                />
                            </Form.Item>
                        ) : null}

                        <Form.Item
                            label="Date Time Open/Close"
                            name="voucherDateTime"
                        >
                            <RangePicker
                                size='large'
                                style={{ width: '100%' }}
                                placement='topRight'
                                disabledDate={disabledDate}
                                disabledTime={disabledTime}
                                showTime={{
                                    hideDisabledOptions: true,
                                }}
                                format='DD-MM-YYYY HH:mm'
                            />
                        </Form.Item>

                        <div className="voucher-drawer-buttons">
                            <div className="cancel-button-container">
                                <Button size={"large"} onClick={handleOnClose} block={true}>
                                    Cancel
                                </Button>
                            </div>
                            <div className="save-button">
                                <Form.Item>
                                    <Button type="primary" size={"large"} htmlType={'submit'} loading={loading}
                                            block={true}>
                                        {voucherData ? "Update" : "Save"}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Drawer>
    )
}

export default VoucherDrawer;