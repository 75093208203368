import axiosInstance from "../api";

const addressService = {
    getProvincesDistricts: async (params) => {
        try {
            return await axiosInstance.get(
                "https://raw.githubusercontent.com/kenzouno1/DiaGioiHanhChinhVN/master/data.json"
            );
        } catch (error) {
            console.error("Error get provinces, districts:", error);
            throw error;
        }
    },
};

export default addressService;
