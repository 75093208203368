import { Plus, TrashSimple } from "@phosphor-icons/react";
import { Button, Form, Input } from "antd";
import React from "react";
import "./InputFAQItem.scss";

const InputFAQItem = () => {
    return (
        <div className="input-faq-item-container">
            <Form.List name="faqs">
                {(fields, { add, remove }) => {
                    return <>
                        {fields.map(({ key, name, ...restField }) => (
                            <div key={key}>
                                <div className="input-faq-item-head">
                                    <div className="input-faq-item-title">No. {key + 1}</div>
                                    <Button type="text"
                                            icon={<TrashSimple/>}
                                            danger={true}
                                            onClick={() => remove(name)}
                                    />
                                </div>
                                <Form.Item
                                    variant="filled"
                                    {...restField}
                                    name={[name, 'question']}
                                    rules={[{ required: true, message: 'Missing question' }]}
                                >
                                    <Input placeholder="Your question" allowClear={true}/>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'answer']}
                                    rules={[{ required: true, message: 'Missing answer' }]}
                                >
                                    <Input.TextArea
                                        rows={5}
                                        autoSize={{ minRows: 5, maxRows: 5 }}
                                        placeholder="Your answer"
                                    />
                                </Form.Item>
                            </div>
                        ))}
                        <Form.Item>
                            <Button className="add-item-button" type="dashed" onClick={() => add()} block icon={<Plus/>}>
                                Add FAQ
                            </Button>
                        </Form.Item>
                    </>
                }}
            </Form.List>
        </div>
    );
}

export default InputFAQItem;
