import { CalendarBlank } from "@phosphor-icons/react";
import React from "react";
import { Link } from "react-router-dom";
import { DateTimePattern } from "../../../../../common/enums/DateTimePattern";
import { MeetingProviders } from "../../../../../common/enums/MeetingProviders";
import { formatDateRange } from "../../../../../common/utils/dateTimeUtils";
import { capitalizeFirstLetter } from "../../../../../common/utils/stringUtils";
import logo from "../../../../assets/icons/logo.svg";
import defaultAvatar from "../../../../assets/images/default_avatar_v3.jpg";
import googleMeetLogo from "../../../../assets/images/gg_meet.png";
import zoomLogo from "../../../../assets/images/zoom.png";
import "./MeetingSummary.scss";

const MeetingSummary = ({ meeting }) => {

    return (
        <div className="meeting-summary-container">
            {meeting &&
                <>
                    <div className="meeting-summary-section meeting-datetime">
                        <div className="meeting-summary-section-title">
                            Time
                        </div>
                        <div className="meeting-datetime-info">
                            <div className="meeting-info-date">
                                <CalendarBlank/> {formatDateRange(meeting?.startDateTime, DateTimePattern.STANDARD_DATE_TIME)}
                            </div>
                            {/*<div className="meeting-info-time">*/}
                            {/*    <Clock/> {formatDateRange(meeting?.startDateTime, DateTimePattern.BASIC_I8N_DATE_TIME)}*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="meeting-summary-section meeting-provider">
                        <div className="meeting-summary-section-title">
                            Host via
                        </div>
                        <div className="meeting-provider-info">
                            <div className="meeting-provider-logo">
                                {meeting?.meetingProvider === MeetingProviders.EVENTURE
                                    ? <img src={logo} alt=""/>
                                    : meeting.meetingProvider === MeetingProviders.ZOOM
                                        ? <img src={zoomLogo} alt=""/>
                                        : <img src={googleMeetLogo} alt=""/>
                                }
                            </div>
                            <div className="meeting-provider-name">
                                {capitalizeFirstLetter(meeting?.meetingProvider)}
                            </div>
                        </div>
                    </div>
                    <div className="meeting-summary-section meeting-organizer">
                        <div className="meeting-summary-section-title">
                            Organized by
                        </div>
                        <div className="meeting-organizer-info">
                            <div className="meeting-organizer-avatar">
                                <img src={meeting?.organizationAvatar || defaultAvatar} alt=""/>
                            </div>
                            <div className="meeting-organizer-detail">
                                <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={`/organizers/${meeting?.organizationId}`}
                                    className="meeting-organizer-name"
                                >
                                    {meeting?.organizationName}
                                </Link>
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    );
};

export default MeetingSummary;