import { PencilSimple, Trash } from "@phosphor-icons/react";
import React from "react";
import { Button, Space, Table } from "antd";


function TicketTable({ data, setSelectedTicket, deleteTicket, disabledAction }) {
    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Remaining tickets",
            dataIndex: "remainingTickets",
            key: "remainingTickets",
        },
        {
            title: "Price (VND)",
            dataIndex: "price",
            key: "price",
            align: "right"
        },
        {
            title: "Start Time",
            dataIndex: "startDateAvailable",
            key: "startDateAvailable",
        },
        {
            title: "End Time",
            dataIndex: "endDateAvailable",
            key: "endDateAvailable",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        icon={<PencilSimple/>}
                        disabled={disabledAction}
                        onClick={() => updateTicket(record)}
                    >
                        Update
                    </Button>
                    <Button
                        icon={<Trash/>}
                        disabled={disabledAction}
                        onClick={() => deleteTicketById(record)}
                        danger={true}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    const deleteTicketById = (ticket) => {
        deleteTicket(ticket)
    }
    const updateTicket = (ticket) => {
        setSelectedTicket(ticket)
    }
    return (
        <section className={"ticket-table-container"}>
            <Table columns={columns} dataSource={data}/>
        </section>
    );
}

export default TicketTable;