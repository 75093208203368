import { ChatDots } from "@phosphor-icons/react";
import { Button } from "antd";
import { VideoSDKSideBarModes as sideBarModes } from "../../../../../../common/enums/VideoSDKSidebarMode";

const ChatButton = ({ sideBarMode, setSideBarMode }) => {
    const handleChatSideBar = () => {
        setSideBarMode((s) =>
            s === sideBarModes.CHAT ? null : sideBarModes.CHAT
        );
    }

    return <Button
        size="large"
        type={sideBarMode === sideBarModes.CHAT ? "default" : "primary"}
        icon={<ChatDots size={24} weight="fill"/>}
        onClick={handleChatSideBar}
    />;
};

export default ChatButton;