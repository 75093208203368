import React from "react";
import "./ProfileStatItem.scss";

const ProfileStatItem = ({ label, value }) => {
    return (
        <div className="profile-stat-item">
            <div className="profile-stat-item-value">
                {value}
            </div>
            <div className="profile-stat-item-label">
                {label}
            </div>
        </div>
    )
};

export default ProfileStatItem;
