import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { ORGANIZATION_PROFILE } from "../../../common/constants/LocalStorageConstants";
import { getEventById } from "../../../common/redux/actions/eventActions";
import { countEventPageView } from "../../../common/redux/actions/statisticAction";
import Loading from "../../modules/components/Loading/Loading";
import EventBanner from "../../modules/partials/EventBanner/EventBanner";
import "./EventDetail.scss";
import EventCTACard from "./partials/EventCTACard/EventCTACard";
import EventInfo from "./partials/EventInfo/EventInfo";
import ImageSlide from "./partials/ImageSlide/ImageSlide";
import RelatedEvents from "./partials/RelatedEvents/RelatedEvents";
import ReportEvent from "./partials/ReportEvent/ReportEvent";

const EventDetail = () => {
    const { setShowSidebar } = useOutletContext();
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const { event, loading } = useSelector((state) => state.event);
    const orgProfile = JSON.parse(sessionStorage.getItem(ORGANIZATION_PROFILE));
    const organizationEvent = orgProfile?.organizationId === event?.organization?.id;
    const [eventMedias, setEventMedias] = useState([])
    const ticketsRef = useRef(null);

    useLayoutEffect(() => {
        setShowSidebar(false);
    }, [setShowSidebar]);

    useEffect(() => {
        dispatch(getEventById({ eventId: eventId }));
        dispatch(countEventPageView({ eventId: eventId }));
    }, [eventId, dispatch]);

    useLayoutEffect(() => {
        document.title = `${event?.eventName} | Eventure`;
    }, [event]);

    useEffect(() => {
        if (event) {
            setEventMedias([event?.thumbnailUrl, ...event?.imageUrls])
        }
    }, [event]);

    const handleViewTickets = () => {
        if (ticketsRef) ticketsRef.current.scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <div className="event-detail-container">
            {loading
                ? <Loading/>
                : (
                    event && <div className="event-detail-content">
                        <EventBanner eventData={event} showAttend={true}/>
                        <div className="event-content-container">
                            <div className="event-content-right">
                                <ImageSlide images={eventMedias}/>
                                <EventInfo refEl={ticketsRef} eventData={event}/>
                            </div>
                            <div className="event-content-left">
                                <EventCTACard
                                    handleViewTickets={handleViewTickets}
                                    organizationEvent={organizationEvent}
                                    eventData={event}
                                />
                            </div>
                        </div>
                        <ReportEvent eventId={event.eventId}/>
                    </div>
                )}
            <div className="event-detail-related-events">
                <RelatedEvents event={event}/>
            </div>
        </div>
    );
};

export default EventDetail;
