import {
    CalendarCheck,
    ChatCentered, EnvelopeSimple, FacebookLogo,
    Globe,
    InstagramLogo,
    LinkedinLogo, Phone,
    Users,
    YoutubeLogo
} from "@phosphor-icons/react";
import { Button, Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import defaultAvatar3 from "../../../../assets/images/default_avatar_v3.jpg";
import "./OrganizerProfileHeader.scss";
import ButtonFollowOrg from "../../../../modules/components/ButtonFollowOrg";

const OrganizerProfileHeader = ({ organizer, startChatting, threadId, goToChatPage }) => {
    const { user } = useSelector((state) => state.auth);

    return (
        <div className="organizer-profile-header-container">
            <div className="organizer-profile-media">
                <div className={`organizer-profile-cover-img ${!organizer?.coverFileUrl && "no-cover-img"}`}>
                    {organizer?.coverFileUrl && <img src={organizer?.coverFileUrl} alt=""/>}
                </div>
                <div className="organizer-profile-follow">
                    <div className="organizer-profile-avatar-img">
                        <img src={organizer?.avatarFileUrl || defaultAvatar3} alt=""/>
                    </div>
                    <div className="organizer-profile-button">
                        {threadId ?
                            (<Button onClick={goToChatPage} type="default" icon={<ChatCentered/>}></Button>)
                            : (<Button onClick={startChatting} type="primary" icon={<ChatCentered/>}>
                                Chat with us
                            </Button>)}
                        {user && <ButtonFollowOrg organizer={organizer}/>}
                    </div>
                </div>
            </div>
            <div className="organizer-profile-info">
                <div className="organizer-profile-name">
                    {organizer.name}
                </div>
                <div className="organizer-profile-intro">
                    {organizer.bio}
                </div>
            </div>
            <div className="organizer-profile-stat">
                {organizer?.organizationContact?.phone &&
                    <div className="organizer-profile-stat-item organizer-profile-follower">
                        <div className="organizer-profile-stat-label">
                            <Phone/>
                        </div>
                        <p className="organizer-profile-stat-value">
                            {organizer?.organizationContact?.phone}
                        </p>
                    </div>}
                {organizer?.organizationContact?.email &&
                    <div className="organizer-profile-stat-item organizer-profile-follower">
                        <div className="organizer-profile-stat-label">
                            <EnvelopeSimple/>
                        </div>
                        <p className="organizer-profile-stat-value">
                            {organizer?.organizationContact?.email}
                        </p>
                    </div>}
                <div className="organizer-profile-stat-item organizer-profile-follower">
                    <div className="organizer-profile-stat-label">
                        <Users/>
                    </div>
                    <p className="organizer-profile-stat-value">
                        {organizer.numberOfFollowers} followers
                    </p>
                </div>
                <div className="organizer-profile-stat-item organizer-profile-event">
                    <div className="organizer-profile-stat-label">
                        <CalendarCheck/>
                    </div>
                    <p className="organizer-profile-stat-value">
                        {organizer.numberOfEvents} events
                    </p>
                </div>
            </div>
            <div className="organizer-profile-about">
                <div className="organizer-profile-about-title">
                    About
                </div>
                <div className="organizer-profile-about-content">
                    {organizer.description}
                </div>
            </div>
            <div className="organizer-profile-social">
                {organizer?.organizationContact?.facebook &&
                    <Tooltip title={`@Facebook: ${organizer?.organizationContact?.facebook}`}>
                        <Link
                            className="organizer-profile-social-link"
                            to={`https://facebook.com/${organizer?.organizationContact?.facebook}`}>
                            <FacebookLogo size={20}/>
                        </Link>
                    </Tooltip>}
                {organizer?.organizationContact?.instagram &&
                    <Tooltip title={`@Instagram: ${organizer?.organizationContact?.instagram}`}>
                        <Link className="organizer-profile-social-link" to="/organizer">
                            <InstagramLogo size={20}/>
                        </Link>
                    </Tooltip>}
                {organizer?.organizationContact?.youtube &&
                    <Tooltip title={`@Youtube: ${organizer?.organizationContact?.youtube}`}>
                        <Link className="organizer-profile-social-link" to="/organizer">
                            <YoutubeLogo size={20}/>
                        </Link>
                    </Tooltip>}
                {organizer?.organizationContact?.linkedin &&
                    <Tooltip title={`@Linkedin: ${organizer?.organizationContact?.linkedin}`}>
                        <Link className="organizer-profile-social-link" to="/organizer">
                            <LinkedinLogo size={20}/>
                        </Link>
                    </Tooltip>}
                {organizer?.organizationContact?.website &&
                    <Tooltip title={`Website: ${organizer?.organizationContact?.website}`}>
                        <Link className="organizer-profile-social-link" to="/organizer">
                            <Globe size={20}/>
                        </Link>
                    </Tooltip>}
            </div>
        </div>

    );
};

export default OrganizerProfileHeader;