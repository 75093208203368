import { Button, Form } from "antd";
import React, { useEffect } from "react";
import "./LoginForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { login, loginWithGoogle } from "../../../../../common/redux/actions/authActions";
import LoginFormFields from "../LoginFormFields/LoginFormFields";
import googleLogo from "./../../../../assets/icons/google-icon.svg";

function LoginForm() {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { user, error, loading } = useSelector((state) => state.auth);

    const onFinish = async (values) => {
        try {
            // eslint-disable-next-line
            const res = await dispatch(login(values)).unwrap()
        } catch (error) {
            toast.error("Invalid username or password!");
            console.error(error)
        }
    };

    useEffect(() => {
        if (user) {
            navigate("/", { replace: true });
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (error) {
        }
    }, [error]);

    const handleLoginWithGoogle = async () => {
        try {
            // eslint-disable-next-line
            const res = await dispatch(loginWithGoogle())
        } catch (e) {
            console.error(e)
        }
    }


    return (
        <div className="login-form-container">
            <div className="signIn-text">Sign in</div>
            <div className="signIn-description">
                <span className="text">New user?</span>
                <span className="signUp-link">
                    <Link to="../register" className="link">
                        Create an account
                    </Link>
                </span>
            </div>
            <div className="form-holder">
                <LoginFormFields
                    form={form}
                    onFinish={onFinish}
                    error={error}
                    loading={loading}
                />
                <div className="googleBtn">
                    <span className="continueText">Or continue with</span>
                    <Button
                        className="google-button"
                        block={true}
                        onClick={handleLoginWithGoogle}
                    >
                        <img className="google-icon" src={googleLogo} alt=""/>
                        Google
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;
