import { Button } from "antd";
import React, { useEffect, useState } from 'react';
import './PublishPage.scss';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { ORGANIZATION_PROFILE } from "../../../common/constants/LocalStorageConstants";
import { publishEvent, unPublishEvent } from "../../../common/redux/actions/eventActions";
import PreviewEventDetails from "./partials/PreviewEventDetails";

function PublishPage() {
    const { event, success, error, loading } = useSelector(state => state.event)
    // eslint-disable-next-line
    const [selectedOption, setSelectedOption] = useState("now");
    const dispatch = useDispatch();
    const { eventId } = useParams();
    const [isEventPublished, setIsEventPublished] = useState(false);
    const organization = JSON.parse(sessionStorage.getItem(ORGANIZATION_PROFILE));

    // eslint-disable-next-line
    const onChange = (e) => {
        setSelectedOption(e.target.value);
    };

    useEffect(() => {
        if (event) {
            setIsEventPublished(event?.isPublished)
        }
    }, [event, success, error]);

    const handlePublish = () => {
        const action = isEventPublished ? unPublishEvent : publishEvent;
        dispatch(action(eventId))
            .unwrap()
            .then(res => {
                if (res) {
                    if (isEventPublished) {
                        setIsEventPublished(false)
                        toast.success("Event has been unpublished")
                    } else {
                        setIsEventPublished(true)
                        toast.success("Event has been published")
                    }
                }
            })
            .catch(err => toast.error(error))
    }

    return (
        <div className={"publish-page-container"}>
            <div className="publish-page-header">
                <h1 className="publish-page-heading">Your Event is almost ready to publish</h1>
                <p className="publish-page-description">Review your settings and let everyone find your event.</p>
            </div>
            <PreviewEventDetails event={event} organization={organization}/>
            <footer className="action-handler">
                <div className="action-container">
                    <Button
                        className={"publish-button"}
                        onClick={handlePublish}
                        loading={loading}
                        type={"primary"}
                    >
                        {isEventPublished ? 'Unpublish' : 'Publish'}
                    </Button>
                </div>
            </footer>

        </div>
    );
}

export default PublishPage;