import { Bank, Wallet } from "@phosphor-icons/react";
import { Card, Col, Form, Row, Statistic, Tabs } from "antd";
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import "./OrganizationFinance.scss";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner";
import { ORGANIZATION_PROFILE } from "../../../common/constants/LocalStorageConstants";
import { DateTimePattern } from "../../../common/enums/DateTimePattern";
import { checkBankAccount, getBanksInfo, updateOrganizationBankInfo } from "../../../common/redux/actions/bankAction";
import { getTransactionsByOrganizationId } from "../../../common/redux/actions/transactionActions";
import { formatDateRange } from "../../../common/utils/dateTimeUtils";
import BankSetting from "./partials/BankSetting";
import OrganizationTransactions from "./partials/OrganizationTransactions";
import { useNavigate } from "react-router-dom";

function OrganizationFinance() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { banks } = useSelector((state) => state.bank);
    const navigate = useNavigate();
    const [banksData, setBanksData] = useState({
        banksInfo: null,
        bankAccount: "",
        selectedBank: null,
        bankAccountNumber: "",
        bankAccountName: ""
    })
    const { organization } = useSelector((state) => state.organization)
    const organizationData = organization || JSON.parse(sessionStorage.getItem(ORGANIZATION_PROFILE))
    const { transactions } = useSelector((state) => state.transaction);

    useLayoutEffect(() => {
        document.title = "Organization finance | Eventure";
    }, []);

    useEffect(() => {
        const getAllBanksInfo = async () => {
            try {
                await dispatch(getBanksInfo());
            } catch (error) {
                throw error;
            }
        }
        getAllBanksInfo();
    }, [dispatch]);

    useEffect(() => {
        const getAllTransactions = async () => {
            try {
                await dispatch(getTransactionsByOrganizationId({ organizationId: organizationData?.organizationId }));
            } catch (error) {
                throw error;
            }
        }
        getAllTransactions();
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (banks) {
            const banksInfo = banks.map((bank) => {
                return {
                    value: bank.id,
                    label: bank.shortName
                }
            })
            setBanksData({
                ...banksData,
                banksInfo
            })
        }
        // eslint-disable-next-line
    }, [banks, dispatch])

    const onBankChange = (values) => {
        const selectedBank = banks?.find(bank => bank.id === values);
        setBanksData(prevState => ({ ...prevState, selectedBank }));
    }

    const formatTransactionsData = (transactions) => {
        return transactions?.map(transaction => ({
            ...transaction,
            key: transaction.id,
            id: transaction.id.split('-')[0],
            createdDate: formatDateRange(transaction.createdDate, DateTimePattern.BASIC_I8N_DATE_TIME),
            totalAmount: `${transaction.totalAmount.toLocaleString()} VND`
        }));
    }
    
    const updateSessionStorage = (updatedData) => {
        const orgLocal = sessionStorage.getItem(ORGANIZATION_PROFILE);
        const existingData = orgLocal !== "undefined" ? JSON.parse(orgLocal) : null;
        const updatedOrgProfile = existingData ? { ...existingData, ...updatedData } : updatedData;
        
        sessionStorage.setItem(ORGANIZATION_PROFILE, JSON.stringify(updatedOrgProfile));
        
    };
    const updateBankInfo = () => {
        try {
            const orgId = organizationData?.organizationId;
            if (orgId) {
                dispatch(updateOrganizationBankInfo({
                    organizationId: orgId,
                    bankAccount: banksData?.bankAccountNumber,
                    bankName: banksData?.selectedBank?.shortName
                }))
                    .unwrap()
                    .then((res) => {
                        updateSessionStorage({
                            bankAccount: banksData?.bankAccountNumber,
                            bankName: banksData?.selectedBank?.shortName
                        })
                        toast.success(`Update bank info successfully`)
                        navigate(0)
                    })
            }
        } catch (error) {
            toast.error(`Update bank info successfully`)
            console.error('Error updating bank info:', error);
        }
    }

    const formattedData = useMemo(() => formatTransactionsData(transactions), [transactions]);


    const validateBankAccount = useMemo(() => {
        return async () => {
            try {
                if (banksData.selectedBank && banksData.bankAccountNumber) {
                    const inputBankInfo = {
                        bin: banksData.selectedBank.bin,
                        accountNumber: banksData.bankAccountNumber
                    };
                    const res = await dispatch(checkBankAccount(inputBankInfo)).unwrap();
                    if (res?.data.data) {
                        setBanksData(prevState => ({ ...prevState, bankAccountName: res.data.data.accountName }));
                    } else {
                        toast.error(res?.data.desc);
                    }
                }
            } catch (error) {
                console.error('Error validating bank account:', error);
            }
        };
    }, [banksData, dispatch]);

    const items = [
        {
            key: "transactions",
            label: "Transactions",
            children: <OrganizationTransactions data={formattedData}/>,
        },
        {
            key: "Bank",
            label: "Bank Setting",
            children: <BankSetting
                onBanksChange={onBankChange}
                banksInfo={banksData?.banksInfo}
                form={form}
                bankAccountName={banksData?.bankAccountName}
                setBankAccountNumber={(e) => setBanksData({ ...banksData, bankAccountNumber: e.target.value })}
                bankAccountNumber={banksData?.bankAccountNumber}
                validateBankAccount={validateBankAccount}
                onSubmit={updateBankInfo}
                organization={organizationData}
            />,
        },
    ];

    return (
        <div className={"organization-finance-page"}>
            <div className="organization-finance-container">
                <div className="finance-header">
                    <h1 className="finance-heading">Finance</h1>
                </div>
                <div className="finance-cards">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card bordered={true}>
                                <Statistic
                                    title="Total earning"
                                    value={`${organizationData?.accountBalance.toLocaleString()} VND`}
                                    precision={2}
                                    valueStyle={{ color: '#147451' }}
                                    prefix={<Wallet size={24} weight="fill"/>}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card bordered={true}>
                                <Statistic
                                    title="Bank account"
                                    value={organizationData?.bankAccount ? `${organizationData.bankAccount} - ${organizationData.bankName}` : '-'}
                                    prefix={<Bank size={25}/>}
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="finance-tabs">
                    <Tabs
                        defaultActiveKey="transactions"
                        items={items}
                    />
                </div>
            </div>
        </div>
    );
}

export default OrganizationFinance;