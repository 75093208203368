import React from "react";
import { EventType } from "../../../../../common/enums/Event";
import { EventAddress } from "../../components/EventAddress/EventAddress";
import { EventAgenda } from "../../components/EventAgenda/EventAgenda";
import { EventCategories } from "../../components/EventCategories/EventCategories";
import { EventDescription } from "../../components/EventDesription/EventDescription";
import { EventFAQ } from "../../components/EventFAQ/EventFAQ";
import { EventOrganizer } from "../../components/EventOrganizer/EventOrganizer";
import { EventSummary } from "../../components/EventSummary/EventSummary";
import { EventTags } from "../../components/EventTags/EventTags";
import { SectionTitle } from "../../components/SectionTitle/SectionTitle";
import { TicketOption } from "../../components/TicketOption/TicketOption";
import "./EventInfo.scss";

const EventInfo = ({ refEl, eventData }) => {
    const {
        summary,
        categories,
        description,
        tickets,
        location,
        organization,
        faqs,
        agendas,
        tags,
        eventType,
    } = eventData;

    return (
        <div className="event-desc-container">
            {tags && (
                <div className="event-desc-section event-desc-section-tags">
                    <EventTags eventTags={tags}/>
                </div>
            )}
            {summary && (
                <div className="event-desc-section event-desc-section-summary">
                    <EventSummary eventSummary={summary}/>
                </div>
            )}
            {categories && (
                <div className="event-desc-section event-desc-section-categories">
                    <EventCategories eventCategories={categories}/>
                </div>
            )}
            {description && (
                <div className="event-desc-section event-desc-section-info">
                    <SectionTitle text="About event"/>
                    <div className="event-desc-section-detail section-detail-info">
                        <EventDescription eventDescription={description}/>
                    </div>
                </div>
            )}
            {agendas?.length > 0 && (
                <div className="event-desc-section event-desc-section-agenda">
                    <SectionTitle text="Agenda"/>
                    <div className="event-desc-section-detail section-detail-agenda">
                        <EventAgenda eventAgenda={agendas}/>
                    </div>
                </div>
            )}
            {location && (
                <div className="event-desc-section event-desc-section-map">
                    <SectionTitle text="Address"/>
                    <div className="event-desc-section-detail section-detail-address">
                        <EventAddress location={location}/>
                    </div>
                </div>
            )}
            {eventType === EventType.ONLINE && (
                <div className="event-desc-section event-desc-section-map">
                    <SectionTitle text="Location"/>
                    <div className="event-desc-section-detail section-detail-address">
                        {/*<EventAddress location={location}/>*/}
                        Online
                    </div>
                </div>
            )}
            {tickets?.length > 0 && (
                <div ref={refEl}
                     id="event-tickets"
                     className="event-desc-section event-desc-section-ticket event-tickets"
                >
                    <SectionTitle text="Tickets"/>
                    <div className="event-desc-section-detail section-detail-ticket">
                        {tickets
                            .slice()
                            .sort((a, b) => a.price - b.price)
                            .map((item) => (
                                <TicketOption
                                    key={item?.id}
                                    title={item.title}
                                    description={item?.description}
                                    startDate={item.startDateAvailable}
                                    dueDate={item.endDateAvailable}
                                    price={item.price}
                                    isSoldOut={item?.soldQuantity === item?.totalQuantity}
                                    slot={item.totalQuantity - item.soldQuantity}
                                />
                            ))}
                    </div>
                </div>
            )}
            {
                faqs?.length > 0 && (
                    <div className="event-desc-section event-desc-section-faq">
                        <SectionTitle text="FAQs"/>
                        <div className="event-desc-section-detail section-detail-faq">
                            <EventFAQ eventFAQ={faqs}/>
                        </div>
                    </div>
                )
            }
            {organization && (
                <div className="event-desc-section event-desc-section-organizer">
                    <SectionTitle text="Organizer"/>
                    <div className="event-desc-section-detail section-detail-organizer">
                        <EventOrganizer organization={organization}/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventInfo;
