import React from "react";
import ImageGallery from "react-image-gallery";
import "./ImageSlide.scss";

const ImageSlide = ({ images }) => {
    images = images
        ? images.length > 1
            ? images.map((image) => ({
                original: image,
                thumbnail: image,
            }))
            : images.map((image) => ({
                original: image,
            }))
        : [];

    return (
        <div className="image-slide-container">
            <ImageGallery
                items={images}
                showPlayButton={false}
                slideDuration={120}
            />
        </div>
    );
};

export default ImageSlide;