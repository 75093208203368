import { useMeeting } from "@videosdk.live/react-sdk";
import "./MeetingIdCopyButton.scss";

const MeetingIdCopyButton = () => {
    const { meetingId } = useMeeting();
    return (
        <div className="meeting-id-copy-button-container">
            {meetingId}
        </div>
    );
};

export default MeetingIdCopyButton;