import { Constants } from "@videosdk.live/react-sdk";

export const VideosdkModel = {
    token: null,
    participantName: "",
    meetingId: null,
    sessionId: null,
    meetingMode: Constants.modes.CONFERENCE,
    micOn: false,
    selectedMic: { id: null },
    micDeviceId: null,
    webcamOn: false,
    selectedWebcam: { id: null },
    webcamDeviceId: null,
    isMeetingStarted: false,
    isMeetingLeft: false,
    loading: null,
    success: null,
    error: null,
};
