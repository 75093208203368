import { CalendarDots } from "@phosphor-icons/react";
import React from "react";
import "./NoDataFound.scss";

const NoDataFound = ({ icon, message }) => {
    return (
        <div className="no-data-container">
            {icon}
            <span>{message}</span>
        </div>
    )
}

export default NoDataFound;