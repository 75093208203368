import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getUserProfile } from "../../../../../common/redux/actions/accountAction";
import Loading from "../../../../modules/components/Loading/Loading";
import "./PersonalProfile.scss";
import ProfileAvatar from "../../components/ProfileAvatar/ProfileAvatar";
import ProfileInfo from "../../components/ProfileInfo/ProfileInfo";

const PersonalProfile = () => {
    const { setActiveItem } = useOutletContext();
    const dispatch = useDispatch();
    const { account, loading } = useSelector((state) => state.account);
    const { user } = useSelector((state) => state.auth);

    useLayoutEffect(() => {
        document.title = "Settings - Profile";
        setActiveItem("profile");
    });

    useEffect(() => {
        const username = user?.id;
        if (username) {
            const params = { username: username };
            dispatch(getUserProfile(params));
        }
    }, [dispatch, user]);

    return (
        <div className="account-section section-profile">
            {loading
                ? <Loading/>
                : (account && Object.keys(account).length > 0) &&
                <>
                    <ProfileAvatar account={account}/>
                    <ProfileInfo account={account}/>
                </>
            }
        </div>
    );
};

export default PersonalProfile;
