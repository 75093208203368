import React from "react";
import "./OrganizerCard.scss";
import { Link } from "react-router-dom";

const OrganizerCard = ({ organizer }) => {

    return (
        <div className="organizer-card-container">
            <div className="organizer-card-head">
                <div className="organizer-card-avatar">
                    <img src={organizer.avatarFileUrl} alt=""/>
                </div>
                <Link className="organizer-card-name" to={`/organizers/${organizer.id}`}>
                    {organizer.name}
                </Link>
                <div className="organizer-card-follower">
                    {organizer.numberOfFollowers} followers
                </div>
            </div>
        </div>
    );
};

export default OrganizerCard;