import React from 'react';
import "./ForgotPasswordLayout.scss"
import logo from "../../../assets/icons/logo.svg";
import {Link} from "react-router-dom";
import {Modal, Result} from "antd";


function ForgotPasswordLayout({heading, description, form,isModalOpen, handleCancelModel, status, modalTitle}) {
    return (
        <div className={"forgot-password-container"}>
            <div className="forgot-password-wrapper">
                <Link className="header-logo" to="/">
                    <img src={logo} alt=""/>
                </Link>
                <div className="forgot-password-heading">
                    <h1 className="forgot-password-heading-text">{heading}</h1>
                    <p className="forgot-password-description">{description}</p>
                </div>
                <div className="forgot-password-form-container">
                    {form}
                </div>
            </div>
            <Modal
                open={isModalOpen}
                footer={null}
                onCancel={handleCancelModel}
                centered
            >
                <Result
                    status={status}
                    title={modalTitle}
                />
            </Modal>
        </div>
    );
}

export default ForgotPasswordLayout;