import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import "./OrganizerList.scss";
import { getAllOrganizers } from "../../../common/redux/actions/organizationActions";
import Loading from "../../modules/components/Loading/Loading";
import OrganizationList from "../../modules/components/OrganizationList/OrganizationList";


const OrganizerList = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [orgList, setOrgList] = useState([]);
    const { setShowSidebar } = useOutletContext();
    const dispatch = useDispatch();
    const { organizations, hasMoreOrganizations, loading } = useSelector((state) => state.organization);

    useLayoutEffect(() => {
        document.title = "All organizations | Eventure";
        setShowSidebar(false);
    });

    const fetchOrganizers = (waitingTime) => {
        setTimeout(() => {
            const params = {
                pageNumber: pageNumber,
                pageSize: 10
            };
            dispatch(getAllOrganizers(params));
            setPageNumber(pageNumber => pageNumber + 1);
        }, waitingTime);
    };

    useEffect(() => {
        fetchOrganizers(0);
    }, [dispatch]);

    useEffect(() => {
        if (organizations) {
            setOrgList([...orgList, ...organizations]);
        }
    }, [organizations]);

    return (
        <div className="organizations-page-container">
            <div className="organizations-page-head">
                <div className="organizations-page-title">
                    🔭 Your favorite organizers may be here!
                </div>
                <div className="organizations-page-desc">
                    Go look for an organization that suits you.
                </div>
            </div>
            {loading && <Loading/>}
            <InfiniteScroll
                dataLength={orgList.length}
                next={() => fetchOrganizers(1500)}
                hasMore={!!hasMoreOrganizations}
                loader={<Loading/>}
            >
                <OrganizationList organizations={orgList}/>
            </InfiniteScroll>
        </div>
    );
};

export default OrganizerList;
