import axiosInstance from "../api";

const channelService = {
    getAllCategory: async (data) => {
        try {
            return await axiosInstance.get(`/api/v1/organization/getAllCategory`, data);
        } catch (error) {
            throw error.response.data;
        }
    },
};

export default channelService;
