import React from "react";
import "./EventDescription.scss";

export const EventDescription = ({ eventDescription }) => {
    const description = { __html: eventDescription };

    return (
        <div className="event-description-container" dangerouslySetInnerHTML={description}/>
    );
};
