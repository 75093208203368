import { ArrowUpRight } from "@phosphor-icons/react";
import { Button, Tag } from "antd";
import React from "react";
import "./ReservationItem.scss";
import { Link } from "react-router-dom";
import { DateTimePattern } from "../../../../common/enums/DateTimePattern";
import { formatDateRange } from "../../../../common/utils/dateTimeUtils";
import defaultAvatar3 from "../../../assets/images/default_avatar_v3.jpg";

const ReservationItem = ({ reservation }) => {

    return (
        <div className="rsv-item-container">
            <div className="rsv-item-left">
                <div className="rsv-item-event-thumbnail">
                    <img src={reservation?.eventThumbnailUrl || defaultAvatar3} alt=""/>
                </div>

                <div className="rsv-item-content">
                    <div className="rsv-item-content-top">
                        <div className="rsv-item-event-time">
                            {formatDateRange(reservation?.eventTime, DateTimePattern.STANDARD_DATE_TIME)}
                        </div>
                        <Link to={`/events/${reservation?.eventId}`}
                              className="rsv-item-event-name">{reservation?.eventName}</Link>
                    </div>

                    <div className="rsv-item-time">
                        Reserved at <span className="highlight">
                        {formatDateRange(reservation?.reservationTime, DateTimePattern.STANDARD_DATE_TIME)}
                    </span>
                    </div>
                    {/*<div className="rsv-item-content-bottom">*/}
                    {/*    <div className="rsv-item-event-location">*/}
                    {/*        <p className="rsv-item-info-label">Location</p>*/}
                    {/*        <p className="rsv-item-info-value">*/}
                    {/*            {reservation?.eventLocation*/}
                    {/*                ? `${reservation?.eventLocation?.district}, ${reservation?.eventLocation?.province}`*/}
                    {/*                : "Online"}*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className="rsv-item-right">
                <div className="rsv-item-admission">
                    <div className="rsv-item-price">
                        {reservation?.totalAmount?.toLocaleString()} VND
                    </div>
                </div>
                <div className="rsv-item-total-ticket">
                    <Tag>{reservation.numberOfTickets} ticket{reservation.numberOfTickets > 1 ? "s" : ""}</Tag>
                    <Link to={`/reservations/${reservation?.id}`} className="rsv-item-open-btn">
                        <Button type="text" icon={<ArrowUpRight/>}></Button>
                    </Link>
                </div>
                {/*<div className="rsv-item-tag">*/}
                {/*    <Tag bordered={false} color={*/}
                {/*        reservation?.used === false*/}
                {/*            ? "blue"*/}
                {/*            : "green"*/}
                {/*    }>*/}
                {/*        {reservation?.used*/}
                {/*            ? capitalizeFirstLetter(TicketStatus.ADMITTED)*/}
                {/*            : capitalizeFirstLetter(TicketStatus.ACTIVE)}*/}
                {/*    </Tag>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default ReservationItem;