import { createAsyncThunk } from "@reduxjs/toolkit";
import mediaService from "../../services/mediaService";
import { UPLOAD_MEDIA, UPLOAD_MULTIPLE_MEDIA } from "../types/media";

export const uploadMedia = createAsyncThunk(
    UPLOAD_MEDIA,
    async (data, { rejectWithValue }) => {
        try {
            return await mediaService.uploadMedia(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);


export const uploadMultipleMedia = createAsyncThunk(
    UPLOAD_MULTIPLE_MEDIA,
    async (data, { rejectWithValue }) => {
        try {
            return await mediaService.uploadMultipleMedia(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);