import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvents } from "../../../../common/redux/actions/eventActions";
import EventListHorizontal from "../../components/EventListHorizontal/EventListHorizontal";
import "./MasterList.scss";
import Loading from "../../components/Loading/Loading";
import NoDataFound from "../../components/NoDataFound";

const MasterList = () => {
    const dispatch = useDispatch();
    const { events, loading} = useSelector((state) => state.event);

    const params = {
        username: null,
        startDate: null,
        endDate: null,
        categories: [],
        eventType: null,
        sortBy: "startDateTime",
        sortOrder: "ASC",
        pageSize: 24,
        pageNumber: 1
    };
    useEffect(() => {
        dispatch(getAllEvents(params));
    }, [dispatch]);

    return (
        <div className="master-list-container">
            <div className="master-list-content">
                {
                    loading || !events
                        ? <Loading/>
                        : (
                            (events && events.length > 0)
                                ? <EventListHorizontal events={events}/>
                                : <NoDataFound message="No data found!"/>
                        )
                }
            </div>
        </div>
    );
};

export default MasterList;
