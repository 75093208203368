import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import React, { useEffect, useState } from "react";
import "./OrgProfileAvatar.scss";
import { toast } from "sonner";
import { getBase64 } from "../../../../../common/utils/fileUtils";
import OrgProfileSectionHead from "../../components/OrgProfileSectionHead/OrgProfileSectionHead";


const OrgProfileAvatar = ({ setProfileImage, avatarUrl }) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    useEffect(() => {
        if (avatarUrl) {
            setImageUrl(avatarUrl)
        }
    }, [avatarUrl])

    const handleChange = async (info) => {
        setLoading(true)
        getBase64(info.file.originFileObj).then((res) => {
                if (res) {
                    setProfileImage(info?.file?.originFileObj);
                    setImageUrl(info.file.url || res);
                    setLoading(false);
                }
            }
        )
    };

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div style={{ marginTop: 8 }}>
                Upload avatar
            </div>
        </button>
    );

    const customRequest = async ({ file, onSuccess, onError }) => {
        try {
            setImageUrl(file)
            onSuccess();
            toast.success("Image uploaded successfully!");
        } catch (error) {
            console.error("Error uploading image:", error);
            onError();
            toast.error("Error uploading image");
        }
    };
    return (
        <div className="org-profile-section org-profile-avatar-container">
            <OrgProfileSectionHead
                title="Organizer profile image"
                description="This is the first image attendees will see at the top of your profile. Use a high quality square image."
            />
            <div className="org-profile-avatar-content">
                <div className="org-profile-avatar-img">
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        onChange={handleChange}
                        customRequest={customRequest}
                        accept={"image/png, image/jpeg, image/gif"}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                    width: '100%',
                                    objectFit: 'contain'
                                }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </div>
                {/*<Button icon={<PencilSimple/>}>Edit</Button>*/}
                {/*<Button>Remove</Button>*/}
            </div>

        </div>
    );
};

export default OrgProfileAvatar;
