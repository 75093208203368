import React from 'react';
import "./MessageList.scss"

function MessageList({ messages, mode }) {
    return (
        <div className="chat-container">
            {messages.map((message) => (
                <div
                    key={message.id}
                    aria-label={mode === message.sender ? 'sender' : 'receiver'}
                    className="message-content"
                >
                    <span className="content">{message.content}</span>
                </div>
            ))}
        </div>
    );
}

export default MessageList;