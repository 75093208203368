import { Table } from "antd";
import React, { useEffect, useState } from "react";

const SalesByTicketType = ({ tickets }) => {
    const [ticketList, setTicketList] = useState([]);

    useEffect(() => {
        if (tickets) {
            const ticketsData = tickets.map((ticket, index) => ({
                key: index,
                ticket_type: ticket?.ticketCode || "",
                price: ticket?.price,
                sold: ticket?.value,
                net_value: ticket?.net_value,
            }));
            setTicketList(ticketsData);
        }
    }, [tickets]);

    const columns = [
        {
            title: "Ticket type",
            dataIndex: "ticket_type",
            key: "ticket_type",
        },
        // {
        //     title: "Price",
        //     dataIndex: "price",
        //     key: "price",
        //     render: value => value?.toLocaleString()
        // },
        {
            title: "Sold",
            dataIndex: "sold",
            key: "sold",
        },
        // {
        //     title: "Net value",
        //     dataIndex: "net_value",
        //     key: "net_value",
        // },
    ];

    return (
        <div className="sales-by-ticket-type-container">
            {ticketList && <Table dataSource={ticketList} columns={columns}/>}
        </div>
    );
}

export default SalesByTicketType;