import React from "react";
import "./OrgProfileSectionHead.scss";


const OrgProfileSectionHead = ({ title, description }) => {
    return (
        <div className="org-profile-section-head">
            <div className="org-profile-section-title">
                {title}
            </div>
            <div className="org-profile-section-description">
                {description}
            </div>
        </div>
    );
};

export default OrgProfileSectionHead
