import { createAsyncThunk } from '@reduxjs/toolkit';
import contentService from "../../services/contentService";
import { GENERATE_EVENT_CONTENT, GENERATE_EVENT_FAQ } from "../types/content";

export const generateEventContent = createAsyncThunk(
    GENERATE_EVENT_CONTENT,
    async (data, { rejectWithValue }) => {
        try {
            return await contentService.generateEventContent(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const generateEventFAQ = createAsyncThunk(
    GENERATE_EVENT_FAQ,
    async (data, { rejectWithValue }) => {
        try {
            return await contentService.generateEventFAQ(data);
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);