import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    ADD_TICKET,
    DELETE_TICKET_BY_ID,
    GET_RESERVED_TICKETS_BY_EVENT_ID,
    GET_TICKET_BY_EVENT_ID, GET_TICKET_BY_ID,
    UPDATE_TICKET
} from "../types/ticket";
import ticketService from "../../services/ticketService";

export const addTicket = createAsyncThunk(
    ADD_TICKET,
    async (data, { rejectWithValue }) => {
        try {
            return await ticketService.addTicket(data)
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getTicketsByEventId = createAsyncThunk(
    GET_TICKET_BY_EVENT_ID,
    async (data, { rejectWithValue }) => {
        try {
            return await ticketService.getTicketsByEventId(data)
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const deleteTicketById = createAsyncThunk(
    DELETE_TICKET_BY_ID,
    async (id, { rejectWithValue }) => {
        try {
            return await ticketService.deleteTicketById(id)
        } catch (error) {
            if (error.response && error.response?.data) {
                return rejectWithValue(error.response?.data);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const updateTicket = createAsyncThunk(
    UPDATE_TICKET,
    async (request, { rejectWithValue }) => {
        try {
            return await ticketService.updateTicket(request)
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getReservedTicketsByEventId = createAsyncThunk(
    GET_RESERVED_TICKETS_BY_EVENT_ID,
    async (data, { rejectWithValue }) => {
        try {
            return await ticketService.getReservedTicketsByEventId(data)
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);

export const getTicketById = createAsyncThunk(
    GET_TICKET_BY_ID,
    async (data, { rejectWithValue }) => {
        try {
            return await ticketService.getTicketById(data)
        } catch (error) {
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);