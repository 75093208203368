const prefix = "recording";

export const GET_RECORDING_BY_EVENT_FOR_ORGANIZER = `${prefix}/getRecordingByMeetingRoom`;
export const GET_RECORDING_BY_EVENT_FOR_ATTENDEE = `${prefix}/getRecordingByEventId`;
export const UPDATE_RECORDING = `${prefix}/updateRecording`;
export const UPDATE_RECORDING_PRIVACY = `${prefix}/updateRecordingPrivacy`;
export const PROCESS_RECORDING = `${prefix}/processRecording`;
export const SEARCH_SCENES = `${prefix}/searchScenes`;
export const APPLY_SCENE_SEARCH = `${prefix}/applySceneSearch`;
export const UPDATE_EXTERNAL_RECORDING = `${prefix}/updateExternalRecording`;
export const SCHEDULE_SESSION = `${prefix}/scheduleSession`;